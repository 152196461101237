import React, { useContext, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import parseISO from 'date-fns/parseISO';
import { useIntl } from 'react-intl';
import StepTitle from '../StepTitle';
import StepSection from '../StepSection';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import UnitInformationForm from './UnitInformationForm';
import Translation from '../../../../../components/stateless/Translation';
import styles from '../styles.scss';
import { sizeMessageProps } from '../constants';

const propTypes = {
  facility: PropTypes.shape(),
  onCheckInDateChange: PropTypes.func.isRequired,
  onPricingTypeChange: PropTypes.func.isRequired,
};

const defaultProps = {
  facility: undefined,
};

const UnitInformation = ({
  availableDates,
  onCheckInDateChange,
  onPricingTypeChange,
  facility,
}) => {
  const intl = useIntl();
  const stepContext = useContext(CurrentStepContext);
  const scrollToRef = useRef(null);

  return (
    <div>
      <StepTitle stepNumber={CHECKOUT_STEPS.UNIT_INFORMATION}>
        {`${CHECKOUT_STEPS.UNIT_INFORMATION}.`}
        {' '}
        <Translation
          id="widgets.checkout.progress.step2"
          defaultMessage="Storage Unit"
        />
      </StepTitle>
      {
        stepContext.currentStep === CHECKOUT_STEPS.UNIT_INFORMATION
        && (
          <StepSection active>
            <UnitInformationForm
              facility={facility}
              onSubmit={stepContext.onStepSubmit}
              currentStep={stepContext.currentStep}
              nextStep={stepContext.isReservation
                ? CHECKOUT_STEPS.COMPLETE
                : CHECKOUT_STEPS.ACCOUNT_DETAILS
              }
              maxStep={stepContext.maxStep}
              formError={stepContext.formError}
              submitting={stepContext.submitting}
              availableDates={availableDates}
              onCheckInDateChange={onCheckInDateChange}
              onPricingTypeChange={onPricingTypeChange}
              formData={stepContext.checkoutData}
              isReservation={stepContext.isReservation}
              scrollToRef={scrollToRef}
            />
          </StepSection>
        )
      }
      {
        stepContext.currentStep !== CHECKOUT_STEPS.UNIT_INFORMATION
        && stepContext.maxStep > CHECKOUT_STEPS.UNIT_INFORMATION
        && (
          <StepSection showEditStepButton stepNumber={CHECKOUT_STEPS.UNIT_INFORMATION}>
            <Row>
              <Col xs={12}>
                <h5>
                  {get(facility, 'unitGroup.size')}
                  {' '}
                  <Translation
                    {...sizeMessageProps[get(facility, 'unitGroup.categoryName', '').toLowerCase()]}
                  />
                </h5>
                <div>
                  <Translation
                    id="widgets.checkout.checkInDate"
                    defaultMessage="Check-in Date:"
                  />
                  {' '}
                  {intl.formatDate(parseISO(get(stepContext, 'checkoutData.moveInDate')))}
                </div>
                <div>
                  <Translation
                    id="widgets.checkout.unit"
                    defaultMessage="Unit"
                  />
                  {' '}
                  {`#${get(stepContext, 'checkoutData.unit.name')}`}
                </div>
              </Col>
            </Row>
          </StepSection>
        )
      }
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  );
};

UnitInformation.propTypes = propTypes;
UnitInformation.defaultProps = defaultProps;

export default UnitInformation;
