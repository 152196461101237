// Format Marchex phone numbers on UK pages
const isEnGb = window.location.pathname.toLocaleLowerCase().startsWith('/en-gb');

if (isEnGb) {
  const targetSelector = '[data-marchex]';
  // Handle mutation events
  const handleMutation = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      // Check if mutation is a character data mutation (i.e., text content changed)
      if (mutation.type === 'characterData') {
        const targetNode = mutation.target.parentElement;
        if (targetNode && targetNode.matches(targetSelector)) {
          formatPhoneNumber(targetNode);
        }
      }
    });
  };

  // Format the phone number to 5 numbers space 6 numbers
  const formatPhoneNumber = (element) => {
    const textNodes = Array.from(element.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);
    const regex = /\d{3} \d{4} \d{4}/;

    textNodes.forEach((textNode) => {
      const currentValue = textNode.nodeValue.trim();
      if (regex.test(currentValue)) {
        const formattedValue = currentValue.replace(/([0-9]{3})\s([0-9]{2})([0-9]{2})\s([0-9]{4})/g, '$1$2 $3$4');
        textNode.nodeValue = formattedValue;
      }
    });
  };

  // Select the target node
  const targetNode = document.body; // You can choose a more specific target node if needed

  // Options for the observer (which mutations to observe)
  const observerOptions = { characterData: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(handleMutation);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, observerOptions);
}
