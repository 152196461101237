import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button as BaseButton } from 'react-bootstrap';
import { pure } from '../../../../../src/web/utils/react';
import style from './Button.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: undefined,
};

const Button = ({
  className,
  children,
  ...props
}) => (
  <BaseButton {...props} className={cx(style.root, className)}>
    {children}
  </BaseButton>
);


Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default pure()(Button);
