const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

const apiProtocol = process.env.VOYAGER_API_PROTOCOL;
const apiHost = process.env.VOYAGER_API_HOST;
const apiPort = process.env.VOYAGER_API_PORT;

const adminProtocol = process.env.VOYAGER_API_PROTOCOL;
const adminHost = process.env.VOYAGER_ADMIN_HOST;
const adminPort = process.env.VOYAGER_ADMIN_PORT;

const webProtocol = process.env.VOYAGER_WEB_PROTOCOL;
const webHost = process.env.VOYAGER_WEB_HOST;
const webPort = process.env.VOYAGER_WEB_PORT;

module.exports = Object.assign({
  host: process.env.VOYAGER_WEB_HOST || process.env.HOST || 'localhost',
  port: process.env.VOYAGER_WEB_PORT || process.env.PORT,
  apiUrl: `${apiProtocol}://${apiHost}:${apiPort}`,
  adminUrl: `${adminProtocol}://${adminHost}:${adminPort}`,
  webUrl: `${webProtocol}://${webHost}:${webPort}`,
  googleMaps: {
    apiKey: process.env.GOOGLE_API_KEY,
    version: 'quarterly',
  },
  app: {
    title: 'Voyager',
    head: {
      meta: [
        { charset: 'utf-8' },
      ],
    },
  },
}, environment);
