import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@canaveral/components';
import cx from 'classnames';
import styles from './styles.scss';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.string.isRequired,
  isAscending: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const defaultProps = {};

const SortButton = ({
  handleClick,
  isActive,
  isAscending,
  children,
}) => (
  <Button
    onClick={handleClick}
    context="bare"
    className={styles.sortButton}
  >
    {children}
    {isActive && (
    <span
      title={`sort ${isAscending ? 'descending' : 'ascending'}`}
      className={cx('fa', `fa-angle-${isAscending ? 'up' : 'down'}`, styles.sortArrow)}
    />
    )}
  </Button>
);

SortButton.propTypes = propTypes;
SortButton.defaultProps = defaultProps;

export default SortButton;
