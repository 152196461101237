import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LimitContext, BASE_LIMIT } from '../contexts/LimitContext';
import useQuery from '../../modules/react/hooks/useQuery';
import useDebounce from '../../modules/react/hooks/useDebounce';
import useCurrentBuilderPage from '../hooks/useCurrentBuilderPage';
import { HEADER_UPDATED_AT } from '../components/MediaGallerySelector/MediaGallerySelector';
import MediaGallerySelector from '../components/MediaGallerySelector';
import getPlaceholderMetadata from '../../modules/media/getPlaceholderMetadata';
import { DEFAULT_LOCALE } from '../../modules/i18n/locales';

const GET_MEDIA = gql`
  query GetAllMedia($filter: MediaFilterInput!, $first: Int!, $locale: String!, $sortParameters: MediaSortInput) {
    media(filter: $filter, first: $first, sortParameters: $sortParameters) {
      edges {
        node {
          id
          ...MediaGallerySelectorMedia
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }

  ${MediaGallerySelector.fragments.medium}
`;

const propTypes = {
  setSelectedMedium: PropTypes.func.isRequired,
  selectedMedium: PropTypes.shape({
    mediumId: PropTypes.string.isRequired,
  }),
  usedMedia: PropTypes.arrayOf(PropTypes.string),
  showTrash: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
};

const defaultProps = {
  selectedMedium: undefined,
  usedMedia: [],
};

const MediaGallerySelectorContainer = ({
  setSelectedMedium,
  selectedMedium,
  usedMedia,
  showTrash,
  showSearch,
  ...props
}) => {
  const [facilityId, setFacilityId] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [limit, setLimit] = useContext(LimitContext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const debouncedSearchInput = useDebounce(searchInput, 1000);
  const { locale } = useCurrentBuilderPage();

  const [activeSortHeader, setActiveSortHeader] = useState(HEADER_UPDATED_AT);
  const [isAscending, setIsAscending] = useState(false);
  const handleSort = (sortHeader) => {
    const isNewSortHeader = sortHeader !== activeSortHeader;
    if (isNewSortHeader) {
      setIsAscending(false);
      setActiveSortHeader(sortHeader);
    } else {
      setIsAscending(!isAscending);
    }
  };

  const isAscToSortDirection = isAsc => (isAsc ? 1 : -1);

  const { data, loading, fetchMore } = useQuery(GET_MEDIA, {
    variables: {
      filter: {
        showTrash,
        excludeIds: usedMedia,
        facilityId,
        fulltext: debouncedSearchInput,
      },
      first: BASE_LIMIT,
      locale: locale || DEFAULT_LOCALE,
      sortParameters: {
        sortBy: activeSortHeader,
        sortDirection: isAscToSortDirection(isAscending),
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.media && selectedMedium) {
      const found = data.media.edges.find(({ node }) => node.id === selectedMedium.id);
      if (!found) { setSelectedMedium(null); }
    }
  }, [data]);

  useEffect(() => {
    setLimit(BASE_LIMIT);
    setSelectedMedium();
  }, [facilityId, debouncedSearchInput]);

  useEffect(() => {
    setLimit(BASE_LIMIT);
    setFacilityId();
    setSearchInput('');
  }, [showTrash]);

  useEffect(() => {
    fetchMore({
      variables: {
        first: limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoadingMore(false);
        if (!fetchMoreResult) { return prev; }
        return {
          media: fetchMoreResult.media,
        };
      },
    });
  }, [limit]);

  const loadMore = () => {
    setIsLoadingMore(true);
    setLimit(limit + BASE_LIMIT);
  };

  let media;
  let totalCount;
  let hasNextPage;

  if (data && data.media) {
    media = data.media.edges.map(({ node }) => {
      const { altText, title } = getPlaceholderMetadata(node, locale);

      return {
        ...node,
        alt: altText,
        title,
      };
    });

    ({ totalCount, pageInfo: { hasNextPage } } = data.media);
  }

  return (
    <MediaGallerySelector
      facilityId={facilityId}
      hasNextPage={hasNextPage}
      isLoadingMore={isLoadingMore}
      loading={loading}
      loadMore={loadMore}
      media={media}
      searchInput={searchInput}
      selectedMedium={selectedMedium}
      setFacilityId={setFacilityId}
      setSearchInput={setSearchInput}
      setSelectedMedium={setSelectedMedium}
      showSearch={showSearch}
      totalCount={totalCount}
      handleSort={handleSort}
      isAscendingSort={isAscending}
      activeHeader={activeSortHeader}
      {...props}
    />
  );
};

MediaGallerySelectorContainer.propTypes = propTypes;
MediaGallerySelectorContainer.defaultProps = defaultProps;

export default MediaGallerySelectorContainer;
