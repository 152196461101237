import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { compose, pureWithState } from '../../../../../../src/web/utils/react';
import Translation from '../../../../stateless/Translation';
import styles from './styles.scss';

const Amenity = ({ amenity: { name, token, shortCode }, iconClassName }) => {
  const { locale, messages } = useIntl();
  const amenityToken = token || shortCode;

  const amenityName = amenityToken ? (
    <Translation
      id={`unit.amenity.${amenityToken}`}
      defaultMessage={name}
    />
  ) : `${name}*`;

  const tooltip = (
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip id="amenity-tooltip" className={styles.amenityTooltip}>
          <IntlProvider locale={locale} messages={messages}>
            <div className={styles.amenityTooltipContainer}>
              <div className={styles.amenityHeader}>
                {amenityName}
              </div>
              <div>
                {amenityToken
                  ? (
                    <Translation
                      id={`unit.amenity.description.${amenityToken}`}
                      defaultMessage={name}
                    />
                  )
                  : `${name}`
                }
              </div>
            </div>
          </IntlProvider>
        </Tooltip>
      )
      }
    >
      <span>
        <Translation id="unit.amenity.moreInfo" default="More info">
          {text => (
            <>
              <span className="sr-only">{text}</span>
              <i
                className={`${styles.tooltip} ${iconClassName} far fa-question-circle`}
                aria-hidden="true"
              />
            </>
          )}
        </Translation>
      </span>
    </OverlayTrigger>
  );

  return (
    <div className={styles.amenityContainer}>
      {amenityName}&nbsp;
      {tooltip}
    </div>
  );
};

Amenity.propTypes = {
  amenity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    token: PropTypes.string,
    short_code: PropTypes.string,
  }).isRequired,
  iconClassName: PropTypes.string,
};

Amenity.defaultProps = {
  iconClassName: undefined,
};

export default compose(
  pureWithState(),
)(Amenity);
