import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import startOfDay from 'date-fns/startOfDay';
import { utcToZonedTime } from 'date-fns-tz';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';

export const getISOStartOfDay = (dt = new Date()) => {
  dt.setHours(0, 0, 0, 0);
  return dt.toISOString();
};

// probably need to adjust this if we ever update date-fns
const sharedDateFormat = 'MMMM do, yyyy';
// this format doesn't get the lowercase am/pm with date-fns 2.0.0-beta.2
const pageBuilderLastSavedFormat = 'MMMM do yyyy, h:mm:ss a';

const listTimestampFormat = 'MM/dd/yyyy';

const reservationToolTipFormat = 'MM-dd-yyyy';

const sitemapDateFormat = 'yyyy-MM-dd';

export const formatMoveInDate = dt => format(new Date(parseInt(dt, 10)), sharedDateFormat);

export const formatBlogEntryTimestamp = dt => format(new Date(dt), sharedDateFormat);

export const getDateStringFromISO = str => (typeof str === 'string' ? str.slice(0, 10) : null);

export const formatShortDate = ({ date = new Date(), utc } = {}) => {
  if (utc) {
    return getDateStringFromISO(date.toISOString());
  }
  return format(date, 'yyyy-MM-dd');
};

export const formatPageBuilderLastSaved = (dt = new Date()) => {
  // see above format issue
  const pre = format(dt, pageBuilderLastSavedFormat);
  return pre.slice(0, -2) + pre.slice(-2).toLocaleLowerCase();
};

export const getUnixDate = (int) => {
  let result = new Date(int);
  if (Number.isNaN(result.getTime())) {
    result = new Date(parseInt(int, 10));
    if (Number.isNaN(result.getTime())) {
      return int;
    }
  }
  return result;
};

export const getTimeFromHour = (hour) => {
  const hours = Math.floor(hour);
  const minutes = (hour - Math.floor(hour)) * 60;
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const parseDateString = (str) => {
  const date = new Date(str);
  if (Number.isNaN(date.getTime())) {
    return new Date();
  }
  return date;
};

export const parseAndAddMonths = (str, n = 0) => addMonths(parseDateString(str), n);

export const checkWithinMonthOfDueDate = (rentMonth, nextDueDate) => {
  const monthLater = parseAndAddMonths(rentMonth, 1);
  const parsedNextDueDate = parseDateString(nextDueDate);
  return isBefore(monthLater, parsedNextDueDate);
};

export const getStartOfDayTZ = (timezone, date = new Date()) => {
  const tzDate = timezone
    ? utcToZonedTime(date, timezone)
    : date;
  return startOfDay(tzDate);
};

export const isBeforeTZ = (dateString, dateToCompare, timezone) => {
  const date = Object.prototype.toString.call(dateString) === '[object Date]' ? dateString : new Date(dateString);
  const tzDate = timezone
    ? utcToZonedTime(date, timezone)
    : date;
  return isBefore(tzDate, dateToCompare);
};

export const createDateAndAddDays = (daysToAdd = 0, date = new Date()) => addDays(date, daysToAdd);

export const formatTimestampForList = timestamp => format(new Date(timestamp), listTimestampFormat);

export const formatForReservationToolTip = date => format(date, reservationToolTipFormat);

export const formatForSitemap = timestamp => format(
  new Date(parseInt(timestamp, 10)), sitemapDateFormat,
);

export const getNextFullMonth = (str) => {
  const date = parseDateString(str);
  return addMonths(date, isFirstDayOfMonth(date) ? 0 : 1);
};
