import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import style from './MultiImageDrop.scss';
import { ImageDropzone, ImageProgress } from '.';

/**
 * This isn't quite a "generic" multi-image drop. This is a "grid-style" which enforces
 * an expected number of drop zones... Perhaps this should be renamed to GridImageDrop
 * or something similar?
 */
export default class MultiImageDrop extends React.Component {
  static propTypes = {
    fileKey: PropTypes.string,
    autoUpload: PropTypes.bool,
    uploadAsset: PropTypes.func.isRequired,
    clearAsset: PropTypes.func.isRequired,
    currentFile: PropTypes.object,
    onUpload: PropTypes.func,
    uploading: PropTypes.bool.isRequired,
    uploadPercent: PropTypes.number,
    error: PropTypes.string,
    uploadSuccess: PropTypes.bool,
  };

  static defaultProps = {
    autoUpload: true,
  }

  componentDidUpdate(prevProps) {
    const { currentFile, onUpload, uploadSuccess } = this.props;
    const { uploadSuccess: prevSuccess } = prevProps;

    if (!prevSuccess && uploadSuccess && currentFile.file) {
      onUpload(currentFile);
    }
  }

  componentWillUnmount() {
    this.props.clearAsset();
  }

  handleDrop = (files) => {
    const { autoUpload } = this.props;

    if (autoUpload) {
      this.props.uploadAsset(files[0]);
    }
  }

  render() {
    const { error, uploading, uploadPercent } = this.props;

    return (
      <div>
        <div className={style.drop}>
          <ImageDropzone handleDrop={this.handleDrop}>
            Drag and drop your image or click here to select images.
          </ImageDropzone>
          {uploading &&
            <ImageProgress percent={uploadPercent} />
          }
        </div>
        {error &&
          <div className={style.alert}>
            <Alert bsStyle="danger">{error}</Alert>
          </div>
        }
      </div>
    );
  }
}
