import { EN_GB } from 'shared/i18n';
import { couponPath } from '../../constants/routes';

export function phone(val, locale) {
  if (locale === EN_GB) return val;

  const numbers = val.replace(/([^\d]+)/gi, '');
  // todo: This is not robust, fix it!
  const ext = numbers.length > 10 ? ` x ${numbers.slice(10)}` : '';

  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}${ext}`;
}

export function printCouponPath(coupon, facilityId) {
  return `${couponPath}${encodeURIComponent(JSON.stringify({ ...coupon, facilityId }))}`;
}

export function slugFromAddress(address, uuid) {
  let slug = uuid;

  if (address) {
    const {
      address1, address2, city, state, postal,
    } = address;
    slug = [address1, address2, city, state, postal].filter(part => part).join('-');
  }

  return slugFromText(slug);
}

export function slugFromText(text) {
  let slug;
  // replace white space with a dash
  slug = text.replace(/\s+/g, '-');
  // remove the characters that are allowed in URLs but forbidden in our settings
  slug = slug.replace(/[$.+!*'(),]/g, '');
  // url encode it
  slug = encodeURIComponent(slug);
  // lowercase the whole thing
  slug = slug.toLowerCase();
  return slug;
}

export function titleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, substr => substr.toUpperCase());
}

export function urlify(str, replace = '-') {
  return str
    .replace(/(^[^a-z0-9]+|[^a-z0-9]+$)/ig, '') // Strip off any characters at the beginning or end we don't want
    .replace(/[^a-z0-9]+/ig, replace)
    .toLowerCase();
}
