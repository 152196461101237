import { getItem } from './localStorage';

// eslint-disable-next-line id-length
const ALGOLIA_INSIGHTS_CONVERSION_KEY = 'algolia-insights-conversion';

export const getQueryId = (storeId) => {
  const data = getItem(ALGOLIA_INSIGHTS_CONVERSION_KEY);
  const queryID = data && data[storeId] && data[storeId].queryID;

  return queryID;
};
