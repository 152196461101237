import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Button from '../../../../modules/react/components/stateless/Button';
import classes from './ConfirmationPopover.scss';

class ConfirmationPopover extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    placement: PropTypes.string,
    confirmation: PropTypes.node,
    cancel: PropTypes.node,
    confirm: PropTypes.node,
    children: PropTypes.node,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  static defaultProps = {
    title: 'Are You Sure?',
    placement: 'left',
    confirmation: 'Are you sure you want to continue?',
    cancel: 'Cancel',
    confirm: 'Confirm',
  };

  overlay = null;

  hide(cb = null) {
    if (this.overlay) {
      this.overlay.hide();
    }

    if (cb) {
      cb();
    }
  }

  registerOverlayRef = (ref) => {
    this.overlay = ref;
  }

  handleCancel = () => {
    this.hide(this.props.onCancel);
  };

  handleConfirmation = () => {
    this.hide(this.props.onConfirm);
  };

  renderOverlay() {
    const {
      id, title, confirmation, cancel, confirm,
    } = this.props;

    return (
      <Popover id={id} title={title} className={classes.popover}>
        {confirmation}
        <div className={classes.actions}>
          <Button bsStyle="link" onClick={this.handleCancel}>{cancel}</Button>
          <Button bsStyle="primary" onClick={this.handleConfirmation}>{confirm}</Button>
        </div>
      </Popover>
    );
  }

  render() {
    const { placement, children } = this.props;

    return (
      <OverlayTrigger
        trigger="click"
        placement={placement}
        overlay={this.renderOverlay()}
        ref={this.registerOverlayRef}
      >
        {children}
      </OverlayTrigger>
    );
  }
}

export default ConfirmationPopover;
