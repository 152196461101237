import React from 'react';
import { LoadingIndicator as CanaveralLoadingIndicator } from '@canaveral/components';
import styles from './styles.scss';

const propTypes = {};

const defaultProps = {};

const LoadingIndicator = () => (
  <CanaveralLoadingIndicator
    className={styles.loadingIndicator}
  />
);

LoadingIndicator.propTypes = propTypes;
LoadingIndicator.defaultProps = defaultProps;

export default LoadingIndicator;
