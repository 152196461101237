import React from 'react';
import { Col, Row } from 'react-bootstrap';
import RadioInput from 'components/form/inputs/Radio/Radio';
import RadioButtonsInput from 'components/form/inputs/Radio/Radio';
import ColorPickerInput from 'components/form/inputs/ColorPicker/ColorPicker';
import TextBox from 'components/form/inputs/TextBox/TextBox';
import Field from '../../../../../modules/react/components/stateful/Field';
import SelectInput from '../../../../../modules/react/components/stateful/SelectInput';
import classes from '../../Section/Section.scss';
import editModal from './editModal';
import BackgroundStylesEditor from './Editors/BackgroundStylesEditor';

export const OPTION_COLOR = 'color';
export const OPTION_PATTERN = 'pattern';

const COLOR_TRANSPARENT = 'transparent';

const additionalColors = [
  { value: COLOR_TRANSPARENT, label: 'None' },
  { value: '#f8f8f8', label: 'Light gray' },
];

const contentOptions = {
  [COLOR_TRANSPARENT]: 'Content area is the same as background',
  [OPTION_COLOR]: 'Content area is a different background color',
  '#fff': 'Make content area white',
};

export const POSITION_NONE = 'none';
const POSITION_FULL = 'full';
export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';

const backgroundOverlayOptions = {
  [POSITION_NONE]: 'No background overlay',
  [POSITION_FULL]: 'Full background overlay',
  [POSITION_LEFT]: 'Background overlay left side',
  [POSITION_RIGHT]: 'Background overlay right side',
};

// Convert falsy values to transparent
const formatColor = value => value || COLOR_TRANSPARENT;
// Convert transparent to null
const normalizeColor = value => value === COLOR_TRANSPARENT ? null : value; // eslint-disable-line no-confusing-arrow

export default (editorProps = () => {}, tabs = []) => editModal(
  (props) => {
    const inherited = editorProps(props) || {};

    return {
      title: inherited.title || 'Background Editor',
      ...inherited,
    };
  },
  (props) => {
    return tabs.concat([{
      name: 'Background Styles',
      children: (
        <BackgroundStylesEditor sectionStyle={props.sectionStyle} />
      ),
    }, {
      name: 'Content Styles',
      children: (
        <div>
          <Field
            label="Content area behavior"
            name="sectionStyle.contentBackground"
            component={RadioInput}
            options={contentOptions}
            format={formatColor}
            normalize={normalizeColor}
          />
          <Field
            label="Content Background Color"
            component={ColorPickerInput}
            name="sectionStyle.contentBackgroundColor"
            allowCustom
            additionalColors={additionalColors}
          />
          <Field
            label="Background Overlay"
            name="sectionStyle.backgroundOverlay"
            component={RadioInput}
            options={backgroundOverlayOptions}
            format={formatColor}
            normalize={normalizeColor}
          />
          <Field
            label="Background Overlay Color"
            component={ColorPickerInput}
            name="sectionStyle.backgroundOverlayColor"
            allowCustom
            additionalColors={additionalColors}
          />
          <Field
            label="Background Overlay Opacity"
            component={TextBox}
            name="sectionStyle.backgroundOverlayOpacity"
            addOn="%"
          />
          <Field
            label="Section Padding top"
            name="sectionStyle.sectionPaddingTop"
            component={TextBox}
            addOn="px"
          />
          <Field
            label="Section Padding bottom"
            name="sectionStyle.sectionPaddingBottom"
            component={TextBox}
            addOn="px"
          />
          <Field
            label="Content Padding top"
            name="sectionStyle.contentPaddingTop"
            component={TextBox}
            addOn="px"
          />
          <Field
            label="Content Padding bottom"
            name="sectionStyle.contentPaddingBottom"
            component={TextBox}
            addOn="px"
          />
          <Field
            label="Section ID (anchor linking)"
            name="sectionStyle.id"
            component={TextBox}
            prefixAddOn="#"
          />
        </div>
      ),
    }]);
  },
);
