import React from 'react';
import styles from './styles.scss';
import SMFormSubmitButton from '../../../../components/stateless/SMFormSubmitButton';

const CheckoutSubmitButton = ({ ...props }) => (
  <SMFormSubmitButton
    className={styles.checkoutSubmitButton}
    {...props}
  />
);

export default CheckoutSubmitButton;
