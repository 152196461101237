import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useEventListener from 'modules/react/hooks/useEventListener';
import useOnClickOutside from 'modules/react/hooks/useOnClickOutside';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { widgetStopSwitching, getWidgetSwitchingId } from 'store/modules/builder';
import Icon from '../../../../modules/react/components/stateless/Icon';
import styles from './ContextMenu.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      iconClass: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      data: PropTypes.any,
    }),
  ).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  clearFix: PropTypes.bool,
  widgetSwitchingId: PropTypes.string,
  onSwitchWidgets: PropTypes.func,
  onStopWidgetSwitch: PropTypes.func,
  rawId: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  style: undefined,
  clearFix: true,
};

const ContextMenu = ({
  children,
  className,
  clearFix,
  heading,
  id,
  menuItems,
  onSwitchWidgets,
  onStopWidgetSwitch,
  rawId,
  style,
  widgetSwitchingId,
}) => {
  const contextRef = useRef();
  const [node, setNode] = useState();
  const [position, setPosition] = useState(null);
  const areaRef = useOnClickOutside(() => setPosition(null), position);

  useEventListener('keydown', (event) => {
    if (!(event.keyCode === 27 || event.key === 'Escape')) {
      return;
    }

    setPosition(null);

    if (widgetSwitchingId) {
      onStopWidgetSwitch();
    }
  }, position);

  useEffect(() => {
    setNode(document.body);

    return () => {
      if (widgetSwitchingId) {
        onStopWidgetSwitch();
      }
    };
  }, []);

  const onClickContextArea = (event) => {
    if (widgetSwitchingId && widgetSwitchingId !== rawId) {
      onSwitchWidgets(widgetSwitchingId, rawId);
      onStopWidgetSwitch();
      return;
    }

    if (widgetSwitchingId && widgetSwitchingId === rawId) {
      onStopWidgetSwitch();
      return;
    }

    const { nativeEvent: { clientX, clientY } } = event;

    let x = clientX;
    let y = clientY;

    // See if we're near the edges of the display and need adjusting.
    const deltaX = document.documentElement.clientWidth - clientX;
    const deltaY = document.documentElement.clientHeight - clientY;

    const width = contextRef.current.offsetWidth;
    const height = contextRef.current.offsetHeight;

    if (deltaX < width) {
      x -= width;
    }

    if (deltaY < height) {
      y -= height;
    }

    setPosition({ x, y });
  };

  const onClickContextMenu = event => event.stopPropagation();

  const onSelectMenuItem = (index) => {
    const menuItem = menuItems[index];
    if (!menuItem) {
      return;
    }

    menuItem.action(menuItem.data);

    setPosition(null);
  };

  const contextAreaClasses = cx(
    className,
    clearFix && 'clearfix',
    position && 'open',
  );

  const contextMenuClasses = cx(
    styles.wording,
    'dropdown-menu pb-tool',
  );

  const contextMenuStyle = {
    display: 'block',
    position: position ? 'absolute' : 'fixed',
    visibility: position ? 'visible' : 'hidden',
    left: position && position.x,
    top: position && position.y,
  };

  return (
    <div
      className={contextAreaClasses}
      onClick={onClickContextArea}
      ref={areaRef}
      style={style}
    >
      {children}
      {node && ReactDOM.createPortal(
        <ul
          className={contextMenuClasses}
          onClick={onClickContextMenu}
          ref={contextRef}
          style={contextMenuStyle}
        >
          <MenuItem
            className={styles.header}
            header
          >
            {heading}
          </MenuItem>
          {menuItems.map(({ label, iconClass, action }, index) => (
            action ? (
              <MenuItem
                key={`context-menu-item-${id}-${index}`}
                eventKey={index}
                onSelect={onSelectMenuItem}
                className={styles.menuItem}
              >
                {iconClass && <Icon id={iconClass} label={label} />}
                {' '}
                {label}
              </MenuItem>
            ) : (
              <div
                key={`context-menu-item-${id}-${index}`}
                className={styles.menuItem}
              >
                {label}
              </div>
            )
          ))}
        </ul>,
        node,
      )}
    </div>
  );
};

ContextMenu.propTypes = propTypes;
ContextMenu.defaultProps = defaultProps;

export default connect(
  state => ({
    widgetSwitchingId: getWidgetSwitchingId(state),
  }),
  dispatch => (
    bindActionCreators({
      onStopWidgetSwitch: widgetStopSwitching,
    }, dispatch)
  ),
)(ContextMenu);
