import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'react-bootstrap';
import { compose } from 'utils/react';
import { fromNamed as translateColor } from 'utils/colors';
import { getColors } from 'store/modules/site';
import classNames from 'classnames';
import gql from 'graphql-tag';
import get from 'lodash/get';
import {
  OPTION_PATTERN,
  POSITION_NONE,
  POSITION_LEFT,
  POSITION_RIGHT,
} from '../sections/decorators/stylesModal';
import styles from './Section.scss';
import useQuery from '../../../../modules/react/hooks/useQuery';

function prepareSectionClassName(backgroundStyle, backgroundOverlay, className) {
  const classes = {
    [styles.section]: true,
    [className]: true,
    [styles.patternDoors]: backgroundStyle === OPTION_PATTERN,
    [styles.backgroundOverlay]: backgroundOverlay && backgroundOverlay !== POSITION_NONE,
    [styles.backgroundOverlayLeft]: backgroundOverlay && backgroundOverlay === POSITION_LEFT,
    [styles.backgroundOverlayRight]: backgroundOverlay && backgroundOverlay === POSITION_RIGHT,
  }

  return classNames(classes);
}

export const GET_MEDIUM = gql`
  query GetMedium($id: ID, $filename: String) {
    medium(id: $id, filename: $filename) {
      id
      filename
      url
      placeholder
      srcSet
      sourceWidth
      sourceHeight
      trash
      defaultMetadata {
        title
        altText
      }
      metadata {
        title
        altText
      }
    }
  }
`;

const Section = ({
  children,
  className,
  backgroundColor,
  contentBackground,
  contentBackgroundColor,
  backgroundStyle,
  inlineStyle,
  sectionPaddingTop,
  sectionPaddingBottom,
  contentPaddingTop,
  contentPaddingBottom,
  backgroundMediumId,
  backgroundOverlay,
  backgroundOverlayColor,
  backgroundOverlayOpacity,
  id,
}) => {
  const { data: mediumData } = useQuery(GET_MEDIUM, {
    variables: {
      id: backgroundMediumId,
    },
  });

  const sectionStyles = {
    paddingTop: sectionPaddingTop ? `${sectionPaddingTop}px` : undefined,
    paddingBottom: sectionPaddingBottom ? `${sectionPaddingBottom}px` : undefined,
    '--backgroundOverlayColor': backgroundOverlayColor,
    '--backgroundOverlayOpacity': backgroundOverlayOpacity ? `.${backgroundOverlayOpacity}` : undefined,
    ...inlineStyle
  };

  if (backgroundStyle === 'image') {
    sectionStyles.backgroundImage = `url("${get(mediumData, 'medium.url')}")`;
    sectionStyles.backgroundSize = 'cover';
    sectionStyles.backgroundPosition = 'center';
  } else {
    sectionStyles.backgroundColor = backgroundColor;
  }

  const gridStyles = {
    backgroundColor: contentBackground === 'color' ? contentBackgroundColor : contentBackground,
    paddingTop: contentPaddingTop ? `${contentPaddingTop}px` : undefined,
    paddingBottom: contentPaddingBottom ? `${contentPaddingBottom}px` : undefined,
  };

  return (
    <div
      id={id}
      className={prepareSectionClassName(backgroundStyle, backgroundOverlay, className)}
      style={sectionStyles}
    >
      <Grid
        className={styles.grid}
        style={gridStyles}
      >
        {children}
      </Grid>
    </div>
  );
}
Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  contentBackground: PropTypes.string,
  backgroundStyle: PropTypes.string,
  inlineStyle: PropTypes.object,
};

export default compose(
  connect(
    (state, { backgroundColor, contentBackground, contentBackgroundColor }) => {
      const colors = getColors(state);
      return {
        backgroundColor: translateColor(colors, backgroundColor),
        contentBackground: translateColor(colors, contentBackground),
        contentBackgroundColor: translateColor(colors, contentBackgroundColor),
      };
    },
    null,
  ),
)(Section);
