import JSCookie from 'js-cookie';
import { UK_FORM_COOKIE, UK_FORM_COOKIE_EXPIRY } from 'modules/storagemart';

const GATE_QUOTE_QUERY_STRING_KEY = 'gatecomplete';

export function setGatedQuoteFormCookie() {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has(GATE_QUOTE_QUERY_STRING_KEY) && urlParams.get(GATE_QUOTE_QUERY_STRING_KEY) === 'true') {
      JSCookie.set(UK_FORM_COOKIE, true, { expires: UK_FORM_COOKIE_EXPIRY });
    }
  }
}
