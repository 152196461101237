import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from '../../../../../src/web/utils/react';
import HtmlSelect from './HtmlSelect';
import ReactSelect from './ReactSelect';

const propTypes = {
  htmlSelect: PropTypes.bool,
};

const defaultProps = {
  htmlSelect: false,
};

const Select = ({
  htmlSelect = false,
  ...props
}) => {
  if (htmlSelect) {
    return (<HtmlSelect {...props} />);
  }

  return (<ReactSelect {...props} />);
};

Select.propTypes = propTypes;

Select.defaultProps = defaultProps;

export default compose(
  pure(),
)(Select);
