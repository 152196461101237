
export const BLOG_CATEGORY_PAGE = 'voyager/pages/types/BLOG_CATEGORY_PAGE';
export const BLOG_HOME_PAGE = 'voyager/pages/types/BLOG_HOME_PAGE';
export const BLOG_PAGE = 'voyager/pages/types/BLOG_PAGE';
export const HOME_PAGE = 'voyager/pages/types/HOME_PAGE';
export const FACILITY_LINK = 'voyager/pages/types/FACILITY_LINK';
export const FACILITY_LISTING = 'voyager/pages/types/FACILITY_LISTING';
export const FACILITY_PAGE = 'voyager/pages/types/FACILITY_PAGE';
export const MISC_PAGE = 'voyager/pages/types/MISC_PAGE';
export const FACILITIES_LIST_PAGE = 'voyager/pages/types/FACILITIES_LIST_PAGE';
export const PRIVACY_PAGE = 'voyager/pages/types/PRIVACY_PAGE';
export const TERMS_PAGE = 'voyager/pages/types/TERMS_PAGE';
export const PAY_ONLINE_PAGE = 'voyager/pages/types/PAY_ONLINE_PAGE';
export const PAGE_LAYOUT = 'voyager/pages/types/PAGE_LAYOUT';

export const SM_AREA_PAGE = 'voyager/pages/types/SM_AREA_PAGE';
export const SM_COUNTRY_PAGE = 'voyager/pages/types/SM_COUNTRY_PAGE';
export const SM_HOME_PAGE = 'voyager/pages/types/SM_HOME_PAGE';
export const SM_INFO_HOME_ONE_PAGE = 'voyager/pages/types/SM_INFO_HOME_ONE_PAGE';
export const SM_INFO_HOME_TWO_PAGE = 'voyager/pages/types/SM_INFO_HOME_TWO_PAGE';
export const SM_HYPERLOCAL_PAGE = 'voyager/pages/types/SM_HYPERLOCAL_PAGE';
export const SM_FACILITY_PAGE = 'voyager/pages/types/SM_FACILITY_PAGE';
export const SM_INFO_DETAILS_PAGE = 'voyager/pages/types/SM_INFO_DETAILS_PAGE';
export const SM_MOVE_IN_PAGE = 'voyager/pages/types/SM_MOVE_IN';
export const SM_METRO_PAGE = 'voyager/pages/types/SM_METRO_PAGE';
export const SM_REGION_PAGE = 'voyager/pages/types/SM_REGION_PAGE';
export const SM_PPC_PAGE = 'voyager/pages/types/SM_PPC_PAGE';
export const SM_SEARCH_RESULTS_PAGE = 'voyager/pages/types/SM_SEARCH_RESULTS_PAGE';
export const SM_CONFIRM_PAGE = 'voyager/pages/types/SM_CONFIRM_PAGE';
export const SM_CONFIRM_CHECKOUT_PAGE = 'voyager/pages/types/SM_CONFIRM_CHECKOUT_PAGE';
export const SM_404_PAGE = 'voyager/pages/types/SM_404_PAGE';
export const SM_FAQ_HOMEPAGE = 'voyager/pages/types/SM_FAQ_HOMEPAGE';
export const SM_FAQ_DETAIL_PAGE = 'voyager/pages/types/SM_FAQ_DETAIL_PAGE';

// Manhattan Mini Storage Pages
export const MMS_HOME_PAGE = 'voyager/pages/types/MMS_HOME_PAGE';
export const MMS_FACILITY_PAGE = 'voyager/pages/types/MMS_FACILITY_PAGE';
export const MMS_AREA_PAGE = 'voyager/pages/types/MMS_AREA_PAGE';
export const MMS_PCC_PAGE = 'voyager/pages/types/MMS_PCC_PAGE';


export const uniquePageTypes = [
  HOME_PAGE,
  FACILITIES_LIST_PAGE,
  TERMS_PAGE,
  PRIVACY_PAGE,
  PAY_ONLINE_PAGE,
  BLOG_HOME_PAGE,
  BLOG_PAGE,
  BLOG_CATEGORY_PAGE,
  SM_SEARCH_RESULTS_PAGE,
  SM_404_PAGE,
  SM_HOME_PAGE,
];

export const PAGE_TYPE_ENUM = [
  BLOG_CATEGORY_PAGE,
  BLOG_HOME_PAGE,
  BLOG_PAGE,
  HOME_PAGE,
  FACILITY_LINK,
  FACILITY_LISTING,
  FACILITY_PAGE,
  MISC_PAGE,
  FACILITIES_LIST_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
  PAY_ONLINE_PAGE,
  PAGE_LAYOUT,
  SM_AREA_PAGE,
  SM_COUNTRY_PAGE,
  SM_HOME_PAGE,
  SM_INFO_HOME_ONE_PAGE,
  SM_INFO_HOME_TWO_PAGE,
  SM_HYPERLOCAL_PAGE,
  SM_FACILITY_PAGE,
  SM_INFO_DETAILS_PAGE,
  SM_MOVE_IN_PAGE,
  SM_METRO_PAGE,
  SM_REGION_PAGE,
  SM_PPC_PAGE,
  SM_SEARCH_RESULTS_PAGE,
  SM_CONFIRM_PAGE,
  SM_CONFIRM_CHECKOUT_PAGE,
  SM_404_PAGE,
  SM_FAQ_HOMEPAGE,
  SM_FAQ_DETAIL_PAGE,
  MMS_HOME_PAGE,
  MMS_FACILITY_PAGE,
  MMS_AREA_PAGE,
  MMS_PCC_PAGE,
];
