import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';
import styles from './styles.scss';

const propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

const defaultProps = {};

const SMTooltipPopover = ({
  body,
  title,
  ...rest
}) => (
  <Popover
    {...rest}
    className={styles.popover}
    id="sm-tooltip-popover"
    placement="bottom"
  >
    <div className={styles.title}>{title}</div>
    <div className={styles.body}>{body}</div>
  </Popover>
);

SMTooltipPopover.propTypes = propTypes;
SMTooltipPopover.defaultProps = defaultProps;

export default SMTooltipPopover;
