import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../../../src/web/utils/react';
import { resolveContentUrl } from '../../../../modules/assets';

const propTypes = {
  file: PropTypes.string.isRequired,
  fileProp: PropTypes.string,
  component: PropTypes.node,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
  fileProp: 'src',
  component: 'img',
  style: undefined,
};

const Asset = ({
  file, fileProp, component, style, ...rest
}) => {
  const href = resolveContentUrl(file);
  const newProps = { ...rest };

  // This might be a bad assumption, but let's assume that a request to put the image
  // in `style` means they want this image as a background image in the inline style
  if (fileProp === 'style') {
    newProps.style = {
      backgroundImage: href,
      ...(style || {}),
    };
  } else {
    newProps[fileProp] = href;
  }

  return React.createElement(component, newProps);
};

Asset.propTypes = propTypes;
Asset.defaultProps = defaultProps;

export default pure()(Asset);
