import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const defaultProps = {
  children: undefined,
};

const Svg = ({ children, ...rest }) => (
  <svg
    {...rest}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);

Svg.propTypes = propTypes;
Svg.defaultProps = defaultProps;

export default Svg;
