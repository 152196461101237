import React from 'react';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import styles from './styles.scss';
import SelectInput from '../../../../../../modules/react/components/stateful/SelectInput';
import Translation from '../../../../../components/stateless/Translation';

const generateYearsObject = () => {
  const currentYear = new Date().getFullYear();
  const yearsObject = {};

  for (let i = 0; i <= 21; i += 1) {
    const year = (currentYear + i).toString().slice(-2);
    yearsObject[year] = year;
  }

  return yearsObject;
};

const CheckoutFormExpirationDate = ({
  setFieldValue,
  errorClassName,
  invalidClassName,
}) => {
  const [monthField, monthMeta] = useField('creditCardExpireMonth');
  const [yearField, yearMeta] = useField('creditCardExpireYear');
  const monthError = monthMeta.touched && !isEmpty(monthMeta.error);
  const yearError = yearMeta.touched && !isEmpty(yearMeta.error);

  const fieldClasses = classNames(
    styles.checkoutFormExpirationDate,
    (monthError || yearError) && styles.error,
    (monthError || yearError) && invalidClassName,
    styles.required,
  );

  return (
    <div className={fieldClasses}>
      <div className={styles.label}>
        <div className={styles.text}>
          <Translation
            id="widgets.checkout.checkout.creditCardExpireDate"
            defaultMessage="Expire Date"
          />
        </div>
      </div>
      <div className={styles.expireDate}>
        <div>
          <SelectInput
            {...monthField}
            id="creditCardExpireMonth"
            name="creditCardExpireMonth"
            autoComplete="cc-exp-month"
            aria-label="Expire Month"
            required
            htmlSelect
            options={{
              1: 1,
              2: 2,
              3: 3,
              4: 4,
              5: 5,
              6: 6,
              7: 7,
              8: 8,
              9: 9,
              10: 10,
              11: 11,
              12: 12,
            }}
            emptyOption="MM"
            customOnChange={event => setFieldValue('creditCardExpireMonth', event.target.value)}
          />
        </div>
        <div role="presentation">/</div>
        <div>
          <SelectInput
            {...yearField}
            id="creditCardExpireYear"
            name="creditCardExpireYear"
            autoComplete="cc-exp-year"
            aria-label="Expire Year"
            required
            htmlSelect
            options={generateYearsObject()}
            emptyOption="YY"
            customOnChange={event => setFieldValue('creditCardExpireYear', event.target.value)}
          />
        </div>
      </div>
      {monthError && (
        <div className={classNames(styles.message, errorClassName)} role="alert">
          <Translation
            defaultMessage="Field is invalid"
            id={monthMeta.error}
          />
        </div>
      )}
      {yearError && (
        <div className={classNames(styles.message, errorClassName)} role="alert">
          <Translation
            defaultMessage="Field is invalid"
            id={monthMeta.error}
          />
        </div>
      )}
    </div>
  );
};

export default CheckoutFormExpirationDate;
