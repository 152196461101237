
const isLocalStorageSupported = (() => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return false;
  }

  const key = 'test';
  const storage = window.localStorage;
  const value = '1';
  try {
    storage.setItem(key, value);
    const testData = storage.getItem(key);
    storage.removeItem(key);

    return testData === value;
  } catch (_) {
    return false;
  }
})();

export function setItem(key, value) {
  if (isLocalStorageSupported) {
    try {
      const item = JSON.stringify(value);
      window.localStorage.setItem(key, item);
    } catch (_) {
      // this is fine to fail silently
    }
  }
}

export function getItem(key) {
  if (!isLocalStorageSupported) {
    return null;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (_) {
    return null;
  }
}
