import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import Translation from '../../../../components/stateless/Translation';

const propTypes = {
  id: PropTypes.string.isRequired,
  context: PropTypes.oneOf([
    'success',
    'warning',
    'danger',
    'info',
  ]),
  defaultMessage: PropTypes.string.isRequired,
};

const defaultProps = {
  context: 'info',
};

const SMFormFeedback = ({
  id,
  context,
  defaultMessage,
}) => (
  <Alert bsStyle={context}>
    <Translation
      id={id}
      defaultMessage={defaultMessage}
    />
  </Alert>
);

SMFormFeedback.propTypes = propTypes;
SMFormFeedback.defaultProps = defaultProps;

export default SMFormFeedback;
