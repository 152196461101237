import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { getQueryParams } from '../../../../../modules/search';
import useLazyQuery from '../../../../../modules/react/hooks/useLazyQuery';
import LoadingSkeleton from './LoadingSkeleton';
import StorageMartCheckoutTotalRecall from '../StorageMartCheckoutTotalRecall/legacy';
import StorageMartCheckoutStoredge from '../StorageMartCheckoutStoredge/legacy';
import { STOREDGE } from '../../../../../constants/softwareProviders';

export const GET_FACILITY = gql`
  query GetSMRentalSummaryData($id: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      currencySymbol
      brandName
      storeNumber
      address {
        country
      }
      settings {
        softwareProvider
        valueCoveragePlans {
          storableId
          default
          closetDefault
        }
      }
      locks {
        id
        description
      }
      pages(filter: { live: true }) {
        id
        path
        locale
      }
    }
  }
`;

const propTypes = {
  isReservation: PropTypes.bool,
};

const defaultProps = {
  isReservation: false,
};

const StorageMartCheckout = (props) => {
  const {
    isBuilderView,
  } = props;
  const [facilityId, setFacilityId] = useState();
  const {
    facilityId: queryFacilityId,
  } = getQueryParams();

  useEffect(() => {
    if (!facilityId && queryFacilityId) {
      setFacilityId(queryFacilityId);
    }
  }, [queryFacilityId]);

  const [facilityQuery, {
    loading: facilityLoading,
    error: facilityError,
    data: facilityData,
  }] = useLazyQuery(GET_FACILITY, {
    variables: { id: facilityId },
  });

  useEffect(() => {
    if (!isBuilderView) {
      if (facilityId && !facilityData && !facilityError) {
        facilityQuery();
      }
    }
  }, [facilityId]);


  if (!isBuilderView
    && (
      (!facilityData || facilityLoading)
      && !facilityError
    )
  ) {
    return (
      <LoadingSkeleton />
    );
  }

  return (
    <div>
      {facilityData?.facility.settings.softwareProvider === STOREDGE ? (
        <StorageMartCheckoutStoredge facilityData={facilityData} {...props} />
      ) : (
        <StorageMartCheckoutTotalRecall {...props} />
      )}
    </div>
  );
};

StorageMartCheckout.propTypes = propTypes;
StorageMartCheckout.defaultProps = defaultProps;

export default StorageMartCheckout;
