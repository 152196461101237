import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const BASE_LIMIT = 25;

const propTypes = {
  children: PropTypes.oneOf([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const LimitContext = createContext();

export const LimitContextProvider = ({ children }) => {
  const [limit, setLimit] = useState(BASE_LIMIT);

  return (
    <LimitContext.Provider value={[limit, setLimit]}>
      {children}
    </LimitContext.Provider>
  );
};

LimitContextProvider.propTypes = propTypes;
