import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Translation from '../../../../components/stateless/Translation';
import styles from './styles.scss';

const propTypes = {
  className: PropTypes.string,
  submitLabel: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  submitting: PropTypes.bool,
  submittingLabel: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  submitting: undefined,
  submittingLabel: {
    defaultMessage: 'Submitting...',
  },
  disabled: false,
};

const SMFormSubmitButton = ({
  className,
  submitLabel,
  submitting,
  submittingLabel,
  disabled,
  ...rest
}) => {
  const classes = cx(
    styles.button,
    className,
  );

  return (
    <button
      {...rest}
      type="submit"
      disabled={disabled || submitting}
      className={classes}
    >
      <Translation {...(submitting ? submittingLabel : submitLabel)} />
    </button>
  );
};

SMFormSubmitButton.propTypes = propTypes;
SMFormSubmitButton.defaultProps = defaultProps;

export default SMFormSubmitButton;
