import React, { useContext } from 'react';
import { object } from 'yup';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import { IntlProvider, useIntl } from 'react-intl';
import styles from './styles.scss';
import withForm from '../../../../../../admin/hocs/withForm';
import Icon from '../../../../../../modules/react/components/stateless/Icon';
import Price from '../../../../../components/stateless/Price';
import Translation from '../../../../../components/stateless/Translation';
import Date from '../../../../../components/stateless/Date';
import SMTooltip from '../../../../components/stateless/SMTooltip';
import { getNextFullMonth } from '../../../../../../modules/datetime';
import CurrentStepContext from '../CurrentStepContext';
import FacilityImage from '../../../../../containers/FacilityImage';
import {
  GBP,
} from '../../../../../../modules/i18n/currencies';

const propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.shape({
      streetAddress: PropTypes.string.isRequired,
      areaAddress: PropTypes.string.isRequired,
    }).isRequired,
    settings: PropTypes.shape({
      useWeeklyRates: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  loading: PropTypes.bool.isRequired,
  showMobileSummary: PropTypes.bool.isRequired,
  setShowMobileSummary: PropTypes.func.isRequired,
  discountPlan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  receipt: PropTypes.shape({
    creditAmount: PropTypes.number.isRequired,
    feeAmount: PropTypes.number.isRequired,
    rentAmount: PropTypes.number.isRequired,
    rentMonth: PropTypes.string.isRequired,
    nextDueDate: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
};

const defaultProps = {
  facility: undefined,
  receipt: undefined,
  discountPlan: undefined,
};

const validationSchema = object().shape({});

const initialValues = {};

export const MobileOrderSummary = ({ setShowMobileSummary, receipt, facility }) => {
  if (!facility || !facility.unitGroup) {
    return null;
  }

  const {
    currency,
    currencyLocale,
    unitGroup,
    settings: { useWeeklyRates },
  } = facility;

  const {
    price,
    discountedPrice,
    discountedPriceWeekly,
    priceWeekly,
  } = unitGroup;

  const weeklyRate = (discountedPriceWeekly || priceWeekly);
  const monthlyRate = (discountedPrice || price);
  const unitPrice = useWeeklyRates ? weeklyRate : monthlyRate;

  let totalPrice;

  if (useWeeklyRates) {
    totalPrice = receipt ? receipt.totalAmount : (unitPrice * 4.33).toFixed(2);
  } else {
    totalPrice = receipt ? receipt.totalAmount : unitPrice;
  }


  return (
    <>
      <div data-test-sum-base className={styles.orderSummaryMobile}>
        <button
          type="button"
          className={`${styles.orderSummaryMobileHeader} bg-[#003D4C] lg:hidden text-left text-white text-base rounded-tr-lg rounded-br-lg p-2 z-20 border-0 block sticky top-0`}
          onClick={() => setShowMobileSummary(true)}
        >
          <Translation
            id="widgets.checkout.summary.title"
            defaultMessage="Order Summary"
          />
          <Icon id="select-arrow" className="pull-right h-2 w-2 mt-1.5 mr-2" />
        </button>
        <h1 className={`${styles.title} text-xl font-bold mt-2.5 mb-2 lg:hidden`}>
          <Translation
            id="widgets.checkout.checkout.mobileTitle"
            defaultMessage="Secure Rental Checkout"
          />
        </h1>
      </div>
      <div data-test-sum className={`hidden ${styles.sticky} `}>
        <h1 className={`${styles.title} text-xl font-bold m-0`}>
          <Translation
            id="widgets.checkout.summary.mainTitle"
            defaultMessage="Checkout"
          />
        </h1>

        <button
          type="button"
          className="lg:hidden p-0 underline text-sm-green"
          onClick={() => setShowMobileSummary(true)}
        >
          <Translation
            id="widgets.checkout.summary.firsttotaldue"
            defaultMessage="1st Month Total"
          />
          {': '}
          <Price
            currency={currency}
            locale={currencyLocale}
            value={totalPrice}
          />
        </button>
      </div>
    </>
  );
};

const OrderSummary = ({
  loading,
  facility,
  showMobileSummary,
  setShowMobileSummary,
  receipt,
  discountPlan,
}) => {
  if (!facility || loading || !facility.unitGroup) {
    return null;
  }

  const stepContext = useContext(CurrentStepContext);
  const { locale, messages } = useIntl();

  const {
    currency,
    currencyLocale,
    unitGroup,
    settings: { useWeeklyRates },
  } = facility;
  const {
    price,
    discountedPrice,
    discountedPriceWeekly,
    priceWeekly,
    promoId,
  } = unitGroup;

  const weeklyRate = (discountedPriceWeekly || priceWeekly);
  const monthlyRate = (discountedPrice || price);
  const unitPrice = useWeeklyRates ? weeklyRate : monthlyRate;

  let totalPrice;
  let unitRate;
  let unitRateWeeklyValue;


  if (receipt) {
    unitRateWeeklyValue = receipt.rentAmount / 4.33;
  } else {
    unitRateWeeklyValue = unitPrice;
  }

  if (useWeeklyRates) {
    totalPrice = receipt ? receipt.totalAmount : (unitPrice * 4.33).toFixed(2);
    unitRate = receipt ? receipt.rentAmount : (unitPrice * 4.33).toFixed(2);
  } else {
    totalPrice = receipt ? receipt.totalAmount : unitPrice;
    unitRate = receipt ? receipt.rentAmount : unitPrice;
  }

  let taxTotalAmount = '--';

  if (receipt) {
    taxTotalAmount = (
      <Price
        currency={currency}
        locale={currencyLocale}
        value={receipt.taxAmount || 0}
      />
    );
  }

  let messageComponent;

  if (receipt && !!promoId) {
    messageComponent = (
      <div className="text-[#565958] mt-4">
        <Translation
          id={`widgets.unitstable.storagemart.unitpromo.${promoId}`}
          defaultMessage="Placeholder"
        >
          {promoName => (
            <Translation
              id="widgets.checkout.summary.discount"
              defaultMessage="You received the {discount} discount. Discount will be applied to your first full month of rent and will appear on your {month} bill."
              values={{
                discount: <strong>{promoName}</strong>,
                month: receipt ? <strong><Date month="long" value={getNextFullMonth(receipt.rentMonth)}>{formattedDate => formattedDate}</Date></strong> : 'next',
              }}
            />
          )}
        </Translation>
      </div>
    );
  } else if (!receipt) {
    messageComponent = (
      <div className="text-[#565958] mt-4">
        <Translation
          id="widgets.checkout.summary.prorated"
          defaultMessage="Your first month will be pro-rated based on your check-in date."
        />
      </div>
    );
  }

  let discount;

  if (receipt && isFirstDayOfMonth(receipt.rentMonth) && discountPlan && discountPlan.amount > 0) {
    discount = (
      <div className="flex gap-2 place-content-between mb-2">
        <div>
          <Translation
            id="widgets.checkout.firstmonthfree"
            defaultMessage="Free Month"
          />
        </div>
        <div className="text-[#2a8703]">
          -<Price
            currency={currency}
            locale={currencyLocale}
            value={discountPlan && discountPlan.amount}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`${styles.orderSummary} 
    bg-[#fbfaf9] rounded-lg sticky top-24 mt-[32px] text-black lg:block ${showMobileSummary ? `${styles.mobile} rounded-none p-4` : ''}`}
      >
        <button type="button" className={`${styles.arrowIcon} w-8 h-8 flex items-center justify-center mb-2 p-0 lg:hidden`} onClick={() => setShowMobileSummary(false)}>
          <Icon id="times" />
        </button>
        <div className="shadow-md border border-gray-200 bg-white mb-4 p-4 flex gap-4 rounded-md">
          <div className="w-1/4 max-w-24 sm:w-24 shrink-0">
            <div className="w-full relative pb-[100%]">
              <FacilityImage
                className="w-full h-full object-cover absolute"
                facility={facility}
              />
            </div>
          </div>
          <div>
            <div className="font-bold text-base leading-4">{facility.address.streetAddress}</div>
            <div className="mb-2 text-base text-[#565958]">{facility.address.areaAddress}</div>
            <div className="flex gap-2 mb-2">
              <div className="font-bold">
                <Translation
                  id="widgets.checkout.summary.unit"
                  defaultMessage="Unit:"
                />
              </div>
              <div>{get(unitGroup, 'size')}</div>
            </div>
          </div>
        </div>
        <div className="shadow-md border border-gray-200 bg-white rounded-md">
          <div className="border-b-1 border-gray-300 p-4">
            <h2 className={`${styles.updateTitle}`}>
              <Translation
                id="widgets.checkout.summary.title"
                defaultMessage="Order Summary"
              />
            </h2>
          </div>
          <div className="p-4">
            <>
              <div className="flex gap-2 place-content-between mb-2">
                <div>
                  <>
                    {!useWeeklyRates ? (
                      <Translation
                        id="widgets.checkout.summary.normalRent"
                        defaultMessage="Monthly Rent"
                      />
                    ) : (
                      <>
                        <Price
                          currency={currency}
                          locale={currencyLocale}
                          value={unitRateWeeklyValue}
                        >
                          {formattedPrice => (
                            <span>
                              {`${formattedPrice} `}
                              <Translation
                                id="widgets.checkout.summary.perWeek"
                                defaultMessage="Per Week"
                              />
                            </span>
                          )}
                        </Price>
                        <div className="text-xs text-[#565958]">
                          <Translation id="widgets.abbreviation.billedmonth" defaultMessage="Billed Monthly" />
                        </div>
                      </>
                    )}
                  </>
                </div>
                <Price
                  currency={currency}
                  locale={currencyLocale}
                  value={unitRate}
                />
              </div>

              {receipt && receipt.creditAmount > 0 && (
                <div className="flex gap-2 place-content-between mb-2">
                  <div>
                    <Translation
                      id="widgets.checkout.summary.proratedcredit"
                      defaultMessage="Pro-rated Credit"
                    />
                  </div>
                  <div className="text-[#2a8703]">
                    -<Price
                      currency={currency}
                      locale={currencyLocale}
                      value={receipt.creditAmount}
                    />
                  </div>
                </div>
              )}
              {discount}

              {receipt && receipt.feeAmount > 0 && (
                <div className="flex gap-2 place-content-between mb-2">
                  <div className="flex gap-2">
                    <Translation
                      id="widgets.checkout.summary.adminonetimefee"
                      defaultMessage="One-time Admin Fee"
                    />
                  </div>
                  <Price
                    currency={currency}
                    locale={currencyLocale}
                    value={receipt.feeAmount}
                  />
                </div>
              )}
              {
                receipt
                && stepContext.checkoutData.isContactless
                && stepContext.checkoutData.lockQty > 0 && (
                  map(stepContext.checkoutData.lockDetails, lockDetail => (
                    <div className="flex gap-2 place-content-between mb-2">
                      <div className="flex gap-2">
                        <Translation
                          id="widgets.checkout.summary.merchandise"
                          defaultMessage="Lock Fee"
                        />
                        <SMTooltip
                          body={(
                            <IntlProvider locale={locale} messages={messages}>
                              <Translation
                                id="widgets.checkout.summary.lockDisclaimer"
                                defaultMessage="You may return the unused lock"
                              />
                            </IntlProvider>
                          )}
                        />
                      </div>
                      <Price
                        currency={currency}
                        locale={currencyLocale}
                        value={lockDetail.amount + lockDetail.tax}
                      />
                    </div>
                  ))
                )
              }
              {currency !== GBP.code && (
                <div className="flex gap-2 place-content-between mb-2">
                  <div>
                    <Translation
                      id="widgets.checkout.summary.taxes"
                      defaultMessage="Taxes"
                    />
                  </div>
                  {taxTotalAmount}
                </div>
              )}
              <div className="border-t-1 mt-4 pt-4 border-gray-300">
                <div className="flex gap-2 place-content-between">
                  <div className="font-bold">
                    <Translation
                      id="widgets.checkout.summary.firsttotaldue"
                      defaultMessage="1st Month Total"
                    />
                  </div>
                  <Price
                    currency={currency}
                    locale={currencyLocale}
                    value={totalPrice}
                  />
                </div>
              </div>
            </>
            <div>
              {messageComponent}
            </div>
          </div>
        </div>
      </div>
      {showMobileSummary && (
        <button aria-label="close overlay Summary" type="button" className="bg-black fixed inset-0 opacity-0 z-50 opacity-50" onClick={() => setShowMobileSummary(false)} />
      )}
    </>
  );
};

OrderSummary.propTypes = propTypes;
OrderSummary.defaultProps = defaultProps;

OrderSummary.fragments = {
  facility: gql`
    fragment OrderSummaryFacility on Facility {
      id
      currency
      currencyLocale
      primaryImageMediumId
      address {
        country
        areaAddress
        streetAddress
      }
      settings {
        useWeeklyRates
      }
      unitGroup(id: $unitGroupId) {
        id
        width
        length
        height
        amenities {
          id
          name
          token
        }
      availableUnitsCount
      discountedPrice
      price
      pricedWeekly
      discountedPriceWeekly
      promoId
      area
      size
      category
      categoryName
      firstAvailableUnitName
      pricingTypeId
      availablePricingTypes {
        pricingTypeId
        marketRate
        marketRateWeekly
        firstAvailableUnitName
        floor
        door1Width
        door1Height
        door2Width
        door2Height
        door3Width
        door3Height
        door4Width
        door4Height
        proximityToAccessPoint
        proximityInDoors
        }
      floor
      door1Width
      door1Height
      door2Width
      door2Height
      door3Width
      door3Height
      door4Width
      door4Height
      proximityToAccessPoint
      proximityInDoors
      }
    }
      `,
};

export default withForm({
  mapPropsToValues: () => (
    initialValues
  ),
  validationSchema,
  handleSubmit: (formPayload, formikBag) => formikBag.props.onSubmit(formPayload),
})(OrderSummary);
