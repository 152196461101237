import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../../../../components/stateless/LoadingIndicator';

const propTypes = {};
const defaultProps = {};

const LoadingSkeleton = () => (
  <Grid>
    <Row>
      <Col xs={12} mdPush={8} md={4}>
        <div style={{
          height: '200px',
          maxWidth: '100%',
          marginTop: '3em',
        }}
        >
          <LoadingIndicator />
        </div>
      </Col>
      <Col xs={12} mdPull={4} md={7}>
        <div style={{
          height: '32px',
          maxWidth: '225px',
          marginTop: '3em',
          marginBottom: '.5em',
        }}
        >
          <LoadingIndicator />
        </div>
        <div style={{
          height: '300px',
          maxWidth: '100%',
          marginTop: '3em',
          marginBottom: '1em',
        }}
        >
          <LoadingIndicator />
        </div>
      </Col>
    </Row>
  </Grid>
);

LoadingSkeleton.propTypes = propTypes;
LoadingSkeleton.defaultProps = defaultProps;

export default LoadingSkeleton;
