import { isDefaultLocale } from '../i18n/locales';

export default (medium, locale) => {
  if (!medium) { return {}; }

  const { defaultMetadata, metadata } = medium;
  let localeMetadata;
  let altText;
  let title;

  if (!isDefaultLocale(locale) && metadata && metadata.length) {
    ([localeMetadata] = metadata);
    ({ altText, title } = localeMetadata);
  } else {
    ({ altText, title } = defaultMetadata);
  }

  return { altText, title };
};
