import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
// Action
export const SELECT_UNIT_SIZE = 'voyager/searchResultsUnitFilter/SELECT_UNIT_SIZE';
export const SELECT_AMENITY = 'voyager/searchResultsUnitFilter/SELECT_AMENITY';

// Reducer
const initialState = {
  selectedUnitSize: undefined,
  selectedAmenities: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_UNIT_SIZE:
      return {
        ...state,
        selectedUnitSize: state.selectedUnitSize === action.unitSize ? undefined : action.unitSize,
      };
    case SELECT_AMENITY: {
      let amenities = state.selectedAmenities;
      const amenityIndex = indexOf(amenities, action.amenity);

      if (amenityIndex >= 0) {
        if (action.amenity === 'closet') {
          const closetAmenities = ['closet', 'top', 'middle', 'bottom'];
          amenities = amenities.filter(amenity => !closetAmenities.includes(amenity));
        } else {
          amenities.splice(amenityIndex, 1);
        }
      } else {
        amenities.push(action.amenity);
      }

      return {
        ...state,
        selectedAmenities: amenities,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function selectUnitSize(unitSize) {
  return {
    type: SELECT_UNIT_SIZE,
    unitSize,
  };
}

export function selectAmenity(amenity) {
  return {
    type: SELECT_AMENITY,
    amenity,
  };
}
