import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import Translation from '../../../../../components/stateless/Translation';
import ThemedButton from '../../../../../components/stateless/ThemedButton';
import styles from '../styles.scss';

const propTypes = {
  esignPath: PropTypes.string.isRequired,
  storedgeEsignUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

const LeaseSignatureForm = ({
  esignPath,
  storedgeEsignUrl,
}) => {
  if (!storedgeEsignUrl) {
    return null;
  }

  const signatureRequestId = storedgeEsignUrl.split('/').slice(-1);
  return (
    <div className={classNames(styles.content, styles.esignature)}>
      <Row>
        <Col xs={3} md={1}>
          <img
            alt="E-Sign Document"
            loading="eager"
            src="https://uploads.storage-mart.com/a0756a77-5ab9-4c50-b715-fd5a68030d77/sign-document.png"
          />
        </Col>
        <Col xs={9} md={8}>
          <h2 className={styles.title}>
            <Translation
              id="widgets.checkoutconfirmation.storable.checkout.esignatureTitle"
              defaultMessage="Accelerate Check-In"
            />
          </h2>
          <Translation
            id="widgets.checkoutconfirmation.storable.checkout.esignatureDescription"
            defaultMessage="Review and sign move-in documents to help save time at check-in."
          />
        </Col>
        <Col xs={9} xsOffset={3} md={3} mdOffset={0}>
          <ThemedButton
            componentClass="a"
            href={`${esignPath}?id=${signatureRequestId}`}
            target="_blank"
            className={`${styles.esignButton} float-none mt-4`}
          >
            <Translation
              id="widgets.checkoutconfirmation.storable.checkout.esignatureButton"
              defaultMessage="Sign Now"
            />
          </ThemedButton>
        </Col>
      </Row>
    </div>
  );
};

LeaseSignatureForm.propTypes = propTypes;
LeaseSignatureForm.defaultProps = defaultProps;

export default LeaseSignatureForm;
