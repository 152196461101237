export const STORAGEMART_BRAND = 'StorageMart';
export const STORAGEMART_BRAND_CODE = 'sm';
export const MANHATTAN_MINI_BRAND = 'Manhattan Mini Storage';
export const MANHATTAN_MINI_BRAND_CODE = 'mms';

export const getBrandCode = brandName => (
  brandName === MANHATTAN_MINI_BRAND ? MANHATTAN_MINI_BRAND_CODE : STORAGEMART_BRAND_CODE
);

export const getBrandName = brandCode => (
  brandCode === MANHATTAN_MINI_BRAND_CODE ? MANHATTAN_MINI_BRAND : STORAGEMART_BRAND
);
