import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useField } from 'formik';
import { HelpBlock } from 'react-bootstrap';
import Svg from '../../../../components/stateless/Svg';
import Translation from '../../../../components/stateless/Translation';
import styles from './styles.scss';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }),
    PropTypes.element,
  ]).isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'checkbox',
    'radio',
  ]).isRequired,
  value: PropTypes.string.isRequired,
  help: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  required: undefined,
  help: undefined,
};

const SMFormFieldToggle = ({
  className,
  name,
  label,
  required,
  type,
  value,
  help,
}) => {
  const [field, meta] = useField(name);
  const error = meta.touched && !isEmpty(meta.error);
  const checked = field.checked || field.value === value || undefined;

  const fieldClasses = cx(
    styles.field,
    error && styles.error,
    required && styles.required,
    className,
  );

  return (
    <div className={fieldClasses}>
      <label className={styles.label}>
        <input
          {...field}
          value={value}
          type={type}
          checked={checked}
          required={required}
          className={styles.toggle}
          data-testid="sm-form-field-toggle"
        />
        <div className={styles.checkbox}>
          <Svg
            viewBox="0 0 16.51 13"
            className={styles.check}
          >
            <polygon
              fill="currentColor"
              points="14.38 0 5.55 8.83 2.04 5.59 0 7.81 5.64 13 16.51 2.13 14.38 0"
            />
          </Svg>
        </div>
        <span className={styles.text}>
          {React.isValidElement(label) ? (
            label
          ) : (
            <Translation {...label} />
          )
          }
        </span>
      </label>
      {help && (
        <HelpBlock>
          <Translation {...help} />
        </HelpBlock>
      )}
      {error && (
        <div className={styles.message}>
          <Translation
            defaultMessage="Field is invalid"
            id={meta.error}
          />
        </div>
      )}
    </div>
  );
};

SMFormFieldToggle.propTypes = propTypes;
SMFormFieldToggle.defaultProps = defaultProps;

export default SMFormFieldToggle;
