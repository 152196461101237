import React from 'react';
import styles from './styles.scss';
import SMFormFieldCreditCard from '../../../../components/stateless/SMFormFieldCreditCard';

const CheckoutFormFieldCreditCard = ({ ...props }) => (
  <SMFormFieldCreditCard
    className={styles.checkoutFormFieldCreditCard}
    autoComplete="cc-number"
    type="tel"
    errorClassName={styles.errorMessage}
    invalidClassName={styles.invalid}
    {...props}
  />
);

export default CheckoutFormFieldCreditCard;
