import { query } from 'utils/graphql';
import {
  BLOG_HOME_PAGE, BLOG_PAGE, BLOG_CATEGORY_PAGE, FACILITY_PAGE,
  FACILITY_LINK, FACILITY_LISTING, uniquePageTypes,
} from 'shared/pageTypes';
import { DEFAULT_LOCALE } from 'shared/i18n';

// Actions
const LOAD = 'voyager/pages/LOAD';
const LOAD_SUCCESS = 'voyager/pages/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/pages/LOAD_FAIL';
const SAVE = 'voyager/pages/SAVE';
const SAVE_SUCCESS = 'voyager/pages/SAVE_SUCCESS';
const SAVE_FAIL = 'voyager/pages/SAVE_FAIL';
const CLONE = 'voyager/pages/CLONE';
const CLONE_SUCCESS = 'voyager/pages/CLONE_SUCCESS';
const CLONE_FAIL = 'voyager/pages/CLONE_FAIL';
const CREATE = 'voyager/pages/CREATE';
const CREATE_SUCCESS = 'voyager/pages/CREATE_SUCCESS';
const CREATE_FAIL = 'voyager/pages/CREATE_FAIL';
const CREATE_CHILD = 'voyager/pages/CREATE_CHILD';
const CREATE_CHILD_SUCCESS = 'voyager/pages/CREATE_CHILD_SUCCESS';
const CREATE_CHILD_FAIL = 'voyager/pages/CREATE_CHILD_FAIL';
const LIVE = 'voyager/pages/LIVE';
export const LIVE_SUCCESS = 'voyager/pages/LIVE_SUCCESS';
const LIVE_FAIL = 'voyager/pages/LIVE_FAIL';
const DRAFT = 'voyager/pages/DRAFT';
export const DRAFT_SUCCESS = 'voyager/pages/DRAFT_SUCCESS';
const DRAFT_FAIL = 'voyager/pages/DRAFT_FAIL';
const SWITCH_LIVE = 'voyager/pages/SWITCH_LIVE';
export const SWITCH_LIVE_SUCCESS = 'voyager/pages/SWITCH_LIVE_SUCCESS';
const SWITCH_LIVE_FAIL = 'voyager/pages/SWITCH_LIVE_FAIL';
const REMOVE = 'voyager/pages/REMOVE';
const REMOVE_SUCCESS = 'voyager/pages/REMOVE_SUCCESS';
const REMOVE_FAIL = 'voyager/pages/REMOVE_FAIL';
const UPDATE = 'voyager/pages/UPDATE';
const SEARCH = 'voyager/pages/SEARCH';
const SEARCH_SUCCESS = 'voyager/pages/SEARCH_SUCCESS';
const SEARCH_FAIL = 'voyager/pages/SEARCH_FAIL';
const UPDATE_FILTERS = 'voyager/pages/UPDATE_FILTERS';
const CLEAR_FILTERS = 'voyager/pages/CLEAR_FILTERS';
const UPDATE_PAGINATION = 'voyager/pages/UPDATE_PAGINATION';
const SWITCH_FACILITY_ID = 'voyager/pages/SWITCH_FACILITY_ID';
const SWITCH_FACILITY_ID_SUCCESS = 'voyager/pages/SWITCH_FACILITY_ID_SUCCESS';
const SWITCH_FACILITY_ID_FAIL = 'voyager/pages/SWITCH_FACILITY_ID_FAIL';
const FACILITY_UPDATE_SUCCESS = 'voyager/facilities/UPDATE_SUCCESS';

// Reducer
const initialState = {
  filters: {
    queryString: '',
    live: null,
  },
  pagination: {
    perPage: 10,
    pageNumber: 1,
  },
  total: 0,
  pages: [],
  isMakingLive: false,
  creatingChild: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        pages: [...action.data.pages],
      };
    case CREATE_CHILD:
      return {
        ...state,
        creatingChild: true,
      };
    case LOAD_FAIL:
    case CLONE_SUCCESS:
    case CREATE_SUCCESS:
      return {
        ...state,
        pages: [
          ...state.pages,
          cleanPage(action.data.page),
        ],
      };
    case CREATE_CHILD_SUCCESS:
      return {
        ...state,
        creatingChild: false,
        pages: [
          ...state.pages,
          cleanPage(action.data.page),
        ],
      };
    case CREATE_CHILD_FAIL:
      return {
        ...state,
        creatingChild: false,
      };
    case SWITCH_LIVE:
      return {
        ...state,
        isSwitchingLive: true,
      };
    case SWITCH_LIVE_FAIL:
      return {
        ...state,
        isSwitchingLive: false,
      };
    case SWITCH_LIVE_SUCCESS:
      return {
        ...state,
        isSwitchingLive: false,
        pages: state.pages.map((page) => {
          if (page.id === action.pageToEnliven.id) {
            return {
              ...page,
              ...action.data.forLive,
            };
          }
          if (page.id === action.pageToEndraften.id) {
            return {
              ...page,
              ...action.data.forDraft,
            };
          }

          return page;
        }),
      };
    case LIVE:
      return {
        ...state,
        isMakingLive: true,
      };
    case LIVE_FAIL:
      return {
        ...state,
        isMakingLive: false,
      };
    case DRAFT:
      return {
        ...state,
        isMakingDraft: true,
      };
    case DRAFT_FAIL:
      return {
        ...state,
        isMakingDraft: false,
      };
    case LIVE_SUCCESS:
    case DRAFT_SUCCESS:
      return {
        ...state,
        isMakingLive: false,
        isMakingDraft: false,
        pages: state.pages.map((page) => {
          if (page.id === action.data.page.id) {
            return cleanPage(action.data.page);
          }

          return page;
        }),
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        pages: state.pages.filter(page => page.id !== action.page.id),
      };
    case UPDATE:
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (action.page.id === page.id) {
            // Make a copy of this object to prevent mutation
            return { ...action.page };
          }

          return page;
        }),
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        pages: action.data.search.pages,
        total: action.data.search.pagesTotal,
      };
    case SEARCH_FAIL:
      return {
        ...state,
      };
    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: { ...action.filter, languages: action.filter.languages ? [...action.filter.languages] : [] },
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
      };
    }
    case CLEAR_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
      };
    }
    case UPDATE_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    }
    case FACILITY_UPDATE_SUCCESS: {
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (action.id === page.facilityId) {
            page.facility = action.data.facility;
          }

          return page;
        }),
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getPages = state => state.pages.pages;

export const getListViewPages = state => (
  getPages(state).filter(page => (
    page.locale === DEFAULT_LOCALE
    && [BLOG_CATEGORY_PAGE, BLOG_HOME_PAGE, BLOG_PAGE].indexOf(page.metadata.type) === -1
  ))
);

export const getPageById = (state, id) => {
  if (!id) {
    return undefined;
  }

  return state.pages.pages.find(page => page.id === id);
};

export const isPageLive = (state, id) => {
  const page = getPageById(state, id);

  if (!page) {
    return undefined;
  }

  return page.live;
};

export const isTogglingLiveness = state => (
  state.pages.isMakingLive || state.pages.isMakingDraft || state.pages.isSwitchingLive
);

export const isDrafting = state => (
  state.pages.isMakingDraft
);

export const getLivePages = state => (
  state.pages.pages.filter(page => page.live)
);

export const getLiveCopy = (state, page) => {
  if (!page) {
    return undefined;
  }

  const { type } = page.metadata;
  const livePages = getLivePages(state);

  const [liveCopy] = livePages.filter(livePage => (
    livePage.metadata.type === type &&
    livePage.id !== page.id &&
    livePage.locale === page.locale
  ));

  return liveCopy;
};

export const canMakeLive = (state, page) => {
  if (!page) {
    return false;
  }

  const { type } = page.metadata;
  const livePages = getLivePages(state);

  if (!livePages || !livePages.length) {
    return true;
  }

  // Multiple non-unique pages may be live at the same time
  // and non-full facility pages can't be cloned.
  if (![...uniquePageTypes, FACILITY_LINK, FACILITY_LISTING].includes(type)) {
    return true;
  }

  if (type === FACILITY_PAGE) {
    return !livePages.some(livePage =>
      livePage.metadata.type === FACILITY_PAGE &&
                           livePages.facilityId === page.facilityId &&
                           livePages.locale === page.locale);
  }

  if (uniquePageTypes.includes(type)) {
    return !livePages.some(livePage =>
      livePage.metadata.type === type &&
                           livePage.locale === page.locale);
  }

  // For all other page types, only one live page may exist at a time.
  if (getLiveCopy(state, page)) {
    return false;
  }

  // No matching pages exist, so go ahead and make it live.
  return true;
};

export const getBlogHomePage = state => (
  state.pages.pages.find(({ metadata: { type } }) => type === BLOG_HOME_PAGE)
);

export const getPagination = state => (
  state.pages.pagination
);

export const getPaginationPerPage = state => (
  state.pages.pagination.perPage
);

export const getPaginationPageNumber = state => (
  state.pages.pagination.pageNumber
);

export const getPagesCount = state => (
  state.pages.total
);

export const getPaginationPagesCount = (state) => {
  const total = getPagesCount(state);
  const perPage = getPaginationPerPage(state);

  if (!total || total < 1) {
    return 1;
  }

  return Math.ceil(total / perPage);
};

export const getFilters = state => (
  state.pages.filters
);

export const creatingChild = state => (
  state.pages.creatingChild
);

// Misc
function cleanPage(page) {
  const newPage = { ...page }; // Let's not mutate the page
  delete newPage.version;
  delete newPage.publishedVersion;
  return newPage;
}

// Action Creators
const pageProps = `
  id
  companyId
  siteId
  facilityId
  disableEnUsPage
  facility {
    id
    name
    brandName
    facilityType
    linkOutUrl
    primaryImage
    address {
      address1
      address2
      city
      state
      postal
      country
      latitude
      longitude
      fullAddress
    }
    enabled
  }
  metadata {
    description
    linkOut
    slug
    title
    internalPageName
    titlePostfix
    type
    noIndex
    customUrl
    metaTags {
      property
      content
    }
    syncOGTags
    canonical
  }
  boilerplate
  live
  path
  publishedVersion {
    createdAt
    id
    addedBy {
      name
    }
  }
  versions {
    id
    createdAt
    addedBy {
      name
    }
  }
  version {
    id
    createdAt
    addedBy {
      name
    }
  }
  locale
  languages {
    code
    enabled
    name
  }
  childPageIds
  children {
    pageId
    lastUpdated
    lastPublished
    unpublishedChanges
    locale
    status {
      id
      context
      text
    }
    live
  }
  lastPublished
  lastUpdated
  unpublishedChanges
`;

export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    force,
    promise: query`
      query GetPages {
        pages {
          ${pageProps}
        }
      }
    `,
  };
}

export function search({ filters, pagination }) {
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    force: true,
    promise: query`
      query searchPages(${{ filters }}: SearchInput, ${{ pagination }}: PaginationInput) {
        search {
          pages(filters: $filters, pagination: $pagination) {
            ${pageProps}
          }
          pagesTotal(filters: $filters)
        }
      }
    `,
  };
}

export function searchPages({ pagination } = {}) {
  return (dispatch, getState) => {
    if (pagination) {
      dispatch(updatePagination(pagination));
    }

    const state = getState();
    const filters = getFilters(state);

    const cleanedFilters = {};
    Object.keys(filters).forEach((key) => {
      let value = filters[key];
      if (value instanceof Array) {
        value = value.filter(val => val.enabled).map(val => val.value);
      }
      cleanedFilters[key] = value;
    });

    return dispatch(search({
      filters: cleanedFilters,
      pagination: getPagination(state),
    }));
  };
}

export function clearAndSearch() {
  return (dispatch, getState) => {
    dispatch(clearFilters());

    const state = getState();

    return dispatch(search({
      filters: getFilters(state),
      pagination: getPagination(state),
    }));
  };
}

export function updateFilters(filter, value) {
  return {
    type: UPDATE_FILTERS,
    filter,
    value,
    force: true,
  };
}

export function updatePagination(pagination) {
  return {
    type: UPDATE_PAGINATION,
    pagination,
  };
}


export function clearFilters() {
  return {
    type: CLEAR_FILTERS,
    force: true,
  };
}

export function makeLive(page) {
  return {
    types: [LIVE, LIVE_SUCCESS, LIVE_FAIL],
    page,
    force: true,
    promise: query`
      mutation ChangePageLiveness(${{ id: page.id }}: String!) {
        page: updatePageLiveness(id: $id, live: true) {
          ${pageProps}
        }
      }
    `,
  };
}

export function makeDraft(page) {
  return {
    types: [DRAFT, DRAFT_SUCCESS, DRAFT_FAIL],
    page,
    force: true,
    promise: query`
      mutation ChangePageLiveness(${{ id: page.id }}: String!) {
        page: updatePageLiveness(id: $id, live: false) {
          ${pageProps}
        }
      }
    `,
  };
}

export function toggleLiveness(page) {
  return page.live ? makeDraft(page) : makeLive(page);
}

export function switchLiveAndDraft(pageToEnliven, pageToEndraften) {
  return {
    types: [SWITCH_LIVE, SWITCH_LIVE_SUCCESS, SWITCH_LIVE_FAIL],
    pageToEnliven,
    pageToEndraften,
    force: true,
    promise: query`
      mutation Switcheroo(${{ enlivenId: pageToEnliven.id }}: String!,
        ${{ endraftenId: pageToEndraften.id }}: String!) {
          forLive: updatePageLiveness(id: $enlivenId, live: true) {
            ${pageProps}
          }
          forDraft: updatePageLiveness(id: $endraftenId, live: false) {
            ${pageProps}
          }
        }
    `,
  };
}

export function switchFacilityId(page, livePage) {
  const toMutate = {
    id: page.id,
    facilityId: page.facilityId,
  };

  const promise = livePage
    ? query`
      mutation SwitchFacilityId(${{ page: { ...toMutate } }}: PageInput!,
        ${{ livePageId: livePage.id }}: String!) {
        forUpdate: updatePage(page: $page) {
          id
        }
        forEndraften: updatePageLiveness(id: $livePageId, live: false) {
          ${pageProps}
        }
      }
    `
    : query`
      mutation SwitchFacilityId(${{ page: { ...toMutate } }}: PageInput!) {
        updatePage(page: $page) {
          id
        }
      }
    `;

  return {
    types: [SWITCH_FACILITY_ID, SWITCH_FACILITY_ID_SUCCESS, SWITCH_FACILITY_ID_FAIL],
    page,
    livePage,
    promise,
    force: true,
  };
}

export function save(page) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    page,
    force: true,
    promise: query`
      mutation UpdatePage(${{ page }}: PageInput!) {
        page: updatePage(page: $page) {
          id
        }
      }
    `,
  };
}

export function clone(page) {
  return {
    types: [CLONE, CLONE_SUCCESS, CLONE_FAIL],
    page,
    force: true,
    promise: query`
      mutation ClonePage(${{ id: page.id }}: String!) {
        page: clonePage(id: $id) {
          ${pageProps}
        }
      }
    `,
  };
}

export function createChild(page, locale) {
  return {
    types: [CREATE_CHILD, CREATE_CHILD_SUCCESS, CREATE_CHILD_FAIL],
    page,
    force: true,
    promise: query`
      mutation CreateChildPage(${{ id: page.id }}: String!, ${{ locale }}: String!) {
        page: createChildPage(id: $id, locale: $locale) {
          ${pageProps}
        }
      }
    `,
  };
}

export function create(type) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    type,
    force: true,
    promise: query`
      mutation CreatePage(${{ type }}: String!) {
        page: createPage(type: $type) {
          ${pageProps}
        }
      }
    `,
  };
}

export function remove(page) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    page,
    promise: query`
      mutation DeletePage(${{ id: page.id }}: String!) {
        deletePage(id: $id)
      }
    `,
  };
}

export function update(page) {
  return {
    type: UPDATE,
    page,
  };
}
