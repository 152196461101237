import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { HelpBlock } from 'react-bootstrap';
import CurrentUserLocationIntlPhoneInput from '../../../../containers/CurrentUserLocationIntlPhoneInput';
import Translation from '../../../../components/stateless/Translation';
import styles from './styles.scss';

const propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  helpBlockClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  help: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  errorClassName: undefined,
  invalidClassName: undefined,
  helpBlockClassName: undefined,
  required: undefined,
  help: undefined,
};

const SMFormFieldPhone = ({
  className,
  errorClassName,
  invalidClassName,
  helpBlockClassName,
  name,
  label,
  required,
  help,
}) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && !isEmpty(meta.error);

  if (field.value === undefined) {
    field.value = {
      country: null,
      phoneNumber: '',
      unformattedPhoneNumber: '',
    };
  }

  const fieldClasses = cx(
    styles.field,
    error && styles.error,
    error && invalidClassName,
    required && styles.required,
    className,
  );

  // we need to stop the <label> from doing its thing or else the dropdown won't open
  const onFlagClick = event => event.preventDefault();

  const onPhoneBlur = () => {
    helpers.setTouched(true);
  };

  // strip formatting and set the full phone number with dial code
  const setPhone = (phoneNumber, dialCode, country) => {
    let unformattedPhoneNumber = '';
    const phoneDigits = phoneNumber.replace(/[(|)|\-|\s]/g, '');
    if (phoneDigits.length !== 0) {
      unformattedPhoneNumber = `+${dialCode}${phoneDigits}`;
    }
    helpers.setValue({
      phoneNumber,
      country,
      unformattedPhoneNumber,
    });
  };

  // use the react-intl-tel-input onPhoneNumberChange callback to change the phone number
  const onPhoneChange = (validationStatus, phoneNumber, { dialCode, iso2: country }) => {
    setPhone(phoneNumber, dialCode, country);
  };

  // use the react-intl-tel-input onSelectFlag callback to change the dial code
  const onFlagChange = (phoneNumber, { dialCode, iso2: country }) => {
    setPhone(phoneNumber, dialCode, country);
  };

  return (
    <div className={fieldClasses}>
      <label className={styles.label}>
        <div className={styles.text}>
          <Translation {...label} />
        </div>
        <CurrentUserLocationIntlPhoneInput
          fieldName={field.name}
          format
          value={field.value.phoneNumber}
          initialCountry={field.value.country}
          inputClassName={styles.input}
          onFlagClick={onFlagClick}
          onPhoneBlur={onPhoneBlur}
          onPhoneChange={onPhoneChange}
          onFlagChange={onFlagChange}
          preferredCountries={['us', 'gb', 'ca']}
          separateDialCode
          required={required}
        />
      </label>
      {help && (
        <HelpBlock bsClass={cx('help-block', helpBlockClassName)}>
          <Translation {...help} />
        </HelpBlock>
      )}
      {error && (
        <div className={cx(styles.message, errorClassName)}>
          <Translation
            defaultMessage="Field is invalid"
            id={meta.error.unformattedPhoneNumber}
          />
        </div>
      )}
    </div>
  );
};

SMFormFieldPhone.propTypes = propTypes;
SMFormFieldPhone.defaultProps = defaultProps;

export default SMFormFieldPhone;
