import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIndicator as CanaveralLoadingIndicator } from '@canaveral/components';

const LoadingIndicator = ({ className }) => (
  <CanaveralLoadingIndicator
    className={`min-h-full w-full ${className}`}
  />
);

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  className: '',
};

export default LoadingIndicator;
