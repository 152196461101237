import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCompanyId,
  getFlippers,
  getNonDefaultLocaleCodes,
} from 'store/modules/company';
import {
  getTheme,
  getPublicLogo,
  isBlogEnabled as getIsBlogEnabled,
  getTranslationTransform,
  PSEUDOLOCALIZE, TRANSLATION_ID, TRANSLATION_TEXT,
} from 'store/modules/site';
import { DEFAULT_LOCALE } from 'shared/i18n';
import { getPageLocale } from 'store/modules/page';
import { getUserAuthProfile } from 'store/modules/user';
import { compose } from 'utils/react';
import { isStorageMart } from 'modules/storagemart';
import authorization from 'modules/authorization';
import CurrentCompanyContext from 'modules/react/contexts/CurrentCompanyContext';
import CurrentSiteContext from 'modules/react/contexts/CurrentSiteContext';
import CurrentUserContext from 'admin/contexts/CurrentUserContext';
import ConnectedIntlProvider from 'containers/ConnectedIntlProvider';

const propTypes = {
  children: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  isBlogEnabled: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    hyper: PropTypes.bool,
    permissions: PropTypes.array,
  }).isRequired,
  theme: PropTypes.object.isRequired,
  publicLogo: PropTypes.object.isRequired,
  flippers: PropTypes.object.isRequired,
  nonDefaultLocales: PropTypes.array.isRequired,
  getLocale: PropTypes.func,
  translationTransform: PropTypes.oneOf([PSEUDOLOCALIZE, TRANSLATION_ID, TRANSLATION_TEXT]),
};

const defaultProps = {
  getLocale: state => getPageLocale(state) || DEFAULT_LOCALE,
};

const legacyContextTypes = {
  store: PropTypes.object.isRequired,
};

const App = ({ children,
  companyId,
  isBlogEnabled,
  profile,
  theme,
  publicLogo,
  flippers,
  nonDefaultLocales,
  getLocale,
  translationTransform,
}) => {
  const currentCompany = {
    id: companyId,
    loaded: true,
    loading: false,
    flippers,
    nonDefaultLocales,
  };

  const currentSite = {
    id: companyId,
    isStorageMart: isStorageMart(companyId),
    isBlogEnabled,
    theme,
    publicLogo,
    loading: false,
    loaded: true,
    translationTransform,
  };

  const user = {
    id: profile && profile.id,
    loading: false,
    loaded: true,
    ...authorization(profile),
  };


  return (
    <ConnectedIntlProvider getLocale={getLocale}>
      <CurrentCompanyContext.Provider value={currentCompany}>
        <CurrentSiteContext.Provider value={currentSite}>
          <CurrentUserContext.Provider value={user}>
            {children}
          </CurrentUserContext.Provider>
        </CurrentSiteContext.Provider>
      </CurrentCompanyContext.Provider>
    </ConnectedIntlProvider>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;
App.contextTypes = legacyContextTypes;

export default compose(
  connect(
    state => ({
      theme: getTheme(state),
      publicLogo: getPublicLogo(state),
      companyId: getCompanyId(state),
      isBlogEnabled: getIsBlogEnabled(state),
      profile: getUserAuthProfile(state),
      flippers: getFlippers(state),
      translationTransform: getTranslationTransform(state),
      nonDefaultLocales: getNonDefaultLocaleCodes(state),
    }),
  ),
)(App);
