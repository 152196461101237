import url from 'url';
import combine from './combine';

const {
  VOYAGER_COMPILED_ASSETS_URL,
  VOYAGER_USER_UPLOADED_CONTENT_URL,
  VOYAGER_USER_UPLOADED_IMAGE_URL,
} = process.env;

const externalRegex = /^[a-z0-9]+:/i;

export const FAVICON_FALLBACK = '/favicon.ico';

// Asset files are checked into the Voyager repo alongside the code. For example,
// a placeholder image or a size guide visual.
export const resolveAssetUrl = path => (
  url.resolve(VOYAGER_COMPILED_ASSETS_URL, path)
);

// Content files are uploaded manually and end up in S3. For example, a
// facility's primary image or images in a slideshow.
export const resolveContentUrl = (path) => {
  if (externalRegex.test(path)) {
    return path;
  }

  // Legacy support for fallback paths that somehow ended up saved to the
  // database.
  if (path.startsWith('/img')) {
    return resolveAssetUrl(path);
  }

  return combine(VOYAGER_USER_UPLOADED_CONTENT_URL, path);
};

// Image files are uploaded manually and end up in S3. For example, a
// facility's primary image or images in a slideshow using media from the Media Gallery
export const resolveMediaUrl = (path, edits = {}) => {
  if (!VOYAGER_USER_UPLOADED_IMAGE_URL) {
    return resolveContentUrl(path);
  }

  if (externalRegex.test(path)) {
    return path;
  }

  // Legacy support for fallback paths that somehow ended up saved to the
  // database.
  if (path.startsWith('/img')) {
    return resolveAssetUrl(path);
  }

  const config = JSON.stringify({
    key: path,
    edits,
  });

  const encodedPath = Buffer.from(config).toString('base64');
  return combine(VOYAGER_USER_UPLOADED_IMAGE_URL, encodedPath);
};

export const FACILITY_PRIMARY_IMAGE_FALLBACK = '/img/facility-default-img.jpg';

export const resolvePrimaryFacilityImageUrl = path => (
  path ? resolveContentUrl(path) : resolveAssetUrl(FACILITY_PRIMARY_IMAGE_FALLBACK)
);

export const LOGO_FALLBACK = '/img/logo.jpg';

export const resolveLogoUrl = path => (
  path ? resolveContentUrl(path) : resolveAssetUrl(LOGO_FALLBACK)
);

export const PERMITTED_IMAGE_TYPES = 'jpg,jpeg,gif,png,webp,svg,svg+xml,bmp';
export const INPUT_PERMITTED_IMAGES_TYPES = PERMITTED_IMAGE_TYPES.split(',').map(type => `image/${type}`).join(',');

export const formatSize = (size) => {
  if (size < 1000) { return `${size}KB`; }
  return `${(size / 1000).toFixed(2)}MB`;
};
