import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { enUS, enGB, fr, es } from 'date-fns/locale';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { FR_CA, EN_GB, EN_US, ES } from '../../../../../modules/i18n/locales';
import Translation from '../../../../components/stateless/Translation';
import Datepicker from '../../../../components/stateful/Datepicker';
import styles from './styles.scss';

const dateLocales = {
  [EN_US]: enUS,
  [EN_GB]: enGB,
  [ES]: es,
  [FR_CA]: fr,
};

const propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

const defaultProps = {
  availableDates: undefined,
  className: undefined,
  errorClassName: undefined,
  invalidClassName: undefined,
  onChange: undefined,
  required: undefined,
};

const SMFormFieldDatePicker = ({
  availableDates,
  className,
  errorClassName,
  invalidClassName,
  name,
  label,
  onChange,
  required,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { locale } = useIntl();
  const calendar = useRef();
  const error = meta.touched && !isEmpty(meta.error);
  const dateLocale = dateLocales[locale];

  const onLabelClick = e => calendar.current.state.open && e.preventDefault();
  const onDatepickerBlur = () => helpers.setTouched(true);
  const onDatepickerChange = (date) => {
    helpers.setValue(date);

    if (typeof onChange === 'function') {
      onChange(date);
    }
  };

  const dateFormat = dateLocale.formatLong.date({ width: 'short' });

  const fieldClasses = cx(
    styles.field,
    error && styles.error,
    error && invalidClassName,
    required && styles.required,
    className,
  );

  return (
    <div className={fieldClasses}>
      <label
        className={styles.label}
        onClick={onLabelClick}
        onKeyPress={onLabelClick}
        role="presentation"
      >
        <div className={styles.text}>
          <Translation {...label} />
        </div>
        <Datepicker
          {...rest}
          className={styles.input}
          dateFormat={dateFormat}
          includeDates={availableDates}
          locale={dateLocale}
          onBlur={onDatepickerBlur}
          onChange={onDatepickerChange}
          ref={calendar}
          selected={field.value}
        />
      </label>
      {error && (
        <div className={cx(styles.message, errorClassName)}>
          <Translation
            defaultMessage="Field is invalid"
            id={meta.error}
          />
        </div>
      )}
    </div>
  );
};

SMFormFieldDatePicker.propTypes = propTypes;
SMFormFieldDatePicker.defaultProps = defaultProps;

export default SMFormFieldDatePicker;
