import React from 'react';
import LeaseSignatureForm from './LeaseSignatureForm';

const propTypes = {};

const defaultProps = {};

const LeaseSignature = ({ formData }) => (
  <div>
    <LeaseSignatureForm formData={formData} />
  </div>
);

LeaseSignature.propTypes = propTypes;
LeaseSignature.defaultProps = defaultProps;

export default LeaseSignature;
