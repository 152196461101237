import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './PagingDots.scss';

const propTypes = {
  slideCount: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
  currentSlide: PropTypes.number,
  activeButtonStyles: PropTypes.string,
  inactiveButtonStyles: PropTypes.string,
  dotStyle: PropTypes.string,
};

const defaultProps = {
  activeButtonStyles: styles.activeButtonStyles,
  inactiveButtonStyles: styles.inactiveButtonStyles,
  dotStyle: styles.dotStyle,
  currentSlide: undefined,
};

const PagingDots = ({
  slideCount,
  slidesToScroll,
  goToSlide,
  currentSlide,
  activeButtonStyles,
  inactiveButtonStyles,
  dotStyle,
}) => (
  <ul className={styles.listStyles}>
    {PagingDots.getIndexes(slideCount, slidesToScroll).map(index => (
      <li className={styles.listItemStyles} key={index}>
        <button
          type="button"
          className={cx(styles.dots, dotStyle,
            currentSlide === index ? activeButtonStyles : inactiveButtonStyles)}
          onClick={(e) => {
            e.stopPropagation();
            goToSlide(index);
          }}
        >
        &bull;
        </button>
      </li>
    ))}
  </ul>
);

PagingDots.getIndexes = (count, inc) => {
  const arr = [];
  for (let i = 0; i < count; i += inc) {
    arr.push(i);
  }
  return arr;
};

PagingDots.propTypes = propTypes;
PagingDots.defaultProps = defaultProps;

export default PagingDots;
