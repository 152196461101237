import React from 'react';
import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CurrentSiteContext from 'modules/react/contexts/CurrentSiteContext';
import Select from '../../../../../../modules/react/components/stateful/Select';
import styles from './ColorPicker.scss';

const COLOR_PRIMARY = 'primary';
const COLOR_SECONDARY = 'secondary';
const COLOR_TERTIARY = 'tertiary';
const COLOR_CUSTOM = 'custom';

const DEFAULT_OPTIONS = [
  { value: COLOR_PRIMARY, label: 'Primary' },
  { value: COLOR_SECONDARY, label: 'Secondary' },
  { value: COLOR_TERTIARY, label: 'Tertiary' },
  { value: COLOR_CUSTOM, label: 'Custom' },
];

const makeOptions = ({ additionalColors = [], allowCustom }) => [
  ...additionalColors,
  ...(allowCustom ? DEFAULT_OPTIONS : DEFAULT_OPTIONS.filter(({ value }) => value !== COLOR_CUSTOM)),
];

export default class ColorPicker extends React.PureComponent {
  static propTypes = {
    additionalColors: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    })),
    allowCustom: PropTypes.bool,
    input: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    allowCustom: true,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.additionalColors !== nextProps.additionalColors) {
      this.options = makeOptions(nextProps);
    }
  }

  getValueColor(value, colors) {
    switch (value) {
      case COLOR_PRIMARY:
        return colors.primary;

      case COLOR_SECONDARY:
        return colors.secondary;

      case COLOR_TERTIARY:
        return colors.tertiary;

      default:
        return value;
    }
  }

  isCustomColor(color) {
    return !this.options
      .map(({ value }) => value)
      .filter(value => value !== COLOR_CUSTOM)
      .includes(color);
  }

  options = makeOptions(this.props);

  handleColorChange = ({ value }) => {
    const { input: { onChange } } = this.props;

    if (value === COLOR_CUSTOM) {
      // If we're setting this to "custom" for the first time, set the actual value to an empty string
      onChange('');
    } else {
      onChange(value);
    }
  }

  handleCustomColorChange = ({ target }) => this.handleColorChange({ value: `#${target.value}` });

  renderOption = ({ value, label }) => (
    <CurrentSiteContext.Consumer>
      {({ theme }) => {
        const color = this.isCustomColor(value) ? 'transparent' : this.getValueColor(value, theme.colors);
        return (
          <span className={styles.label}>
            <div
              style={{ backgroundColor: color }}
              className={styles.colorBlock}
            />
            {label}
          </span>
        );
      }}
    </CurrentSiteContext.Consumer>
  );

  renderColorField() {
    const { input: { value } } = this.props;

    return (
      <InputGroup className={styles.customInputGroup}>
        <InputGroup.Addon>#</InputGroup.Addon>
        <FormControl
          type="text"
          name="color"
          value={value ? value.substr(1) : ''}
          className={styles.customInput}
          onChange={this.handleCustomColorChange}
        />
      </InputGroup>
    );
  }

  render() {
    const { input: { value } } = this.props;
    const isCustomColor = this.isCustomColor(value);

    return (
      <Row>
        <Col xs={4}>
          <Select
            autoBlur
            className={styles.selectColorOptions}
            clearable={false}
            instanceId="color-selector"
            onChange={this.handleColorChange}
            optionRenderer={this.renderOption}
            valueRenderer={this.renderOption}
            options={this.options}
            value={isCustomColor ? COLOR_CUSTOM : value}
            searchable={false}
          />
        </Col>
        <Col xs={8}>
          {isCustomColor ? this.renderColorField() : false}
        </Col>
      </Row>
    );
  }
}
