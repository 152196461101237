import { query } from 'utils/graphql';

// Actions
const LOAD = 'voyager/robots/LOAD';
const LOAD_SUCCESS = 'voyager/robots/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/robots/LOAD_FAIL';
const UPDATE = 'voyager/robots/UPDATE';
const UPDATE_SUCCESS = 'voyager/robots/UPDATE_SUCCESS';
const UPDATE_FAIL = 'voyager/robots/UPDATE_FAIL';

// Reducer
const initialState = {
  content: 'User-agent: *\nDisallow: /',
  errors: null,
  saving: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        errors: null,
        content: action.data.robots.content,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        errors: null,
        content: action.data.updateRobots.content,
        saving: false,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        saving: false,
        errors: action.error.map(({ message }) => message),
      };
    case UPDATE:
      return {
        ...state,
        saving: true,
      };
    default:
      return state;
  }
}

export function getContent(state) {
  return state.robots.content;
}

export function getErrors(state) {
  return state.robots.errors;
}

export function isSaving(state) {
  return state.robots.saving;
}

// Action Creators
export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    force,
    promise: query`
      {
        robots {
          content
        }
      }
    `,
  };
}

export function update(content) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    force: true,
    content,
    promise: query`
      mutation UpdateRobots(${{ content }}: String!) {
        updateRobots(content: $content) {
          content 
        }
     }
    `,
  };
}
