import React from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';

const propTypes = {};

const defaultProps = {};

// TODO: Upgrade to `react-select` v2.
// For a list of available props, see:
// https://github.com/JedWatson/react-select/tree/v1.x#select-props
const Select = ({ ...rest }) => (
  <ReactSelect {...rest} />
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
