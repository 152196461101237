import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';
import Icon from '../../../../../modules/react/components/stateless/Icon';
import SMTooltipPopover from '../SMTooltipPopover';
import styles from './styles.scss';

const propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

const defaultProps = {
  className: undefined,
};

const SMTooltip = ({
  body,
  className,
  title,
}) => {
  const overlay = (
    <SMTooltipPopover
      body={body}
      title={title}
    />
  );

  const classes = cx(
    styles.toggle,
    className,
  );

  return (
    <OverlayTrigger
      overlay={overlay}
      placement="bottom"
      rootClose
      trigger="click"
    >
      <button
        className={classes}
        type="button"
      >
        <Icon id="question-circle-solid" label="Tooltip icon" />
      </button>
    </OverlayTrigger>
  );
};

SMTooltip.propTypes = propTypes;
SMTooltip.defaultProps = defaultProps;

export default SMTooltip;
