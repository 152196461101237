import React from 'react';
import PropTypes from 'prop-types';
import { getQueryParams } from '../../../../../modules/search';
import StorageMartCheckoutConfirmationTotalRecall from '../StorageMartCheckoutConfirmationTotalRecall/legacy';
import StorageMartCheckoutConfirmationStoredge from '../StorageMartCheckoutConfirmationStoredge/legacy';

const propTypes = {
  isReservation: PropTypes.bool,
  isBuilderView: PropTypes.bool,
};

const defaultProps = {
  isReservation: false,
  isBuilderView: false,
};

const StorageMartCheckoutConfirmation = (props) => {
  const {
    confirmationNumber,
    confirmationId,
    leadId,
  } = getQueryParams();

  const { isBuilderView } = props;

  return (
    <div>
      {confirmationNumber ? (
        <StorageMartCheckoutConfirmationTotalRecall {...props} />
      ) : null}
      {confirmationId || leadId || isBuilderView ? (
        <StorageMartCheckoutConfirmationStoredge {...props} />
      ) : null}
    </div>
  );
};

StorageMartCheckoutConfirmation.propTypes = propTypes;
StorageMartCheckoutConfirmation.defaultProps = defaultProps;

export default StorageMartCheckoutConfirmation;
