import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { compose, pure } from 'utils/react';
import RadioOption from './RadioOption';

const RadioButton = ({
  options,
  ...props
}) => (
  <ButtonToolbar>
    <ButtonGroup>
      {Object.keys(options).map((value, index) => (
        <RadioOption
          {...props}
          value={value}
          key={index}
        >
          {options[value]}
        </RadioOption>
      ))}
    </ButtonGroup>
  </ButtonToolbar>
);
RadioButton.propTypes = {
  options: PropTypes.object.isRequired,
};

export default compose(
  pure(),
)(RadioButton);
