import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'react-bootstrap';
import { Modal } from '@canaveral/components';
import Button from '../../../../modules/react/components/stateless/Button';
import Form from '../../../../modules/react/components/stateful/Form';
import styles from './TabbedModal.scss';

export default class TabbedModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialValues: PropTypes.object,
    alwaysShowTabs: PropTypes.bool,
    wrapWithForm: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    onSelect: PropTypes.func,
    submitText: PropTypes.string,
    validate: PropTypes.func,
    onlyValidateOnSubmit: PropTypes.bool,
  };

  static defaultProps = {
    alwaysShowTabs: false,
    children: undefined,
    initialValues: {},
    onMount: undefined,
    onSelect: undefined,
    onUnmount: undefined,
    validate: undefined,
    wrapWithForm: false,
    submitText: 'Save',
    onlyValidateOnSubmit: undefined,
  };

  componentDidMount() {
    const { onMount } = this.props;

    if (onMount) {
      onMount.call(this);
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;

    if (onUnmount) {
      onUnmount.call(this);
    }
  }

  renderTabs() {
    const { children, alwaysShowTabs, onSelect } = this.props;

    if (React.Children.toArray(children).length === 1 && !alwaysShowTabs) {
      return children;
    }

    return (
      <Tabs id="tabbed-modal" className={styles.tabs} defaultActiveKey={0} onSelect={onSelect}>
        {React.Children.map(
          children,
          (element, index) => React.cloneElement(element, { eventKey: index }),
        )}
      </Tabs>
    );
  }

  render() {
    // We pull out the other props here to prevent them from being sent down into the Modal
    /* eslint-disable no-unused-vars */
    const {
      title,
      name,
      initialValues,
      children,
      alwaysShowTabs,
      wrapWithForm,
      onSave,
      onCancel,
      onMount,
      onUnmount,
      submitText,
      validate,
      onlyValidateOnSubmit,
      ...props
    } = this.props;
    /* eslint-enable no-unused-vars */

    const tabs = this.renderTabs();

    // todo: Respect `wrapWithForm`

    return (
      <Modal
        show
        onDismiss={onCancel}
        aria-labelledby="tabbed-modal"
        size="lg"
        {...props}
      >
        <Form
          form={name}
          onSubmit={onSave}
          initialValues={initialValues}
          validate={validate}
          onlyValidateOnSubmit={onlyValidateOnSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title id={title}>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {tabs}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit" bsStyle="success">{submitText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
