import { ID as FACILITY_SALE_BANNER_ID } from './FacilitySaleBanner/properties';
import { ID as FACILITY_UNITS_STORAGE_MART_ID } from './FacilityUnitsStorageMart/properties';
import { ID as SM_CONTACT_FORM_ID } from './SMContactForm/properties';
import { ID as SM_FACILITY_AMENITIES_ID } from './SMFacilityAmenities/properties';
import { ID as SM_FACILITY_INFORMATION_ID } from './SMFacilityInformation/properties';
import { ID as SM_PUBLIC_TRANSPORTATION_ID } from './SMPublicTransportation/properties';
import { ID as STORAGE_MART_ESIGN_STOREDGE_ID } from './StorageMartEsignStoredge/properties';
import { ID as STORAGE_MART_EXPRESS_CHECKOUT_ID } from './StorageMartExpressCheckout/properties';
import { ID as STORAGE_MART_FACILITY_LIST_ID } from './StorageMartFacilityList/properties';
import { ID as STORAGE_MART_MOVE_IN_ID } from './StorageMartMoveIn/properties';
import { ID as STORAGE_MART_HEADER_ID } from './StorageMartHeader/properties';
import { ID as STORAGE_MART_COMBINED_HEADER_ID } from './StorageMartCombinedHeader/properties';
import { ID as STORAGE_MART_NEARBY_LOCATIONS_ID } from './StorageMartNearbyLocations/properties';
import { ID as SM_SCROLL_TO_TOP_ID } from './SMScrollToTop/properties';
import { ID as STORAGE_MART_RENTAL_SUMMARY_ID } from './StorageMartRentalSummary/properties';
import { ID as STORAGE_MART_SEARCH_GEO_ID } from './StorageMartSearchGeo/properties';
import { ID as STORAGE_MART_SEARCH_HEADER_ID } from './StorageMartSearchHeader/properties';
import { ID as STORAGE_MART_SEARCH_RESULTS_ID } from './StorageMartSearchResults/properties';
import { ID as STORAGE_MART_SIZE_GUIDE_ID } from './StorageMartSizeGuide/properties';
import { ID as STORAGE_MART_TEXT_ACCORDION_ID } from './StorageMartTextAccordion/properties';
import { ID as STORAGE_MART_STORAGE_CALCULATOR_ID } from './StorageMartStorageCalculator';
import { ID as STORAGE_MART_CHECKOUT_ID } from './StorageMartCheckout';
import { ID as STORAGE_MART_CHECKOUT_CONFIRMATION_ID } from './StorageMartCheckoutConfirmation';
import { ID as STORAGE_MART_UNITS_TABLE_ID } from './StorageMartUnitsTable/properties';

export default {
  [FACILITY_SALE_BANNER_ID]: () => import('./FacilitySaleBanner/legacy'),
  [FACILITY_UNITS_STORAGE_MART_ID]: () => import('./FacilityUnitsStorageMart/legacy'),
  [SM_CONTACT_FORM_ID]: () => import('./SMContactForm/legacy'),
  [SM_FACILITY_AMENITIES_ID]: () => import('./SMFacilityAmenities/legacy'),
  [SM_FACILITY_INFORMATION_ID]: () => import('./SMFacilityInformation/legacy'),
  [SM_PUBLIC_TRANSPORTATION_ID]: () => import('./SMPublicTransportation/legacy'),
  [STORAGE_MART_ESIGN_STOREDGE_ID]: () => import('./StorageMartEsignStoredge/legacy'),
  [STORAGE_MART_EXPRESS_CHECKOUT_ID]: () => import('./StorageMartExpressCheckout/legacy'),
  [STORAGE_MART_FACILITY_LIST_ID]: () => import('./StorageMartFacilityList/legacy'),
  [STORAGE_MART_MOVE_IN_ID]: () => import('./StorageMartMoveIn/legacy'),
  [STORAGE_MART_HEADER_ID]: () => import('./StorageMartHeader/legacy'),
  [STORAGE_MART_COMBINED_HEADER_ID]: () => import('./StorageMartCombinedHeader/legacy'),
  [STORAGE_MART_NEARBY_LOCATIONS_ID]: () => import('./StorageMartNearbyLocations/legacy'),
  [SM_SCROLL_TO_TOP_ID]: () => import('./SMScrollToTop/legacy'),
  [STORAGE_MART_RENTAL_SUMMARY_ID]: () => import('./StorageMartRentalSummary/legacy'),
  [STORAGE_MART_SEARCH_GEO_ID]: () => import('./StorageMartSearchGeo/legacy'),
  [STORAGE_MART_SEARCH_HEADER_ID]: () => import('./StorageMartSearchHeader/legacy'),
  [STORAGE_MART_SEARCH_RESULTS_ID]: () => import('./StorageMartSearchResults/legacy'),
  [STORAGE_MART_SIZE_GUIDE_ID]: () => import('./StorageMartSizeGuide/legacy'),
  [STORAGE_MART_TEXT_ACCORDION_ID]: () => import('./StorageMartTextAccordion/legacy'),
  [STORAGE_MART_STORAGE_CALCULATOR_ID]: () => import('./StorageMartStorageCalculator/legacy'),
  [STORAGE_MART_CHECKOUT_ID]: () => import('./StorageMartCheckout/legacy'),
  [STORAGE_MART_CHECKOUT_CONFIRMATION_ID]: () => import('./StorageMartCheckoutConfirmation/legacy'),
  [STORAGE_MART_UNITS_TABLE_ID]: () => import('./StorageMartUnitsTable/legacy'),
};
