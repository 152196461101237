import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import StepTitle from '../StepTitle';
import StepSection from '../StepSection';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import PaymentForm from './PaymentForm';
import Translation from '../../../../../components/stateless/Translation';
import styles from '../styles.scss';

const propTypes = {
  facility: PropTypes.shape().isRequired,
  receipt: PropTypes.shape({
    totalAmount: PropTypes.number.isRequired,
  }),
};

const defaultProps = {
  receipt: undefined,
};

const Payment = ({ facility, receipt }) => {
  const stepContext = useContext(CurrentStepContext);
  const scrollToRef = useRef(null);

  return (
    <div>
      <StepTitle stepNumber={CHECKOUT_STEPS.PAYMENT}>
        {`${CHECKOUT_STEPS.PAYMENT}.`}
        {' '}
        <Translation
          id="widgets.checkout.progress.step5"
          defaultMessage="Payment"
        />
      </StepTitle>
      {
        stepContext.currentStep === CHECKOUT_STEPS.PAYMENT
        && (
          <StepSection active>
            <PaymentForm
              onSubmit={stepContext.onStepSubmit}
              currentStep={stepContext.currentStep}
              nextStep={CHECKOUT_STEPS.COMPLETE}
              formError={stepContext.formError}
              errorMessage={stepContext.errorMessage}
              submitting={stepContext.submitting}
              formData={stepContext.checkoutData}
              leadStatus={stepContext.leadData?.status}
              facility={facility}
              scrollToRef={scrollToRef}
              receipt={receipt}
            />
          </StepSection>
        )
      }
      {
        stepContext.currentStep !== CHECKOUT_STEPS.PAYMENT
        && stepContext.maxStep > CHECKOUT_STEPS.PAYMENT
        && (
          <StepSection showEditStepButton stepNumber={CHECKOUT_STEPS.PAYMENT}>
            {get(stepContext, 'checkoutData.paymentMethod.payLater') && (
              <h5>
                <Translation
                  id="widgets.checkout.payingLater"
                  defaultMessage="Paying later in store"
                />
              </h5>
            )}
            {!get(stepContext, 'checkoutData.paymentMethod.payLater') && (
              <>
                <h5>{get(stepContext, 'checkoutData.paymentMethod.cardType')}</h5>
                <div>
                  <Translation
                    id="widgets.checkout.cardEndingIn"
                    defaultMessage="Ending in"
                  />
                  {' '}
                  {get(stepContext, 'checkoutData.paymentMethod.maskedNumber')}
                </div>

                <h5>
                  <Translation
                    id="widgets.checkout.autopayEnrolled"
                    defaultMessage="Enrolled in Autopay:"
                  />
                  {' '}
                  {get(stepContext, 'checkoutData.paymentMethod.autopayEnroll') ? 'Yes' : 'No'}
                </h5>
              </>
            )}
          </StepSection>
        )
      }
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  );
};

Payment.propTypes = propTypes;
Payment.defaultProps = defaultProps;

export default Payment;
