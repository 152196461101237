import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import Translation from '../../../../../website/components/stateless/Translation';

const propTypes = {
  disableOption: PropTypes.func,
  shouldCheckOnPropsChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  innerInputComponent: PropTypes.node.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    errors: PropTypes.string,
  }).isRequired,
  emptyOption: PropTypes.oneOf([PropTypes.shape(), PropTypes.string]),
};

const defaultProps = {
  disableOption: undefined,
  shouldCheckOnPropsChange: undefined,
  emptyOption: null,
};

class HtmlSelect extends React.Component {
  componentWillReceiveProps() {
    const { shouldCheckOnPropsChange, input } = this.props;
    if (shouldCheckOnPropsChange) {
      shouldCheckOnPropsChange(input);
    }
  }

  renderEmptyOption() {
    const {
      emptyOption,
    } = this.props;

    return (typeof emptyOption === 'string' || emptyOption instanceof String)
      ? <option value="">{emptyOption}</option>
      : (
        <Translation {...emptyOption}>
          {
            text => <option value="">{text}</option>
          }
        </Translation>
      );
  }

  render() {
    const {
      input,
      meta, // eslint-disable-line no-unused-vars
      emptyOption,
      options,
      disableOption,
      shouldCheckOnPropsChange,
      ...props
    } = this.props;
    return (
      <FormControl
        componentClass="select"
        {...input}
        {...props}
        onChange={(e) => {
          if (input) {
            input.onChange(e);
          }
          const { customOnChange } = props;
          if (customOnChange) {
            customOnChange(e);
          }
        }
        }
      >
        {emptyOption !== null
          ? this.renderEmptyOption()
          : null}
        {Object.keys(options).map((value, index) => (
          <option
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            value={value}
            disabled={disableOption ? disableOption(options[value]) : false}
          >{options[value]}
          </option>
        ))}
      </FormControl>
    );
  }
}
HtmlSelect.propTypes = propTypes;

HtmlSelect.defaultProps = defaultProps;

export default HtmlSelect;
