import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import styles from './styles.scss';
import { resolveAssetUrl } from '../../../../../../modules/assets';
import SizeMeter from './SizeMeter';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  setShowInventoryList: PropTypes.func.isRequired,
};

function EmptyUnit({ setShowInventoryList }) {
  return (
    <React.Fragment>
      <Col xs={6} mdPush={6}>
        <div>
          <div className={styles.introHeader}>
            <Translation
              id="storagecalculator.emptyunit.title"
              defaultMessage="Finding the perfect size is easy. What would you like to store?"
            />
          </div>

          <div className={styles.introDescription}>
            <Translation
              id="storagecalculator.emptyunit.additemstobegin"
              defaultMessage="Add items to begin"
            />
          </div>

          <SizeMeter suggestedSize={undefined} setShowInventoryList={setShowInventoryList} />
        </div>
      </Col>
      <Col xs={6} mdPull={6} className={styles.unitImage}>
        <img
          src={resolveAssetUrl('/img/enterprise/storage-calculator/emptyunit.svg')}
          alt=""
          height="154"
          width="150"
        />

        <div className={styles.introDescription}>
          <Translation
            id="storagecalculator.emptyunit.additemstobegin"
            defaultMessage="Add items to begin"
          />
        </div>
      </Col>
    </React.Fragment>
  );
}

EmptyUnit.propTypes = propTypes;

export default EmptyUnit;
