import { query } from 'utils/graphql';
import { LOAD_APP_SUCCESS } from './app';

// Actions
const LOAD = 'voyager/hostnames/LOAD';
const LOAD_SUCCESS = 'voyager/hostnames/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/hostnames/LOAD_FAIL';
const BULK_MANAGE = 'voyager/hostnames/BULK_MANAGE';
export const BULK_MANAGE_SUCCESS = 'voyager/hostnames/BULK_MANAGE_SUCCESS';
const BULK_MANAGE_FAIL = 'voyager/hostnames/BULK_MANAGE_FAIL';

// Reducer
const initialState = {
  items: null,
  saving: false,
  saveError: null,
  fqdn: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_APP_SUCCESS:
    case LOAD_SUCCESS:
      return {
        ...state,
        items: [...action.data.hostnames],
        fqdn: getFqdn(action.data.hostnames),
      };
    case BULK_MANAGE:
      return {
        ...state,
        saving: true,
        saveError: null,
      };
    case BULK_MANAGE_SUCCESS: {
      // This overly complicated block of code merges the hostname changes
      const updates = {};
      action.updates.forEach((item) => {
        updates[item.id] = item;
      });
      const items = state.items.filter(({ id }) =>
        action.deletes.indexOf(id) === -1).map((item) => {
        if (updates[item.id]) {
          const newItem = { ...item, ...updates[item.id] };
          delete updates[item.id];
          return newItem;
        }

        return item;
      });
      Object.keys(updates).forEach((id) => {
        items.push(updates[id]);
      });

      return {
        ...state,
        items,
        saving: false,
      };
    }
    case BULK_MANAGE_FAIL:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };
    default:
      return state;
  }
}

function getFqdn(hostnames) {
  // the API returns hostnames as an array of objects with the primary, if defined, being the first one.
  if (!hostnames || !hostnames.length || !hostnames[0].primary || !hostnames[0].hostname.length) return undefined;
  return `${hostnames[0].ssl === true ? 'https://' : 'http://'}${hostnames[0].hostname}`;
}

// Action Creators
export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    force,
    promise: query`
      {
        hostnames {
          id
          hostname
          redirects {
            source
            target
          }
          primary
          ssl
        }
      }
    `,
  };
}

export function bulkManage(updates, deletes = []) {
  return {
    types: [BULK_MANAGE, BULK_MANAGE_SUCCESS, BULK_MANAGE_FAIL],
    force: true,
    updates,
    deletes,
    promise: query`
      mutation UpdateHostnames(${{ updates }}: [HostnameInput], ${{ deletes }}: [String]) {
        updateHostnames(hostnames: $updates)
        deleteHostnames(ids: $deletes)
      }
    `,
  };
}

// Selectors
export const getHostnames = state => state.hostnames;
