import { query } from 'utils/graphql';

// Actions
const LOAD_POSTS = 'voyager/adminBlog/LOAD_POSTS';
const LOAD_POSTS_SUCCESS = 'voyager/adminBlog/LOAD_POSTS_SUCCESS';
const LOAD_POSTS_FAIL = 'voyager/adminBlog/LOAD_POSTS_FAIL';
const LOAD_POST = 'voyager/adminBlog/LOAD_POST';
const LOAD_POST_SUCCESS = 'voyager/adminBlog/LOAD_POST_SUCCESS';
const LOAD_POST_FAIL = 'voyager/adminBlog/LOAD_POST_FAIL';
const LOAD_CATEGORIES = 'voyager/adminBlog/LOAD_CATEGORIES';
const LOAD_CATEGORIES_SUCCESS = 'voyager/adminBlog/LOAD_CATEGORIES_SUCCESS';
const LOAD_CATEGORIES_FAIL = 'voyager/adminBlog/LOAD_CATEGORIES_FAIL';
const CREATE_POST = 'voyager/adminBlog/CREATE_POST';
const CREATE_POST_SUCCESS = 'voyager/adminBlog/CREATE_POST_SUCCESS';
const CREATE_POST_FAIL = 'voyager/adminBlog/CREATE_POST_FAIL';
const UPDATE_POST = 'voyager/adminBlog/UPDATE_POST';
const UPDATE_POST_SUCCESS = 'voyager/adminBlog/UPDATE_POST_SUCCESS';
const UPDATE_POST_FAIL = 'voyager/adminBlog/UPDATE_POST_FAIL';
const DELETE_POST = 'voyager/adminBlog/DELETE_POST';
const DELETE_POST_SUCCESS = 'voyager/adminBlog/DELETE_POST_SUCCESS';
const DELETE_POST_FAIL = 'voyager/adminBlog/DELETE_POST_FAIL';

// Reducer
const initialState = {
  loading: false,
  saving: false,
  categories: null,
  categoryNames: null,
  posts: null,
  post: null,
  createErrors: null,
  updateErrors: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_POSTS:
    case LOAD_POST:
      return {
        ...state,
        post: null,
        loading: true,
      };
    case LOAD_POSTS_FAIL:
    case LOAD_POST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.data.blog.posts,
      };
    case LOAD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.data.blog.post,
      };
    case LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.data.blog.categories,
        categoryNames: action.data.blog.categories.map(({ name }) => name),
      };
    case CREATE_POST:
      return {
        ...state,
        saving: true,
      };
    case CREATE_POST_FAIL:
      return {
        ...state,
        saving: false,
        createErrors: action.error.response.body.errors,
      };
    case CREATE_POST_SUCCESS: {
      const nextState = {
        ...state,
        saving: false,
      };
      if (state.posts) {
        nextState.posts = [
          action.data.post,
          ...state.posts,
        ];
      }

      return nextState;
    }
    case UPDATE_POST:
      return {
        ...state,
        saving: true,
      };
    case UPDATE_POST_FAIL:
      return {
        ...state,
        saving: false,
        updateErrors: action.error,
      };
    case UPDATE_POST_SUCCESS: {
      const nextState = {
        ...state,
        saving: false,
      };
      if (state.posts) {
        nextState.posts = state.posts.map((post) => {
          if (post.id === action.data.post.id) {
            return action.data.post;
          }

          return post;
        });
      }

      return nextState;
    }
    case DELETE_POST: {
      const nextState = { ...state };
      if (state.posts) {
        nextState.posts = state.posts.filter(({ id }) => id !== action.id);
      }

      return nextState;
    }
    default:
      return state;
  }
}

// Action creators
const CategoryFragment = `
  fragment CategoryFields on BlogCategory {
    id
    name
    slug
  }
`;

const PostFragment = `
  fragment PostFields on BlogPost {
    id
    author
    content
    slug
    snippet
    timestamp
    title
  }
`;

export function loadPosts() {
  return {
    types: [LOAD_POSTS, LOAD_POSTS_SUCCESS, LOAD_POSTS_FAIL],
    promise: query`
      {
        blog {
          posts {
            ...PostFields
            category {
              ...CategoryFields
            }
          }
        }
      }

      ${PostFragment}
      ${CategoryFragment}
    `,
  };
}

export function loadPost(id) {
  return {
    types: [LOAD_POST, LOAD_POST_SUCCESS, LOAD_POST_FAIL],
    force: true,
    promise: query`
      query getPost(${{ id }}: String) {
        blog {
          post(id: $id) {
            ...PostFields
            category {
              ...CategoryFields
            }
          }
        }
      }

      ${PostFragment}
      ${CategoryFragment}
    `,
  };
}

export function loadCategories() {
  return {
    types: [LOAD_CATEGORIES, LOAD_CATEGORIES_SUCCESS, LOAD_CATEGORIES_FAIL],
    promise: query`
      {
        blog {
          categories {
            ...CategoryFields
          }
        }
      }

      ${CategoryFragment}
    `,
  };
}

export function createPost(category, post) {
  return {
    types: [CREATE_POST, CREATE_POST_SUCCESS, CREATE_POST_FAIL],
    force: true,
    promise: query`
      mutation(${{ category }}: String!, ${{ post }}: BlogPostInput!) {
        post: createBlogPost(category: $category, post: $post) {
          ...PostFields
          category {
            ...CategoryFields
          }
        }
      }

      ${PostFragment}
      ${CategoryFragment}
    `,
  };
}

export function updatePost(id, category, post) {
  return {
    types: [UPDATE_POST, UPDATE_POST_SUCCESS, UPDATE_POST_FAIL],
    force: true,
    promise: query`
      mutation(${{ id }}: String!, ${{ category }}: String!, ${{ post }}: BlogPostInput!) {
        post: updateBlogPost(id: $id, category: $category, post: $post) {
          ...PostFields
          category {
            ...CategoryFields
          }
        }
      }

      ${PostFragment}
      ${CategoryFragment}
    `,
  };
}

export function deletePost(categoryId, postId) {
  return {
    types: [DELETE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAIL],
    id: postId,
    promise: query`
      mutation(${{ categoryId }}: String!, ${{ postId }}: String!) {
        deleteBlogPost(categoryId: $categoryId, postId: $postId)
      }
    `,
  };
}
