import React from 'react';
import PropTypes from 'prop-types';
import parentStyles from '../styles.scss';
import SendMeResultsModal from '../SendMeResultsModal';
import {
  TRACK_CLICK_SIZING_GUIDE_SEND_EMAIL,
} from '../../../../../../src/web/utils/dataLayer';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  success: PropTypes.bool,
};

const defaultProps = {
  success: undefined,
};

function SendMeResultsButton({ onSubmit, show, showModal, reset, success }) {
  return (
    <React.Fragment>
      <button
        className={parentStyles.ctaButton}
        type="button"
        {...TRACK_CLICK_SIZING_GUIDE_SEND_EMAIL}
        onClick={showModal}
      >
        <Translation
          id="storagecalculator.sendmeresultsbutton"
          defaultMessage="Send me results"
        />
      </button>
      <SendMeResultsModal show={show} reset={reset} onSubmit={onSubmit} success={success} />
    </React.Fragment>
  );
}

SendMeResultsButton.propTypes = propTypes;
SendMeResultsButton.defaultProps = defaultProps;

export default SendMeResultsButton;
