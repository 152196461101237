import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Clearfix } from 'react-bootstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import gql from 'graphql-tag';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import get from 'lodash/get';
import styles from './styles.scss';
import Translation from '../../../../components/stateless/Translation';
import LinkOut from '../../../../components/stateless/LinkOut';
import renderAmenities from '../../../../components/stateful/UnitsTable/themes/StorageMart/renderAmenities';
import useLazyQuery from '../../../../../modules/react/hooks/useLazyQuery';
import { getQueryParams } from '../../../../../modules/search';
import LoadingIndicator from '../../../../components/stateless/LoadingIndicator';
import Price from '../../../../components/stateless/Price';
import Date from '../../../../components/stateless/Date';
import SMFormFeedback from '../../../components/stateless/SMFormFeedback';
import {
  TRACK_CLICK_CHECKOUT_GET_DIRECTIONS,
  TRACK_CLICK_CHECKOUT_PRINT_ORDER,
  TRACK_CHECKOUT_STEP,
  TRACK_CHECKOUT_PURCHASE,
} from '../../../../../src/web/utils/dataLayer';
import { getNextFullMonth } from '../../../../../modules/datetime';
import { getBrandName } from '../../../../../constants/smBrandNames';
import { sizeMessageProps } from '../StorageMartCheckoutTotalRecall/constants';
import LeaseSignature from './LeaseSignature';
import GET_CONFIRMATION_QUERY from './fragments/GetConfirmation';
import GET_FACILITY_QUERY from './fragments/GetFacility';
import GET_FACILITY_WITH_UNIT_GROUP_QUERY from './fragments/GetFacilityWithUnitGroup';

const GET_CONFIRMATION = gql`
  ${GET_CONFIRMATION_QUERY}
`;

export const GET_FACILITY = gql`
  ${GET_FACILITY_QUERY}
`;

export const GET_FACILITY_WITH_UNIT_GROUP = gql`
  ${GET_FACILITY_WITH_UNIT_GROUP_QUERY}
`;

const propTypes = {
  isReservation: PropTypes.bool,
  isBuilderView: PropTypes.bool,
};

const defaultProps = {
  isReservation: false,
  isBuilderView: false,
};

const StorageMartCheckoutConfirmation = ({
  isReservation,
  isBuilderView,
}) => {
  const intl = useIntl();
  const { locale } = intl;
  const {
    confirmationNumber: encodedConfirmationNumber,
    unitGroupId,
  } = getQueryParams();
  const [facilityId, setFacilityId] = useState();
  const signInUrl = `https://my-account.storage-mart.com?lang=${locale}`;

  const [checkoutQuery, {
    loading: confirmationLoading,
    error: confirmationError,
    data: checkoutData,
  }] = useLazyQuery(GET_CONFIRMATION, {
    variables: {
      confirmationNumber: encodedConfirmationNumber,
    },
    onCompleted: (confirmationResponse) => {
      if (get(confirmationResponse, 'storageMartCheckoutConfirmation.successful', false)) {
        const {
          checkout: {
            storedgeFacilityId,
          },
        } = confirmationResponse.storageMartCheckoutConfirmation;
        setFacilityId(storedgeFacilityId);
      }
    },
  });

  const [facilityQuery, {
    loading: facilityLoading,
    error: facilityError,
    data: facilityData,
  }] = useLazyQuery(GET_FACILITY, {
    variables: { id: facilityId },
  });

  const [facilityWithUnitGroupQuery, {
    loading: facilityWithUnitGroupLoading,
    error: facilityWithUnitGroupError,
    data: facilityWithUnitGroupData,
  }] = useLazyQuery(GET_FACILITY_WITH_UNIT_GROUP, {
    variables: { id: facilityId, unitGroupId },
  });

  useEffect(() => {
    if (checkoutData
      && checkoutData.storageMartCheckoutConfirmation.successful
      && facilityWithUnitGroupData
    ) {
      const checkoutUnitGroup = get(facilityWithUnitGroupData, 'facility.unitGroup');
      TRACK_CHECKOUT_STEP(get(facilityWithUnitGroupData, 'facility.unitGroup'), get(facilityWithUnitGroupData, 'facility'), 'new_booking_flow_step5', 'thank you and e-signature');
      TRACK_CHECKOUT_PURCHASE({
        unitGroup: checkoutUnitGroup,
        facility: get(facilityWithUnitGroupData, 'facility'),
        confirmationNumber: get(checkoutData, 'storageMartCheckoutConfirmation.checkout.confirmationNumber'),
        intl,
        confirmationUnit: get(checkoutData, 'storageMartCheckoutConfirmation.checkout.unit'),
        discountPlan: checkoutUnitGroup.promoId,
      });
    }
  }, [facilityWithUnitGroupData, checkoutData]);

  useEffect(() => {
    if (!checkoutData && !isBuilderView) {
      checkoutQuery();
    }
  }, [facilityId, encodedConfirmationNumber]);

  useEffect(() => {
    if (facilityId && (!unitGroupId || unitGroupId === 'undefined') && !facilityData && !isBuilderView) {
      facilityQuery();
    }

    if (facilityId && unitGroupId && unitGroupId !== 'undefined' && !facilityWithUnitGroupData && !isBuilderView) {
      facilityWithUnitGroupQuery();
    }
  }, [facilityId, unitGroupId]);

  if (confirmationError || facilityError || facilityWithUnitGroupError
    || (checkoutData && !checkoutData.storageMartCheckoutConfirmation.successful)
  ) {
    return (
      <SMFormFeedback
        context="danger"
        id="widgets.checkoutconfirmation.loadingError"
        defaultMessage="There was an error loading the confirmation"
      />
    );
  }

  if (!isBuilderView
    && (!checkoutData
      || (!facilityData && !facilityWithUnitGroupData)
      || confirmationLoading
      || facilityLoading
      || facilityWithUnitGroupLoading
    )
  ) {
    return (
      <div>
        <div style={{
          height: '100px',
          maxWidth: '450px',
        }}
        >
          <LoadingIndicator />
        </div>
        <div className={styles.orderSummary}>
          <Row>
            <Col xs={12}>
              <div style={{
                height: '32px',
                maxWidth: '225px',
                marginTop: '3em',
                marginBottom: '1em',
              }}
              >
                <LoadingIndicator />
              </div>
            </Col>
          </Row>
          <div className={classNames('row', styles.flex)}>
            <Col xs={12} md={4}>
              <div style={{ height: '450px', marbinBottom: '1em' }}><LoadingIndicator /></div>
            </Col>
            <Col xs={12} md={8}>
              <div style={{ height: '450px' }}><LoadingIndicator /></div>
            </Col>
          </div>
        </div>
      </div>
    );
  }

  const {
    storageMartCheckoutConfirmation: {
      checkout: {
        moveInDate,
        tenant: {
          firstName,
          lastName,
          address: tenantAddress,
          phone: tenantPhone,
          email,
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactPhone,
          contactFirstName,
          contactLastName,
        },
        unit: {
          name: unitName,
          length,
          width,
          amenities,
          smartSize,
        },
        paymentMethod: {
          payLater,
          maskedNumber,
          cardType,
          autopayEnroll,
          // billingAddress,
        },
        lineItems,
        discountId,
        unitsStartDate,
      },
    },
  } = isBuilderView ? {
    storageMartCheckoutConfirmation: {
      checkout: {
        moveInDate: '2022-12-31',
        tenant: {
          firstName: 'John',
          lastName: 'Appleseed',
          address: {
            address1: '1000 Main Street',
            address2: '',
            city: 'Columbia',
            state: 'MO',
            postal: '65203',
          },
          phone: '(555) 555-5555',
          email: 'email@storagemart.com',
          emergencyContactFirstName: 'Emergency',
          emergencyContactLastName: 'Contact',
          emergencyContactPhone: '(555) 555-5555',
          contactFirstName: 'Contact',
          contactLastName: 'Name',
        },
        unit: {
          name: '100',
          length: 10,
          width: 10,
          height: 8,
          amenities: [],
          smartSize: 'Medium',
        },
        paymentMethod: {
          payLater: false,
          maskedNumber: '1234',
          cardType: 'Visa',
          autopayEnroll: true,
          billingAddress: {
            address1: '1000 Main Street',
            address2: '',
            city: 'Columbia',
            state: 'MO',
            postal: '65203',
          },
        },
        lineItems: [
          {
            type: 'Rent',
            amount: 100,
            duedate: '2022-03-26T05:00:00',
            sort: 1,
          },
          {
            type: 'Discount',
            amount: -50,
            duedate: '2022-03-26T05:00:00',
            sort: 1,
          },
        ],
        discountId: 5,
        unitsStartDate: '2022-03-26T05:00:00',
        contractIds: [],
        leaseInfo: {},
        hasSignedLease: false,
      },
    },
  } : checkoutData;

  const {
    facility: {
      currency,
      currencyLocale,
      brandName,
      address: facilityAddress,
      phone: facilityPhone,
    },
  } = isBuilderView ? {
    facility: {
      currency: 'USD',
      currencyLocale: 'en-us',
      brandName: 'sm',
      address: {
        address1: '9999 Main Street',
        address2: '',
        city: 'Columbia',
        state: 'MO',
        postal: '65203',
      },
      phone: '(555) 555-5555',
    },
  } : (facilityData || facilityWithUnitGroupData);

  const printDialog = () => (
    window.print()
  );

  const rentLineItem = find(lineItems, { type: 'Rent' });
  const prorateLineItem = find(lineItems, { type: 'Pro-Rate' });
  const adminFeeLineItem = find(lineItems, { type: 'Admin Fee' });
  const paymentLineItem = find(lineItems, { type: 'Payment' });
  const discountLineItem = find(lineItems, { type: 'Discount' });
  const merchandiseLineItem = find(lineItems, { type: 'Merchandise' });

  return (
    <div className={styles.checkoutConfirmation}>
      <div className={styles.header}>
        {
          isReservation
            ? (
            <Row>
              <Col xs={12} md={8}>
                <h1 className={styles.title}>
                  <Translation
                    id="widgets.checkoutconfirmation.reservation.title"
                    defaultMessage="Thank you for your reservation."
                  />
                </h1>
                <p>
                  <Translation
                    id="widgets.checkoutconfirmation.reservation.titleText1"
                    defaultMessage="We’ve emailed your order confirmation and next steps to complete your rental."
                  />
                </p>
                <p>
                  <Translation
                    id="widgets.checkoutconfirmation.checkout.signin"
                    defaultMessage="sign in"
                  >
                    {linkText => (
                      <Translation
                        id="widgets.checkoutconfirmation.reservation.titleText2"
                        defaultMessage="You can also {link} to our online portal to keep track of your unit."
                        values={{ link: <LinkOut href={signInUrl}>{linkText}</LinkOut> }}
                      />
                    )}
                  </Translation>
                </p>
              </Col>
            </Row>
            )
            : (
            <>
              <Row>
                <Col xs={12}>
                  <LeaseSignature formData={checkoutData} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  <h1 className={styles.title}>
                    <Translation
                      id="widgets.checkoutconfirmation.checkout.title"
                      defaultMessage="Thank you for your rental."
                    />
                  </h1>
                  <p>
                    <Translation
                      id="widgets.checkoutconfirmation.checkout.titleText1"
                      defaultMessage="We’ve emailed your order confirmation and unit access instructions."
                    />
                  </p>
                  <p>
                    <Translation
                      id="widgets.checkoutconfirmation.checkout.signin"
                      defaultMessage="sign in"
                    >
                      {linkText => (
                        <Translation
                          id="widgets.checkoutconfirmation.checkout.titleText2"
                          defaultMessage="You can also {link} to our online portal to keep track of your unit."
                          values={{ link: <LinkOut href={signInUrl}>{linkText}</LinkOut> }}
                        />
                      )}
                    </Translation>
                  </p>
                </Col>
              </Row>
            </>
            )
        }
      </div>
      {
        isReservation && (
          <div className={styles.nextSteps}>
            <Row>
              <Col xs={12} md={8}>
                <h2 className={styles.subTitle}>
                  <Translation
                    id="widgets.checkoutconfirmation.reservation.nextStepsTitle"
                    defaultMessage="Next Steps:"
                  />
                </h2>
                <p>
                  <Translation
                    id="widgets.checkoutconfirmation.reservation.nextStepsText1"
                    defaultMessage="Please visit your {brandName} office within 5 days to make your payment. We accept cash, credit card, debit, or check."
                    values={{
                      brandName: getBrandName(brandName),
                    }}
                  />
                </p>
              </Col>
            </Row>
          </div>
        )
      }
      <div className={styles.orderSummary}>
        <Row>
          <Col xs={12}>
            <h2 className={classNames(styles.subTitle, styles.inline)}>
              <Translation
                id="widgets.checkoutconfirmation.orderSummary.title"
                defaultMessage="Order Summary"
              />
            </h2>
            <a
              className={classNames(styles.actionLink, styles.printOrder)}
              onClick={printDialog}
              onKeyPress={printDialog}
              role="button"
              tabIndex="0"
              {...TRACK_CLICK_CHECKOUT_PRINT_ORDER}
            >
              <Translation
                id="widgets.checkoutConfirmation.printOrder"
                defaultMessage="Print my order"
              />
            </a>
          </Col>
        </Row>
        <div className={classNames('row', styles.flex)}>
          <Col xs={12} md={4}>
            <div className={styles.content}>
              <h3>
                <Translation
                  id="widgets.checkoutconfirmation.leftContent.title"
                  defaultMessage="Unit Details"
                />
              </h3>
              <div className={styles.block}>
                <h5>
                  <Translation
                    id="widgets.checkoutconfirmation.leftContent.block1Title"
                    defaultMessage="Unit"
                  />
                  {' '}
                  {`#${unitName}`}
                </h5>
                {/*
                <div>
                  <Translation
                    id="widgets.checkoutconfirmation.unitDetails.gateCode"
                    defaultMessage="Gate Code:"
                  />
                  {' '}
                  XXXX
                </div>
                */}
                <div>
                  <Translation
                    id="widgets.checkoutconfirmation.unitDetails.checkInDate"
                    defaultMessage="Check-in Date:"
                  />
                  {' '}
                  <Date
                    day="2-digit"
                    month="long"
                    value={moveInDate}
                    year="numeric"
                  />
                </div>
              </div>
              <div className={styles.block}>
                <h5>
                  {`${width}x${length}`}
                  {' '}
                  <Translation
                    {...sizeMessageProps[smartSize.toLowerCase()]}
                  />
                </h5>
                {renderAmenities(amenities, styles.amenity, styles.tooltipIcon)}
              </div>
              <div className={styles.block}>
                <h5>
                  <Translation
                    id="widgets.checkoutconfirmation.unitDetails.block3Title"
                    defaultMessage="Location"
                  />
                </h5>
                <div>{facilityAddress.address1}</div>
                <div>{facilityAddress.address2}</div>
                <div>{`${facilityAddress.city}, ${facilityAddress.state} ${facilityAddress.postal}`}</div>
                <div>{facilityPhone}</div>

                <a
                  className={classNames(styles.actionLink, styles.getDirections)}
                  href={`https://maps.google.com/?q=${facilityAddress.address1}, ${facilityAddress.address2}, ${facilityAddress.city}, ${facilityAddress.state} ${facilityAddress.postal}`}
                  target="_blank"
                  rel="noreferrer"
                  {...TRACK_CLICK_CHECKOUT_GET_DIRECTIONS}
                >
                  <Translation
                    id="widgets.checkoutConfirmation.getDirections"
                    defaultMessage="Get Directions"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className={styles.content}>
              <Row>
                <Col xs={12}>
                  {
                    isReservation
                      ? (
                        <h3>
                          <Translation
                            id="widgets.checkoutconfirmation.reservation.rightContent.title"
                            defaultMessage="Contact and Payment Information"
                          />
                        </h3>
                      )
                      : (
                        <h3>
                          <Translation
                            id="widgets.checkoutconfirmation.checkout.rightContent.title"
                            defaultMessage="Personal and Billing Information"
                          />
                        </h3>
                      )
                  }
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={5}>
                  <div className={styles.block}>
                    <h5>
                      {firstName}
                      {' '}
                      {lastName}
                    </h5>
                    {
                      !isReservation && (
                        <>
                          <div>{tenantAddress.address1}</div>
                          <div>{tenantAddress.address2}</div>
                          <div>{`${tenantAddress.city}, ${tenantAddress.state} ${tenantAddress.postal}`}</div>
                        </>
                      )
                    }
                    <div>{email}</div>
                    <div>{tenantPhone}</div>
                  </div>
                  {
                    emergencyContactFirstName && (
                      <div className={styles.block}>
                        <h5>
                          <Translation
                            id="widgets.checkoutconfirmation.rightContent.block2Title"
                            defaultMessage="Emergency Contact"
                          />
                        </h5>
                        <div>
                          {emergencyContactFirstName}
                          {' '}
                          {emergencyContactLastName}
                        </div>
                        <div>{emergencyContactPhone}</div>
                      </div>
                    )
                  }
                  {
                    contactFirstName && (
                      <div className={styles.block}>
                        <h5>
                          <Translation
                            id="widgets.checkoutconfirmation.rightContent.block3Title"
                            defaultMessage="Authorized to Access"
                          />
                        </h5>
                        <div>
                          {contactFirstName}
                          {' '}
                          {contactLastName}
                        </div>
                      </div>
                    )
                  }
                </Col>
                <Col xs={12} sm={6}>
                  {
                    !!discountId && (
                      <div className={styles.promoDescription}>
                        <Translation
                          id={`widgets.unitstable.storagemart.unitpromo.${discountId}`}
                          defaultMessage="Placeholder"
                        >
                          {promoName => (
                            <Translation
                              id="widgets.checkout.summary.discount"
                              defaultMessage="You received the {discount} discount. Discount will be applied to your first full month of rent and will appear on your {month} bill."
                              values={{
                                discount: <strong>{promoName}</strong>,
                                month: <strong><Date month="long" value={getNextFullMonth(unitsStartDate)}>{formattedDate => formattedDate}</Date></strong>,
                              }}
                            />
                          )}
                        </Translation>
                      </div>
                    )
                  }
                  <div className={styles.orderSummaryDetails}>
                    { rentLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          <Date
                            month="long"
                            value={rentLineItem.duedate}
                          >
                            {formattedDate => (
                              <Translation
                                id="widgets.checkout.summary.rent"
                                defaultMessage="{date} rent"
                                values={{
                                  date: formattedDate,
                                }}
                              />
                            )}
                          </Date>
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={rentLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    { prorateLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          <Translation
                            id="widgets.checkout.summary.proratedcredit"
                            defaultMessage="Pro-rated credit"
                          />
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={prorateLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    { discountLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          <Translation
                            id={`widgets.unitstable.storagemart.unitpromo.${discountId}`}
                            defaultMessage="Placeholder"
                          />
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={discountLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    { merchandiseLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          <Translation
                            id="widgets.checkout.summary.merchandise"
                            defaultMessage="Door Lock"
                          />
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={merchandiseLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    { adminFeeLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          <Translation
                            id="widgets.checkout.summary.adminfee"
                            defaultMessage="Admin fee"
                          />
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={adminFeeLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    { paymentLineItem && (
                      <Clearfix className="row-no-gutters">
                        <Col xs={8}>
                          {paymentLineItem.type}
                        </Col>
                        <Col xs={4} className="text-right">
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={paymentLineItem.amount}
                          />
                        </Col>
                      </Clearfix>
                    )}
                    <Clearfix className={classNames(styles.totalRow, 'row-no-gutters')}>
                      <Col xs={8}>
                        <Translation
                          id="widgets.checkout.summary.totaldue"
                          defaultMessage="Total"
                        />
                      </Col>
                      <Col xs={4} className="text-right">
                        <Price
                          currency={currency}
                          locale={currencyLocale}
                          value={sumBy(lineItems, 'amount')}
                        />
                      </Col>
                    </Clearfix>
                  </div>
                  {
                    !isReservation && !payLater && (
                      <>
                        <div className={styles.block}>
                          <h5>
                            <Translation
                              id="widgets.checkoutconfirmation.rightContent.block5Title"
                              defaultMessage="Paid with {cardType} ending with {lastFour}"
                              values={{
                                cardType,
                                lastFour: maskedNumber,
                              }}
                            />
                          </h5>
                          {
                            autopayEnroll && (
                              <div>
                                <Translation
                                  id="widgets.checkoutconfirmation.rightContent.authorizedAutopay"
                                  defaultMessage="Monthly autopay authorized"
                                />
                              </div>
                            )
                          }
                        </div>
                        {/*
                        <div className={styles.block}>
                          <h5>
                            <Translation
                              id="widgets.checkoutconfirmation.rightContent.block6Title"
                              defaultMessage="Billing Address"
                            />
                          </h5>
                          <div>{billingAddress.address1}</div>
                          <div>{billingAddress.address2}</div>
                          <div>
                            {billingAddress.city}
                            {', '}
                            {billingAddress.state}
                            {' '}
                            {billingAddress.postal}
                          </div>
                        </div>
                        */}
                      </>
                    )
                  }
                  {
                    !isReservation && payLater && (
                      <div className={styles.block}>
                        <h5>
                          <Translation
                            id="widgets.checkoutconfirmation.rightContent.block7Title"
                            defaultMessage="Paying in store"
                          />
                        </h5>
                      </div>
                    )
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

StorageMartCheckoutConfirmation.propTypes = propTypes;
StorageMartCheckoutConfirmation.defaultProps = defaultProps;

export default StorageMartCheckoutConfirmation;
