import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getTranslations } from 'store/modules/company';

const mapStateToProps = (state, ownProps) => {
  const { getLocale } = ownProps;
  const locale = getLocale(state);
  const props = { locale };
  const translations = getTranslations(state);
  const enabledTranslations = translations.filter(translation => translation.enabled);
  const translation = enabledTranslations.find(trans => trans.language === locale);
  if (translation) {
    const messages = {};
    translation.tokens.forEach((token) => {
      if (!token.key) return;
      messages[token.key] = token.value || token.defaultValue;
    });
    props.messages = messages;
  }

  return props;
};

const propTypes = {
  locale: PropTypes.string.isRequired,
};

const defaultProps = {};
const handleIntlProviderErrors = (err) => {
  if (process.env.NODE_ENV === 'production') return; // eat errors in production

  // downgrade these to informational messages -- my eyes are bleeding!
  console.info(err);
};

const ConnectedIntlProvider = props => (
  <IntlProvider textComponent="span" key={props.locale} {...props} onError={handleIntlProviderErrors} />
);

ConnectedIntlProvider.propTypes = propTypes;
ConnectedIntlProvider.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
)(ConnectedIntlProvider);
