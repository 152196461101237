import { createContext } from 'react';

export const defaultValue = {
  preload: false,
  lazy: false,
  eager: false,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const ImageLoadingContext = createContext(defaultValue);

ImageLoadingContext.displayName = 'ImageLoadingContext';

export default ImageLoadingContext;
