import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import cx from 'classnames';
import styles from './styles.scss';

const propTypes = {
  disabled: PropTypes.bool,
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      fullAddress: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const defaultProps = {
  disabled: undefined,
};

const FacilitySelectLabel = ({ disabled, facility: { name, address } }) => {
  const classes = cx(
    styles.label,
    disabled && styles.disabled,
  );

  return (
    <div className={classes}>
      <div>{name}</div>
      <div>{address.fullAddress}</div>
    </div>
  );
};

FacilitySelectLabel.propTypes = propTypes;
FacilitySelectLabel.defaultProps = defaultProps;

FacilitySelectLabel.fragments = {
  facility: gql`
    fragment FacilitySelectLabelFacility on Facility {
      id
      name
      address {
        fullAddress
      }
    },
  `,
};


export default FacilitySelectLabel;
