const GET_FACILITY_QUERY = `
    query GetSMRentalSummaryData($id: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      brandName
      storeNumber
      address {
        address1
        address2
        city
        state
        postal
        country
      }
      phone
      unitGroups {
        id
        amenities {
          id
          name
          token
        }
        availableUnitsCount
        category
        categoryName
        discountedPrice
        price
        pricedWeekly
        promoId
        area
        size
        categoryName
        width
        length
        height
      }
    }
  }
`;

export default GET_FACILITY_QUERY;
