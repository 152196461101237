import { withFormik } from 'formik';

const withForm = ({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  enableReinitialize = false,
}) => Component => withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  enableReinitialize,
})(Component);

export default withForm;
