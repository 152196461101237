import {
  CANADA,
  UNITED_KINGDOM,
  UNITED_STATES,
} from './countries';
import { FR_CA, EN_US, EN_GB, EN_CA } from './locales';
import { QUEBEC } from './states';

export const CAD = {
  code: 'CAD',
  symbol: '$',
};

export const GBP = {
  code: 'GBP',
  symbol: '£',
};

export const USD = {
  code: 'USD',
  symbol: '$',
};

export const DEFAULT_CURRENCY = USD;

export const getCurrencyCodeForCountry = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case UNITED_STATES.code:
      return USD.code;
    case CANADA.code:
      return CAD.code;
    case UNITED_KINGDOM.code:
      return GBP.code;
    default:
      return USD.code;
  }
};

export const getCurrencyLocaleForState = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case QUEBEC.code:
      return FR_CA;
    default:
      return null;
  }
};

export const getCurrencyLocaleForCountry = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case UNITED_STATES.code:
      return EN_US;
    case CANADA.code:
      return EN_CA;
    case UNITED_KINGDOM.code:
      return EN_GB;
    default:
      return EN_US;
  }
};
