import React from 'react';
import PropTypes from 'prop-types';
import omitBy from 'lodash/omitBy';
import { reduxForm, SubmissionError } from 'redux-form';
import BaseForm from '../BaseForm';

const ReduxForm = reduxForm()(BaseForm);

const propTypes = {
  onlyValidateOnSubmit: PropTypes.bool,
  validate: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onlyValidateOnSubmit: undefined,
  validate: undefined,
  onSubmit: undefined,
};

const Form = ({ onlyValidateOnSubmit, onSubmit, validate, ...props }) => {
  const handleSubmit = (data) => {
    if (onlyValidateOnSubmit && validate) {
      const errors = omitBy(validate(data), value => value === undefined);
      if (Object.keys(errors).length) {
        throw new SubmissionError(errors);
      }
    }
    return onSubmit(data);
  };

  return (
    <ReduxForm
      {...props}
      validate={onlyValidateOnSubmit ? null : validate}
      onSubmit={handleSubmit}
    />
  );
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
