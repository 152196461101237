import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadList } from 'store/modules/facilities';
import SelectInput from '../../../../../modules/react/components/stateful/SelectInput';

const propTypes = {
  load: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  facilityId: PropTypes.string,
};

const defaultProps = {
  facilityId: '',
};

class FacilitySelect extends React.Component {
  componentDidMount() {
    const { load, loaded } = this.props;

    if (!loaded) {
      // only load enabled facilities
      load(true);
    }
  }

  render() {
    const {
      load, loaded, facilityId, options, ...rest
    } = this.props;

    const { [facilityId]: _, ...filteredOptions } = options;

    return <SelectInput options={filteredOptions} {...rest} htmlSelect />;
  }
}

FacilitySelect.propTypes = propTypes;
FacilitySelect.defaultProps = defaultProps;

const mapStateToProps = ({ facilities: { allFacilities, loaded } }) => ({
  loaded,
  options: allFacilities.reduce((options, facility) => {
    if (facility.facilityType === 'full') {
      options[facility.id] = `${facility.name} - ${facility.address.fullAddress}`;
    }
    return options;
  }, {}),
});

const mapDispatchToProps = {
  load: loadList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilitySelect);
