import React from 'react';
import PropTypes from 'prop-types';
import { Radio as BootstrapRadio } from 'react-bootstrap';
import { compose, pure } from 'utils/react';

const SingleRadio = ({
  label,
  input = {},
  value,
  meta, // eslint-disable-line no-unused-vars
  ...props
}) => (
  <BootstrapRadio
    {...input}
    {...props}
    value={value}
    checked={input && input.value === value}
  >
    {label}
  </BootstrapRadio>
);
SingleRadio.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.any,
};

const Radio = ({
  options,
  ...props
}) => (
  <div>
    {Object.keys(options).map((value, index) => (
      <SingleRadio
        {...props}
        value={value}
        key={index}
        label={options[value]}
      />
    ))}
  </div>
);
Radio.propTypes = {
  options: PropTypes.object.isRequired,
};

export default compose(
  pure(),
)(Radio);
