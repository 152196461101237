import React from 'react';
import styles from './styles.scss';
import SMFormStateDropdown from '../../../../components/stateless/SMFormStateDropdown';

const CheckoutFormStateDropdown = ({ ...props }) => (
  <SMFormStateDropdown
    className={styles.checkoutFormStateDropdown}
    errorClassName={styles.errorMessage}
    invalidClassName={styles.invalid}
    {...props}
  />
);

export default CheckoutFormStateDropdown;
