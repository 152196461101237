export const ID = '7f490626-968d-4f03-8407-8ba306c7ee5b';

export const NAME = 'StorageMart Nearby Locations';

export const OPTION_NEAR_FACILITY = 'facilityDistance';
export const OPTION_NEAR_FACILITY_GROUP = 'facilityGroup';
export const OPTION_NEAR_CURRENT_USER = 'userDistance';

export const OPTION_DEFAULT = OPTION_NEAR_CURRENT_USER;

export const FACILITY_DIST = 10;
export const HEADER_TEXT = NAME;
