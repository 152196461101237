// The constants in this file are used by companies to monitor events through
// the data layer. Only change these values after consulting with product as they
// are responsible for the events triggers tied to these ids and would be very
// mad if they suddenly stopped working.

// All data layer event identifiers are HTML data-* attributes with the `track`
// prefix. Using a data attribute reduces the chance they'll be accidentally
// overwritten or renamed by webpack in the way a class may be. Prefixing them
// with `track` reduces the chance they will conflict with other data attributes.
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes

// disable eslint for this file to allow longer id constants
/* eslint-disable */
import { getBrandCode } from '../../../constants/smBrandNames';
import { getQueryId } from './algoliaInsights';

const DATA_TRACK = 'data-track';

const dataTrack = id => ({ [DATA_TRACK]: id });

// Button or link click identifiers.
const DATA_TRACK_CLICK = `${DATA_TRACK}-click`;

const dataTrackClick = id => ({ [DATA_TRACK_CLICK]: id });

const { ALGOLIA_INDEX_NAME } = process.env;


// Voyager click event tracking.
export const TRACK_CLICK_RESERVE = dataTrackClick('reserve');
export const TRACK_CLICK_UNITS_TABLE_CALL = dataTrackClick('units-table-call');
export const TRACK_CLICK_MOVE_IN = dataTrackClick('move-in');
export const TRACK_CLICK_FACILITY_INFO_CALL = dataTrackClick('facility-info-call');

// StorageMart click event tracking.
export const TRACK_SEARCH_BY_ZIP = dataTrackClick('searchByZip');
export const TRACK_SEARCH_NEARBY_LOCATIONS = dataTrackClick('searchNearbyLocations');

// StorageMart click event tracking.
export const TRACK_CLICK_FILTER_BY_SIZE_LARGE = dataTrackClick('filterBySizeLarge');
export const TRACK_CLICK_FILTER_BY_SIZE_MEDIUM = dataTrackClick('filterBySizeMedium');
export const TRACK_CLICK_FILTER_BY_SIZE_PARKING = dataTrackClick('filterBySizeParking');
export const TRACK_CLICK_FILTER_BY_SIZE_SMALL = dataTrackClick('filterBySizeSmall');
export const TRACK_CLICK_FILTER_BY_SIZE_CLOSET = dataTrackClick('filterBySizeCloset');
export const TRACK_CLICK_READ_REVIEWS = dataTrackClick('readReviews');
export const TRACK_CLICK_RESERVE_UNIT_CLICK = dataTrackClick('reserveUnitClick');
export const TRACK_CLICK_FILTER_BY_SIZE_WORKSHOP = dataTrackClick('filterBySizeWorkshop');
export const TRACK_CLICK_NAV_CURRENT_LOCATION_CLICK = dataTrackClick('navCurrentLocationClick');
export const TRACK_CLICK_NAV_SEARCH_BY_ZIP = dataTrackClick('navSearchByZip');
export const TRACK_CLICK_GET_DIRCTIONS_CLICK = dataTrackClick('getDirectionsClick');
export const TRACK_CLICK_REVIEW_US_CLICK = dataTrackClick('reviewUsClick');
export const TRACK_CLICK_RIN_STEP_ONE_COMPLETE = dataTrackClick('rinStep1Complete');
export const TRACK_CLICK_RIN_STEP_TWO_COMPLETE = dataTrackClick('rinStep2Complete');
export const TRACK_CLICK_RIN_STEP_TWO_PAY_IN_STORE_COMPLETE = dataTrackClick('rinStep2PayInStoreComplete');
export const TRACK_CLICK_VIEW_PROMO_CALLOUT_STORE = dataTrackClick('viewPromoCalloutStore');
export const TRACK_CLICK_VIDEO_PLAY = dataTrackClick('videoPlay');
export const TRACK_CLICK_SURVEY_CLICK = dataTrackClick('surveyClick');

// StorageMart social events.
export const TRACK_CLICK_SOCIAL_FACEBOOK = dataTrackClick('socialShareFacebook');
export const TRACK_CLICK_SOCIAL_EMAIL = dataTrackClick('socialShareEmail');
export const TRACK_CLICK_SOCIAL_OTHER = dataTrackClick('socialShareOther');
export const TRACK_CLICK_SOCIAL_SMS = dataTrackClick('socialShareSMS');
export const TRACK_CLICK_SOCIAL_TWITTER = dataTrackClick('socialShareTwitter');

// MMS social events.
export const TRACK_CLICK_MMS_SOCIAL_FACEBOOK = dataTrackClick('socialShareFacebookMMS');
export const TRACK_CLICK_MMS_SOCIAL_OTHER = dataTrackClick('socialShareOtherMMS');
export const TRACK_CLICK_MMS_SOCIAL_TWITTER = dataTrackClick('socialShareTwitterMMS');

// StorageMart misc events.
export const TRACK_NO_RESULTS_LOCATION_SEARCH = dataTrack('noResultsLocationSearch');

// StorageMart value pricing events.
export const TRACK_VALUE_PRICING_NO_THANKS = dataTrackClick('vpUpgradeModalNoThanks');
export const TRACK_VALUE_CONTINUE_TO_CHECKOUT = dataTrackClick('vpUpgradeModalContiueWithUpgrade');
export const TRACK_VALUE_CONTINUE_TO_CHECKOUT_WITHOUT_UPGRADE = dataTrackClick('vpUpgradeModalContiueWithOutUpgrade');

// StorageMart sizing guide
export const TRACK_CLICK_SIZING_GUIDE_SEARCH = dataTrackClick('sizingGuideSearch');
export const TRACK_CLICK_SIZING_GUIDE_BACK_TO_CALCULATOR = dataTrackClick('sizingGuideBackToCalculator');
export const TRACK_CLICK_SIZING_GUIDE_SEND_EMAIL = dataTrackClick('sizingGuideSendEmail');
export const TRACK_CLICK_SIZING_GUIDE_LIST_MODAL = dataTrackClick('sizingGuideListModal');
export const TRACK_CLICK_SIZING_GUIDE_SELECT_UNIT = dataTrackClick('sizingGuideSelectUnit');
export const TRACK_CLICK_SIZING_GUIDE_CATEGORY = (category) => dataTrackClick(`sizingGuide${category.replace(/\s/g, '')}`);
export const TRACK_CLICK_SIZING_GUIDE_SUBTRACT_ITEM = (item) => dataTrackClick(`sizingGuideSubtract${item.replace(/\s/g, '')}`);
export const TRACK_CLICK_SIZING_GUIDE_ADD_ITEM = (item) => dataTrackClick(`sizingGuideAdd${item.replace(/\s/g, '')}`);
export const TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK1 = dataTrackClick('sizingGuideFormModalBack1');
export const TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK2 = dataTrackClick('sizingGuideFormModalBack2');
export const TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_SUBMIT = dataTrackClick('sizingGuideFormModalSubmit');
export const TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_FIND_STORAGE = dataTrackClick('sizingGuideFormModalFindStorage');
export const TRACK_CLICK_SIZING_GUIDE_SEARCH_NEARBY_LOCATIONS = dataTrackClick('sizingGuideSearchNearbyLocations');
export const TRACK_CLICK_SIZING_GUIDE_RESET = dataTrackClick('sizingGuideReset');
export const TRACK_CLICK_SIZING_GUIDE_UNIT_TABLE_CALL_TO_ACTION = dataTrackClick('sizingGuideUnitTableCallToAction');

// Checkout flow
export const TRACK_CLICK_CHECKOUT_RENT_ONLINE = dataTrackClick('checkoutRentOnline');
export const TRACK_CLICK_CHECKOUT_RENT_IN_STORE = dataTrackClick('checkoutRentInStore');
export const TRACK_CLICK_CHECKOUT_RESERVATION_STEP_ONE_SUBMIT = dataTrackClick('checkoutReservationStep1Submit');
export const TRACK_CLICK_CHECKOUT_STEP_ONE_SUBMIT = dataTrackClick('checkoutStep1Submit');
export const TRACK_CLICK_CHECKOUT_RESERVATION_STEP_TWO_SUBMIT = dataTrackClick('checkoutReservationStep2Submit');
export const TRACK_CLICK_CHECKOUT_STEP_TWO_SUBMIT = dataTrackClick('checkoutStep2Submit');
export const TRACK_CLICK_CHECKOUT_UPGRADE_EASY = dataTrackClick('checkoutUpgradeEasy');
export const TRACK_CLICK_CHECKOUT_UPGRADE_EASIER = dataTrackClick('checkoutUpgradeEasier');
export const TRACK_CLICK_CHECKOUT_UPGRADE_EASIEST = dataTrackClick('checkoutUpgradeEasiest');
export const TRACK_CLICK_CHECKOUT_STEP_THREE_SUBMIT = dataTrackClick('checkoutStep3Submit');
export const TRACK_CLICK_CHECKOUT_STEP_FOUR_SUBMIT = dataTrackClick('checkoutStep4Submit');
export const TRACK_CLICK_CHECKOUT_STEP_FIVE_SUBMIT = dataTrackClick('checkoutStep5Submit');
export const TRACK_CLICK_CHECKOUT_STEP_SIX_SUBMIT = dataTrackClick('checkoutStep6Submit');
export const TRACK_CLICK_CHECKOUT_GET_DIRECTIONS = dataTrackClick('checkoutGetDirections');
export const TRACK_CLICK_CHECKOUT_PRINT_ORDER = dataTrackClick('checkoutPrintOrder');

// Form submission identifiers.
const DATA_TRACK_SUBMIT = `${DATA_TRACK}-submit`;

const dataTrackSubmit = id => ({ [DATA_TRACK_SUBMIT]: id });

// Voyager success event tracking.
export const TRACK_SUBMIT_RESERVATION = dataTrackSubmit('reservation');
export const TRACK_SUBMIT_CURIOUS_ABOUT_STORAGE_FORM_SUCCESS = dataTrackSubmit('curiousAboutStorageFormSuccess');
export const TRACK_SUBMIT_RIN_ZERO_SUCCESS_CONFIRMATION = dataTrackSubmit('rin0SuccessConfirmation');
export const TRACK_SUBMIT_RIN_SUCCESS_CONFIRMATION = dataTrackSubmit('rinSuccessConfirmation');
export const TRACK_SUBMIT_SEND_TEXT_SUCCESS = dataTrackSubmit('sendTextSuccess');
export const TRACK_SUBMIT_RIN_STEP_ONE = dataTrackSubmit('rinStep1');
export const TRACK_SUBMIT_RIN_STEP_TWO = dataTrackSubmit('rinStep2');

// Event constants
export const UK_GET_ONLINE_QUOTES = 'ukGatedQuoteFormSuccess';
export const CALL_ME_FORM_SUCCESS = 'callMeFormSuccess';

export function trackDataLayerEvent(event) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
  });
}

export function trackDataLayerValue(value) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(value);
}

// Ecommerce tracking
const ecommerceTransformUnitGroup = (unitGroup, facility, index, intl) => {
  return (
    {
      index,
      item_id: unitGroup.id,
      item_name: `${unitGroup.width}'w' X ${unitGroup.length}'d' X ${unitGroup.height}'h`,
      coupon: unitGroup.promoId > 0 ? intl.formatMessage({
        id: `widgets.unitstablev2.storagemart.unitpromo.${unitGroup.promoId}`,
      }) : undefined,
      currency: facility.currency,
      item_brand: getBrandCode(facility.brandName),
      item_category: facility.storeNumber,
      item_category2: 'new_booking_flow',
      item_category3: unitGroup.categoryName,
      item_category4: unitGroup.amenities?.map((amenity) => amenity.name).join(', '),
      item_category5: unitGroup.availableUnitsCount > 0 ? 'available' : 'sold out',
      item_list_id: unitGroup.category,
      item_list_name: unitGroup.categoryName || unitGroup.sizeName,
      price: parseFloat(unitGroup.discountedPrice || unitGroup.price),
      quantity: 1,
    }
  );
}

export const TRACK_UNIT_GROUPS_VIEW = (unitGroups, facility, intl) => {
  trackDataLayerValue({
    event: 'view_item_list',
    ecommerce: {
      items: unitGroups.map((unitGroup, index) => ecommerceTransformUnitGroup(unitGroup, facility, index, intl))
    }
  });
};

export const TRACK_SELECT_UNIT_GROUP = (unitGroup, facility, intl) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);

  trackDataLayerValue({ ecommerce: null });
  trackDataLayerValue({
    event: 'select_item',
    ecommerce: {
      items: [transformedUnitGroup],
    },
  });
};

export const TRACK_ADD_TO_CART_UNIT_GROUP = (unitGroup, facility, intl) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);
  transformedUnitGroup.unit_upgrade = unitGroup.unit_upgrade;

  const ecommerce = {
    items: [transformedUnitGroup],
  }
  const algolia = {
    indexName: ALGOLIA_INDEX_NAME || '',
  }
  const queryID = getQueryId(facility.storeNumber);

  // fire Algolia Insights event if queryID is present
  if (queryID) {
    trackDataLayerValue({ ecommerce: null, algolia: null });
    trackDataLayerValue({
      event: 'add_to_cart_after_search',
      ecommerce,
      algolia: {
        ...algolia,
        queryID,
      },
    });
  }

  trackDataLayerValue({ ecommerce: null, algolia: null });
  trackDataLayerValue({
    event: 'add_to_cart',
    ecommerce,
    algolia,
  });
};

export const TRACK_REMOVE_FROM_CART_UNIT_GROUP = (unitGroup, facility, intl) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);

  trackDataLayerValue({ ecommerce: null });
  trackDataLayerValue({
    event: 'remove_from_cart',
    ecommerce: {
      items: [transformedUnitGroup],
    },
  });
};

export const TRACK_BEGIN_CHECKOUT = (unitGroup, facility, intl) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);

  trackDataLayerValue({
    event: 'begin_checkout',
    ecommerce: {
      items: [transformedUnitGroup],
    },
  })
};

export const TRACK_VIEW_CART = (unitGroup, facility, intl) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);

  trackDataLayerValue({
    event: 'view_cart',
    ecommerce: {
      currency: facility.currency,
      value: parseFloat(transformedUnitGroup.price),
      items: [transformedUnitGroup],
    },
  })
};

export const TRACK_CHECKOUT_STEP = (unitGroup, facility, event, step) => (
  trackDataLayerValue({
    event,
    step_name: step,
    store_id: facility.storeNumber,
    brand: getBrandCode(facility.brandName),
    item_id: unitGroup.id,
    item_name: `${unitGroup.width}'w' X ${unitGroup.length}'d' X ${unitGroup.height}'h`,
  })
);

export const TRACK_CHECKOUT_ADD_PAYMENT_INFO = (unitGroup, facility, total, intl, rentalType) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);
  transformedUnitGroup.item_variant = rentalType;

  trackDataLayerValue({
    event: 'add_payment_info',
    ecommerce: {
      currency: facility.currency,
      value: parseFloat(total),
      coupon: unitGroup.promoId > 0 ? intl.formatMessage({
        id: `widgets.unitstable.storagemart.unitpromo.${unitGroup.promoId}`,
      }) : undefined,
      payment_type: rentalType === 'RIN' ? 'credit card' : 'pay in store',
      items: [transformedUnitGroup],
    },
  });
};

export const TRACK_CHECKOUT_PURCHASE = ({ unitGroup, facility, confirmationNumber, intl, confirmationUnit, discountPlan }) => {
  const transformedUnitGroup = ecommerceTransformUnitGroup(unitGroup, facility, 0, intl);
  transformedUnitGroup.discountedPrice = confirmationUnit.price;

  const ecommerce = {
    transaction_id: confirmationNumber,
    currency: facility.currency,
    value: parseFloat(confirmationUnit.price),
    taxes: '',
    coupon: discountPlan && discountPlan.toString() !== '0' ? intl.formatMessage({
      id: `widgets.unitstablev2.storagemart.unitpromo.${discountPlan}`,
    }) : undefined,
    items: [transformedUnitGroup],
  }

  const algolia = {
    indexName: ALGOLIA_INDEX_NAME || '',
  }
  const queryID = getQueryId(facility.storeNumber);
  
  // fire Algolia Insights event if queryID is present
  if (queryID) {
    trackDataLayerValue({
      event: 'purchase_after_search',
      ecommerce,
      algolia: {
        ...algolia,
        queryID,
      },
    });
  }

  trackDataLayerValue({
    event: 'purchase',
    ecommerce,
    algolia,
  });
};
