import { query } from 'utils/graphql';

// Actions
const SHOW_MODAL = 'voyager/reservations/SHOW_MODAL';
const HIDE_MODAL = 'voyager/reservations/HIDE_MODAL';
const SUBMIT = 'voyager/reservations/SUBMIT';
const SUBMIT_SUCCESS = 'voyager/reservations/SUBMIT_SUCCESS';
const SUBMIT_FAIL = 'voyager/reservations/SUBMIT_FAIL';
const CLEAR_RESERVATION = 'voyager/reservations/CLEAR_RESERVATION';

// Reducer
const initialState = {
  submitting: false,
  error: null,
  receipt: null,
  activeGroup: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        activeGroup: action.id,
      };
    case HIDE_MODAL:
      return {
        ...state,
        activeGroup: null,
        error: false,
        receipt: false,
      };
    case SUBMIT:
      return {
        ...state,
        submitting: true,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
        receipt: action.data.receipt,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.error.length > 0 ? action.error[0].message : 'Unable to complete reservation',
      };
    case CLEAR_RESERVATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// Action Creators
export function clearReservation() {
  return {
    type: CLEAR_RESERVATION,
  };
}

export function showModal(groupId) {
  return {
    type: SHOW_MODAL,
    id: groupId,
  };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function submit({
  leadId, facilityId, unitId, unitGroupId, moveInDate, tenantId, tenant,
  discountPlanId, autoApplyDiscountPlanIds, softReservation,
  locale, pricingTypeId,
}) {
  return {
    types: [SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL],
    id: facilityId,
    force: true,
    promise: query`
      mutation ReserveUnit(
        ${{ leadId }}: String
        ${{ facilityId }}: String!
        ${{ unitId }}: String
        ${{ unitGroupId }}: String
        ${{ moveInDate }}: String
        ${{ tenantId }}: String
        ${{ tenant }}: ReservationTenantInput
        ${{ discountPlanId }}: String
        ${{ softReservation }}: Boolean
        ${{ autoApplyDiscountPlanIds }}: [String]
        ${{ locale }}: String
        ${{ pricingTypeId }}: Int
      ) {
        receipt: reserveUnit(
          leadId: $leadId
          facilityId: $facilityId
          unitId: $unitId
          unitGroupId: $unitGroupId
          moveInDate: $moveInDate
          tenantId: $tenantId
          tenant: $tenant
          discountPlanId: $discountPlanId
          autoApplyDiscountPlanIds: $autoApplyDiscountPlanIds,
          softReservation: $softReservation,
          locale: $locale,
          pricingTypeId: $pricingTypeId
        ) {
          leadId
          tenant {
            id
            firstName
            lastName
            email
            phone
            address1
            address2
            city
            country
            state
            postal
          }
          desiredMoveInDate
          discountPlan {
            id
            name
            description
          }
          dueAtMoveIn
          confirmation
          invoiceableFees {
            description
            amount
          }
          leaseUrl
        }
      }
    `,
  };
}
