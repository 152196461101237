import { query } from 'utils/graphql';

// Actions
export const LOAD = 'voyager/googleReviews/LOAD';
export const LOAD_SUCCESS = 'voyager/googleReviews/LOAD_SUCCESS';
export const LOAD_FAIL = 'voyager/googleReviews/LOAD_FAIL';

// Reducer
const initialState = {
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        [action.id]: action.data.reviews,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

// Action Creators
export function load(placeId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id: placeId,
    promise: query`
      query Reviews(${{ placeId }}: String!) {
        reviews: googleReviews(placeId: $placeId) {
          averageRating
          reviewCount
          reviews {
            author {
              name
            }
            rating
            body
            time
          }
        }
      }
    `,
  };
}
