import React from 'react';
import PropTypes from 'prop-types';
import { Dropzone } from 'components';

const ImageDropzone = ({
  handleDrop,
  className,
  children,
  multiple,
  hideIcon,
  disabled,
}) => (
  <Dropzone
    accept="image/*"
    onDrop={handleDrop}
    multiple={multiple}
    className={className}
    hideIcon={hideIcon}
    disabled={disabled}
    disableClick={disabled}
  >
    {children}
  </Dropzone>
);

ImageDropzone.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOf(PropTypes.arrayOf(PropTypes.any), PropTypes.any),
  hideIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

ImageDropzone.defaultProps = {
  multiple: false,
  children: undefined,
  className: undefined,
  hideIcon: false,
  disabled: undefined,
};

export default ImageDropzone;
