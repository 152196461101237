import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Tab } from 'react-bootstrap';
import TabbedModal from '../../../../website/components/stateful/TabbedModal';

/**
 *
 * @param {Object} config - the configuration
 * @param {string} config.tabbedModalName - the tabbed modal's name property
 * @param {string} config.tabbedModalTitle - the tabbed modal's title property
 * @param {(Object|function(props: Object, store: Object): Object)} config.modalPropsFn - the `TabbedModal` modal props or an object that returns them
 * @param {(Object[]|function(props: Object, store: Object): Object[])} config.tabsFn - an array of components to populate the tabs or a function that returns them
 * @param {Object} config.Component - the component to wrap
 * @return {Object} the wrapped component
 */
export default ({
  tabbedModalName,
  tabbedModalTitle,
  modalPropsFn,
  tabsFn,
  Component,
}) => {
  class EditModal extends React.Component {
    static propTypes = {
      onFinishEdit: PropTypes.func,
      onCancelEdit: PropTypes.func,
      isEditing: PropTypes.bool.isRequired,
      editChannel: PropTypes.string,
      payload: PropTypes.object,
    };

    static contextTypes = {
      store: PropTypes.object.isRequired,
    };

    getModalProps() {
      let modalProps = modalPropsFn;
      // If the modal props was a function instead of an object, call it
      if (typeof modalProps === 'function') {
        modalProps = modalProps(this.props, this.context.store);
      }

      return modalProps;
    }

    getTabs() {
      let tabs = tabsFn;
      if (typeof tabs === 'function') {
        const {
          onCancelEdit, onFinishEdit, payload, ...props
        } = this.props; // eslint-disable-line no-unused-vars
        tabs = tabs({ ...props, ...payload }, this.context.store);
      }

      return tabs;
    }

    handleSave = (data) => {
      const { payload } = this.props;

      this.props.onFinishEdit({ ...payload, ...data });
    };

    shouldShowModal(modalProps) {
      const { isEditing, editChannel } = this.props;

      if (modalProps.isEditing === undefined) {
        return isEditing && !editChannel;
      }

      return modalProps.isEditing;
    }

    renderModal(modalProps) {
      const { onCancelEdit, payload } = this.props;
      const tabs = this.getTabs();

      return React.createElement(
        TabbedModal,
        {
          name: tabbedModalName,
          title: tabbedModalTitle,
          onSave: this.handleSave,
          onCancel: onCancelEdit,
          initialValues: payload,
          ...modalProps,
        },
        tabs.map(({ name, children }, key) =>
          React.createElement(Tab, { key, title: name }, children)),
      );
    }

    render() {
      const parent = <Component {...this.props} />;
      const modalProps = this.getModalProps();

      if (!this.shouldShowModal(modalProps)) {
        return parent;
      }

      return (
        <div>
          {parent}
          {this.renderModal(modalProps)}
        </div>
      );
    }
  }
  EditModal.displayName = `EditModal(${Component.displayName || Component.name})`;

  return hoistNonReactStatic(EditModal, Component);
};
