import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'shared/i18n';

const Price = ({
  value,
  element = 'span',
  currency = '$',
  decimals = 2,
  state = null,
  ...props
}) => React.createElement(
  element,
  props,
  formatCurrency({ price: value, currencySymbol: currency, state, decimals }),
);
Price.propTypes = {
  value: PropTypes.number.isRequired,
  element: PropTypes.string,
  currency: PropTypes.string,
  decimals: PropTypes.number,
  state: PropTypes.string,
};

export default Price;
