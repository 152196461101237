import React from 'react';
import PropTypes from 'prop-types';
import valid from 'card-validator';
import { useField } from 'formik';
import SMFormFieldText from '../SMFormFieldText';
import blank from './CC_blank.png';
import visa from './CC_Visa.png';
import mastercard from './CC_Mastercard.png';
import discover from './CC_Discover.png';
import amex from './CC_amex.png';
import Translation from '../../../../components/stateless/Translation';

const propTypes = {
  name: PropTypes.string.isRequired,
  currentCountry: PropTypes.string.isRequired,
};

const defaultMask = '9999 9999 9999 9999 999';

const buildCardMask = (card, inputLength) => {
  let maskLength = Math.max(...card.lengths);

  const suitableLength = card.lengths.find(length => length >= inputLength);
  if (suitableLength) {
    maskLength = suitableLength;
  }

  return Array.from(Array(maskLength), (_, i) => {
    if (i === 16 && maskLength > 16) {
      return ' 9';
    }
    return card.gaps.includes(i) ? ' 9' : '9';
  }).join('');
};

const SMFormFieldCreditCard = ({ name, currentCountry, ...rest }) => {
  const [field] = useField(name);
  const { value } = field;
  const { card } = valid.number(value);
  const mask = card ? buildCardMask(card, value.length) : value && defaultMask;

  const renderCardTypeInput = (type) => {
    const getImage = (cardType) => {
      switch (cardType) {
        case 'visa':
          return visa;
        case 'mastercard':
          return mastercard;
        case 'discover':
          return discover;
        case 'american-express':
        case 'american_express':
          return amex;
        default:
          return blank;
      }
    };
    return (
      <img
        src={getImage(type)}
        alt=""
        aria-hidden="true"
        width={41}
        height={30}
        style={{
          position: 'absolute',
          top: '36px',
          left: '8px',
          width: '30px',
          height: 'auto',
        }}
      />
    );
  };

  const validTypes = {
    US: ['visa', 'mastercard', 'discover', 'amex'],
    CA: ['visa', 'mastercard', 'amex'],
    UK: ['visa', 'mastercard'],
    GB: ['visa', 'mastercard'],
    default: ['visa', 'mastercard', 'discover', 'amex'],
  };

  const cardImages = {
    visa,
    mastercard,
    discover,
    amex,
  };

  const getImage = cardType => cardImages[cardType] || cardImages.default;

  const renderCardType = () => {
    const countryTypes = validTypes[currentCountry] || validTypes.default;
    const icons = countryTypes.map((type) => {
      const image = getImage(type);
      return (
        <img
          key={type}
          src={image}
          alt=""
          aria-hidden="true"
        />
      );
    });

    return (
      <div style={{ display: 'flex', gap: '1em', margin: '1em 0 1.5em' }}>
        <div className="sr-only">
          <Translation
            id="widgets.checkout.acceptedCreditCardsTitle"
            defaultMessage="Accepted Credit Cards"
          />
          {countryTypes.map(type => `${type}`)}
        </div>
        {icons}
      </div>
    );
  };
  return (
    <>
      {renderCardType()}
      <div style={{ position: 'relative', maxWidth: '350px' }}>
        <SMFormFieldText
          style={{ paddingLeft: '54px' }}
          name={name}
          mask={mask}
          maskChar=" "
          {...rest}
          {...field}
        />
        {renderCardTypeInput(card?.type)}
      </div>
    </>
  );
};

SMFormFieldCreditCard.propTypes = propTypes;

export default SMFormFieldCreditCard;
