import {
  ENGLISH_US,
  ENGLISH_UK,
  SPANISH,
  CANADIAN_FRENCH,
} from './languages';
import {
  FR_CA,
  EN_GB,
  EN_US,
  ES,
  DEFAULT_LOCALE,
} from './locales';
import {
  isUnitedKingdom,
} from './countries';

export const LANGUAGE_NAME_MAPPING = {
  [EN_US]: ENGLISH_US.name,
  [EN_GB]: ENGLISH_UK.name,
  [ES]: SPANISH.name,
  [FR_CA]: CANADIAN_FRENCH.name,
};

export const LANGUAGE_NATIVE_NAME_MAPPING = {
  [EN_US]: ENGLISH_US.nativeName,
  [EN_GB]: ENGLISH_UK.nativeName,
  [ES]: SPANISH.nativeName,
  [FR_CA]: CANADIAN_FRENCH.nativeName,
};

export const formatStreetAddress = ({
  address1 = '',
  address2 = '',
}) => {
  if (address1 && address2) {
    return `${address1} ${address2}`;
  }

  if (address1) {
    return address1;
  }

  if (address2) {
    return address2;
  }

  return '';
};

export const formatAreaAddress = ({
  city = '',
  postal = '',
  state = '',
  country = '',
}) => {
  // This is how StorageMart asked their UK facility addresses to be formatted. If we have other
  // customers with UK facilities in the future, we may need some sort of check here to include
  // the state for those customers.
  const formattedState = isUnitedKingdom(country) ? '' : state;

  const formattedCityState = [
    city,
    formattedState,
  ]
    .filter(Boolean)
    .join(', ');

  return [
    formattedCityState,
    postal,
  ]
    .filter(Boolean)
    .join(' ');
};

export const formatFullAddress = ({
  address1 = '',
  address2 = '',
  city = '',
  postal = '',
  state = '',
  country = '',
}) => {
  const formattedStreetAddress = formatStreetAddress({ address1, address2 });
  const formattedAreaAddress = formatAreaAddress({ city, postal, state, country });

  return [
    formattedStreetAddress || undefined,
    formattedAreaAddress,
  ]
    .filter(Boolean)
    .join(', ');
};

export const internationalizePath = (locale, path) => {
  if (locale === DEFAULT_LOCALE) {
    return path;
  }

  return `/${locale}${path}`;
};
