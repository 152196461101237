import React from 'react';
import PropTypes from 'prop-types';
import Asset from '../../../../../website/components/stateless/Asset';
import styles from './ImageUploadRow.scss';
import style from './ImageUploadRow.scss';
import { ImageUploadRow, VideoUploadRow } from '.';

const HERO_IMAGE_SLIDESHOW = 'hero-image-slideshow';
const IMAGE_GRID_WIDGET_IMAGE = 'image-grid-widget-image';
const FACILITY_IMAGE = 'facility-image';
export const SM_NO_SEARCH_RESULTS = 'no-search-results';

const propTypes = {
  currentFile: PropTypes.object,
  count: PropTypes.number,
  files: PropTypes.array,
  fileKey: PropTypes.string,
  handleDeleteDefaultImage: PropTypes.func,
  lockAlt: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  currentFile: null,
  files: [],
  lockAlt: false,
};

class FileList extends React.Component {
  setTarget = index => ({ target }) => {
    this.changeValue(index, value => ({
      ...value,
      target: target.value,
    }));
  }

  changeValue = (targetIndex, valueFn) => {
    const { onChange, files } = this.props;
    const updatedFiles = files.map((val, index) => (index === targetIndex ? valueFn(val) : val));

    onChange(updatedFiles);
  };

  handleAltChange = index => ({ target }) => {
    this.changeValue(index, value => ({
      ...value,
      alt: target.value,
    }));
  }

  handleTitleChange = index => ({ target }) => {
    this.changeValue(index, value => ({
      ...value,
      title: target.value,
    }));
  }

  handleHrefChange = index => ({ target }) => {
    this.changeValue(index, value => ({
      ...value,
      link: target.value,
    }));
  }

  handleDelete = index => () => {
    const { handleDeleteDefaultImage, files, onChange } = this.props;

    if (files[index].isDefaultImage && handleDeleteDefaultImage) {
      handleDeleteDefaultImage();
    }

    onChange(
      files.filter((file, idx) => idx !== index),
    );
  }

  handleVideoEmbedChange = index => (e) => {
    this.changeValue(index, () => ({
      videoEmbedCode: e.target.value,
    }));
  }

  disableLinkField = () => {
    const { fileKey } = this.props;

    return [SM_NO_SEARCH_RESULTS, HERO_IMAGE_SLIDESHOW].includes(fileKey);
  }

  moveFileRow = (index, pivot) => () => {
    const { onChange, files } = this.props;
    const fileRows = files.slice();
    const target = index + pivot;

    if (target < 0 || target >= fileRows.length) {
      return;
    }

    const fileRow = fileRows[index];
    fileRows[index] = fileRows[target];
    fileRows[target] = fileRow;

    onChange(fileRows);
  }

  renderPlaceHolderText = () => {
    const { fileKey } = this.props;

    if (fileKey === HERO_IMAGE_SLIDESHOW) {
      return 'Links are unavailable on Hero Images.';
    } if (fileKey === IMAGE_GRID_WIDGET_IMAGE || FACILITY_IMAGE) {
      return 'http://www.domain.com';
    }
    return '';
  }

  renderRow = (value, index) => {
    if (!value) return null;

    return (
      <div key={`multi-image-drop-${index}`} className={styles.row}>
        {value.file ? this.renderImageRow(value, index) : this.renderVideoRow(value, index)}
      </div>
    );
  }

  renderImageRow({
    file,
    alt,
    link,
    target,
    title,
    decorative,
  },
  index) {
    const { lockAlt } = this.props;
    let image;
    let name;

    const defaultTarget = link === '' ? '_self' : target;

    if (!file) {
      return false;
    }

    if (typeof file === 'string') {
      image = <Asset file={file} />;
      name = file.split(/[\\/]/).pop();
    } else {
      image = <img src={file.preview} alt="" />;
      name = file.name; // eslint-disable-line prefer-destructuring
    }

    return (
      <ImageUploadRow
        name={name}
        placeholderText={this.renderPlaceHolderText()}
        disableLinkField={this.disableLinkField()}
        alt={alt}
        image={image}
        link={link}
        index={index}
        title={title}
        decorative={decorative}
        handleDelete={this.handleDelete(index)}
        handleAltChange={this.handleAltChange(index)}
        handleTitleChange={this.handleTitleChange(index)}
        handleHrefChange={this.handleHrefChange(index)}
        lockAlt={lockAlt}
        moveFileRowUp={this.moveFileRow(index, -1)}
        moveFileRowDown={this.moveFileRow(index, 1)}
        setTarget={this.setTarget}
        target={defaultTarget}
      />
    );
  }

  renderVideoRow({ videoEmbedCode, thumbnail }, index) {
    return (
      <VideoUploadRow
        videoEmbedCode={videoEmbedCode}
        thumbnail={thumbnail}
        handleDelete={this.handleDelete(index)}
        handleVideoEmbedChange={this.handleVideoEmbedChange(index)}
        moveFileRowDown={this.moveFileRow(index, 1)}
        moveFileRowUp={this.moveFileRow(index, -1)}
      />
    );
  }

  render() {
    const { files, count } = this.props;

    return (
      <React.Fragment>
        {count > 1 &&
          <div className={styles.count}>
            {files.length} / {count} uploaded
          </div>
        }
        <div className={style.rowContainer}>
          {files.map(this.renderRow)}
        </div>
      </React.Fragment>
    );
  }
}

FileList.propTypes = propTypes;
FileList.defaultProps = defaultProps;

export default FileList;
