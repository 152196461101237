import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import styles from './styles.scss';
import parentStyles from '../styles.scss';
import {
  TRACK_CLICK_SIZING_GUIDE_LIST_MODAL,
} from '../../../../../../src/web/utils/dataLayer';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  suggestedSize: PropTypes.shape,
  setShowInventoryList: PropTypes.func.isRequired,
};

const defaultProps = {
  suggestedSize: undefined,
};

function SizeMeter({ suggestedSize, setShowInventoryList }) {
  const percentageFilled = get(suggestedSize, 'percentageFilled', 0);
  const smallBucket = percentageFilled > 0 && percentageFilled < 0.4;
  const mediumBucket = percentageFilled >= 0.4 && percentageFilled < 0.80;
  const largeBucket = percentageFilled >= 0.80;

  const classes = classNames({
    [styles.meterSmall]: smallBucket,
    [styles.meterMedium]: mediumBucket,
    [styles.meterLarge]: largeBucket,
  });

  const description = () => {
    let id = 'storagecalculator.sizemeter.noitems';
    let defaultMessage = 'No items';

    if (smallBucket) {
      id = 'storagecalculator.sizemeter.low';
      defaultMessage = 'Lots of space';
    } else if (mediumBucket) {
      id = 'storagecalculator.sizemeter.middle';
      defaultMessage = 'Good to go';
    } else if (largeBucket) {
      id = 'storagecalculator.sizemeter.full';
      defaultMessage = 'Getting full';
    }

    return (
      <Translation
        id={id}
        defaultMessage={defaultMessage}
      />
    );
  };

  return (
    <div>
      <div className={styles.meter}>
        <div className={classes} style={{ width: `${percentageFilled * 100}%` }} />
      </div>
      <div className={styles.progressDescription}>
        <Translation id="storagecalculator.reviewList" defaultMessage="Review your list">
          {text => (
            <button
              aria-label={text}
              className={parentStyles.bareButton}
              onClick={() => setShowInventoryList(true)}
              type="button"
              {...TRACK_CLICK_SIZING_GUIDE_LIST_MODAL}
            >
              <i className="fa fa-fw fa-info-circle" aria-hidden="true" />
            </button>
          )}
        </Translation>
        {' '}
        {description()}
      </div>
    </div>
  );
}

SizeMeter.propTypes = propTypes;
SizeMeter.defaultProps = defaultProps;

export default SizeMeter;
