import React from 'react';
import gql from 'graphql-tag';
import useQuery from '../../modules/react/hooks/useQuery';
import IntlPhoneInput, { IntlPhoneInputLoading } from '../components/stateful/IntlPhoneInput';

export const GET_DATA = gql`
  query GetCurrentUserLoctionIntlPhoneInputData {
    currentUser {
      ...IntlPhoneInputCurrentUser
    }
  }
  ${IntlPhoneInput.fragments.currentUser}
`;

const propTypes = {};
const defaultProps = {};

const loadingIndicator = (
  <IntlPhoneInputLoading />
);


const CurrentUserLocationIntlPhoneInput = ({
  ...rest
}) => {
  const { loading, error, data } = useQuery(GET_DATA, {
    ssr: false,
  });

  if (loading) {
    return loadingIndicator;
  }
  if (error) {
    return null;
  }

  const { currentUser } = data;
  return (
    <IntlPhoneInput
      {...rest}
      currentUser={currentUser}
    />
  );
};

CurrentUserLocationIntlPhoneInput.propTypes = propTypes;
CurrentUserLocationIntlPhoneInput.defaultProps = defaultProps;

export default CurrentUserLocationIntlPhoneInput;
