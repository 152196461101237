import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Translation from '../../../../components/stateless/Translation';
import SMFacilityLink from '../SMFacilityLink';
import styles from './styles.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {};

const SMFacilityLinkButton = ({
  id,
  defaultMessage,
  facility,
}) => (
  <SMFacilityLink facility={facility} className={styles.button}>
    <Translation
      id={id}
      defaultMessage={defaultMessage}
    />
  </SMFacilityLink>
);

SMFacilityLinkButton.propTypes = propTypes;
SMFacilityLinkButton.defaultProps = defaultProps;

SMFacilityLinkButton.fragments = {
  facility: gql`
    fragment SMFacilityLinkButtonFacility on Facility {
      ...SMFacilityLinkFacility
    }

    ${SMFacilityLink.fragments.facility}
  `,
};

export default SMFacilityLinkButton;
