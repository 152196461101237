import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useIntl } from 'react-intl';
import { isUnitedKingdom } from '../../../../../modules/i18n/countries';
import { isDefaultLocale, EN_GB } from '../../../../../modules/i18n/locales';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  facility: PropTypes.shape({
    linkOutUrl: PropTypes.string,
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
      locale: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  multiLocale: PropTypes.bool,
  anchorTag: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  multiLocale: false,
  anchorTag: undefined,
};

const SMFacilityLink = ({
  children,
  facility: { pages, page: facilityPage, address: { country } },
  multiLocale,
  anchorTag,
  ...rest
}) => {
  const { locale } = useIntl();
  const anchorTagSuffix = anchorTag ? `#${anchorTag}` : '';

  let foundPage;
  if (facilityPage) {
    foundPage = facilityPage;
  } else if (multiLocale) {
    foundPage = pages.find(page => page.locale === locale)
      || pages.find(page => isDefaultLocale(page.locale))
      || (isUnitedKingdom(country) && pages.find(page => page.locale === EN_GB));
  } else {
    foundPage = pages.find(page => page.locale === locale);
  }

  if (foundPage && foundPage.path) {
    const pageHref = `${foundPage.path}${anchorTagSuffix}`;
    return (
      <a {...rest} href={pageHref}>
        {children}
      </a>
    );
  }

  return (
    <span {...rest}>
      {children}
    </span>
  );
};

SMFacilityLink.propTypes = propTypes;
SMFacilityLink.defaultProps = defaultProps;

SMFacilityLink.fragments = {
  facility: gql`
    fragment SMFacilityLinkFacility on Facility {
      id
      address {
        country
      }
      pages(filter: { live: true }) {
        id
        path
        locale
      }
    }
  `,
};

export default SMFacilityLink;
