import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import styles from './styles.scss';
import CurrentStepContext from '../CurrentStepContext';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  stepNumber: PropTypes.number.isRequired,
  showOnDesktop: PropTypes.bool,
  showOnMobile: PropTypes.bool,
};

const defaultProps = {
  showOnDesktop: false,
  showOnMobile: false,
};

const EditStepButton = ({ stepNumber, showOnDesktop, showOnMobile }) => {
  const stepContext = useContext(CurrentStepContext);

  return (
    <Button
      className={classNames(styles.editButton, {
        [styles.desktopActive]: showOnDesktop,
        [styles.mobileActive]: showOnMobile,
      })}
      bsStyle="link"
      onClick={() => stepContext.onReturnToStep(stepNumber)}
    >
      <Translation
        id="widgets.checkout.editButton"
        defaultMessage="Edit"
      />
    </Button>
  );
};

EditStepButton.propTypes = propTypes;
EditStepButton.defaultProps = defaultProps;

export default EditStepButton;
