/**
 * General Flippers
 */

// Shows promos on the units table.
export const FLIPPER_PROMOS = 'flipper_voyager_promos';

// Allows Voyager to be used with Channel Promos.
export const FLIPPER_CHANNEL_BASED_PROMOS = 'flipper_voyager_channel_based_promos';

// Shows filters, search, and checkbox column on list view.
export const FLIPPER_ADMIN_LIST_VIEW = 'flipper_voyager_builder_list_view_feature';

// Shows the language column on list view + other internationalization features.
export const FLIPPER_INTERNATIONALIZATION = 'flipper_voyager_internationalization';

// Shows the builder facility grouping feature.
export const FLIPPER_FACILITY_GROUPS = 'flipper_voyager_facility_groups';

// Shows section and widget moving feature.
export const FLIPPER_SECTION_WIDGET_MOVING = 'flipper_voyager_section_widget_moving';

// Shows enterprise widgets and sections.
export const FLIPPER_ENTERPRISE_OPTIONS = 'flipper_voyager_access_enterprise_options';

// Shows cookie policy banner
export const FLIPPER_POLICY_BANNER = 'flipper_voyager_policy_banner';

// Enables the full story data collection in site admin
export const FLIPPER_FULL_STORY = 'flipper_voyager_full_story';

// Enables ability to customize favicon
export const FLIPPER_FAVICON = 'flipper_voyager_custom_favicon';

// Enables custom headers/footers per page type per locale
export const FLIPPER_VOYAGER_HEADER_FOOTER_SETS = 'flipper_voyager_header_footer_sets';

// Enables ability access the media gallery
export const FLIPPER_VOYAGER_MEDIA_GALLERY = 'flipper_voyager_media_gallery';

// To be enabled when we are aware of StorEDGE FMS downtime
export const FLIPPER_VOYAGER_SE_FMS_DOWNTIME = 'flipper_voyager_se_fms_downtime';

/**
 * StorageMart Flippers
 */

// Enables the A/B testing script for VWO.
export const FLIPPER_STORAGEMART_VWO = 'flipper_voyager_storagemart_vwo';

// Shows StorageMart page types.
export const FLIPPER_STORAGEMART_GENERAL = 'flipper_voyager_storagemart_general';

// Enables cateogry for Rental Center widgets
export const FLIPPER_RENTAL_CENTER_OPTIONS = 'flipper_voyager_access_rental_center_options';

// Enables unit upgrade modal for StorageMart
export const FLIPPER_STORAGEMART_UNIT_UPGRADE_MODAL = 'flipper_voyager_storagemart_unit_upgrade_modal';

// Enables new storage calculator modal for StorageMart
export const FLIPPER_STORAGEMART_STORAGE_CALCULATOR_MODAL = 'flipper_voyager_storagemart_storage_calculator_modal';

// Enables the welcome modal in admin for new users
export const FLIPPER_VOYAGER_WELCOME_MODAL = 'flipper_voyager_welcome_modal';

// Enables algolia search results
export const FLIPPER_ALGOLIA_SEARCH = 'flipper_voyager_algolia_search';
