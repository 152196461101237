import React from 'react';
import PropTypes from 'prop-types';
import { Icon as CanaveralIcon } from '@canaveral/components';

const propTypes = {
  className: PropTypes.string,
  context: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  context: undefined,
  label: undefined,
};

const Icon = ({
  className,
  context,
  id,
  label,
}) => (
  <CanaveralIcon
    className={className}
    context={context}
    id={id}
    label={label}
  />
);

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
