import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Lightbox } from 'components';
import { Button } from '@canaveral/components';
import { resolveContentUrl } from '../../../../../modules/assets';
import styles from './VideoUpload.scss';

const getThumbnailLink = (embed) => {
  // Only getting thumbnails for youtube because vimeo requires
  // sending a request to their API.
  const sourceRegex = /youtube/;
  // This will find the video id for youtube and vimeo videos
  // in order to get the video's thumbnail.
  const idRegex = /(video\/|embed\/)([^"?]+)/igm;

  if (sourceRegex.test(embed)) {
    const id = idRegex.exec(embed);
    return id ? `http://img.youtube.com/vi/${id[0].slice(6)}/0.jpg` : null;
  }

  return null;
};

const propTypes = {
  onSave: PropTypes.func.isRequired,
};
const defaultProps = {};
const urlHelperImage = { file: '/img/copy-url-image.png', alt: 'helper' };

class VideoUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoEmbedCode: '',
      displayLightBox: false,
    };
  }

  onVideoEmbedEdit = (e) => {
    this.setState({ videoEmbedCode: e.target.value });
  }

  onAddVideo = () => {
    const { onSave } = this.props;
    const { videoEmbedCode } = this.state;

    onSave({
      videoEmbedCode,
      thumbnail: getThumbnailLink(videoEmbedCode),
    });
    this.setState({ videoEmbedCode: '' });
  }

  openLightbox = () => () => this.setState({ displayLightBox: true });

  closeLightbox = () => () => this.setState({ displayLightBox: false });

  renderLightbox = (image) => {
    const { displayLightBox } = this.state;

    if (!displayLightBox) {
      return false;
    }

    const items = (
      [<img
        src={resolveContentUrl(image.file)}
        alt={image.alt}
      />]
    );

    const lightboxProps = {
      items,
      itemIndex: 0,
      onClickClose: this.closeLightbox(),
      onClickDot: noop,
      onClickNext: noop,
      onClickPrev: noop,
    };

    return <Lightbox {...lightboxProps} />;
  }

  render() {
    const { videoEmbedCode } = this.state;

    return (
      <React.Fragment>
        <div className={styles.title}>
          VIDEO EMBED URL
          <Button
            icon="tool-tip"
            className={styles.helpIcon}
            onClick={this.openLightbox()}
          />
          {this.renderLightbox(urlHelperImage)}
        </div>
        <div className={styles.videoDescriptiveText}>
          <span>
          Paste the URL that appears within the video embed code here.
          This is the URL that appears between, but not including, the quotation marks.
          </span>
          <span>
          Only videos from YouTube or Vimeo are available for use with this feature.
          </span>
        </div>
        <input
          className={styles.textarea}
          name="content"
          value={videoEmbedCode}
          onChange={this.onVideoEmbedEdit}
        />
        <div className={styles.buttonContainer}>
          <Button
            context="success"
            onClick={this.onAddVideo}
            size="sm"
            disabled={videoEmbedCode.length < 1}
          >
            Add
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
VideoUpload.propTypes = propTypes;
VideoUpload.defaultProps = defaultProps;
export default VideoUpload;
