import React from 'react';
import Amenity from './Amenity';

const amenitiesToIgnore = ['closet'];

const renderAmenities = (amenities, className, iconClassName) => {
  const amenitiesList = [];
  const amenitiesToShow = amenities.filter(amenity => (
    !amenitiesToIgnore.includes(amenity.token || amenity.short_code)
  ));

  if (amenitiesToShow.length === 0) {
    return false;
  }

  for (let amenity = 0; amenity < 4; amenity += 1) {
    if (amenitiesToShow[amenity]) {
      amenitiesList.push(
        <Amenity
          key={amenity}
          amenity={amenitiesToShow[amenity]}
          className={className}
          iconClassName={iconClassName}
        />,
      );
    }
  }
  return amenitiesList;
};

export default renderAmenities;
