import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import RadioButtonsInput from 'components/form/inputs/Radio/Radio';
import ColorPickerInput from 'components/form/inputs/ColorPicker/ColorPicker';
import TextBox from 'components/form/inputs/TextBox/TextBox';
import Field from '../../../../../../modules/react/components/stateful/Field';
import SelectInput from '../../../../../../modules/react/components/stateful/SelectInput';
import classes from '../../../Section/Section.scss';
import { LimitContextProvider } from '../../../../../../admin/contexts/LimitContext';
import MediaGallerySelector from '../../../../../../admin/containers/MediaGallerySelector';

export const OPTION_COLOR = 'color';
export const OPTION_PATTERN = 'pattern';
export const OPTION_IMAGE = 'image';

const PATTERN_DOORS = 'doors';
const COLOR_TRANSPARENT = 'transparent';

const radioOptions = {
  [OPTION_COLOR]: 'Color',
  [OPTION_PATTERN]: 'Pattern',
  [OPTION_IMAGE]: 'Media Gallery Image',
};

const availablePatterns = [
  { value: PATTERN_DOORS, label: 'storage doors' },
];

const additionalColors = [
  { value: COLOR_TRANSPARENT, label: 'None' },
  { value: '#f8f8f8', label: 'Light gray' },
];

// Convert falsy option values into color
const formatOption = value => value || OPTION_COLOR;
const formatPattern = ({ value } = {}) => value || PATTERN_DOORS;

const BackgroundSelector = ({ input }) => {
  const [selectedMedium, setSelectedMedium] = useState();

  useEffect(() => {
    input.onChange(get(selectedMedium, 'mediumId'));
  }, [selectedMedium]);

  return (
    <LimitContextProvider>
      <MediaGallerySelector
        setSelectedMedium={setSelectedMedium}
        selectedMedium={selectedMedium}
        showTrash={false}
        showSearch
      />
    </LimitContextProvider>
  );
}


const BackgroundStylesEditor = ({ sectionStyle, dispatch }) => {
  const wantsPattern = sectionStyle ? sectionStyle.backgroundStyle === OPTION_PATTERN : false;
  const wantsImage = sectionStyle ? sectionStyle.backgroundStyle === OPTION_IMAGE : false;

  return (
    <div>
      <Field
        label="Section Background options"
        component={RadioButtonsInput}
        name="sectionStyle.backgroundStyle"
        options={radioOptions}
        format={formatOption}
      />
      {wantsPattern ? (
        <Row>
          <Col xs={4}>
            <Field
              label="Pattern"
              component={SelectInput}
              name="sectionStyle.backgroundPattern"
              instanceId="pattern-style-selector"
              clearable={false}
              searchable={false}
              format={formatPattern}
              options={availablePatterns}
              className={classes.patternSelect}
            />
          </Col>
        </Row>
      ) : false}
      {!wantsImage ? (
        <Field
          label={`Color${wantsPattern ? ' overlay' : ''}`}
          component={ColorPickerInput}
          name="sectionStyle.backgroundColor"
          allowCustom
          additionalColors={additionalColors}
        />
      ) : null}
      {wantsImage ? (
        <Field
          label="Background Image"
          component={BackgroundSelector}
          name="sectionStyle.backgroundMediumId"
        />
      ) : null}
    </div>
  );
};

export default connect()(BackgroundStylesEditor);
