import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import CurrentStepContext from '../CurrentStepContext';
import RequiredFieldsLabel from '../RequiredFieldsLabel';
import EditStepButton from '../EditStepButton';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  stepNumber: PropTypes.string().isRequired,
};

const defaultProps = {};

const StepTitle = ({ children, stepNumber }) => {
  const stepContext = useContext(CurrentStepContext);

  return (
    <div className={`flex justify-between xsm:items-center flex-col xsm:flex-row mb-2 ${styles.stepTitle}`}>
      <h5>
        {children}
      </h5>
      <div className={`${styles.subTitle} float-right leading-6 text-lg m-2 mt-0 mb-0`}>
        <div className={`${styles.requiredFields} ${stepContext.currentStep === stepNumber ? styles.active : ''}`}>
          <RequiredFieldsLabel />
        </div>
        <div className={`uppercase ${styles.stepProgress} ${stepContext.currentStep === stepNumber ? styles.active : ''}`}>
          <Translation
            id="widgets.checkout.step"
            defaultMessage="Step"
          />
          {' '}
          {stepNumber}
          /5
        </div>
        <EditStepButton
          stepNumber={stepNumber}
          showOnMobile={stepContext.currentStep > stepNumber}
        />
      </div>
    </div>
  );
};

StepTitle.propTypes = propTypes;
StepTitle.defaultProps = defaultProps;

export default StepTitle;
