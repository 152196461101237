import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import ImageLoadingContext from '../../../../../modules/react/contexts/ImageLoadingContext';
import contextMenu from './contextMenu';

/**
 * A decorator/HOC used to describe a widget component.
 *
 * @param {Object} details - the widget details
 * @param {string=} details.name - the widget's name
 * @param {string=} details.icon - the widget's icon (this is no longer used)
 * @param {Object=} options - widget options
 * @param {Object=} options.defaults - the widget's default payload
 * @param {Object=} options.resolve - the widget's resolve function
 * @param {Object=} options.menu - options to construct a context menu with
 * @param {function(store: Object, staticWidget: Object, payload: Object): Object} resolve - a function that can return an action, array of actions or a promise that must resolve before the widget is in a valid state (for server-side rendering)
 */
export default (details, {
  defaults = {}, resolve = null, menu = {},
} = {}) => {
  const createHoc = (Component) => {
    const name = Component.displayName || Component.name || 'WidgetComponent';
    const manifest = {
      name,
      componentId: Component.componentId,
      icon: 'http://placehold.it/50x50',
      ...details,
      defaultPayload: defaults,
    };

    class Widget extends React.Component {
      static manifestInfo = manifest;

      static propTypes = {
        isEditing: PropTypes.bool,
        isBuilderView: PropTypes.bool,
        eager: PropTypes.bool,
        lazy: PropTypes.bool,
        preload: PropTypes.bool,
      };

      render() {
        const { eager, lazy, preload } = this.props;
        return (
          <ImageLoadingContext.Provider value={{ eager, lazy, preload }}>
            <Component {...this.props} />
          </ImageLoadingContext.Provider>
        );
      }
    }

    Widget.widgetName = manifest.name;
    Widget.displayName = `Widget(${name})`;
    if (resolve) {
      Widget.resolve = resolve;
    }

    const WrappedComponent = hoistNonReactStatic(Widget, Component);
    if (menu === false) {
      return WrappedComponent;
    }

    if (typeof menu === 'function') {
      return contextMenu(menu)(WrappedComponent);
    }

    return contextMenu({ ...menu })(WrappedComponent);
  };

  if (process.env.NODE_ENV === 'testing') {
    createHoc.$$voyagerTestWhitelist = true;
  }

  return createHoc;
};
