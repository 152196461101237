import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../modules/react/components/stateless/Icon';
import PagingDots from './PagingDots';
import style from './Lightbox.scss';

const propTypes = {
  items: PropTypes.array.isRequired,
  itemIndex: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onClickDot: PropTypes.func.isRequired,
};

const defaultProps = {};

const Lightbox = ({
  items,
  itemIndex,
  onClickNext,
  onClickPrev,
  onClickClose,
  onClickDot,
}) => {
  const closeIcon = (
    <button
      className={`${style.actionIcon} ${style.closeIcon}`}
      onClick={onClickClose}
      type="button"
    >
      <Icon
        id="exit"
        label="close"
      />
    </button>
  );

  const prevClick = (e) => {
    e.stopPropagation();
    onClickPrev();
  };

  const nextClick = (e) => {
    e.stopPropagation();
    onClickNext();
  };

  const prevIcon = (
    <button
      className={`${style.actionIcon} ${style.prevIcon} ${style.inline}`}
      onClick={prevClick}
      type="button"
    >
      <Icon
        id="back-arrow"
        label="previous"
      />
    </button>
  );

  const nextIcon = (
    <button
      className={`${style.actionIcon} ${style.nextIcon} ${style.inline}`}
      onClick={nextClick}
      type="button"
    >
      <Icon
        id="select-arrow"
        label="next"
      />
    </button>
  );

  const showIcon = icon => (items.length !== 1 ? icon : false);

  const pagingDotsProps = {
    slideCount: items.length,
    slidesToScroll: 1,
    goToSlide: onClickDot,
    currentSlide: itemIndex,
    activeButtonStyles: style.activeButtonStyles,
    inactiveButtonStyles: style.inactiveButtonStyles,
    dotStyle: style.dotStyle,
  };

  return (
    <div className={style.lightboxContainer} onClick={onClickClose}>
      <div className={style.lightboxItem}>
        {showIcon(prevIcon)}
        <div className={style.itemContainer}>
          <div className={style.relative}>
            {closeIcon}
          </div>
          <div>
            {items[itemIndex]}
          </div>
        </div>
        {showIcon(nextIcon)}
        <div className={style.dotContainer}>
          {showIcon(<PagingDots {...pagingDotsProps} />)}
        </div>
      </div>
    </div>
  );
};

Lightbox.propTypes = propTypes;
Lightbox.defaultProps = defaultProps;

export default Lightbox;
