import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { FormControl } from 'react-bootstrap';
import { compose, pure } from '../../../../../src/web/utils/react';


const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string,

};

const defaultProps = {
  type: undefined,
};

const Input = ({
  type = 'text',
  input,
  ...props
}) => {
  let finalInput = input;
  if (type === 'file') {
    // File inputs are very picky, so we ignore the value to prevent a bunch of DOM warnings
    finalInput = omit(input, ['value']);
  }

  return (
    <FormControl
      type={type}
      {...finalInput}
      {...props}
    />
  );
};

Input.propTypes = propTypes;

Input.defaultProps = defaultProps;


export default compose(
  pure(),
)(Input);
