import { query } from 'utils/graphql';

// Actions
const UPDATE = 'voyager/lead/UPDATE';
const UPDATE_SUCCESS = 'voyager/lead/UPDATE_SUCCESS';
const UPDATE_FAIL = 'voyager/lead/UPDATE_FAIL';
const REMOVE_CONTACT = 'voyager/lead/REMOVE_CONTACT';
const REMOVE_CONTACT_SUCCESS = 'voyager/lead/REMOVE_CONTACT_SUCCESS';
const REMOVE_CONTACT_FAIL = 'voyager/lead/REMOVE_CONTACT_FAIL';
const UPDATE_CONTACT = 'voyager/lead/UPDATE_CONTACT';
const UPDATE_CONTACT_SUCCESS = 'voyager/lead/UPDATE_CONTACT_SUCCESS';
const UPDATE_CONTACT_FAIL = 'voyager/lead/UPDATE_CONTACT_FAIL';
const ADD_CONTACT = 'voyager/lead/ADD_CONTACT';
const ADD_CONTACT_SUCCESS = 'voyager/lead/ADD_CONTACT_SUCCESS';
const ADD_CONTACT_FAIL = 'voyager/lead/ADD_CONTACT_FAIL';
const RESET = 'voyager/lead/RESET';

// Reducer
const initialState = {
  updating: false,
  updated: false,
  errorMessage: '',
  removingContact: false,
  removedContact: false,
  removeContactError: undefined,
  updatingContact: false,
  updatedContact: false,
  updateContactError: undefined,
  addingContact: false,
  addedContact: false,
  addContactError: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        updating: true,
        updated: false,
        errorMessage: '',
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    case REMOVE_CONTACT:
      return {
        ...state,
        removingContact: true,
        removedContact: false,
        removeContactError: undefined,
      };
    case REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        removingContact: false,
        removedContact: true,
      };
    case REMOVE_CONTACT_FAIL:
      return {
        ...state,
        removingContact: false,
        removedContact: false,
        removeContactError: action.error.response.body.errors[0].message,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        updatingContact: true,
        updatedContact: false,
        updateContactError: undefined,
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        updatingContact: false,
        updatedContact: true,
      };
    case UPDATE_CONTACT_FAIL:
      return {
        ...state,
        updatingContact: false,
        updatedContact: false,
        updateContactError: action.error.response.body.errors[0].message,
      };
    case ADD_CONTACT:
      return {
        ...state,
        addingContact: true,
        addedContact: false,
        addContactError: undefined,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        addingContact: false,
        addedContact: true,
      };
    case ADD_CONTACT_FAIL:
      return {
        ...state,
        addingContact: false,
        addedContact: false,
        addContactError: action.error.response.body.errors[0].message,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function update({
  facilityId,
  tenantId,
  tenant,
}) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation UpdateTenant(
        ${{ tenantId }}: String!
        ${{ facilityId }}: String!
        ${{ tenant }}: TenantInput!
      ) {
        updateTenant: updateTenant(
          facilityId: $facilityId
          tenantId: $tenantId
          tenant: $tenant
        ) {
          success
          error
          tenant {
            id
            isMilitary
            commanderFirstName
            commanderLastName
            unitAddress
            unitCity
            unitState
            unitPostal
            contacts {
              id
              firstName
              lastName
              phoneNumber
              primary
              description
            }
          }
        }
      }
    `,
  };
}

export function removeContact({
  facilityId,
  tenantId,
  contactId,
}) {
  return {
    types: [REMOVE_CONTACT, REMOVE_CONTACT_SUCCESS, REMOVE_CONTACT_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation RemoveContact(
        ${{ tenantId }}: String!
        ${{ facilityId }}: String!
        ${{ contactId }}: String!
      ) {
        removeContact: removeContact(
          facilityId: $facilityId
          tenantId: $tenantId
          contactId: $contactId
        ) {
          success
          error
        }
      }
    `,
  };
}

export function updateContact({
  facilityId,
  tenantId,
  contactId,
  contact,
}) {
  return {
    types: [UPDATE_CONTACT, UPDATE_CONTACT_SUCCESS, UPDATE_CONTACT_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation UpdateContact(
        ${{ tenantId }}: String!
        ${{ facilityId }}: String!
        ${{ contactId }}: String!
        ${{ contact }}: ContactInput!
      ) {
        updateContact: updateContact(
          facilityId: $facilityId
          tenantId: $tenantId
          contactId: $contactId
          contact: $contact
        ) {
          success
          error
          contact {
            id
            firstName
            lastName
            phoneNumber
            primary
            description
          }
        }
      }
    `,
  };
}

export function addContact({
  facilityId,
  tenantId,
  contact,
}) {
  return {
    types: [ADD_CONTACT, ADD_CONTACT_SUCCESS, ADD_CONTACT_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation AddContact(
        ${{ tenantId }}: String!
        ${{ facilityId }}: String!
        ${{ contact }}: ContactInput!
      ) {
        addContact: addContact(
          facilityId: $facilityId
          tenantId: $tenantId
          contact: $contact
        ) {
          success
          error
          contact {
            id
            firstName
            lastName
            phoneNumber
            primary
            description
          }
        }
      }
    `,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

// Selectors
export const getTenant = state => state.tenant;
