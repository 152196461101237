import get from 'lodash/get';
import padStart from 'lodash/padStart';
import format from 'date-fns/format';
import { CHECKOUT_STEPS } from './CurrentStepContext';
import googleSessionID from '../../../../lib/googleSessionID';
import { getClientId } from '../../../../../src/web/utils/googleAnalytics';

export default function tranformPayload(
  values,
  checkoutData = {},
  unitGroupId,
  step,
  isReservation,
) {
  const payload = {
    confirmationNumber: get(checkoutData, 'confirmationNumber'),
    unitGroupId,
    isReservation,
    googleClientId: getClientId(),
  };

  if (step === CHECKOUT_STEPS.CONTACT_INFORMATION) {
    payload.tenant = Object.assign({}, checkoutData.tenant, {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: get(values, 'phone.unformattedPhoneNumber'),
      smsOptIn: values.smsOptIn,
      address: {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        postal: values.postal,
      },
    });
  } else if (checkoutData.tenant) {
    payload.tenant = checkoutData.tenant;
  }

  if (step === CHECKOUT_STEPS.UNIT_INFORMATION) {
    payload.pricingTypeId = parseInt(values.pricingTypeId, 10);
    payload.moveInDate = format(values.moveInDate, 'yyyy-MM-dd');
    payload.tenant = checkoutData.tenant;
  } else if (checkoutData.moveInDate) {
    payload.pricingTypeId = checkoutData.unit.pricingTypeId;
    payload.moveInDate = checkoutData.moveInDate.slice(0, 10);
  }

  if (step === CHECKOUT_STEPS.ACCOUNT_DETAILS) {
    payload.tenant = Object.assign(checkoutData.tenant, {
      activeDutyMilitary: values.activeDutyMilitary === 'yes',
      commanderFullName: values.commanderFullName,
      unitAddress: {
        address1: values.unitAddress1,
        address2: values.unitAddress2,
        city: values.unitCity,
        state: values.unitState,
        postal: values.unitPostal,
      },
      addEmergencyContact: values.addEmergencyContact === 'yes',
      emergencyContactFirstName: values.emergencyContactFirstName,
      emergencyContactLastName: values.emergencyContactLastName,
      emergencyContactPhone: get(values, 'emergencyContactPhone.unformattedPhoneNumber'),
      addContact: values.addContact === 'yes',
      contactFirstName: values.contactFirstName,
      contactLastName: values.contactLastName,
    });
    payload.storedValue = values.storedValue || '0';
  } else if (checkoutData.storedValue) {
    payload.storedValue = checkoutData.storedValue.toString();
  }

  if (step === CHECKOUT_STEPS.PAYMENT) {
    payload.paymentMethod = {
      payLater: values.payOption === 'payLater',
      cardNumber: values.creditCardNumber,
      expirationDate: `${padStart(values.creditCardExpireMonth, 2, '0')}/${values.creditCardExpireYear}`,
      securityCode: values.creditCardSecurityCode,
      autopayEnroll: values.autopayEnroll,
      cardType: 'visa',
      billingAddressSameAsMailingAddress: values.billingAddressSameAsMailingAddress,
      billingAddress: {
        address1: values.billingAddress1,
        address2: values.billingAddress2,
        city: values.billingCity,
        state: values.billingState,
        postal: values.billingPostal,
      },
    };
    payload.storedValue = (checkoutData.storedValue || '0').toString();
  }

  if (window.Storage && localStorage.getItem('utm')) {
    const utmData = JSON.parse(localStorage.getItem('utm'));

    if (new Date() < new Date(utmData.expire)) {
      payload.utmSource = utmData.source;
      payload.utmMedium = utmData.medium;
      payload.utmCampaign = utmData.campaign;
    }
  }

  const ga4SessionID = googleSessionID();
  if (ga4SessionID) {
    payload.googleSessionId = ga4SessionID;
  }

  return payload;
}
