import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { update as updateFacility } from 'store/modules/facilities';
import { Input } from 'containers/form';
import TabbedModal from '../../../../website/components/stateful/TabbedModal';

class FacilityImageEditor extends React.Component {
  static propTypes = {
    onCancelEdit: PropTypes.func.isRequired,
    facility: PropTypes.shape({
      name: PropTypes.string,
      primaryImage: PropTypes.string,
    }).isRequired,
    onSave: PropTypes.func.isRequired,
  };

  getInitialValue() {
    const { facility } = this.props;

    return {
      files: [{
        file: facility.primaryImage || '/img/grid-4.jpg',
        alt: facility.name,
        link: '',
        target: '',
      }],
    };
  }

  handleSave = (data) => {
    const { onSave, onCancelEdit, facility } = this.props;

    onSave(
      facility.id,
      {
        primaryImage: data.files[0].file,
      },
    );
    onCancelEdit();
  };

  render() {
    const { onCancelEdit } = this.props;

    return (
      <TabbedModal
        title="Facility Image"
        name="facility-image"
        onCancel={onCancelEdit}
        onSave={this.handleSave}
        id="facilityImage"
      >
        <Field
          component={Input.FileUpload}
          count={1}
          fileKey="facility-image"
          name="files"
          initialValues={this.getInitialValue()}
        />
      </TabbedModal>
    );
  }
}

export default connect(null, { onSave: updateFacility })(FacilityImageEditor);
