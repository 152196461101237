import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import classNames from 'classnames';
import { HelpBlock } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import SelectInput from '../../../../../modules/react/components/stateful/SelectInput';
import { getCountryInfo } from '../../../../../src/web/utils/geography';
import styles from './styles.scss';
import Translation from '../../../../components/stateless/Translation';

const propTypes = {
  name: PropTypes.name.isRequired,
  country: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  help: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

const defaultProps = {
  country: 'US',
  className: undefined,
  errorClassName: undefined,
  invalidClassName: undefined,
  required: false,
  help: undefined,
  id: 'state',
};

const SMFormStateDropdown = ({
  country,
  className,
  required,
  name,
  label,
  help,
  onChange,
  id,
  errorClassName,
  invalidClassName,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const error = meta.touched && !isEmpty(meta.error);
  const { states } = getCountryInfo(country);

  const fieldClasses = classNames(
    styles.field,
    error && styles.error,
    error && invalidClassName,
    required && styles.required,
    className,
  );

  return (
    <div className={fieldClasses}>
      <label htmlFor={id} className={styles.label}>
        <div className={styles.text}>
          <Translation {...label} />
        </div>
        <div className={styles.selectWrapper}>
          <SelectInput
            {...field}
            {...rest}
            id={id}
            name={name}
            htmlSelect
            required={required}
            options={states}
            className={styles.input}
            data-testid="sm-form-state-dropdown"
            input={{
              onChange,
            }}
          />
        </div>
      </label>
      {help && <HelpBlock>{help}</HelpBlock>}
      {error && (
        <div className={classNames(styles.message, errorClassName)}>
          <Translation
            defaultMessage="Field is invalid"
            id={meta.error}
          />
        </div>
      )}
    </div>
  );
};

SMFormStateDropdown.propTypes = propTypes;
SMFormStateDropdown.defaultProps = defaultProps;

export default SMFormStateDropdown;
