import React from 'react';
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const propTypes = {};

const defaultProps = {};

const Datepicker = React.forwardRef((props, ref) => (
  <ReactDatepicker
    ref={ref}
    {...props}
  />
));

Datepicker.propTypes = propTypes;
Datepicker.defaultProps = defaultProps;

export default Datepicker;
