import React from 'react';
import CurrentSiteContext from 'modules/react/contexts/CurrentSiteContext';

export default function withTheme(Component) {
  return function ThemedComponent(props) {
    return (
      <CurrentSiteContext.Consumer>
        {({ theme }) => <Component {...props} theme={theme} />}
      </CurrentSiteContext.Consumer>
    );
  };
}
