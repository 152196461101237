const GET_FACILITY_QUERY = `
    query GetSMRentalSummaryData($id: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      brandName
      storeNumber
      address {
        address1
        address2
        city
        state
        postal
        country
      }
      phone
    }
  }
`;

export default GET_FACILITY_QUERY;
