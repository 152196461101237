import { useEffect, useRef } from 'react';

const useOnClickOutside = (handler, show = true) => {
  const ref = useRef();
  // Create a ref that stores handler.
  const savedHandler = useRef();

  // Update ref.current value if handler changes. This allows our effect below
  // to always get latest handler without us needing to pass it in effect
  // dependencies array and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      if (!show) {
        return undefined;
      }

      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements.
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        savedHandler.current(event);
      };

      document.addEventListener('click', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('click', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [show],
  );

  return ref;
};

export default useOnClickOutside;
