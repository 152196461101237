import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'react-bootstrap';
import Button from '../../../../modules/react/components/stateless/Button';
import BasicTenantForm from '../../../../website/components/stateful/BasicTenantForm';
import Form from '../../../../modules/react/components/stateful/Form';
import Field from '../../../../modules/react/components/stateful/Field';
import LiveFacilitySelectInput from '../../containers/form/inputs/LiveFacilitySelectInput';

const propTypes = {
  facilityId: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

const defaultProps = {
  facilityId: null,
  submitText: null,
  errorMessage: '',
};

const LeadForm = ({
  facilityId,
  submitText,
  onSubmit,
  isSubmitting,
  errorMessage,
}) => (
  <Form form="leadForm" onSubmit={onSubmit}>
    <Row>
      <Col xs={12}>
        {errorMessage && <Alert bsStyle="danger">{errorMessage}</Alert>}
        <BasicTenantForm required />
        {!facilityId &&
          <Field
            component={LiveFacilitySelectInput}
            label="Facility"
            name="facilityId"
            emptyOption=""
            required
          />
        }
      </Col>
    </Row>
    {submitText &&
      <Row className="text-center">
        <Col xs={12}>
          <Button
            type="submit"
            bsStyle="success"
            disabled={isSubmitting}
            className="color-secondary-background color-primary-background-hover"
          >
            {submitText}
          </Button>
        </Col>
      </Row>
    }
  </Form>
);

LeadForm.propTypes = propTypes;
LeadForm.defaultProps = defaultProps;

export default LeadForm;
