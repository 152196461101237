import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { object, bool } from 'yup';
import { Row, Col } from 'react-bootstrap';
import { Alert } from '@canaveral/components';
import get from 'lodash/get';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import classNames from 'classnames';
import SMFormFieldText from '../../../../components/stateless/SMFormFieldText';
import withForm from '../../../../../../admin/hocs/withForm';
import Translation from '../../../../../components/stateless/Translation';
import CheckoutSubmitButton from '../../StorageMartCheckoutTotalRecall/CheckoutSubmitButton';
import {
  TRACK_CLICK_CHECKOUT_STEP_SIX_SUBMIT,
} from '../../../../../../src/web/utils/dataLayer';
import styles from '../styles.scss';

const propTypes = {
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
};

const defaultProps = {};

const validationSchema = object().shape({
  agreed: bool().oneOf([true], 'widgets.checkout.agreed.required'),
});

const initialValues = {
  agreed: false,
};

const LeaseSignatureForm = ({
  formData,
  setFieldValue,
}) => {
  const [agreed, setAgreed] = useState(false);
  const hasSignedLease = get(formData, 'storageMartCheckoutConfirmation.checkout.hasSignedLease', false);
  const [completed, setCompleted] = useState(hasSignedLease);
  const [error, setError] = useState(false);
  const leaseInfo = get(formData, 'storageMartCheckoutConfirmation.checkout.leaseInfo', '{}');
  const contractIds = get(formData, 'storageMartCheckoutConfirmation.checkout.contractIds', []);

  const onAgreed = () => {
    setAgreed(true);
    setFieldValue('agreed', true);
  };

  const onUnagree = () => {
    setAgreed(false);
    setFieldValue('agreed', false);
  };

  const onSubmit = (event) => {
    setError(false);

    const groupKey = get(_ps.getAll(), '[0].parameters.context.groups.initialized.keys[0]');
    if (groupKey) {
      _ps(`${groupKey}:send`, 'agreed', {
        disable_sending: false,
        event_callback: (err) => {
          if (err) {
            setError(true);
            return false;
          }

          setCompleted(true);
          return true;
        },
      });
    } else {
      setError(true);
    }

    event.preventDefault();
  };

  return (
    <>
      {contractIds.length ? (
        <>
          {completed ? (
            <h1 className={styles.title}>
              <Translation
                id="widgets.checkoutconfirmation.checkout.esignatureTitleCompleted"
                defaultMessage="Rental Complete!"
              />
            </h1>
          ) : (
            <>
              <h1 className={styles.title}>
                <Translation
                  id="widgets.checkoutconfirmation.checkout.esignatureTitle"
                  defaultMessage="Almost finished"
                />
              </h1>
              <p>
                <Translation
                  id="widgets.checkoutconfirmation.checkout.esignatureSubtext"
                  defaultMessage="Customers who e-sign save an average of 20 minutes on moving day!"
                />
              </p>
            </>
          )}
          <div className={classNames(styles.content, styles.esignature)}>
            {completed ? <div className={styles.esignatureOverlay} /> : null}
            {leaseInfo && (
              <Translation
                id="widgets.checkoutconfirmation.checkout.acceptanceLanguage"
                defaultMessage="I understand and agree to {{contracts}}"
              >
                {acceptanceLanguage => (
                  <PSClickWrap
                    dynamic
                    filter={`id==${contractIds.join(',')}`}
                    accessId="b87e046c-763b-459a-9687-3590d41579f7"
                    signerId={get(formData, 'storageMartCheckoutConfirmation.checkout.tenant.email')}
                    renderData={JSON.parse(leaseInfo)}
                    clickWrapStyle="combined"
                    onValid={onAgreed}
                    onInvalid={onUnagree}
                    disableSending
                    acceptanceLanguage={acceptanceLanguage}
                  />
                )}
              </Translation>
            )}
            <Row>
              <Col xs={12}>
                <form onSubmit={onSubmit}>
                  <SMFormFieldText
                    type="hidden"
                    name="agreed"
                  />
                  <CheckoutSubmitButton
                    submitLabel={{
                      id: 'widgets.checkout.esignButton.submit',
                      defaultMessage: 'e-Sign Rental Agreement',
                    }}
                    submitting={completed}
                    submittingLabel={{
                      id: 'widgets.checkout.esignButton.completed',
                      defaultMessage: 'Thank You',
                    }}
                    disabled={!agreed}
                    {...TRACK_CLICK_CHECKOUT_STEP_SIX_SUBMIT}
                  />
                  {error ? (
                    <Alert context="danger">
                      <Translation
                        id="widgets.checkoutconfirmation.checkout.esignatureError"
                        defaultMessage="There was an error sending the e-signature. Please try again."
                      />
                    </Alert>
                  ) : null}
                </form>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <h1 className={styles.title}>
            <Translation
              id="widgets.checkoutconfirmation.checkout.nonEsignatureTitle"
              defaultMessage="Almost finished"
            />
          </h1>
          <div className={classNames(styles.content, styles.esignature)}>
            <Translation
              id="widgets.checkoutconfirmation.checkout.nonEsignatureDescription"
              defaultMessage="In order to finish your rental you will need to sign your lease."
            />
          </div>
        </>
      )}
    </>
  );
};

LeaseSignatureForm.propTypes = propTypes;
LeaseSignatureForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: () => (
    initialValues
  ),
  validationSchema,
})(LeaseSignatureForm);
