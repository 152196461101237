import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

const defaultRenderer = data => (<span>{data.label}</span>);

export default class ReactSelect extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    optionRenderer: PropTypes.func,
    valueRenderer: PropTypes.func,
  };

  static defaultProps = {
    optionRenderer: defaultRenderer,
    valueRenderer: defaultRenderer,
  };

  render() {
    const {
      meta, // eslint-disable-line react/prop-types
      input,
      ...props
    } = this.props;

    return (
      <Select
        {...input}
        {...props}
      />
    );
  }
}
