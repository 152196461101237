import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  isReservation: PropTypes.bool.isRequired,
};

const defaultProps = {};

const Step = ({ currentStep, step, stepNumber, translationId, defaultMessage }) => (
  <div className={`${styles.step} ${currentStep === step ? 'font-bold' : ''} ${currentStep > step ? 'items-center flex' : ''} text-black text-base`}>
    <span className={`border-2 border-black rounded-full mr-2 ${styles.number} ${currentStep === step ? 'bg-[#FFC72C]' : ''}  ${currentStep > step ? 'bg-black px-1.5 py-0' : 'px-2 py-1'}`}>
      {currentStep > step ? <i className="fas fa-check text-[#FFC72C] text-[0.9em]" /> : stepNumber}
    </span>
    <Translation id={translationId} defaultMessage={defaultMessage} />
  </div>
);

const CheckoutProgress = ({ isReservation }) => {
  const { currentStep } = useContext(CurrentStepContext);

  return (
    <div className={`${styles.checkoutProgress} bg-gray-200 shadow-md mb-8 py-4 sticky top-0 z-10 gap-4 items-center justify-center`}>
      <Step currentStep={currentStep} step={CHECKOUT_STEPS.CONTACT_INFORMATION} stepNumber={CHECKOUT_STEPS.CONTACT_INFORMATION} translationId="widgets.checkout.progress.step1" defaultMessage="Contact Information" />
      <Step currentStep={currentStep} step={CHECKOUT_STEPS.UNIT_INFORMATION} stepNumber={CHECKOUT_STEPS.UNIT_INFORMATION} translationId="widgets.checkout.progress.step2" defaultMessage="Storage Unit" />
      {!isReservation && (
        <>
          <Step currentStep={currentStep} step={CHECKOUT_STEPS.ACCOUNT_DETAILS} stepNumber={CHECKOUT_STEPS.ACCOUNT_DETAILS} translationId="widgets.checkout.progress.step3" defaultMessage="Account Details" />
          <Step currentStep={currentStep} step={CHECKOUT_STEPS.PAYMENT} stepNumber={CHECKOUT_STEPS.PAYMENT} translationId="widgets.checkout.progress.step5" defaultMessage="Payment" />
        </>
      )}
    </div>
  );
};

CheckoutProgress.propTypes = propTypes;
CheckoutProgress.defaultProps = defaultProps;

export default CheckoutProgress;
