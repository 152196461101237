import { query } from 'utils/graphql';
import { LOAD_APP, LOAD_APP_SUCCESS, LOAD_APP_FAIL } from './app';

// Actions
const LOAD = 'voyager/user/LOAD';
const LOAD_SUCCESS = 'voyager/user/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/user/LOAD_FAIL';
const AUTH_CHECK = 'voyager/user/AUTH_CHECK';
const AUTH_CHECK_SUCCESS = 'voyager/user/AUTH_CHECK_SUCCESS';
const AUTH_CHECK_FAIL = 'voyager/user/AUTH_CHECK_FAIL';

// Reducer
const initialState = {
  loaded: false,
  loading: false,
  isAuthenticated: false,
  userLocation: null,
  ipAddress: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isAuthenticated: action.data.user && action.data.user.id,
        auth: action.data.user,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case AUTH_CHECK_SUCCESS:
    case AUTH_CHECK_FAIL:
      return {
        ...state,
        isAuthenticated: action.type === AUTH_CHECK_SUCCESS,
      };
    case LOAD_APP:
      return {
        ...state,
        loading: true,
      };
    case LOAD_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        userLocation: action.data.user.location,
        ipAddress: action.data.user.ipAddress,
      };
    case LOAD_APP_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Selectors

export const getUserAuthProfile = state => (
  state.user && state.user.auth
);

export const isHyper = state => (
  state.user.auth.hyper
);
export const getUser = state => state.user;

export const isLoaded = state => (
  state.user.loaded
);

export const isAuthenticated = state => (
  !!(state.user.auth && state.user.auth.id)
);

export const getUserLocation = state => state.user.userLocation;

export const getUserIpAddress = state => state.user.ipAddress;

// Action Creators
export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async (client) => {
      // First, we ensure that the token the server has is still valid
      await client.get('/auth/check');

      return (query`
        {
          user {
            id
            companyId
            name
            hyper
            permissions {
              can
              actions
              subjects
            }
          }
        }
      `)(client);
    },
  };
}

export function authCheck() {
  return {
    types: [AUTH_CHECK, AUTH_CHECK_SUCCESS, AUTH_CHECK_FAIL],
    force: true,
    promise: client => client.get('/auth/check'),
  };
}
