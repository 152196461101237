import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import cx from 'classnames';
import IntlTelInput from 'react-intl-tel-input';
import { useIntl } from 'react-intl';
import maxPhoneLengthByCountry from './maxPhoneLengthByCountry';
import getCountries from '../../../../src/web/utils/getCountries';
import 'react-intl-tel-input/dist/main.css';
import './styles.scss';

const propTypes = {
  currentUser: PropTypes.shape({
    location: PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  format: PropTypes.boolean,
  initialCountry: PropTypes.string,
  inputClassName: PropTypes.string,
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  onPhoneBlur: PropTypes.func,
  onFlagClick: PropTypes.func,
  onFlagChange: PropTypes.func,
  separateDialCode: PropTypes.boolean,
  style: PropTypes.object.shape({}),
  wrapperClassName: PropTypes.string,
  required: PropTypes.bool,
};

const defaultProps = {
  preferredCountries: ['us', 'ca', 'gb'],
  style: {},
  wrapperClassName: '',
  initialCountry: null,
  inputClassName: '',
  format: false,
  separateDialCode: false,
  onPhoneBlur: () => {},
  onFlagClick: () => {},
  onFlagChange: () => {},
  required: false,
};

const IntlPhoneInput = ({
  currentUser,
  fieldName,
  format,
  preferredCountries,
  value,
  wrapperClassName,
  inputClassName,
  initialCountry,
  separateDialCode,
  style,
  onFlagClick,
  onPhoneBlur,
  onPhoneChange,
  onFlagChange,
  required,
}) => {
  const defaultCountry = initialCountry || currentUser.location.countryCode.toLowerCase();

  const { locale } = useIntl();

  const [inputMaxLength, setInputMaxLength] = useState(maxPhoneLengthByCountry(defaultCountry));

  const codeChange = (phone, country, fullPhone) => {
    onFlagChange(phone, country, fullPhone);
    onPhoneChange(true, phone, country, fullPhone);
    setInputMaxLength(maxPhoneLengthByCountry(country.iso2));
  };

  const countries = getCountries(locale);
  return (
    <IntlTelInput
      countriesData={countries}
      containerClassName={[cx(wrapperClassName, 'intl-tel-input')]}
      defaultCountry={defaultCountry}
      fieldName={fieldName}
      format={format}
      inputClassName={[cx(inputClassName, 'form-control')]}
      numberType="FIXED_LINE_OR_MOBILE"
      onSelectFlag={codeChange}
      onFlagClick={onFlagClick}
      onPhoneNumberBlur={onPhoneBlur}
      onPhoneNumberChange={onPhoneChange}
      preferredCountries={preferredCountries}
      separateDialCode={separateDialCode}
      telInputProps={{
        maxLength: inputMaxLength,
        style,
        required,
      }}
      useMobileFullscreenDropdown
      value={value}
    />
  );
};

IntlPhoneInput.propTypes = propTypes;
IntlPhoneInput.defaultProps = defaultProps;

IntlPhoneInput.fragments = {
  currentUser: gql`
    fragment IntlPhoneInputCurrentUser on CurrentUser {
      location {
        countryCode
      }
    }
  `,
};

export default IntlPhoneInput;
