import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import filter from 'lodash/filter';
import map from 'lodash/map';
import ApolloClientConsumer from '../../../modules/react/components/stateful/ApolloClientConsumer';
import SendMeResultsButton from '../widgets/dynamic/StorageMartStorageCalculator/SendMeResultsButton';

const SEND_ME_RESULTS = gql`
  mutation SMStorageCalculatorSendMeResults($payload: SMStorageCalculatorSendMeResultsInput!) {
    storageMartStorageCalculatorSendMeResults(payload: $payload) {
      success
    }
  }
`;

const propTypes = {
  inventory: PropTypes.shape.isRequired,
  locale: PropTypes.shape.isRequired,
  suggestedSize: PropTypes.shape,
};

const defaultProps = {
  suggestedSize: {
    id: 'None selected',
  },
};

const SMStorageCalculatorSendMeResults = ({
  inventory,
  locale,
  suggestedSize,
  ...rest
}) => {
  const [success, setSuccess] = useState();
  const [show, setShow] = useState(false);

  const showModal = () => (
    setShow(true)
  );

  const reset = () => {
    setShow(false);
    setSuccess(undefined);
  };

  return (
    <ApolloClientConsumer>
      {(client) => {
        const onSubmit = async (values) => {
          await client.mutate({
            mutation: SEND_ME_RESULTS,
            variables: {
              payload: {
                name: values.name,
                email: values.email,
                phone: values.phone.unformattedPhoneNumber,
                smsOptIn: values.smsOptIn,
                locale,
                suggestedSize: suggestedSize.id,
                itemList: map(filter(inventory, item => item.count > 0), ({
                  id,
                  category,
                  defaultMessage,
                  count,
                  size,
                }) => (
                  {
                    id,
                    category,
                    count,
                    size,
                    name: defaultMessage,
                  }
                )),
              },
            },
          }).then((res) => {
            setSuccess(res.data.storageMartStorageCalculatorSendMeResults.success);
          }).catch(() => {
            setSuccess(false);
          });
        };

        return (
          <SendMeResultsButton
            apolloClient={client}
            {...rest}
            onSubmit={onSubmit}
            reset={reset}
            show={show}
            showModal={showModal}
            success={success}
          />
        );
      }}
    </ApolloClientConsumer>
  );
};

SMStorageCalculatorSendMeResults.propTypes = propTypes;
SMStorageCalculatorSendMeResults.defaultProps = defaultProps;

export default SMStorageCalculatorSendMeResults;
