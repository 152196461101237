import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Icon, InputGroup } from '@canaveral/components';
import gql from 'graphql-tag';
import cx from 'classnames';
import SortButton from './SortButton';
import MediaGallerySelectorRow from '../MediaGallerySelectorRow';
import FacilitySelect from '../../containers/FacilitySelect';
import LoadingIndicator from '../LoadingIndicator';
import styles from './styles.scss';

export const HEADER_FILENAME = 'filename';
export const HEADER_IDENTIFIER = 'identifier';
export const HEADER_UPDATED_AT = 'updatedAt';

const propTypes = {
  hasNextPage: PropTypes.bool,
  facilityId: PropTypes.string,
  isLoadingMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  media: PropTypes.arrayOf(PropTypes.shape({
    filename: PropTypes.string.isRequired,
    formattedUpdatedAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    defaultMetadata: PropTypes.shape({
      title: PropTypes.string,
      altText: PropTypes.string,
    }),
  })).isRequired,
  searchInput: PropTypes.string.isRequired,
  setFacilityId: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  setSelectedMedium: PropTypes.func.isRequired,
  selectedMedium: PropTypes.string,
  showSearch: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  isAscendingSort: PropTypes.bool.isRequired,
  activeHeader: PropTypes.string.isRequired,
  searchClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

const defaultProps = {
  hasNextPage: undefined,
  facilityId: undefined,
  selectedMedium: undefined,
  searchClassName: undefined,
  containerClassName: undefined,
};

const MediaGallerySelector = ({
  hasNextPage,
  facilityId,
  isLoadingMore,
  loading,
  loadMore,
  media,
  searchInput,
  setFacilityId,
  setSelectedMedium,
  setSearchInput,
  selectedMedium,
  showSearch,
  handleSort,
  isAscendingSort,
  activeHeader,
  searchClassName,
  containerClassName,
}) => (
  <div className={cx(styles.container, containerClassName)}>
    <div className={styles.stickyHeader}>
      {showSearch && (
        <div className={cx(styles.search, searchClassName)}>
          <FacilitySelect
            className={styles.input}
            disabled={loading}
            showDisabled
            clearable
            onChange={option => setFacilityId(option && option.value)}
            value={facilityId}
            style={{ borderColor: '#d8d8d8', color: '#333333 !important' }}
            placeholder={(
              <span className={styles.facilitySelect}>
                <Icon id="new-location" label="facility" className={styles.icon} />
                Select a facility from the list or start typing
              </span>
            )}
          />
          <InputGroup className={styles.input}>
            <InputGroup.Text
              disabled={loading}
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
              icon="search"
              onClear={searchInput.length ? (() => setSearchInput('')) : null}
              placeholder="Search by file name, identifier, or title text"
            />
          </InputGroup>
        </div>
      )}
      <div className={styles.headers}>
        <div className={styles.imageColumn} />
        <div className={cx(styles.header, styles.fileColumn)}>
          <SortButton
            handleClick={() => handleSort(HEADER_FILENAME)}
            isActive={activeHeader === HEADER_FILENAME}
            isAscending={isAscendingSort}
            activeHeader={activeHeader}
          >
            Files
          </SortButton>
        </div>
        <div className={cx(styles.header, styles.facilityColumn)}>
          Facility
        </div>
        <div className={cx(styles.header, styles.identifierColumn)}>
          <SortButton
            handleClick={() => handleSort(HEADER_IDENTIFIER)}
            isActive={activeHeader === HEADER_IDENTIFIER}
            isAscending={isAscendingSort}
            activeHeader={activeHeader}
          >
            File Identifier
          </SortButton>
        </div>
        <div className={cx(styles.header, styles.dateColumn)}>
          <SortButton
            handleClick={() => handleSort(HEADER_UPDATED_AT)}
            isActive={activeHeader === HEADER_UPDATED_AT}
            isAscending={isAscendingSort}
            activeHeader={activeHeader}
          >
            Date
          </SortButton>
        </div>
      </div>
    </div>
    {loading && <LoadingIndicator className={styles.loading} />}
    {!loading && media && media.length > 0 && (
      <>
        {media.map(medium => (
          <MediaGallerySelectorRow
            key={medium.id}
            medium={medium}
            selectedMedium={selectedMedium}
            setSelectedMedium={setSelectedMedium}
          />
        ))}
      </>
    )}
    {!loading && hasNextPage && (
      <div className={styles.loadMoreContainer}>
        <Button
          context={isLoadingMore ? 'bare' : 'neutral'}
          onClick={loadMore}
          loading={isLoadingMore}
          size="sm"
        >
          {isLoadingMore ? 'Loading more...' : 'Load more'}
        </Button>
      </div>
    )}
    {!loading && media && !media.length && showSearch && (
      <Alert
        className={styles.alert}
        context="info"
      >
        <Icon id="info" context="info" label="info" />
        <span className={styles.alertText}>
          {"There are no images that match the filters you've selected."}
        </span>
      </Alert>
    )}
  </div>
);

MediaGallerySelector.propTypes = propTypes;
MediaGallerySelector.defaultProps = defaultProps;

MediaGallerySelector.fragments = {
  medium: gql`
    fragment MediaGallerySelectorMedia on Medium {
      ...MediaGallerySelectorRowMedia
    }

    ${MediaGallerySelectorRow.fragments.medium}
  `,
};

export default MediaGallerySelector;
