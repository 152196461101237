const GET_FACILITY_WITH_UNIT_GROUP_QUERY = `
  query GetSMRentalSummaryData($id: ID!, $unitGroupId: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      brandName
      storeNumber
      address {
        address1
        address2
        city
        state
        postal
        country
      }
      phone
      unitGroup(id: $unitGroupId) {
        id
        amenities {
          id
          name
          token
        }
        availableUnitsCount
        category
        categoryName
        discountedPrice
        price
        pricedWeekly
        promoId
        area
        size
        categoryName
        width
        length
        height
      }
    }
  }
`;

export default GET_FACILITY_WITH_UNIT_GROUP_QUERY;
