import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import styles from './styles.scss';
import SizeSuggestion from './SizeSuggestion';
import ItemSelection from './ItemSelection';
import InventoryList from './InventoryList';
import { items, determineSize } from './data';
import Translation from '../../../../components/stateless/Translation';
import {
  TRACK_CLICK_SIZING_GUIDE_RESET,
} from '../../../../../src/web/utils/dataLayer';
import useLocalStorage from '../../../../../modules/react/hooks/useLocalStorage';

const propTypes = {
  locale: PropTypes.string.isRequired,
  modalView: PropTypes.bool,
  toggleModal: PropTypes.func,
};
const defaultProps = {
  modalView: false,
  toggleModal: noop,
};

const StorageMartStorageCalculator = ({ locale, modalView, toggleModal }) => {
  const [inventorySize, setInventorySize] = useState(0);
  const [suggestedSize, setSuggestedSize] = useLocalStorage('storageMartStorageCalculatorSuggestedSize', null);
  const [showInventoryList, setShowInventoryList] = useState(false);
  const [inventory, setInventory] = useLocalStorage('storageMartStorageCalculatorInventory', [...items]);

  const resetInventory = () => {
    setInventory(items.map(item => ({ ...item, count: 0 })));
  };

  useEffect(() => {
    setInventorySize(sumBy(inventory, item => item.count * item.size));
  }, [inventory]);

  useEffect(() => {
    setSuggestedSize(Object.assign({}, determineSize(inventorySize, inventory)));
  }, [inventorySize]);

  return (
    <div className={classNames(styles.storageCalculator, { [styles.modal]: modalView })}>
      <div className={classNames(styles.header, 'clearfix')}>
        <h1 className={styles.title}>
          <Translation
            id="storagecalculator.title"
            defaultMessage="Self Storage Calculator"
          />
        </h1>
        <button
          className={styles.resetButton}
          type="button"
          tabIndex={0}
          onClick={resetInventory}
          {...TRACK_CLICK_SIZING_GUIDE_RESET}
        >
          <i className="fa fa-fw fa-undo" aria-hidden="true" />
          {' '}
          <Translation
            id="storagecalculator.resetbutton"
            defaultMessage="Reset"
          />
        </button>
      </div>
      <Row className={styles.flex}>
        <Col xs={12} md={6} mdPush={6} className={styles.suggestionContainer}>
          <SizeSuggestion
            resetInventory={resetInventory}
            inventorySize={inventorySize}
            suggestedSize={suggestedSize}
            setShowInventoryList={setShowInventoryList}
            inventory={inventory}
            locale={locale}
            modalView={modalView}
            toggleModal={toggleModal}
          />
          {
            showInventoryList
            && (
              <InventoryList
                inventory={inventory}
                suggestedSize={suggestedSize}
                setShowInventoryList={setShowInventoryList}
              />
            )
          }
        </Col>
        <Col xs={12} md={6} mdPull={6}>
          <ItemSelection inventory={inventory} setInventory={setInventory} />
        </Col>
      </Row>
    </div>
  );
};

StorageMartStorageCalculator.propTypes = propTypes;
StorageMartStorageCalculator.defaultProps = defaultProps;

export default StorageMartStorageCalculator;
