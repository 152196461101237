import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.scss';
import parentStyles from '../styles.scss';
import EmptyUnit from './EmptyUnit';
import SuggestedUnit from './SuggestedUnit';
import Translation from '../../../../../components/stateless/Translation';
import {
  TRACK_CLICK_SIZING_GUIDE_RESET,
} from '../../../../../../src/web/utils/dataLayer';

const propTypes = {
  resetInventory: PropTypes.func.isRequired,
  inventorySize: PropTypes.number.isRequired,
  suggestedSize: PropTypes.shape,
  setShowInventoryList: PropTypes.func.isRequired,
  inventory: PropTypes.shape.isRequired,
  locale: PropTypes.string.isRequired,
  modalView: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
const defaultProps = {
  suggestedSize: undefined,
};

const SizeSuggestion = ({
  resetInventory,
  inventorySize,
  suggestedSize,
  setShowInventoryList,
  inventory,
  locale,
  modalView,
  toggleModal,
}) => (
  <Row className={styles.sizeSuggestion}>
    <Col xs={12} className={styles.resetButtonContainer}>
      <button
        className={classNames(parentStyles.resetButton, styles.resetButton)}
        type="button"
        tabIndex={0}
        onClick={resetInventory}
        {...TRACK_CLICK_SIZING_GUIDE_RESET}
      >
        <i className="fa fa-fw fa-undo" aria-hidden="true" />
        {' '}
        <Translation
          id="storagecalculator.resetbutton"
          defaultMessage="Reset"
        />
      </button>
    </Col>
    {inventorySize === 0 && <EmptyUnit setShowInventoryList={setShowInventoryList} />}
    {
      inventorySize > 0
      && (
        <SuggestedUnit
          suggestedSize={suggestedSize}
          setShowInventoryList={setShowInventoryList}
          inventory={inventory}
          locale={locale}
          modalView={modalView}
          toggleModal={toggleModal}
        />
      )
    }
  </Row>
);

SizeSuggestion.propTypes = propTypes;
SizeSuggestion.defaultProps = defaultProps;

export default SizeSuggestion;
