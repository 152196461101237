import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, Col } from 'react-bootstrap';
import { object, string } from 'yup';
import styles from './styles.scss';
import parentStyles from '../styles.scss';
import withForm from '../../../../../../admin/hocs/withForm';
import SMFormFieldText from '../../../../components/stateless/SMFormFieldText';
import SMFormFieldPhone from '../../../../components/stateless/SMFormFieldPhone';
import SMFormFeedback from '../../../../components/stateless/SMFormFeedback';
import SMFormFieldToggle from '../../../../components/stateless/SMFormFieldToggle';
import Translation from '../../../../../components/stateless/Translation';
import { pushRoute } from '../../../../../../modules/routing';
import {
  TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK1,
  TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK2,
  TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_SUBMIT,
  TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_FIND_STORAGE,
} from '../../../../../../src/web/utils/dataLayer';

const propTypes = {
  show: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
};

const defaultProps = {
  success: undefined,
};

const initialValues = {
  name: '',
  email: '',
  phone: {
    country: null,
    phoneNumber: '',
    unformattedPhoneNumber: '',
  },
  smsOptIn: false,
};

const validationSchema = object().shape({
  name: string().required('storagecalculator.sendmeresults.name.required'),
  email: string().email().required('storagecalculator.sendmeresults.email.required'),
  phone: object().shape({
    unformattedPhoneNumber: string()
      .matches(/^[0-9|(|)|\-|\s|+]*$/),
  }),
});

function SendMeResultsModal({
  show,
  reset,
  handleSubmit,
  success,
  isSubmitting,
}) {
  const [step, setStep] = useState(2);

  useEffect(() => {
    if (success) {
      setStep(3);
    }
  }, [success]);

  const goBack = () => {
    reset();
    setStep(2);
  };

  const nextStep = () => {
    if (step === 2) {
      handleSubmit();
    } else if (step === 3) {
      pushRoute('/storage-near-me');
    }
  };

  const step2 = () => (
    <React.Fragment>
      <div className={styles.subtitle}>
        <Translation
          id="storagecalculator.sendmeresults.step2.subtitle"
          defaultMessage="Need to keep this info handy?"
        />
      </div>
      <h4>
        <Translation
          id="storagecalculator.sendmeresults.step2.title"
          defaultMessage="Save your storage details:"
        />
      </h4>

      <SMFormFieldText
        name="name"
        label={{
          defaultMessage: 'Your name',
          id: 'storagecalculator.sendmeresults.name',
        }}
      />

      <SMFormFieldText
        name="email"
        label={{
          defaultMessage: 'Your e-mail',
          id: 'storagecalculator.sendmeresults.email',
        }}
      />

      <SMFormFieldPhone
        name="phone"
        label={{
          defaultMessage: 'Phone number',
          id: 'storagecalculator.sendmeresults.phone',
        }}
      />

      <SMFormFieldToggle
        type="checkbox"
        name="smsOptIn"
        value
        label={{
          defaultMessage: 'I want to get information about newly available storage units near me by SMS',
          id: 'storagecalculator.sendmeresults.smsoptin',
        }}
        className={styles.smsOptIn}
      />
    </React.Fragment>
  );

  const step3 = () => (
    <React.Fragment>
      <h4>
        <Translation id="storagecalculator.sendmeresults.step3.title" defaultMessage="Let's go!" />
      </h4>
      <p>
        <Translation
          id="storagecalculator.sendmeresults.step3.info1"
          defaultMessage="Remember, you can upsize or downsize a unit if you discover
            that you need more or less space."
        />
      </p>
      <p>
        <Translation
          id="storagecalculator.sendmeresults.step3.info2"
          defaultMessage="We're happy to help you select a different unit."
        />
      </p>
    </React.Fragment>
  );

  return (
    <Modal className={styles.modal} show={show} backdrop="static">
      <Modal.Header className={styles.header}>
        <Col xs={4} className={styles.segment}>
          <Translation
            id="storagecalculator.sendmeresults.menu.step1"
            defaultMessage="1. Select items"
          />
          <div className={styles.arrowRight} />
          <div className={classNames(styles.arrowRight, styles.outline)} />
        </Col>
        <Col xs={4} className={classNames(styles.segment, { [styles.active]: step === 2 })}>
          <Translation
            id="storagecalculator.sendmeresults.menu.step2"
            defaultMessage="2. Save details"
          />
          <div className={styles.arrowRight} />
          <div className={classNames(styles.arrowRight, styles.outline)} />
        </Col>
        <Col
          xs={4}
          className={classNames(styles.segment, styles.incomplete, { [styles.active]: step === 3 })}
        >
          <Translation
            id="storagecalculator.sendmeresults.menu.step3"
            defaultMessage="3. Select unit"
          />
        </Col>
      </Modal.Header>
      <Modal.Body className={classNames('clearfix', styles.modalBody)}>
        <Col xs={12} md={8} mdOffset={2}>
          {success === false
            && (
              <SMFormFeedback
                context="danger"
                id="storagecalculator.sendmeresults.error"
                defaultMessage="There was an error processing your request. Please try again."
              />
            )
          }
          {step === 2 && step2()}
          {step === 3 && step3()}
        </Col>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Col xs={12} md={8} mdOffset={2}>
          <button
            className={classNames(parentStyles.button, styles.button)}
            onClick={goBack}
            type="button"
            disabled={isSubmitting}
            {...(step === 2
              ? TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK1
              : TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_BACK2
            )}
          >
            &lt;
            {' '}
            <Translation
              id="storagecalculator.sendmeresults.backbutton"
              defaultMessage="Back"
            />
          </button>

          <button
            className={classNames(parentStyles.button, styles.button_cta)}
            onClick={nextStep}
            type="button"
            disabled={isSubmitting}
            {...(step === 2
              ? TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_SUBMIT
              : TRACK_CLICK_SIZING_GUIDE_FORM_MODAL_FIND_STORAGE
            )}
          >
            {step === 2
              && (
                <Translation
                  id="storagecalculator.sendmeresults.nextstepbutton"
                  defaultMessage="Next step"
                />
              )
            }
            {step === 3
              && (
                <Translation
                  id="storagecalculator.sendmeresults.findstoragenearmebutton"
                  defaultMessage="Find storage near me"
                />
              )
            }
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

SendMeResultsModal.propTypes = propTypes;
SendMeResultsModal.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: (formPayload, formikBag) => formikBag.props.onSubmit(formPayload),
})(SendMeResultsModal);
