const GET_CONFIRMATION_QUERY = `
  query SMCheckoutConfirmation($confirmationNumber: String!) {
    storageMartCheckoutConfirmation(confirmationNumber: $confirmationNumber) {
      successful
      errors
      checkout {
        confirmationNumber
        storedgeFacilityId
        tenant {
          firstName
          lastName
          email
          phone
          address {
            address1
            address2
            city
            state
            postal
          }
          emergencyContactFirstName
          emergencyContactLastName
          emergencyContactPhone
          contactFirstName
          contactLastName
        }
        unit {
          id
          name
          price
          pricingTypeId
          smartSize
          length
          width
          height
          amenities {
            name
            token
          }
        }
        moveInDate
        paymentMethod {
          payLater
          maskedNumber
          autopayEnroll
          cardType
          billingAddress {
            address1
            address2
            city
            state
            postal
          }
        }
        lineItems {
          type
          amount
          duedate
          sort
        }
        discountId
        unitsStartDate
        contractIds
        leaseInfo
        hasSignedLease
      }
    }
  }
`;

export default GET_CONFIRMATION_QUERY;
