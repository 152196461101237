import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InputElement from 'react-input-mask';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { HelpBlock } from 'react-bootstrap';
import Translation from '../../../../components/stateless/Translation';
import styles from './styles.scss';

const propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'text',
    'url',
    'tel',
  ]),
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  help: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  errorClassName: undefined,
  invalidClassName: undefined,
  required: undefined,
  type: 'text',
  mask: undefined,
  maskChar: undefined,
  help: undefined,
};

const SMFormFieldText = ({
  className,
  errorClassName,
  invalidClassName,
  label,
  required,
  name,
  type,
  help,
  prefixAddOn,
  validate,
  ...rest
}) => {
  const [field, meta] = useField({ name, validate });
  const error = meta.touched && !isEmpty(meta.error);

  const fieldClasses = cx(
    styles.field,
    error && styles.error,
    error && invalidClassName,
    required && styles.required,
    prefixAddOn && styles.prefixAddedOn,
    className,
  );

  return (
    <div className={fieldClasses}>
      <label className={styles.label}>
        {
          type !== 'hidden' && (
            <div className={styles.text}>
              <Translation {...label} />
            </div>
          )
        }
        <div className={styles.inputWrapper}>
          {prefixAddOn ? <span className={styles.prefixAddOn}>{prefixAddOn}</span> : null}
          <InputElement
            {...field}
            {...rest}
            type={type}
            required={required}
            className={styles.input}
            data-testid="sm-form-field-text"
          />
        </div>
      </label>
      {help && <HelpBlock>{help}</HelpBlock>}
      {error && (
        <div className={cx(styles.message, errorClassName)}>
          <Translation
            defaultMessage="Field is invalid"
            id={meta.error}
          />
        </div>
      )}
    </div>
  );
};

SMFormFieldText.propTypes = propTypes;
SMFormFieldText.defaultProps = defaultProps;

export default SMFormFieldText;
