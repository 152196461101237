import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Icon as CanaveralIcon } from '@canaveral/components';
import Icon from 'components/Icon/Icon';
import styles from './ImageUploadRow.scss';

const propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleVideoEmbedChange: PropTypes.func.isRequired,
  moveFileRowDown: PropTypes.func.isRequired,
  moveFileRowUp: PropTypes.func.isRequired,
  thumbnail: PropTypes.string,
  videoEmbedCode: PropTypes.string.isRequired,
};
const defaultProps = {};

const VideoUploadRow = ({
  handleDelete,
  handleVideoEmbedChange,
  moveFileRowDown,
  moveFileRowUp,
  thumbnail,
  videoEmbedCode,
}) => (
  <Row>
    <Col xs={12} className={styles.videoRow}>
      <div className={styles.imageInfoContainer}>
        <div className={styles.image}>
          {thumbnail ?
            <img src={thumbnail} alt="video" />
            :
            <CanaveralIcon
              id="social-youtube"
              className={styles.preview}
              label="video preview"
              context="secondary"
            />
          }
        </div>
        <div className={styles.controls}>
          <input
            className={styles.textarea}
            onChange={handleVideoEmbedChange}
            value={videoEmbedCode}
          />
        </div>
        <div className={styles.rowControls}>
          <div>
            {moveFileRowUp && <Icon className={styles.sort} icon="collapse" onClick={moveFileRowUp} />}
          </div>
          <div>
            {moveFileRowDown && <Icon className={styles.sort} icon="expand" onClick={moveFileRowDown} />}
          </div>
          <div className={styles.delete}>
            <i className="fa fa-fw fa-trash-o" onClick={handleDelete} aria-hidden="true" />
          </div>
        </div>
      </div>
    </Col>
  </Row>
);

VideoUploadRow.propTypes = propTypes;
VideoUploadRow.defaultProps = defaultProps;

export default VideoUploadRow;
