import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import styles from './styles.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: undefined,
  children: undefined,
};

const BaseForm = ({ handleSubmit, className, children }) => (
  <Form
    onSubmit={handleSubmit}
    className={cx(styles.root, className)}
  >
    {children}
  </Form>
);

BaseForm.propTypes = propTypes;
BaseForm.defaultProps = defaultProps;

export default BaseForm;
