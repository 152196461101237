import React from 'react';
import PropTypes from 'prop-types';
import { Field as ReduxField } from 'redux-form';
import {
  ControlLabel,
  FormControl,
  HelpBlock,
  FormGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import Icon from '../../stateless/Icon';
import stylesGlobal from '../../../../../src/web/theme/facility-globals.scss';

function renderTooltip(tooltip) {
  if (!tooltip) {
    return null;
  }

  const tooltipBody = (
    <Tooltip id="tooltip" className={stylesGlobal.toolTip}>
      <div className={stylesGlobal.tooltipInner}>{tooltip}</div>
    </Tooltip>
  );

  return (
    <span>
      <OverlayTrigger
        placement="right"
        overlay={tooltipBody}
      >
        <span>
          <Icon id="tool-tip" />
        </span>
      </OverlayTrigger>
      {' '}
    </span>
  );
}

function renderLabel(label, tooltip, className) {
  return (
    <ControlLabel className={className}>
      {renderTooltip(tooltip)}{label}
    </ControlLabel>
  );
}

function renderHelp(help, error) {
  if (!help && !error) {
    return null;
  }

  return (
    <HelpBlock>
      {error || help}
    </HelpBlock>
  );
}

const FieldGroup = ({
  label,
  labelClassName,
  tooltip,
  showFeedback = false,
  help,
  inputComponent,
  groupClassName,
  input,
  id,
  meta,
  required,
  children,
  ...props
}) => {
  const hasError = meta.error && meta.touched;
  const labelClass = [
    required ? 'required' : null,
    labelClassName,
  ].filter(Boolean).join(' ');

  return (
    <FormGroup validationState={hasError ? 'error' : null} controlId={id || input.name} className={groupClassName}>
      {renderLabel(label, tooltip, labelClass)}
      {React.createElement(inputComponent, { ...props, input, meta, required }, children)}
      {showFeedback ? <FormControl.Feedback /> : null}
      {renderHelp(help, hasError ? meta.error : null)}
    </FormGroup>
  );
};

FieldGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltip: PropTypes.string,
  help: PropTypes.node,
  required: PropTypes.bool,
  showFeedback: PropTypes.bool,
  inputComponent: PropTypes.node,
  input: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
};

FieldGroup.defaultProps = {
  id: undefined,
  label: undefined,
  groupClassName: undefined,
  labelClassName: undefined,
  tooltip: undefined,
  help: undefined,
  required: undefined,
  showFeedback: undefined,
  inputComponent: undefined,
};


const FieldWrapper = ({ component, ...props }) => (
  <ReduxField {...props} component={FieldGroup} inputComponent={component} />
);

FieldWrapper.propTypes = {
  component: PropTypes.node.isRequired,
};

export default FieldWrapper;
