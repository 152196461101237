import React from 'react';
import styles from './styles.scss';
import SMFormFieldText from '../../../../components/stateless/SMFormFieldText';

const CheckoutFormFieldText = ({ ...props }) => (
  <SMFormFieldText
    className={styles.checkoutFormFieldText}
    errorClassName={styles.errorMessage}
    invalidClassName={styles.invalid}
    {...props}
  />
);

export default CheckoutFormFieldText;
