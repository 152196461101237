import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Authorization from 'containers/Authorization';
import { ContextMenu } from 'components';
import { BUILDER } from 'shared/abilities';

const DELETE_LABEL = 'Delete';

/**
 * This decorator/HOC will wrap the widget in a clickable area that will open up
 * a context menu for the widget. By default, the context menu will hook into the
 * edit and delete event properties and provide those as options in the menu.
 *
 * @param {(Object|function(props: Object, store: Object): Object)=} options - the options for configuring the context menu
 * @param {string=} options.heading - the context menu heading
 * @param {boolean=} options.isEnabled - whether or not to display the context menu
 * @param {{label: string, iconClass: string, action: function}[]=} options.menuItems - the menu items
 * @return {function(Component: Object): Object} the decorator or HOC to apply to the original component
 */

export default options => (Component) => {
  const componentName = Component.widgetName || Component.displayName || Component.name;

  class ContextMenuWidget extends React.Component {
    static propTypes = {
      id: PropTypes.string,
      idPostfix: PropTypes.string,
      onWidgetEditStart: PropTypes.func,
      onDelete: PropTypes.func,
      isEditable: PropTypes.bool.isRequired,
      isEditing: PropTypes.bool.isRequired,
      unauthorized: PropTypes.bool,
      sectionWidgetMoving: PropTypes.bool,
      onStartWidgetSwitch: PropTypes.func,
      onSwitchWidgets: PropTypes.func,
    };

    static contextTypes = {
      store: PropTypes.object.isRequired,
    };

    compileOptions() {
      const { isEditable, isEditing } = this.props;

      let menuOptions = {
        heading: `${componentName} Widget`,
        isEnabled: isEditable && !isEditing,
        items: ['edit', 'delete'],
      };

      if (options) {
        let overrides = options;
        if (typeof overrides === 'function') {
          overrides = overrides(this.props, this.context.store);
        }

        menuOptions = { ...menuOptions, ...overrides };
      }

      // Replace any string items with their constant counterparts
      menuOptions.items = menuOptions.items.map((item) => {
        switch (item) {
          case 'edit':
            return {
              label: 'Edit',
              iconClass: 'pencil',
              action: this.props.onWidgetEditStart,
            };
          case 'delete':
            return {
              label: 'Delete',
              iconClass: 'trash',
              action: this.props.onDelete,
            };
          default:
            return item;
        }
      });

      return menuOptions;
    }

    menuItems(authorized, compiledItems) {
      const {
        unauthorized, onStartWidgetSwitch, isEditable, sectionWidgetMoving,
      } = this.props;

      if (unauthorized) {
        return [{
          label: 'You do not have permission to edit this. Please contact support.',
          action: () => {},
        }];
      }

      if (!authorized) return compiledItems.filter(item => item.label !== DELETE_LABEL);

      if (isEditable && sectionWidgetMoving) {
        compiledItems.push({
          label: 'Switch Widget Location',
          iconClass: 'exchange',
          action: onStartWidgetSwitch,
        });
      }

      return compiledItems;
    }

    render() {
      const {
        idPostfix, unauthorized, onSwitchWidgets, ...props
      } = this.props;

      const component = <Component {...props} />;
      const compiledOptions = this.compileOptions();

      if (!compiledOptions.isEnabled) {
        return component;
      }

      let id = componentName;
      let rawId;
      if (this.props.id) {
        id = this.props.id; // eslint-disable-line prefer-destructuring
        rawId = id;
      }

      id = `widget-menu-${id}`;
      if (idPostfix) {
        id = `${id}-${idPostfix}`;
      }

      return (
        <Authorization action="manage" subject={BUILDER}>
          {authorized => (
            <ContextMenu
              id={id}
              heading={compiledOptions.heading}
              menuItems={this.menuItems(authorized, compiledOptions.items)}
              onSwitchWidgets={onSwitchWidgets}
              rawId={rawId}
            >
              {component}
            </ContextMenu>
          )}
        </Authorization>
      );
    }
  }
  ContextMenuWidget.displayName = `ContextMenu(${componentName})`;

  return hoistNonReactStatic(ContextMenuWidget, Component);
};
