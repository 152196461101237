import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/react';
import {
  getAllFacilities,
  facilitiesLoaded,
  loadList,
} from 'store/modules/facilities';
import { getPageLocale } from 'store/modules/page';
import SelectInput from '../../../../../modules/react/components/stateful/SelectInput';

const propTypes = {
  loaded: PropTypes.bool.isRequired,
  loadFacilities: PropTypes.func.isRequired,
};

class LiveFacilitySelect extends React.Component {
  componentDidMount = () => {
    const { loaded, loadFacilities } = this.props;
    if (!loaded) loadFacilities();
  }

  render = () => {
    const { loaded, loadFacilities, ...props } = this.props;
    return <SelectInput {...props} htmlSelect />;
  }
}

LiveFacilitySelect.propTypes = propTypes;

export default compose(
  connect((state) => {
    const locale = getPageLocale(state);

    const options = getAllFacilities(state).filter((facility) => { // eslint-disable-line arrow-body-style
      return facility.facilityType === 'full' &&
      facility.pagePaths.some(path => path.locale === locale);
    }).reduce((opts, facility) => {
      opts[facility.id] = `${facility.name} - ${facility.address.fullAddress}`;
      return opts;
    }, {});

    return {
      options,
      loaded: facilitiesLoaded(state),
    };
  }, {
    loadFacilities: loadList,
  }),
)(LiveFacilitySelect);
