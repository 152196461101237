import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

export default class HidableInput extends React.Component {
  static propTypes = {
    inputComponent: PropTypes.any,
    valueComponent: PropTypes.any,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    blurOnEmpty: PropTypes.bool,
    showOnEmpty: PropTypes.bool,
  };

  static defaultProps = {
    inputComponent: FormControl,
    valueComponent: 'div',
    placeholder: 'Enter text',
    blurOnEmpty: true,
    showOnEmpty: false,
  };

  state = {
    showField: this.props.showOnEmpty && !this.props.value,
  };

  componentWillReceiveProps(nextProps) {
    if (!this.state.showField) {
      this.setState({
        showField: nextProps.showOnEmpty && !this.props.value,
      });
    }
  }

  handleBlur = (event) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    // Only blur if we've met the criteria
    if (this.props.blurOnEmpty || this.props.value) {
      this.setState({ showField: false });
    }
  };

  renderInput() {
    const {
      inputComponent, valueComponent, blurOnEmpty, showOnEmpty, ...props
    } = this.props; // eslint-disable-line no-unused-vars

    return React.createElement(
      inputComponent,
      {
        autoFocus: true,
        ...props,
        onBlur: this.handleBlur,
      },
    );
  }

  renderValue() {
    const { valueComponent, value, placeholder } = this.props;
    const props = {
      onClick: () => this.setState({ showField: true }),
    };

    return React.createElement(
      valueComponent,
      props,
      value || <span className="placeholder">{placeholder}</span>,
    );
  }

  render() {
    const { showField } = this.state;

    if (showField) {
      return this.renderInput();
    }

    return this.renderValue();
  }
}
