import React from 'react';
import styles from './styles.scss';
import SMFormFieldDatepicker from '../../../../components/stateful/SMFormFieldDatepicker';

const CheckoutFormFieldDatePicker = ({ ...props }) => (
  <SMFormFieldDatepicker
    className={styles.checkoutFormFieldDatePicker}
    errorClassName={styles.errorMessage}
    invalidClassName={styles.invalid}
    {...props}
  />
);

export default CheckoutFormFieldDatePicker;
