// Usage: import { getClientId } from 'utils/googleAnalytics';
//
// const clientId = getClientId();

// get the client id from the _ga cookie
const getClientIdFromCookie = () =>
  document.cookie
    .split('; ')
    .map(cookie => cookie.split('='))
    .find(([name]) => name === '_ga')
    ?.slice(1)
    .flatMap(value => value.split('.'))
    .slice(2, 4)
    .join('.')
    || null;

export const getClientId = () => getClientIdFromCookie();
