import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import parentStyles from '../styles.scss';
import styles from './styles.scss';
import { resolveAssetUrl } from '../../../../../../modules/assets';
import SizeMeter from './SizeMeter';
import SMStorageCalculatorSendMeResults from '../../../../containers/SMStorageCalculatorSendMeResults';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  suggestedSize: PropTypes.shape,
  setShowInventoryList: PropTypes.func.isRequired,
  inventory: PropTypes.shape.isRequired,
  locale: PropTypes.string.isRequired,
  modalView: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
const defaultProps = {
  suggestedSize: undefined,
};

function SuggestedUnit({
  suggestedSize,
  setShowInventoryList,
  inventory,
  locale,
  modalView,
  toggleModal,
}) {
  const renderCTA = () => (
    modalView
      ? (
        <button
          className={parentStyles.ctaButton}
          type="button"
          onClick={toggleModal}
        >
          <Translation
            id="storagecalculator.selectunitbutton"
            defaultMessage="Select Unit"
          />
        </button>
      ) : (
        <SMStorageCalculatorSendMeResults
          inventory={inventory}
          locale={locale}
          suggestedSize={suggestedSize}
        />
      )
  );

  return (
    <>
      <Col xs={6} mdPush={6}>
        <div>
          <div className={styles.sizeHeader}>
            <Translation
              id="storagecalculator.suggestedsize.title"
              defaultMessage="We recommend:"
            />
          </div>

          <div className={styles.size}>
            {suggestedSize.id}
          </div>

          <div className={styles.sizeDescription}>
            <Translation
              id={suggestedSize.translationId}
              defaultMessage={suggestedSize.translationDefaultMessage}
            />
          </div>

          <SizeMeter suggestedSize={suggestedSize} setShowInventoryList={setShowInventoryList} />
        </div>
      </Col>
      <Col xs={6} mdPull={6} className={styles.unitImage}>
        <div xsHidden>
          <img
            src={resolveAssetUrl(`/img/enterprise/storage-calculator/${suggestedSize.id}.svg`)}
            alt=""
            aria-hidden="true"
            loading="lazy"
            xsHidden
          />
        </div>
        <Col xs={12} mdHidden lgHidden>
          {renderCTA()}
        </Col>
      </Col>
      <Col xsHidden smHidden md={12} className="text-center">
        {renderCTA()}
      </Col>
    </>
  );
}

SuggestedUnit.propTypes = propTypes;
SuggestedUnit.defaultProps = defaultProps;

export default SuggestedUnit;
