import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { Row, Col, Alert } from 'react-bootstrap';
import get from 'lodash/get';
import { Button } from '@canaveral/components';
import { useIntl } from 'react-intl';
import { format, parseISO } from 'date-fns';
import styles from './styles.scss';
import withForm from '../../../../../../admin/hocs/withForm';
import Translation from '../../../../../components/stateless/Translation';
import renderAmenities from '../../../../../components/stateful/UnitsTable/themes/StorageMart/renderAmenities';
import {
  EASIER_PRICING_TYPE_ID,
  EASIEST_PRICING_TYPE_ID,
  FIRST_MONTH_FREE_ID,
  sizeMessageProps,
} from '../constants';
import CheckoutSubmitButton from '../CheckoutSubmitButton';
import CheckoutFormFieldText from '../CheckoutFormFieldText';
import CheckoutFormFieldDatePicker from '../CheckoutFormFieldDatePicker';
import {
  TRACK_CLICK_CHECKOUT_RESERVATION_STEP_TWO_SUBMIT,
  TRACK_CLICK_CHECKOUT_STEP_TWO_SUBMIT,
  TRACK_CLICK_CHECKOUT_UPGRADE_EASY,
  TRACK_CHECKOUT_STEP,
  TRACK_ADD_TO_CART_UNIT_GROUP,
  TRACK_REMOVE_FROM_CART_UNIT_GROUP,
} from '../../../../../../src/web/utils/dataLayer';
import UnitUpgradeOptions from './unit-upgrade-options';

const propTypes = {
  facility: PropTypes.shape({
    settings: PropTypes.shape({
      useWeeklyRates: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onCheckInDateChange: PropTypes.func.isRequired,
  onPricingTypeChange: PropTypes.func.isRequired,
  isReservation: PropTypes.bool.isRequired,
  maxStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

const defaultProps = {
  facility: undefined,
};

const DATE_FORMAT = 'yyyy-MM-dd';

const parseDateString = (value) => {
  if (value) {
    return format(new Date(value), DATE_FORMAT);
  }

  return null;
};

const validationSchema = (props) => {
  const parsedDates = props.availableDates.map(availableDate => (
    format(availableDate, DATE_FORMAT)
  ));

  return object().shape({
    moveInDate: string()
      .transform(parseDateString)
      .oneOf(parsedDates, 'widgets.checkout.moveInDate.notInRange')
      .required('widgets.checkout.moveInDate.required'),
    pricingTypeId: string()
      .required('widgets.checkout.pricingType.required'),
  });
};

const initialValues = {
  moveInDate: '',
  pricingTypeId: '',
};

const UnitInformationForm = ({
  handleSubmit,
  submitting,
  formError,
  availableDates,
  onCheckInDateChange,
  onPricingTypeChange,
  facility,
  setFieldValue,
  values,
  isReservation,
  maxStep,
  currentStep,
}) => {
  if (!facility) {
    return null;
  }
  const intl = useIntl();
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);

  const {
    currency,
    currencyLocale,
    unitGroup,
    settings: { useWeeklyRates } = {},
  } = facility;

  const {
    price,
    discountedPrice,
    availablePricingTypes,
    discountedPriceWeekly,
    priceWeekly,
    promoId,
    pricingTypeId: unitGroupPricingTypeId,
  } = unitGroup;

  const [pricingTypeId, setPricingTypeId] = useState(unitGroupPricingTypeId);
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);

  useEffect(() => {
    if (facility && !values.pricingTypeId) {
      setPricingTypeId(unitGroupPricingTypeId);
      setFieldValue('pricingTypeId', unitGroupPricingTypeId);
    } else if (values.pricingTypeId) {
      setPricingTypeId(values.pricingTypeId);
    }
  }, [facility, values]);

  useEffect(() => {
    if (!hasTrackedDataLayer && facility) {
      setHasTrackedDataLayer(true);
      TRACK_CHECKOUT_STEP(get(facility, 'unitGroup'), facility, 'new_booking_flow_step2', 'storage unit');
    }
  }, [facility, hasTrackedDataLayer]);

  const weeklyRate = (discountedPriceWeekly || priceWeekly);
  const monthlyRate = (discountedPrice || price);
  const unitPrice = useWeeklyRates ? weeklyRate : monthlyRate;

  const easierPricingType = availablePricingTypes.find(availablePricingType => (
    availablePricingType.pricingTypeId === EASIER_PRICING_TYPE_ID
  ));
  const easiestPricingType = availablePricingTypes.find(availablePricingType => (
    availablePricingType.pricingTypeId === EASIEST_PRICING_TYPE_ID
  ));

  const setPricingType = (newPricingTypeId) => {
    setFieldValue('pricingTypeId', newPricingTypeId);
    setPricingTypeId(newPricingTypeId);
    onPricingTypeChange(newPricingTypeId);
  };

  useEffect(() => {
    if (values.moveInDate && (easierPricingType || easiestPricingType)) {
      if (values.pricingTypeId === unitGroupPricingTypeId && maxStep === currentStep) {
        setPricingType((easierPricingType || easiestPricingType).pricingTypeId);
      }
      setShowUpgradeOptions(true);
    }
  }, [values.moveInDate, easierPricingType, easiestPricingType, setShowUpgradeOptions]);

  const firstMonthFree = promoId === FIRST_MONTH_FREE_ID;

  const setEasierPricingType = () => {
    if (
      (unitGroupPricingTypeId === EASIER_PRICING_TYPE_ID || easierPricingType)
      && pricingTypeId !== EASIER_PRICING_TYPE_ID
    ) {
      setPricingType(get(easierPricingType, 'pricingTypeId', 0));
    }
  };

  const setEasiestPricingType = () => {
    if (
      (unitGroupPricingTypeId === EASIEST_PRICING_TYPE_ID || easiestPricingType)
      && pricingTypeId !== EASIEST_PRICING_TYPE_ID
    ) {
      setPricingType(get(easiestPricingType, 'pricingTypeId', 0));
    }
  };

  const setDefaultPricingType = () => {
    if (pricingTypeId !== unitGroupPricingTypeId) {
      setPricingType(unitGroupPricingTypeId);
    }
  };

  const fireDataLayerEvents = () => {
    if (pricingTypeId !== unitGroupPricingTypeId) {
      const defaultUnitGroup = get(facility, 'unitGroup');
      const selectedPricingType = availablePricingTypes.find(availablePricingType => (
        availablePricingType.pricingTypeId === pricingTypeId
      ));
      TRACK_REMOVE_FROM_CART_UNIT_GROUP(defaultUnitGroup, facility, intl);
      defaultUnitGroup.discountedPrice = selectedPricingType.marketRate;
      defaultUnitGroup.unit_upgrade = 'yes';
      TRACK_ADD_TO_CART_UNIT_GROUP(defaultUnitGroup, facility, intl);
    }
  };

  return (
    <div>
      {
        formError && (
          <Alert bsStyle="danger">
            <Translation
              id="widgets.checkout.formError"
              defaultMessage="An error was encountered when submitting the form. Please try again."
            />
          </Alert>
        )
      }
      <div className={styles.unitInformation}>
        <Row>
          <Col xs={6}>
            <h3>
              {get(facility, 'unitGroup.size')}
              {' '}
              <Translation
                {...sizeMessageProps[get(facility, 'unitGroup.categoryName').toLowerCase()]}
              />
            </h3>
            <div className={styles.amenityContainer}>
              {renderAmenities(get(facility, 'unitGroup.amenities'), styles.amenity, 'text-sm', styles.tooltipIcon)}
            </div>
          </Col>
        </Row>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mt-6"
      >
        <Row>
          <Col xs={12} md={8}>
            <CheckoutFormFieldDatePicker
              required
              name="moveInDate"
              availableDates={availableDates}
              onChange={onCheckInDateChange}
              label={{
                defaultMessage: 'Check-in date',
                id: 'widgets.checkout.moveInDate',
              }}
            />
          </Col>
        </Row>
        {showUpgradeOptions
          && (
            <UnitUpgradeOptions
              easierPricingType={easierPricingType}
              setEasierPricingType={setEasierPricingType}
              easiestPricingType={easiestPricingType}
              setEasiestPricingType={setEasiestPricingType}
              currency={currency}
              currencyLocale={currencyLocale}
              useWeeklyRates={useWeeklyRates}
              unitPrice={unitPrice}
              unitGroup={unitGroup}
              pricingTypeId={pricingTypeId}
              unitGroupPricingTypeId={unitGroupPricingTypeId}
              firstMonthFree={firstMonthFree}
            />
          )}
        <Row>
          <Col xs={12}>
            <CheckoutFormFieldText
              type="hidden"
              name="pricingTypeId"
            />
            {isReservation
              ? (
                <CheckoutSubmitButton
                  submitLabel={{
                    id: 'widgets.checkout.reserveButton.submit',
                    defaultMessage: 'Reserve Unit Now',
                  }}
                  submitting={submitting}
                  submittingLabel={{
                    id: 'widgets.checkout.reserveButton.submitting',
                    defaultMessage: 'Reserving...',
                  }}
                  {...TRACK_CLICK_CHECKOUT_RESERVATION_STEP_TWO_SUBMIT}
                />
              )
              : (
                <CheckoutSubmitButton
                  submitLabel={{
                    id: 'widgets.checkout.saveButton.submit',
                    defaultMessage: 'Save and Continue',
                  }}
                  submitting={submitting}
                  submittingLabel={{
                    id: 'widgets.checkout.saveButton.submitting',
                    defaultMessage: 'Saving...',
                  }}
                  onClick={fireDataLayerEvents}
                  {...TRACK_CLICK_CHECKOUT_STEP_TWO_SUBMIT}
                />
              )
            }
            {showUpgradeOptions
              && (
                <Button
                  context="link"
                  onClick={setDefaultPricingType}
                  className="mt-4 float-right"
                  disabled={submitting}
                  type="submit"
                  {...TRACK_CLICK_CHECKOUT_UPGRADE_EASY}
                >
                  <Translation
                    id="widgets.checkout.noThanksButton"
                    defaultMessage="No Thanks"
                  />
                </Button>
              )
            }
          </Col>
        </Row>
      </form>
    </div>
  );
};

UnitInformationForm.propTypes = propTypes;
UnitInformationForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: (props) => {
    const formattedInitialValues = initialValues;

    if (props.formData) {
      formattedInitialValues.moveInDate = parseISO(props.formData.moveInDate);
      formattedInitialValues.pricingTypeId = props.formData.unit.pricingTypeId;
    }

    return formattedInitialValues;
  },
  validationSchema,
  handleSubmit: (formPayload, formikBag) => (
    formikBag.props.onSubmit(
      formPayload,
      formikBag.props.currentStep,
      formikBag.props.nextStep,
      formikBag.props.scrollToRef,
    )
  ),
})(UnitInformationForm);
