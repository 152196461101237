import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Alert } from 'react-bootstrap';
import gql from 'graphql-tag';
import get from 'lodash/get';
import omit from 'lodash/omit';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ContactInformation from './ContactInformation';
import UnitInformation from './UnitInformation';
import AccountDetails from './AccountDetails';
import Payment from './Payment';
import OrderSummary, { MobileOrderSummary } from './OrderSummary';
import CheckoutProgress from './CheckoutProgress';
import CurrentStepContext, { CHECKOUT_STEPS } from './CurrentStepContext';
import { getQueryParams } from '../../../../../modules/search';
import useLazyQuery from '../../../../../modules/react/hooks/useLazyQuery';
import ApolloClientConsumer from '../../../../../modules/react/components/stateful/ApolloClientConsumer';
import SMMoveInUnavailable from '../../../containers/SMMoveInUnavailable';
import styles from './styles.scss';
import intlPath from '../../../../../src/web/utils/intlPath';
import transformPayload from './transformPayload';
import { trackDataLayerValue, TRACK_BEGIN_CHECKOUT, TRACK_VIEW_CART } from '../../../../../src/web/utils/dataLayer';
import { getBrandCode } from '../../../../../constants/smBrandNames';
import { errorTokens } from './constants';
import Translation from '../../../../components/stateless/Translation';

const typeNameList = [
  '__typename',
  'tenant.__typename',
  'tenant.address.__typename',
  'tenant.unitAddress.__typename',
  'unit.__typename',
  'paymentMethod.__typename',
  'paymentMethod.billingAddress.__typename',
];

const CHECKOUT = gql`
  mutation SMCheckout($facilityId: String!, $payload: SMCheckoutInput!) {
    storageMartCheckout(facilityId: $facilityId, payload: $payload) {
      successful
      errors
      checkout {
        confirmationNumber
        tenant {
          id
          firstName
          lastName
          email
          phone
          smsOptIn
          address {
            address1
            address2
            city
            state
            postal
          }
          activeDutyMilitary
          commanderFullName
          unitAddress {
            address1
            address2
            city
            state
            postal
          }
          addEmergencyContact
          emergencyContactFirstName
          emergencyContactLastName
          emergencyContactPhone
          addContact
          contactFirstName
          contactLastName
        }
        unit {
          id
          name
          price
          pricingTypeId
        }
        moveInDate
        paymentMethod {
          payLater
          maskedNumber
          expirationDate
          autopayEnroll
          cardType
          billingAddressSameAsMailingAddress
          billingAddress {
            address1
            address2
            city
            state
            postal
          }
        }
        lineItems {
          type
          amount
          duedate
          sort
        }
        contractIds
        leaseInfo
        isContactless
        lockQty
        lockDetails {
          amount
          tax
          code
        }
        storedValue
      }
    }
  }
`;

const GET_CONFIRMATION = gql`
  query SMCheckoutConfirmation($confirmationNumber: String!) {
    storageMartCheckoutConfirmation(confirmationNumber: $confirmationNumber) {
      successful
      errors
      checkout {
        confirmationNumber
        storedgeUnitGroupId
        storedgeFacilityId
        discountId
        unitsStartDate
        contractIds
        leaseInfo
        hasSignedLease
        storedValue
        completed
        tenant {
          firstName
          lastName
          email
          phone
          smsOptIn
          address {
            address1
            address2
            city
            state
            postal
          }
          addEmergencyContact
          emergencyContactFirstName
          emergencyContactLastName
          emergencyContactPhone
          addContact
          contactFirstName
          contactLastName
          activeDutyMilitary
          commanderFullName
          unitAddress {
            address1
            address2
            city
            state
            postal
          }
        }
        unit {
          id
          name
          price
          pricingTypeId
        }
        moveInDate
        paymentMethod {
          payLater
          maskedNumber
          autopayEnroll
          cardType
          billingAddress {
            address1
            address2
            city
            state
            postal
          }
        }
        lineItems {
          type
          amount
          duedate
          sort
        }
      }
    }
  }
`;

export const GET_FACILITY = gql`
  query GetSMRentalSummaryData($id: ID!, $unitGroupId: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      currencySymbol
      brandName
      storeNumber
      ...OrderSummaryFacility
    }
  }

  ${OrderSummary.fragments.facility}
`;

const propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  completeReviewCost: PropTypes.func.isRequired,
  unitUnavailableError: PropTypes.string,
  updateCost: PropTypes.func.isRequired,
  discountPlan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  receipt: PropTypes.shape({
    creditAmount: PropTypes.number.isRequired,
    feeAmount: PropTypes.number.isRequired,
    rentAmount: PropTypes.number.isRequired,
    rentMonth: PropTypes.string.isRequired,
    nextDueDate: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
  isReservation: PropTypes.bool,
};

const defaultProps = {
  unitUnavailableError: undefined,
  receipt: undefined,
  discountPlan: undefined,
  isReservation: false,
};

const StorageMartCheckout = ({
  availableDates,
  completeReviewCost,
  updateCost,
  unitUnavailableError,
  receipt,
  discountPlan,
  isReservation,
  payload,
  isBuilderView,
}) => {
  const intl = useIntl();
  const [unitGroupId, setUnitGroupId] = useState();
  const [facilityId, setFacilityId] = useState();
  const {
    unitGroupId: queryUnitGroupId,
    facilityId: queryFacilityId, confirmationNumber: encodedConfirmationNumber,
  } = getQueryParams();
  const [checkoutData, setCheckoutData] = useState();
  const [currentStep, setCurrentStep] = useState(CHECKOUT_STEPS.CONTACT_INFORMATION);
  const [maxStep, setMaxStep] = useState(CHECKOUT_STEPS.CONTACT_INFORMATION);
  const [formError, setFormError] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showMobileSummary, setShowMobileSummary] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorTokens.default);
  const parsedAvailableDates = availableDates ? availableDates.sort().map(parseISO) : undefined;
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);

  const onCheckInDateChange = date => (
    updateCost(date)
  );

  const onPricingTypeChange = pricingTypeId => (
    completeReviewCost(unitGroupId, facilityId, pricingTypeId)
  );

  const onReturnToStep = previousStep => (
    setCurrentStep(previousStep)
  );

  const redirectToConfirmation = (confirmationNumber, confirmationUnitGroupId) => {
    const queryString = `confirmationNumber=${confirmationNumber}&unitGroupId=${confirmationUnitGroupId}`;
    window.location = isReservation
      ? `${intlPath(intl.locale, payload.reservationConfirmationPath)}?${queryString}`
      : `${intlPath(intl.locale, payload.checkoutConfirmationPath)}?${queryString}`;
  };

  const [query, {
    loading: facilityLoading,
    error: facilityError,
    data,
  }] = useLazyQuery(GET_FACILITY, {
    variables: { id: facilityId, unitGroupId },
  });

  const [checkoutQuery, {
    loading: confirmationLoading,
    error: confirmationLoadingError,
  }] = useLazyQuery(GET_CONFIRMATION, {
    variables: {
      confirmationNumber: encodedConfirmationNumber,
    },
    onCompleted: (confirmationResponse) => {
      if (get(confirmationResponse, 'storageMartCheckoutConfirmation.successful', false)) {
        if (confirmationResponse.storageMartCheckoutConfirmation.checkout.completed) {
          redirectToConfirmation(encodedConfirmationNumber);
        } else {
          const {
            checkout,
            checkout: {
              storedgeUnitGroupId,
              storedgeFacilityId,
            },
          } = confirmationResponse.storageMartCheckoutConfirmation;
          setUnitGroupId(storedgeUnitGroupId);
          setFacilityId(storedgeFacilityId);
          setCheckoutData(omit(checkout, typeNameList));
          if (checkout.moveInDate) {
            onCheckInDateChange(new Date(checkout.moveInDate));
          }
        }
      } else {
        setConfirmationError(true);
      }
    },
  });

  useEffect(() => {
    if (!unitGroupId) {
      setUnitGroupId(queryUnitGroupId);
    }

    if (!facilityId) {
      setFacilityId(queryFacilityId);
    }
  }, [queryUnitGroupId, queryFacilityId]);

  useEffect(() => {
    if (!isBuilderView) {
      if (!hasTrackedDataLayer && data && get(data, 'facility') && get(data, 'facility.unitGroup')) {
        setHasTrackedDataLayer(true);
        const facility = get(data, 'facility');
        TRACK_BEGIN_CHECKOUT(get(facility, 'unitGroup'), facility, intl);
        TRACK_VIEW_CART(get(facility, 'unitGroup'), facility, intl);
      }
    }
  }, [data, hasTrackedDataLayer]);

  useEffect(() => {
    if (!isBuilderView) {
      if (unitGroupId && facilityId && !availableDates.length && availableDates.length === 0) {
        completeReviewCost(unitGroupId, facilityId, 0);
      }

      if (unitGroupId && facilityId && !data) {
        query();
      }

      if (encodedConfirmationNumber && !unitGroupId && !facilityId) {
        checkoutQuery();
      }
    }
  }, [unitGroupId, facilityId, availableDates]);

  useEffect(() => {
    if (currentStep === CHECKOUT_STEPS.COMPLETE) {
      redirectToConfirmation(checkoutData.confirmationNumber, unitGroupId);
    }
  }, [currentStep]);

  return (
    <ApolloClientConsumer>
      {(client) => {
        const onStepSubmit = async (values, thisStep, nextStep, scrollToRef) => {
          const checkoutPayload = transformPayload(
            values,
            checkoutData,
            unitGroupId,
            thisStep,
            isReservation,
          );

          setFormError(false);
          setErrorMessage(errorTokens.default);
          setSubmitting(true);

          await client.mutate({
            mutation: CHECKOUT,
            variables: {
              facilityId,
              payload: checkoutPayload,
            },
          }).then((res) => {
            if (res.data.storageMartCheckout.successful) {
              if (nextStep > maxStep) {
                setMaxStep(nextStep);
              }

              if (nextStep === CHECKOUT_STEPS.COMPLETE) {
                if (checkoutPayload.paymentMethod.payLater) {
                  trackDataLayerValue({
                    event: 'rin0_success',
                    category_rin0: 'Success',
                    action_rin0: 'RIN0',
                    brand: getBrandCode(get(data, 'facility.brandName')),
                    storeId: get(data, 'facility.storeNumber'),
                  });
                } else {
                  trackDataLayerValue({
                    event: 'rin_success',
                    category_rin: 'Success',
                    action_rin: 'RIN',
                    brand: getBrandCode(get(data, 'facility.brandName')),
                    storeId: get(data, 'facility.storeNumber'),
                  });
                }
              }
              setCurrentStep(nextStep);
              setSubmitting(false);
              setCheckoutData(omit(res.data.storageMartCheckout.checkout, typeNameList));
              scrollToRef.current?.scrollIntoView({ behavior: 'smooth' }); // eslint-disable-line no-unused-expressions
            } else {
              if (res.data.storageMartCheckout.errors.length
                && errorTokens[res.data.storageMartCheckout.errors[0]]
              ) {
                setErrorMessage(errorTokens[res.data.storageMartCheckout.errors[0]]);
              }
              setFormError(true);
              setSubmitting(false);
            }
          }).catch(() => {
            setFormError(true);
            setSubmitting(false);
          });
        };

        return (
          <CurrentStepContext.Provider value={
            {
              currentStep,
              maxStep,
              onStepSubmit,
              formError,
              submitting,
              checkoutData,
              onReturnToStep,
              isReservation,
              errorMessage,
            }
          }
          >
            <div>
              <CheckoutProgress isReservation={isReservation} />
              <Grid>
                <Row>
                  <Col xs={12}>
                    <div className={classNames('row', styles.flex)}>
                      <Col xs={14} mdPush={8} md={4}>
                        <OrderSummary
                          facility={get(data, 'facility')}
                          loading={facilityLoading || confirmationLoading}
                          showMobileSummary={showMobileSummary}
                          setShowMobileSummary={setShowMobileSummary}
                          receipt={receipt}
                          discountPlan={discountPlan}
                        />
                      </Col>
                      <Col xs={12} mdPull={4} md={7}>
                        <MobileOrderSummary setShowMobileSummary={setShowMobileSummary} facility={get(data, 'facility')} receipt={receipt} />
                        {
                          (unitUnavailableError || facilityError || (get(data, 'facility') && !get(data, 'facility.unitGroup')))
                          && <SMMoveInUnavailable facilityId={facilityId} />
                        }
                        {
                          (confirmationError || confirmationLoadingError)
                          && (
                            <Alert bsStyle="danger">
                              <Translation
                                id="widgets.checkout.confirmationError"
                                defaultMessage="An error was encountered when loading the checkout"
                              />
                            </Alert>
                          )
                        }
                        {
                          !unitUnavailableError && !facilityError && !confirmationLoadingError && !confirmationError && get(data, 'facility.unitGroup') && (
                            <>
                              <ContactInformation
                                facility={get(data, 'facility')}
                              />
                              <UnitInformation
                                facility={get(data, 'facility')}
                                availableDates={parsedAvailableDates}
                                onCheckInDateChange={onCheckInDateChange}
                                onPricingTypeChange={onPricingTypeChange}
                              />
                              {!isReservation && (
                                <>
                                  <AccountDetails
                                    facility={get(data, 'facility')}
                                  />
                                  <Payment
                                    facility={get(data, 'facility')}
                                    receipt={receipt}
                                  />
                                </>
                              )}
                            </>
                          )
                        }
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </CurrentStepContext.Provider>
        );
      }}
    </ApolloClientConsumer>
  );
};

StorageMartCheckout.propTypes = propTypes;
StorageMartCheckout.defaultProps = defaultProps;

export default StorageMartCheckout;
