import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ItemCategories from '../ItemCategories';
import ItemList from '../ItemList';
import styles from './styles.scss';
import { categories } from '../data';

const propTypes = {
  inventory: PropTypes.shape().isRequired,
  setInventory: PropTypes.func.isRequired,
};

const ItemSelection = ({ inventory, setInventory }) => {
  const [selectedCategory, setSelectedCategory] = useState(Object.keys(categories)[0]);
  const [previousCategory, setPreviousCategory] = useState();
  const [searchText, setSearchText] = useState();

  const setCategory = (category) => {
    setPreviousCategory(selectedCategory);
    setSelectedCategory(category);
  };

  return (
    <div className={classNames(styles.itemSelection)}>
      <ItemCategories
        previousCategory={previousCategory}
        selectedCategory={selectedCategory}
        setCategory={setCategory}
        setSearchText={setSearchText}
      />
      <ItemList
        inventory={inventory}
        setInventory={setInventory}
        selectedCategory={selectedCategory}
        searchText={searchText}
      />
    </div>
  );
};

ItemSelection.propTypes = propTypes;

export default ItemSelection;
