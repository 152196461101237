import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.scss';

const propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentStep: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  nextBtnDisabled: PropTypes.bool,
  futureStepsDisabled: PropTypes.bool,
};

const defaultProps = {
  nextBtnDisabled: false,
  futureStepsDisabled: false,
};

const Breadcrumbs = ({
  steps,
  currentStep,
  onClick,
  nextBtnDisabled,
  futureStepsDisabled,
}) => {
  const currentIdx = steps.findIndex(({ value }) => value === currentStep);
  const crumbSteps = steps.map(({ name, value }, i) => {
    const className = cx(
      styles.crumb,
      {
        [styles.selected]: i === currentIdx,
        [styles.disabled]: (i === currentIdx + 1 && nextBtnDisabled) ||
          (i > currentIdx + 1 && futureStepsDisabled),
      },
    );

    return (
      <li key={value} className={className}>
        <button type="button" onClick={() => onClick(value)}>
          <span className={styles.count}>{i + 1}</span>
          {name}
        </button>
      </li>
    );
  });

  return (
    <ul className={styles.breadcrumbs}>
      {crumbSteps}
    </ul>
  );
};

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
