import React from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';

const propTypes = {};

const defaultProps = {};

const Select = props => (
  <ReactSelect {...props} />
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
