import React from 'react';
import PropTypes from 'prop-types';
import InputElement from 'react-input-mask';
import { compose, pure } from '../../../../../src/web/utils/react';

const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
};

const defaultProps = {
  input: undefined,

};

const PhoneInput = ({
  input,
  meta, // eslint-disable-line no-unused-vars
  ...props
}) => (
  <InputElement
    className="form-control"
    type="phone"
    mask="(999) 999-9999"
    maskChar=" "
    {...props}
    {...input}
  />
);

PhoneInput.propTypes = propTypes;
PhoneInput.defaultProps = defaultProps;
export default compose(
  pure(),
)(PhoneInput);
