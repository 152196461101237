import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../../modules/react/components/stateless/Button';

export default class RadioOption extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.any,
    }).isRequired,
    value: PropTypes.any,
  };

  handleClick = () => {
    const { input: { onChange }, value } = this.props;

    onChange(value);
  };

  render() {
    const { children, input, value } = this.props;

    return (
      <Button
        onClick={this.handleClick}
        active={input && input.value === value}
      >
        {children}
      </Button>
    );
  }
}
