import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import get from 'lodash/get';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.scss';
import parentStyles from '../styles.scss';
import {
  TRACK_CLICK_SIZING_GUIDE_BACK_TO_CALCULATOR,
} from '../../../../../../src/web/utils/dataLayer';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  inventory: PropTypes.shape.isRequired,
  suggestedSize: PropTypes.shape.isRequired,
  setShowInventoryList: PropTypes.func.isRequired,
};

function InventoryList({ inventory, suggestedSize, setShowInventoryList }) {
  return (
    <div className={styles.inventoryList}>
      <div className={styles.header}>
        <h4>
          <Translation
            id="storagecalculator.inventorylist.title"
            defaultMessage="Your list:"
          />
        </h4>
      </div>
      <div className={styles.items}>
        {
          map(filter(inventory, item => item.count > 0), item => (
            <div key={item.id} className={styles.item}>
              <Row>
                <Col xs={6}>
                  <Translation
                    id={`storagecalculator.item.${item.translationId}`}
                    defaultMessage={item.defaultMessage}
                  />
                </Col>
                <Col xs={6} className="text-right">{item.count}</Col>
              </Row>
            </div>
          ))
        }
      </div>
      <div className={styles.footer}>
        <Row className={styles.row}>
          <Col xs={6}>
            <strong>
              <Translation
                id="storagecalculator.inventorylist.total"
                defaultMessage="Total:"
              />
            </strong>
          </Col>
          <Col xs={6} className="text-right">
            <strong>
              <Translation
                id="storagecalculator.inventorylist.itemcount"
                defaultMessage="{count} items"
                values={{
                  count: sumBy(inventory, 'count'),
                }}
              />
            </strong>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col xs={6}>
            <strong>
              <Translation
                id="storagecalculator.inventorylist.werecommend"
                defaultMessage="We recommend:"
              />
            </strong>
          </Col>
          <Col xs={6} className="text-right">
            <strong>{get(suggestedSize, 'id', 'N/A')}</strong>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col xs={6} />
          <Col xs={6} className="text-right">
            <button
              className={classNames(parentStyles.button, parentStyles.active)}
              onClick={() => setShowInventoryList(false)}
              type="button"
              {...TRACK_CLICK_SIZING_GUIDE_BACK_TO_CALCULATOR}
            >
              <Translation
                id="storagecalculator.inventorylist.backtocalculatorbutton"
                defaultMessage="Back to calculator"
              />
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

InventoryList.propTypes = propTypes;

export default InventoryList;
