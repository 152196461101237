import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import Translation from '../../../../../components/stateless/Translation';
import { RENT_MONTH_NAMES, VALUE_COVERAGE_MONTH_NAMES } from '../constants';
import Price from '../../../../../components/stateless/Price';
import SMTooltip from '../../../../components/stateless/SMTooltip';

const propTypes = {
  lineItem: PropTypes.shapeOf().isRequired,
  currency: PropTypes.string().isRequired,
  currencyLocale: PropTypes.string().isRequired,
  discountPlan: PropTypes.shapeOf(),
};
const defaultProps = {
  discountPlan: undefined,
};

const dateRegex = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/;
const valueCoverageRegex = /protection plan/i;
const rentItemRegex = /rent unit/i;
const webOnlyText = '[WEB ONLY] ';
const adminFeeRegex = /administrative fee/i;
const lockRegex = /lock/i;

const RentLineItem = ({
  children,
  currency,
  currencyLocale,
  price,
}) => (
  <div className="flex gap-2 place-content-between mb-2">
    <div>
      {children}
    </div>
    <Price
      currency={currency}
      locale={currencyLocale}
      value={price}
    />
  </div>
);

const ValueCoverageLineItem = ({
  children,
  currency,
  currencyLocale,
  price,
}) => (
  <div className="flex gap-2 place-content-between mb-2" data-value-coverage-line-item="true">
    <div>
      {children}
    </div>
    <Price
      currency={currency}
      locale={currencyLocale}
      value={price}
    />
  </div>
);


const DiscountLineItem = ({
  children,
  currency,
  currencyLocale,
  price,
}) => (
  <div className="flex gap-2 place-content-between mb-2">
    <div>
      {children}
    </div>
    <div className="text-[#2a8703]">
      <Price
        currency={currency}
        locale={currencyLocale}
        value={price}
      />
    </div>
  </div>
);

const AdminFeeLineItem = ({
  currency,
  currencyLocale,
  price,
}) => (
  <div className="flex gap-2 place-content-between mb-2">
    <div>
      <Translation
        id="widgets.checkout.summary.adminonetimefee"
        defaultMessage="One-time Admin Fee"
      />
    </div>
    <Price
      currency={currency}
      locale={currencyLocale}
      value={price}
    />
  </div>
);

const LockLineItem = ({
  currency,
  currencyLocale,
  price,
  locale,
  messages,
}) => (
  <div className="flex gap-2 place-content-between mb-2">
    <div className="flex gap-2">
      <Translation
        id="widgets.checkout.summary.merchandise"
        defaultMessage="Lock Fee"
      />
      <SMTooltip
        body={(
          <IntlProvider locale={locale} messages={messages}>
            <Translation
              id="widgets.checkout.summary.lockDisclaimer"
              defaultMessage="You may return the unused lock"
            />
          </IntlProvider>
        )}
      />
    </div>
    <Price
      currency={currency}
      locale={currencyLocale}
      value={price}
    />
  </div>
);

const LineItem = ({
  children,
  currency,
  currencyLocale,
  price,
}) => (
  <div className="flex gap-2 place-content-between mb-2">
    <div>
      {children}
    </div>
    <Price
      currency={currency}
      locale={currencyLocale}
      value={price}
    />
  </div>
);

const SummaryLineItem = ({
  lineItem,
  currency,
  currencyLocale,
  discountPlan,
}) => {
  const { formatMessage, locale, messages } = useIntl();
  const lineItemsToRender = [];
  const fullDescription = lineItem.fullDescription || lineItem.full_description;
  let lineItemPrice = lineItem.amount >= 0 ? lineItem.amount : lineItem.subtotal;
  const discountAmount = lineItem.discountAmount || lineItem.discount_amount;
  const dateResult = dateRegex.exec(fullDescription);
  const valueCoverageResult = valueCoverageRegex.exec(fullDescription);
  const rentItemResult = rentItemRegex.exec(fullDescription);
  let mainDescription = lineItem.description.replace(webOnlyText, '');

  if (dateResult) {
    const [month] = dateResult[0].split('/');
    mainDescription = valueCoverageResult
      ? formatMessage(VALUE_COVERAGE_MONTH_NAMES[month])
      : formatMessage(RENT_MONTH_NAMES[month]);
  }

  if (rentItemResult && discountAmount > 0 && discountPlan) {
    lineItemPrice += discountAmount;
    lineItemsToRender.push((
      <DiscountLineItem
        currency={currency}
        currencyLocale={currencyLocale}
        price={discountAmount * -1}
      >
        <Translation id={`widgets.unitstablev2.storageMart.unitPromo.${discountPlan.name}`} />
      </DiscountLineItem>
    ));
  }

  if (rentItemResult) {
    lineItemsToRender.unshift((
      <RentLineItem currency={currency} currencyLocale={currencyLocale} price={lineItemPrice}>
        {mainDescription}
      </RentLineItem>
    ));
  }

  if (valueCoverageResult) {
    lineItemsToRender.unshift((
      <ValueCoverageLineItem
        currency={currency}
        currencyLocale={currencyLocale}
        price={lineItemPrice}
      >
        {mainDescription}
      </ValueCoverageLineItem>
    ));
  }

  if (adminFeeRegex.exec(fullDescription)) {
    lineItemsToRender.unshift((
      <AdminFeeLineItem
        currency={currency}
        currencyLocale={currencyLocale}
        price={lineItemPrice}
      >
        {mainDescription}
      </AdminFeeLineItem>
    ));
  }

  if (lockRegex.exec(fullDescription)) {
    lineItemsToRender.unshift((
      <LockLineItem
        currency={currency}
        currencyLocale={currencyLocale}
        price={lineItemPrice}
        locale={locale}
        messages={messages}
      >
        {mainDescription}
      </LockLineItem>
    ));
  }

  if (!lineItemsToRender.length) {
    lineItemsToRender.unshift((
      <LineItem
        currency={currency}
        currencyLocale={currencyLocale}
        price={lineItemPrice}
      >
        {mainDescription}
        {lineItem.quantity > 1 ? ` (${lineItem.quantity})` : null}
      </LineItem>
    ));
  }

  return (
    <>
      {lineItemsToRender.map(item => (
        item
      ))}
    </>
  );
};

SummaryLineItem.propTypes = propTypes;
SummaryLineItem.defaultProps = defaultProps;

export default SummaryLineItem;
