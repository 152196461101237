import { query } from 'utils/graphql';
import get from 'lodash/get';
import { STOREDGE } from '../../../../constants/softwareProviders';

// Actions
const MAKE_PAYMENT = 'voyager/movein/MAKE_PAYMENT';
const MAKE_PAYMENT_SUCCESS = 'voyager/movein/MAKE_PAYMENT_SUCCESS';
const MAKE_PAYMENT_FAIL = 'voyager/movein/MAKE_PAYMENT_FAIL';
const REVIEW_COST = 'voyager/movein/REVIEW_COST';
const REVIEW_COST_SUCCESS = 'voyager/movein/REVIEW_COST_SUCCESS';
const REVIEW_COST_FAIL = 'voyager/movein/REVIEW_COST_FAIL';
const COMPLETE_TENANT_MOVEIN = 'voyager/movein/COMPLETE_TENANT_MOVEIN';
const COMPLETE_TENANT_MOVEIN_SUCCESS = 'voyager/movein/COMPLETE_TENANT_MOVEIN_SUCCESS';
const COMPLETE_TENANT_MOVEIN_FAIL = 'voyager/movein/COMPLETE_TENANT_MOVEIN_FAIL';
const COMPLETE_STORABLE_TENANT_MOVEIN = 'voyager/movein/COMPLETE_STORABLE_TENANT_MOVEIN';
const COMPLETE_STORABLE_TENANT_MOVEIN_SUCCESS = 'voyager/movein/COMPLETE_STORABLE_TENANT_MOVEIN_SUCCESS';
const COMPLETE_STORABLE_TENANT_MOVEIN_FAIL = 'voyager/movein/COMPLETE_STORABLE_TENANT_MOVEIN_FAIL';
const UPDATE_PRICING = 'voyager/movein/UPDATE_PRICING';
const UPDATE_SUMMARY = 'voyager/movein/UPDATE_SUMMARY';
const AUTH_TOKEN = 'voyager/movein/AUTH_TOKEN';
const AUTH_TOKEN_SUCCESS = 'voyager/movein/AUTH_TOKEN_SUCCESS';
const AUTH_TOKEN_FAIL = 'voyager/movein/AUTH_TOKEN_FAIL';
const CLEAR_MOVEIN = 'voyager/movein/CLEAR_MOVEIN';
const REFRESH_MOVEIN = 'voyager/movein/REFRESH_MOVEIN';
const CHECK_UNIT_GROUP_AVAILABILITY = 'voyager/movein/CHECK_UNIT_GROUP_AVAILABILITY';
const CHECK_UNIT_GROUP_AVAILABILITY_SUCCESS = 'voyager/movein/CHECK_UNIT_GROUP_AVAILABILITY_SUCCESS';
const CHECK_UNIT_GROUP_AVAILABILITY_FAIL = 'voyager/movein/CHECK_UNIT_GROUP_AVAILABILITY_FAIL';
const FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP = 'voyager/movein/FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP';
const FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_SUCCESS = 'voyager/movein/FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_SUCCESS';
const FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_FAIL = 'voyager/movein/FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_FAIL';
const STORABLE_CHECKOUT_CONFIRMATION = 'voyager/movein/STORABLE_CHECKOUT_CONFIRMATION';
const STORABLE_CHECKOUT_CONFIRMATION_SUCCESS = 'voyager/movein/STORABLE_CHECKOUT_CONFIRMATION_SUCCESS';
const STORABLE_CHECKOUT_CONFIRMATION_FAIL = 'voyager/movein/STORABLE_CHECKOUT_CONFIRMATION_FAIL';
const STORABLE_GET_LEDGER = 'voyager/movein/STORABLE_GET_LEDGER';
const STORABLE_GET_LEDGER_SUCCESS = 'voyager/movein/STORABLE_GET_LEDGER_SUCCESS';
const STORABLE_GET_LEDGER_FAIL = 'voyager/movein/STORABLE_GET_LEDGER_FAIL';

// Reducer
const initialState = {
  loading: false,
  loaded: false,
  tenant: {},
  token: {},
  saving: false,
  authenticating: false,
  authenticated: false,
  availableUnit: {},
  error: null,
  availability: {},
  findingAvailableUnit: false,
  availableUnitResponse: null,
  loadingStorableConfirmation: false,
  storableConfirmationData: undefined,
  storableConfirmationError: undefined,
  loadingStorableLedger: false,
  storableLedgerData: undefined,
  storableLedgerError: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MAKE_PAYMENT:
      return {
        ...state,
        saving: true,
      };
    case MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        saving: false,
        tenantId: action.data.id,
      };
    case MAKE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.error.map(err => err.message),
        saving: false,
      };
    case COMPLETE_TENANT_MOVEIN:
      return {
        ...state,
        saving: true,
      };
    case COMPLETE_TENANT_MOVEIN_SUCCESS:
      return {
        ...state,
        saving: false,
        tenant: action.data.completingTenantMoveIn.tenant,
        unit: action.data.completingTenantMoveIn.unit,
      };
    case COMPLETE_TENANT_MOVEIN_FAIL:
      return {
        ...state,
        saving: false,
        tenant: {
          ...state.tenant,
          error: action.error || 'Unable to complete move-in',
        },
      };
    case COMPLETE_STORABLE_TENANT_MOVEIN:
      return {
        ...state,
        saving: true,
      };
    case COMPLETE_STORABLE_TENANT_MOVEIN_SUCCESS:
      return {
        ...state,
        saving: false,
        tenantId: get(action, 'data.storableTenantMoveIn.tenant.id'),
        unitId: get(action, 'data.storableTenantMoveIn.unit.id'),
        unitName: get(action, 'data.storableTenantMoveIn.unit.name'),
        error: get(action, 'data.storableTenantMoveIn.error'),
      };
    case COMPLETE_STORABLE_TENANT_MOVEIN_FAIL:
      return {
        ...state,
        saving: false,
        error: action.error.message,
      };
    case REVIEW_COST:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case REVIEW_COST_SUCCESS:
      return {
        ...state,
        reviewedFacilityId: action.facilityId,
        reviewedUnitGroupId: action.unitGroupId,
        moveInDate: action.moveInDate || state.moveInDate,
        saving: false,
        pricing: action.data.reviewCost.pricing,
      };
    case REVIEW_COST_FAIL:
      return {
        ...state,
        saving: false,
        availableUnit: {
          ...state.availableUnit,
          error: action.error,
          errorMessage: action.error.response.body.errors[0].message,
        },
      };
    case CHECK_UNIT_GROUP_AVAILABILITY:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case CHECK_UNIT_GROUP_AVAILABILITY_SUCCESS:
      return {
        ...state,
        reviewedFacilityId: action.facilityId,
        reviewedUnitGroupId: action.unitGroupId,
        saving: false,
        availability: action.data.checkUnitGroupAvailability,
      };
    case CHECK_UNIT_GROUP_AVAILABILITY_FAIL:
      return {
        ...state,
        saving: false,
        availability: { available: false },
      };
    case UPDATE_PRICING:
      return {
        ...state,
        moveInDate: action.date,
      };
    case UPDATE_SUMMARY:
      return {
        ...state,
        nextChargeAmount: action.nextChargeAmount,
        unitGroupId: action.unitGroupId,
        facilityId: action.facilityId,
      };
    case AUTH_TOKEN:
      return {
        ...state,
        authenticating: true,
        authenticated: false,
      };
    case AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        ...action.data.authToken,
      };
    case AUTH_TOKEN_FAIL:
      return {
        ...state,
        error: action.error.map(err => err.message),
        authenticating: false,
        authenticated: false,
      };
    case CLEAR_MOVEIN:
      return {
        ...initialState,
      };
    case REFRESH_MOVEIN:
      return {
        ...state,
        tenant: action.tenant,
        unit: action.unit,
      };
    case FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP:
      return {
        ...state,
        findingAvailableUnit: true,
        availableUnitResponse: null,
      };
    case FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_SUCCESS:
      return {
        ...state,
        findingAvailableUnit: false,
        availableUnitResponse: action.data.firstAvailableUnitFromUnitGroup,
      };
    case FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_FAIL:
      return {
        ...state,
        findingAvailableUnit: false,
        availableUnitResponse: { success: false, error: 'There was an error when trying to find the first available unit' },
      };
    case STORABLE_CHECKOUT_CONFIRMATION:
      return {
        ...state,
        loadingStorableConfirmation: true,
        storableConfirmationData: undefined,
        storableConfirmationError: undefined,
      };
    case STORABLE_CHECKOUT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loadingStorableConfirmation: false,
        storableConfirmationData: action.data.storableCheckoutConfirmation,
      };
    case STORABLE_CHECKOUT_CONFIRMATION_FAIL:
      return {
        ...state,
        loadingStorableConfirmation: false,
        storableConfirmationError: { success: false, error: 'There was an error loading the checkout confirmation' },
      };
    case STORABLE_GET_LEDGER:
      return {
        ...state,
        loadingStorableLedger: true,
        storableLedgerData: undefined,
        storableLedgerError: undefined,
      };
    case STORABLE_GET_LEDGER_SUCCESS:
      return {
        ...state,
        loadingStorableLedger: false,
        storableLedgerData: action.data.ledger || action.data.ledgerWithAddons,
      };
    case STORABLE_GET_LEDGER_FAIL:
      return {
        ...state,
        loadingStorableLedger: false,
        storableLedgerError: { success: false, error: 'There was an error loading the ledger' },
      };
    default:
      return state;
  }
}

export function clearMoveIn() {
  return {
    type: CLEAR_MOVEIN,
  };
}

export function updatePricing(date) {
  return {
    type: UPDATE_PRICING,
    date,
  };
}

export function refreshMoveInData(tenant, unit) {
  return {
    type: REFRESH_MOVEIN,
    tenant,
    unit,
  };
}

export function updateSummary(nextChargeAmount, unitGroupId, facilityId) {
  return {
    type: UPDATE_SUMMARY,
    nextChargeAmount,
    unitGroupId,
    facilityId,
  };
}

export function reviewCost(unitGroupId, facilityId, pricingTypeId, moveInDate, discountPlanId, leadId, invoiceableItems, insuranceId) {
  return {
    types: [REVIEW_COST, REVIEW_COST_SUCCESS, REVIEW_COST_FAIL],
    unitGroupId,
    facilityId,
    pricingTypeId,
    moveInDate,
    discountPlanId,
    leadId,
    invoiceableItems,
    insuranceId,
    force: true,
    promise: query`
      mutation ReviewCost(${{ unitGroupId }}: String!, ${{ facilityId }}: String!, ${{ pricingTypeId }}: Int, ${{ moveInDate }}: String, ${{ discountPlanId }}: String, ${{ leadId }}: String, ${{ invoiceableItems }}: [InvoiceableItemInput], ${{insuranceId}}: String) {
        reviewCost(unitGroupId: $unitGroupId, facilityId: $facilityId, pricingTypeId: $pricingTypeId, moveInDate: $moveInDate, discountPlanId: $discountPlanId, leadId: $leadId, invoiceableItems: $invoiceableItems, insuranceId: $insuranceId) {
          success
          error
          pricing {
            discountPlans {
              publicDescription
              discountPlanDiscounts {
                amount
              }
            }
            dueAtMoveIn
            dueFirstMonth
            invoiceableFees {
              description
              fullDescription
              amount
              discountAmount
              quantity
            }
            moveInDate
            nextDueDate
            proratedCredit
            taxAmount
          }
        }
      }
    `,
  };
}

export function checkUnitGroupAvailability(unitGroupId, facilityId) {
  return {
    types: [CHECK_UNIT_GROUP_AVAILABILITY, CHECK_UNIT_GROUP_AVAILABILITY_SUCCESS, CHECK_UNIT_GROUP_AVAILABILITY_FAIL],
    unitGroupId,
    facilityId,
    force: true,
    promise: query`
      mutation CheckUnitGroupAvailability(${{ unitGroupId }}: String!, ${{ facilityId }}: String!) {
        checkUnitGroupAvailability(unitGroupId: $unitGroupId, facilityId: $facilityId) {
          available
          error
        }
      }
    `,
  };
}

export function getFirstAvailableUnitFromUnitGroup(unitGroupId, facilityId) {
  return {
    types: [FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP, FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_SUCCESS, FIRST_AVAILABLE_UNIT_FROM_UNIT_GROUP_FAIL],
    unitGroupId,
    facilityId,
    force: true,
    promise: query`
      query GetFirstAvailableUnitFromUnitGroup(${{ unitGroupId }}: String!, ${{ facilityId }}: String!) {
        firstAvailableUnitFromUnitGroup(unitGroupId: $unitGroupId, facilityId: $facilityId) {
          success
          error
          units {
            id
            name
            price
            tier {
              id
              rank
            }
            custom_fields {
              name
              value
            }
          }
        }
      }
    `,
  };
}

export function completingTenantMovein(facilityId, paymentMethod, moveIn, address) {
  return {
    types: [COMPLETE_TENANT_MOVEIN, COMPLETE_TENANT_MOVEIN_SUCCESS, COMPLETE_TENANT_MOVEIN_FAIL],
    facilityId,
    force: true,
    promise: query`
      mutation CompletingTenantMoveIn(
      ${{ facilityId }}: String!, ${{ paymentMethod }}: PaymentMethod!,
        ${{ moveIn }}: MoveInInput!, ${{ address }}: AddressInput!) {
        completingTenantMoveIn(facilityId: $facilityId, paymentMethod: $paymentMethod,
          moveIn: $moveIn, address: $address){
          tenant {
            id
          }
          unit {
            id
            name
          }
        }
      }
    `,
  };
}

export function storableTenantMovein({ facilityId, tenantId, unitId, leadId, moveInDate, paymentMethod, invoiceableItems, insuranceId }) {
  return {
    types: [COMPLETE_STORABLE_TENANT_MOVEIN, COMPLETE_STORABLE_TENANT_MOVEIN_SUCCESS, COMPLETE_STORABLE_TENANT_MOVEIN_FAIL],
    facilityId,
    force: true,
    promise: query`
      mutation StorableTenantMoveIn(
      ${{ facilityId }}: String!, ${{ leadId }}: String!, ${{ paymentMethod }}: PaymentMethod,
      ${{ tenantId }}: String!, ${{ unitId }}: String!, ${{ moveInDate }}: String!, ${{invoiceableItems}}: [InvoiceableItemInput],
      ${{insuranceId}}: String) {
        storableTenantMoveIn(facilityId: $facilityId, leadId: $leadId, paymentMethod: $paymentMethod,
        tenantId: $tenantId, unitId: $unitId, moveInDate: $moveInDate, invoiceableItems: $invoiceableItems,
        insuranceId: $insuranceId) {
          success
          error
          id
          moveInUnitEventId
          ledgerId
          unit {
            id
            name
          }
          tenant {
            id
          }
        }
      }
    `,
  };
}

export function storableCheckoutConfirmation(facilityId, moveInUnitEventId) {
  return {
    types: [STORABLE_CHECKOUT_CONFIRMATION, STORABLE_CHECKOUT_CONFIRMATION_SUCCESS, STORABLE_CHECKOUT_CONFIRMATION_FAIL],
    facilityId,
    moveInUnitEventId,
    force: true,
    promise: query`
      query StorableCheckoutConfirmation(${{ facilityId }}: String!, ${{ moveInUnitEventId }}: String!) {
        storableCheckoutConfirmation(facilityId: $facilityId, moveInUnitEventId: $moveInUnitEventId) {
          success
          error
          id
          ledger_id
          unit_name
          tenant_name
          move_in_subtotal
          move_in_taxes_total
          move_in_total
          esign_url
          invoice_line_items {
            description
            full_description
            subtotal
            total
            tax_total
            quantity
            discount_amount
          }
          successful_payment_events {
            amount
            payment_method {
              card_number
              card_type
            }
          }
        }
      }
    `,
  };
}

export function makePayment(facilityId, paymentInput) {
  return {
    types: [MAKE_PAYMENT, MAKE_PAYMENT_SUCCESS, MAKE_PAYMENT_FAIL],
    force: true,
    promise: query`
      mutation MakePayment(${{ facilityId }}: String!, ${{ paymentInput }}: PaymentInput!) {
        makePayment(facilityId: $facilityId, paymentInput: $paymentInput) {
          id
        }
      }
    `,
  };
}

export function authToken(token) {
  return {
    types: [AUTH_TOKEN, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAIL],
    force: true,
    promise: query`
      mutation AuthToken(${{ token }}: String!) {
        authToken(token: $token) {
          ledgerId
          nextChargeAmount
          tenantId
          tokenUnitGroupId
          tokenFacilityId
          accessToken
          unit {
            name
            size
          }
          discountId
          discountName
        }
      }
    `,
  };
}

export function getLedger(facilityId, ledgerId) {
  return {
    types: [STORABLE_GET_LEDGER, STORABLE_GET_LEDGER_SUCCESS, STORABLE_GET_LEDGER_FAIL],
    facilityId,
    ledgerId,
    force: true,
    promise: query`
      query GetLedger(${{ facilityId }}: String!, ${{ ledgerId }}: String!) {
        ledger(facilityId: $facilityId, ledgerId: $ledgerId) {
          success
          error
          id
          moved_in_at
          autopay_info {
           autopay_enabled
          }
          tenant {
            first_name
            last_name
            email
            phone_numbers {
              formatted_number
              primary
              sms_opt_in
            }
            mailing_address {
              address1
              address2
              city
              state
              postal
            }
            contacts {
              first_name
              last_name
              primary
              description
              phone_numbers {
                formatted_number
                primary
              }
            }
          }
          unit {
            id
            name
            price
            length
            width
            height
            unit_type {
              name
            }
            unit_amenities {
              name
              token
              short_code
            }
            unit_group_id
          }
        }
      }
    `,
  };
}

export function getLedgerWithAddons(facilityId, ledgerId) {
  return {
    types: [STORABLE_GET_LEDGER, STORABLE_GET_LEDGER_SUCCESS, STORABLE_GET_LEDGER_FAIL],
    facilityId,
    ledgerId,
    force: true,
    promise: query`
      query GetLedgerWithAddons(${{ facilityId }}: String!, ${{ ledgerId }}: String!) {
        ledgerWithAddons(facilityId: $facilityId, ledgerId: $ledgerId) {
          success
          error
          id
          moved_in_at
          autopay_info {
           autopay_enabled
          }
          tenant {
            first_name
            last_name
            email
            phone_numbers {
              formatted_number
              primary
              sms_opt_in
            }
            mailing_address {
              address1
              address2
              city
              state
              postal
            }
            contacts {
              first_name
              last_name
              primary
              description
              phone_numbers {
                formatted_number
                primary
              }
            }
          }
          unit {
            id
            name
            price
            length
            width
            height
            unit_type {
              name
            }
            unit_amenities {
              name
              token
              short_code
            }
            unit_group_id
          }
          discounts {
            id
            name
            kind
            description
          }
        }
      }
    `,
  };
}
