import React from 'react';
import styles from './styles.scss';
import SMFormFieldPhone from '../../../../components/stateless/SMFormFieldPhone';

const CheckoutFormFieldPhone = ({ ...props }) => (
  <SMFormFieldPhone
    className={styles.checkoutFormFieldPhone}
    errorClassName={styles.errorMessage}
    invalidClassName={styles.invalid}
    helpBlockClassName={styles.helpBlock}
    {...props}
  />
);

export default CheckoutFormFieldPhone;
