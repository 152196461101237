import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import useQuery from '../../modules/react/hooks/useQuery';
import FacilityImage from '../components/stateless/FacilityImage';

const propTypes = {
  facility: PropTypes.shape({
    primaryImageMediumId: PropTypes.string,
  }).isRequired,
};

const defaultProps = {};

const GET_MEDIUM = gql`
  query GetPrimaryFacilityImage($id: ID!) {
    medium(id: $id) {
      id
      trash
      filename
      url
      placeholder
      srcSet
      sourceWidth
      sourceHeight
      defaultMetadata {
        title
        altText
      }
      metadata {
        title
        altText
      }
    }
  }
`;

const FacilityImageContainer = ({
  facility,
  ...rest
}) => {
  const { primaryImageMediumId } = facility;
  const { data } = useQuery(GET_MEDIUM, {
    variables: { id: primaryImageMediumId },
    skip: !primaryImageMediumId,
  });

  if (data) {
    const { medium } = data;
    return (
      <FacilityImage
        {...rest}
        medium={!medium.trash && medium}
        facility={facility}
      />
    );
  }

  if (!primaryImageMediumId) {
    return (
      <FacilityImage
        {...rest}
        facility={facility}
      />
    );
  }

  return null;
};

FacilityImageContainer.propTypes = propTypes;
FacilityImageContainer.defaultProps = defaultProps;

FacilityImageContainer.fragments = FacilityImage.fragments;

export default FacilityImageContainer;
