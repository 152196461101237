// Validate credit card expiration date
import padStart from 'lodash/padStart';

function validateExpireDate(expireMonth, expireYear, currentDate) {
  const fullMonth = padStart(expireMonth, 2, '0');
  const fullYear = 2000 + parseInt(expireYear, 10);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const isInvalidMonth = expireMonth < 1 || expireMonth > 12;
  const isInvalidYear = fullYear < currentYear;
  const isInvalidExpirationDate = expireMonth < currentMonth && fullYear <= currentYear;

  // field is required
  if (!expireMonth || !expireYear) {
    return 'widgets.checkout.creditCardExpireDate.required';
  }

  // field must be a valid month
  if (isInvalidMonth) {
    return 'widgets.checkout.creditCardExpireDate.invalidMonth';
  }

  // field must be a valid year
  if (isInvalidYear) {
    return 'widgets.checkout.creditCardExpireDate.invalidYear';
  }

  // field must be a valid expiration date
  if (isInvalidExpirationDate) {
    return 'widgets.checkout.creditCardExpireDate.invalid';
  }

  // field is valid
  const validCreditCardExpireDateRegex = new RegExp('^[0-9]{2}/[0-9]{2}$');
  if (expireMonth && expireYear && !validCreditCardExpireDateRegex.test(`${padStart(fullMonth, 2, '0')}/${expireYear}`)) {
    return 'widgets.checkout.creditCardExpireDate.invalid';
  }

  return null;
}

// Validate credit card number
function validateCreditCardNumber(creditCardNumber) {
  const validCreditCardRegex = new RegExp('^[0-9]{13,19}$');
  const userInput = creditCardNumber.replace(/\s/g, '');

  // field is required
  if (!userInput) {
    return 'widgets.checkout.creditCardNumber.required';
  }

  // field must be a number between 13 and 19 digits
  if (!validCreditCardRegex.test(userInput)) {
    return 'widgets.checkout.creditCardNumber.invalid';
  }


  return null;
}

function validateCreditCardSecurityCode(creditCardSecurityCode) {
  const validCreditCardSecurityCodeRegex = new RegExp('^[0-9]{3,4}$');

  // field is required
  if (!creditCardSecurityCode) {
    return 'widgets.checkout.creditcardsecuritycode.required';
  }

  // field must be a number between 3 and 4 digits
  if (!validCreditCardSecurityCodeRegex.test(creditCardSecurityCode)) {
    return 'widgets.checkout.creditcardsecuritycode.invalid';
  }

  return null;
}

export {
  validateCreditCardNumber,
  validateCreditCardSecurityCode,
  validateExpireDate,
};
