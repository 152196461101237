import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import StepTitle from '../StepTitle';
import StepSection from '../StepSection';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import AccountDetailsForm from './AccountDetailsForm';
import Translation from '../../../../../components/stateless/Translation';
import styles from '../styles.scss';

const propTypes = {
  facility: PropTypes.shape().isRequired,
};

const defaultProps = {};

const AccountDetails = ({ facility }) => {
  const stepContext = useContext(CurrentStepContext);
  const scrollToRef = useRef(null);

  return (
    <div>
      <StepTitle stepNumber={CHECKOUT_STEPS.ACCOUNT_DETAILS}>
        {`${CHECKOUT_STEPS.ACCOUNT_DETAILS}.`}
        {' '}
        <Translation
          id="widgets.checkout.progress.step3"
          defaultMessage="Account Details"
        />
      </StepTitle>
      {
        stepContext.currentStep === CHECKOUT_STEPS.ACCOUNT_DETAILS
        && (
          <StepSection active>
            <AccountDetailsForm
              onSubmit={stepContext.onStepSubmit}
              currentStep={stepContext.currentStep}
              nextStep={CHECKOUT_STEPS.PAYMENT}
              formError={stepContext.formError}
              submitting={stepContext.submitting}
              formData={stepContext.checkoutData}
              maxStep={stepContext.maxStep}
              facility={facility}
              scrollToRef={scrollToRef}
            />
          </StepSection>
        )
      }
      {
        stepContext.currentStep !== CHECKOUT_STEPS.ACCOUNT_DETAILS
        && stepContext.maxStep > CHECKOUT_STEPS.ACCOUNT_DETAILS
        && (
          <StepSection showEditStepButton stepNumber={CHECKOUT_STEPS.ACCOUNT_DETAILS}>
            <h5>
              <Translation
                id="widgets.checkout.activeMilitaryTitle"
                defaultMessage="Active-Duty Military:"
              />
              {' '}
              {get(stepContext, 'checkoutData.tenant.activeDutyMilitary', false) ? 'Yes' : 'No'}
            </h5>
            <div>{get(stepContext, 'checkoutData.tenant.commanderFullName')}</div>
            {
              get(stepContext, 'checkoutData.tenant.unitAddress.address1') && (
                <>
                  <div>{get(stepContext, 'checkoutData.tenant.unitAddress.address1')}</div>
                  <div>{get(stepContext, 'checkoutData.tenant.unitAddress.address2')}</div>
                  <div>
                    {get(stepContext, 'checkoutData.tenant.unitAddress.city')}
                    {', '}
                    {get(stepContext, 'checkoutData.tenant.unitAddress.state')}
                    {' '}
                    {get(stepContext, 'checkoutData.tenant.unitAddress.postal')}
                  </div>
                </>
              )
            }

            <h5>
              <Translation
                id="widgets.checkout.emergencyContactTitle"
                defaultMessage="Emergency Contact:"
              />
              {' '}
              {get(stepContext, 'checkoutData.tenant.addEmergencyContact', false) ? 'Yes' : 'No'}
            </h5>
            <div>
              {get(stepContext, 'checkoutData.tenant.emergencyContactFirstName')}
              {' '}
              {get(stepContext, 'checkoutData.tenant.emergencyContactLastName')}
            </div>
            <div>{get(stepContext, 'checkoutData.tenant.emergencyContactPhone')}</div>

            <h5>
              <Translation
                id="widgets.checkout.additionalContactTitle"
                defaultMessage="Authorize Access:"
              />
              {' '}
              {get(stepContext, 'checkoutData.tenant.addContact', false) ? 'Yes' : 'No'}
            </h5>
            <div>
              {get(stepContext, 'checkoutData.tenant.contactFirstName')}
              {' '}
              {get(stepContext, 'checkoutData.tenant.contactLastName')}
            </div>

            {get(stepContext, 'checkoutData.storedValue') ? (
              <>
                <h5>
                  <Translation
                    id="widgets.checkout.storedValueTitle"
                    defaultMessage="Approximate Stored Value:"
                  />
                  {' '}
                  {get(facility, 'currencySymbol')}
                  {get(stepContext, 'checkoutData.storedValue')}
                </h5>
              </>
            ) : null}
          </StepSection>
        )
      }
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  );
};

AccountDetails.propTypes = propTypes;
AccountDetails.defaultProps = defaultProps;

export default AccountDetails;
