import PhoneNumber from 'awesome-phonenumber';
import countries from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';

const cache = {};

countries.registerLocale(es);
countries.registerLocale(en);
countries.registerLocale(fr);

const getFormattedCountryCode = regionCode => (
  `${PhoneNumber.getCountryCodeForRegionCode(regionCode)}`
);

/**
  * react-intl-tel-input provides a countriesData prop that allows us to determine what countries to display, their region codes,
  * dial codes, and a "priority" for conflicting dial codes.
  * When countriesData is not set, it will default to https://github.com/patw0929/react-intl-tel-input/blob/master/src/components/AllCountries.js
 */
const getCountries = (language) => {
  const formattedLanguage = language.split('-')[0];
  if (countries[formattedLanguage]) {
    return countries[formattedLanguage];
  }
  const countryNamesByRegionCode = countries.getNames(formattedLanguage);

  const countriesForLanguage = Object.keys(countryNamesByRegionCode).map(regionCode => ([
    countryNamesByRegionCode[regionCode],
    regionCode.toLowerCase(),
    getFormattedCountryCode(regionCode),
    // prioritize us, ca, and gb over every other country with conflicting dial codes so the flag dropdown doesn't jump around when typing.
    regionCode === 'us' || regionCode === 'ca' || regionCode === 'gb' ? 1 : -1,
  ]));

  cache[formattedLanguage] = countriesForLanguage;

  return countriesForLanguage;
};

export default getCountries;
