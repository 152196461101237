import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Button } from '@canaveral/components';
import cx from 'classnames';
import styles from './styles.scss';

const propTypes = {
  medium: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    formattedUpdatedAt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    facility: PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.shape({
        fullAddress: PropTypes.string.isRequired,
      }).isRequired,
    }),
    identifier: PropTypes.string,
    defaultMetadata: PropTypes.shape({
      altText: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
  selectedMedium: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  setSelectedMedium: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedMedium: undefined,
};

const MediaGallerySelectorRow = ({ medium, selectedMedium, setSelectedMedium }) => {
  const {
    filename,
    id,
    formattedUpdatedAt,
    url,
    facility,
    identifier,
    defaultMetadata: { altText, title },
  } = medium;

  return (
    <Button
      key={id}
      context="neutral"
      onClick={() => setSelectedMedium({ ...medium, mediumId: id })}
      className={cx(
        styles.row,
        { [styles.highlight]: selectedMedium && selectedMedium.mediumId === id },
      )}
    >
      <div className={styles.imageColumn}>
        <img src={url} alt={altText} className={styles.thumbnail} />
      </div>
      <div className={styles.fileColumn}>
        <span className={styles.info}>
          <div>
            {title || filename}
          </div>
          <div className={styles.filename}>
            {filename}
          </div>
        </span>
      </div>
      <div className={styles.facilityColumn}>
        <span>
          <div>
            {facility && facility.name}
          </div>
          <div className={styles.address}>
            {facility && facility.address.fullAddress}
          </div>
        </span>
      </div>
      <div className={styles.identifierColumn}>
        {identifier}
      </div>
      <div className={styles.dateColumn}>
        {formattedUpdatedAt}
      </div>
    </Button>
  );
};

MediaGallerySelectorRow.propTypes = propTypes;
MediaGallerySelectorRow.defaultProps = defaultProps;

MediaGallerySelectorRow.fragments = {
  medium: gql`
    fragment MediaGallerySelectorRowMedia on Medium {
      filename
      id
      formattedUpdatedAt
      url
      companyId
      facility {
        name
        address {
          fullAddress
        }
      }
      identifier
      decorative
      defaultMetadata {
        altText
        title
      }
      metadata(locale: $locale) {
        id
        locale
        altText
        title
      }
    }
  `,
};

export default MediaGallerySelectorRow;
