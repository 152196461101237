import url from 'url';

export const FR_CA = 'fr-ca';
export const EN_CA = 'en-ca';
export const EN_GB = 'en-gb';
export const EN_US = 'en-us';
export const ES = 'es';

export const LOCALES = [
  FR_CA,
  EN_GB,
  EN_US,
  ES,
  EN_CA,
];

export const DEFAULT_LOCALE = EN_US;
export const NON_DEFAULT_LOCALES = LOCALES.filter(locale => locale !== DEFAULT_LOCALE);

export const LOCALE_PILL_VALUES = {
  [EN_US]: 'EN',
  [EN_GB]: 'EN-GB',
  [ES]: 'ES',
  [FR_CA]: 'FR-CA',
  [EN_CA]: 'EN-CA',
};

export const LOCALE_LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'en-US (American English)',
  [EN_GB]: 'en-GB (British English)',
  [ES]: 'es (Spanish)',
  [FR_CA]: 'fr-CA (French Canadian)',
  [EN_CA]: 'en-CA (Canadian English)',
};

export const LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'English (US)',
  [EN_GB]: 'English (UK)',
  [ES]: 'Spanish',
  [FR_CA]: 'French (Canada)',
  [EN_CA]: 'English (Canada)',
};

export const NATIVE_LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'English (US)',
  [EN_GB]: 'English (UK)',
  [ES]: 'Español',
  [FR_CA]: 'Français',
  [EN_CA]: 'English (CA)',
};

export const isDefaultLocale = locale => locale === DEFAULT_LOCALE;

export const resolveLocaleUrl = (baseUrl, locale) => (
  isDefaultLocale(locale) ? baseUrl : url.resolve(baseUrl, locale)
);
