import React from 'react';
import LoadingIndicator from '../../stateless/LoadingIndicator';
import styles from './styles.scss';

const propTypes = {};

const defaultProps = {};

const IntlPhoneInputLoading = () => (
  <div className={styles.loading}>
    <LoadingIndicator />
  </div>
);

IntlPhoneInputLoading.propTypes = propTypes;
IntlPhoneInputLoading.defaultProps = defaultProps;

export default IntlPhoneInputLoading;
