import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import MediaGalleryImage from '../../../../modules/react/components/stateful/MediaGalleryImage';
import { resolvePrimaryFacilityImageUrl } from '../../../../modules/assets';

const propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  facility: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postal: PropTypes.string,
    }),
  }).isRequired,
  medium: PropTypes.shape(),
};

const defaultProps = {
  medium: undefined,
  className: undefined,
};

const FacilityImage = ({
  className,
  alt,
  facility: {
    name,
    address:
    {
      address1,
      address2,
      city,
      state,
      postal,
    },
  },
  medium,
  ...rest
}) => {
  const defaultAltText = [name, address1, address2, city, state, postal].filter(Boolean).join('\n');
  const defaultPrimaryImage = resolvePrimaryFacilityImageUrl();

  if (medium) {
    return (
      <MediaGalleryImage
        medium={medium}
        legacyImage={{ file: defaultPrimaryImage, alt: defaultAltText }}
        className={className}
        width={medium.sourceWidth ? medium.sourceWidth : '455'}
        height={medium.sourceHeight ? medium.sourceHeight : '248'}
      />
    );
  }

  return (
    <img
      {...rest}
      className={className}
      alt={defaultAltText}
      src={defaultPrimaryImage}
      width="455"
      height="248"
    />
  );
};

FacilityImage.propTypes = propTypes;
FacilityImage.defaultProps = defaultProps;

FacilityImage.fragments = {
  facility: gql`
    fragment FacilityImageFacility on Facility {
      id
      companyId
      name
      primaryImageMediumId
    }
  `,
};

export default FacilityImage;
