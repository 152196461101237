import { query } from 'utils/graphql';

// Actions
const CREATE = 'voyager/groups/CREATE';
const CREATE_SUCCESS = 'voyager/groups/CREATE_SUCCESS';
const CREATE_FAIL = 'voyager/groups/CREATE_FAIL';
const LOAD = 'voyager/groups/LOAD';
const LOAD_SUCCESS = 'voyager/groups/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/groups/LOAD_FAIL';
const LOAD_LIST = 'voyager/groups/LOAD_LIST';
const LOAD_LIST_SUCCESS = 'voyager/groups/LOAD_LIST_SUCCESS';
const LOAD_LIST_FAIL = 'voyager/groups/LOAD_LIST_FAIL';
const UPDATE = 'voyager/groups/UPDATE';
const UPDATE_SUCCESS = 'voyager/groups/UPDATE_SUCCESS';
const UPDATE_FAIL = 'voyager/groups/UPDATE_FAIL';
const DELETE = 'voyager/groups/DELETE';
const DELETE_SUCCESS = 'voyager/groups/DELETE_SUCCESS';
const DELETE_FAIL = 'voyager/groups/DELETE_FAIL';
const CLONE = 'voyager/groups/CLONE';
const CLONE_SUCCESS = 'voyager/groups/CLONE_SUCCESS';
const CLONE_FAIL = 'voyager/groups/CLONE_FAIL';
const CLEAR_ERRORS = 'voyager/groups/CLEAR_ERRORS';
const SEARCH = 'voyager/groups/SEARCH';
const SEARCH_SUCCESS = 'voyager/groups/SEARCH_SUCCESS';
const SEARCH_FAIL = 'voyager/groups/SEARCH_FAIL';
const UPDATE_FILTERS = 'voyager/groups/UPDATE_FILTERS';

// Reducer
const initialState = {
  groups: [],
  group: {
    keys: [],
    name: '',
  },
  filters: {
    queryString: '',
  },
  errors: null,
  saving: false,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    case CLONE:
    case CREATE:
    case UPDATE:
      return {
        ...state,
        saving: true,
        loaded: false,
        errors: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        group: action.data.group,
        loaded: true,
      };
    case LOAD_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        groups: [...action.data.groups],
      };
    case CLONE_SUCCESS:
    case CREATE_SUCCESS:
      return {
        ...state,
        saving: false,
        groups: [
          action.data.group,
          ...state.groups,
        ],
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        saving: false,
        loaded: true,
        groups: [
          action.data.group,
          ...state.groups.filter(({ id }) => id !== action.data.group.id),
        ],
      };
    case DELETE:
      return {
        ...state,
        errors: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        errors: null,
        groups: state.groups.filter(({ id }) => id !== action.id),
      };
    case UPDATE_FAIL:
    case CREATE_FAIL:
      return {
        ...state,
        saving: false,
        errors: action.error.map(({ message: { name } }) => name),
      };
    case LOAD_FAIL:
    case LOAD_LIST_FAIL:
    case CLONE_FAIL:
    case DELETE_FAIL:
      return {
        ...state,
        saving: false,
        errors: action.error.message.body.errors.map(({ message }) => message),
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        groups: [...action.data.search.groups],
      };
    case SEARCH_FAIL:
      return {
        ...state,
      };
    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.filter,
        },
      };
    }
    default:
      return state;
  }
}

// Selectors

export const getAllFacilityGroups = state => state.groups.groups && state.groups.groups.filter(group =>
  group.type === 'facility');

export const groupsLoaded = state => state.groups.loaded;

export const getGroup = state => state.groups.group;

const GroupFragment = `
  fragment GroupFields on Group {
    id
    companyId
    siteId
    keys
    name
    type
    lastUpdated
    sortType
    customOrder
  }
`;

// Action Creators
export function load(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    force: true,
    promise: query`
      query GetGroup(${{ id }}: String!) {
        group(id: $id) {
          ...GroupFields
        }
      }

      ${GroupFragment}
    `,
  };
}

export function search(filters) {
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    force: true,
    promise: query`
      query searchGroups(${{ filters }}: SearchInput) {
        search {
          groups(filters: $filters) {
            ...GroupFields
          }
        }
      }

      ${GroupFragment}
    `,
  };
}

export function searchGroups() {
  return (dispatch, getState) => {
    const state = getState();

    return dispatch(search(state.groups.filters));
  };
}

export function updateFilters(filter) {
  return {
    type: UPDATE_FILTERS,
    filter,
    force: true,
  };
}

export function loadList() {
  return {
    types: [LOAD_LIST, LOAD_LIST_SUCCESS, LOAD_LIST_FAIL],
    promise: query`
      {
        groups {
          ...GroupFields
        }
      }

      ${GroupFragment}
    `,
  };
}

export function create(group) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    force: true,
    promise: query`
      mutation CreateGroup(
        ${{ group }}: GroupInput!,
      ) {
        group: createGroup(group: $group) {
          ...GroupFields
        }
      }

      ${GroupFragment}
    `,
  };
}

export function update(id, group) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    force: true,
    promise: query`
      mutation UpdateGroup(
        ${{ id }}: String!,
        ${{ group }}: GroupInput!,
      ) {
        group: updateGroup(id: $id, group: $group) {
          ...GroupFields
        }
      }

      ${GroupFragment}
    `,
  };
}

export function remove(id) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    force: true,
    id,
    promise: query`
      mutation DeleteGroup(
        ${{ id }}: String!
      ) {
        deleteGroup(id: $id)
      }
    `,
  };
}

export function clone(id) {
  return {
    types: [CLONE, CLONE_SUCCESS, CLONE_FAIL],
    force: true,
    id,
    promise: query`
      mutation CloneGroup(
        ${{ id }}: String!
      ) {
        group: cloneGroup(id: $id) {
          ...GroupFields
        }
      }
      ${GroupFragment}
    `,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}
