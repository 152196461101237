// These constants map to the available software providers in FMS
// https://github.com/rednovalabs/fms/blob/master/lib/software_provider.rb
export const STOREDGE = 'storedge';
export const SITELINK = 'sitelink';
export const TOTAL_RECALL = 'totalrecall';

export const SOFTWARE_PROVIDERS = [
  STOREDGE,
  SITELINK,
  TOTAL_RECALL,
];
