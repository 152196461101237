import React from 'react';
import PropTypes from 'prop-types';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Col,
  Row,
  Radio,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Alert, Icon as CanaveralIcon } from '@canaveral/components';
import Icon from 'components/Icon/Icon';
import stylesGlobal from 'theme/facility-globals.scss';
import IconV2 from '../../../../../modules/react/components/stateless/Icon';
import style from './ImageUploadRow.scss';

const ImageUploadRow = ({
  image,
  link,
  index,
  name,
  placeholderText,
  disableLinkField,
  handleDelete,
  setTarget,
  handleAltChange,
  handleDecorativeChange,
  handleTitleChange,
  handleHrefChange,
  handleWidthChange,
  handleHeightChange,
  moveFileRowUp,
  moveFileRowDown,
  target,
  trash,
  decorative,
  alt = '',
  title = '',
  lockAlt,
  width = undefined,
  height = undefined,
}) => (
  <Row className={style.imageRowContainer}>
    <Col xs={12}>
      {trash &&
        <Alert
          context="warning"
          className={style.trashAlert}
        >
          <CanaveralIcon id="warning-triangle" context="warning" label="warning" />
          <span className={style.trashAlertText}>
            This image is in the trash and will not display on your website unless
            it's restored in the Media Gallery.
          </span>
        </Alert>
      }
      <div className={style.title}>
        {name}
      </div>
      <div className={style.imageInfoContainer}>
        <div className={style.image}>
          {image}
        </div>
        <div className={style.controls}>
          <FormGroup controlId="alt" className={style.alt}>
            <ControlLabel className={style.label} required>
              Alt Text {!decorative ? (<span className={style.altRequired}>*</span>) : null}
            </ControlLabel>
            <FormControl
              name="alt"
              value={alt}
              onChange={handleAltChange}
              required={!decorative}
              disabled={lockAlt || trash}
            />
          </FormGroup>
          <FormGroup controlId="decorative" className={style.alt}>
            <ControlLabel className={style.label}>
              Decorative Only
            </ControlLabel>
            <Checkbox
              onChange={handleDecorativeChange}
              name="decorative"
              checked={decorative}
            />
          </FormGroup>
          <FormGroup controlId="title" className={style.alt}>
            <ControlLabel className={style.label} required>Title Text</ControlLabel>
            <FormControl
              name="title"
              value={title}
              onChange={handleTitleChange}
              disabled={trash}
            />
          </FormGroup>
          {handleWidthChange && (
            <FormGroup controlId="width" className={style.alt}>
              <ControlLabel className={style.label} required>Width</ControlLabel>
              <FormControl
                name="width"
                value={width}
                onChange={handleWidthChange}
                disabled={trash}
              />
            </FormGroup>
          )}
          {handleHeightChange && (
            <FormGroup controlId="height" className={style.alt}>
              <ControlLabel className={style.label} required>Height</ControlLabel>
              <FormControl
                name="height"
                value={height}
                onChange={handleHeightChange}
                disabled={trash}
              />
            </FormGroup>
          )}
          {
            !disableLinkField &&
            <div>
              <FormGroup controlId="href" className={style.link}>
                <ControlLabel className={style.label} required>
                  Link
                  {
                    !disableLinkField &&
                    <div className={style.tooltip}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={index} className={stylesGlobal.toolTip}>
                            <div className={stylesGlobal.tooltipInner}>
                              If you want to link to a page on your website, enter the URL extension of the page (Example: /url-of-your-page). To link to a page on another website, enter its entire URL (Example: http://www.google.com).
                            </div>
                          </Tooltip>
                        }
                      >
                        <span>
                          <IconV2 id="tool-tip" label="Link Info" />
                        </span>
                      </OverlayTrigger>
                    </div>
                  }
                </ControlLabel>
                <FormControl
                  name="href"
                  value={link}
                  onChange={handleHrefChange}
                  placeholder={placeholderText}
                  disabled={disableLinkField || trash}
                />
              </FormGroup>
              <div className={style.radioContainer}>
                <Radio
                  className={style.tab}
                  checked={target === '_self'}
                  value="_self"
                  onChange={setTarget(index)}
                  disabled={trash}
                >
                  Open link in same tab
                </Radio>
                <Radio
                  className={style.tab}
                  checked={target === '_blank'}
                  value="_blank"
                  onChange={setTarget(index)}
                  disabled={trash}
                >
                  Open link in new tab
                </Radio>
              </div>
            </div>
          }
        </div>
        <div className={style.rowControls}>
          <div>
            {moveFileRowUp && <Icon className={style.sort} icon="collapse" onClick={moveFileRowUp} />}
          </div>
          <div>
            {moveFileRowDown && <Icon className={style.sort} icon="expand" onClick={moveFileRowDown} />}
          </div>
          <div className={style.delete}>
            <i className="fa fa-fw fa-trash-o" onClick={handleDelete} aria-hidden="true" />
          </div>
        </div>
      </div>
    </Col>
  </Row>
);

ImageUploadRow.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDecorativeChange: PropTypes.func,
  handleAltChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  handleHrefChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  disableLinkField: PropTypes.bool.isRequired,
  setTarget: PropTypes.func.isRequired,
  moveFileRowUp: PropTypes.func,
  moveFileRowDown: PropTypes.func,
  alt: PropTypes.string,
  decorative: PropTypes.bool,
  link: PropTypes.string,
  target: PropTypes.string,
  index: PropTypes.number,
  fileKey: PropTypes.string,
  title: PropTypes.string,
  lockAlt: PropTypes.bool,
  trash: PropTypes.bool,
};

ImageUploadRow.defaultProps = {
  alt: undefined,
  link: undefined,
  decorative: undefined,
  target: undefined,
  index: undefined,
  fileKey: undefined,
  title: undefined,
  moveFileRowUp: undefined,
  moveFileRowDown: undefined,
  handleAltChange: undefined,
  handleTitleChange: () => { },
  lockAlt: false,
  trash: undefined,
};

export default ImageUploadRow;
