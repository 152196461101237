import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as BaseButton } from '@canaveral/components';
import { pure } from '../../../../src/web/utils/react';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  children: undefined,
  secondary: false,
  tertiary: false,
};

const ThemedButton = ({
  className = '',
  children,
  secondary,
  tertiary,
  ...props
}) => {
  const classes = {
    'themed-button': true,
    'themed-secondary-button': secondary,
    'themed-tertiary-button': tertiary,
    [className]: !!className,
  };

  return (
    <BaseButton {...props} className={classNames(classes)}>
      {children}
    </BaseButton>
  );
};

ThemedButton.propTypes = propTypes;
ThemedButton.defaultProps = defaultProps;

export default pure()(ThemedButton);
