/* eslint-disable prefer-destructuring */
import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { upload, clear } from 'store/modules/assets';
import FileUpload from 'components/FileUpload';
import useFlipper from '../../../../../modules/react/hooks/useFlipper';
import { FLIPPER_VOYAGER_MEDIA_GALLERY } from '../../../../../constants/flippers';
import useMutation from '../../../../../modules/react/hooks/useMutation';

const propTypes = {
  currentFile: PropTypes.object,
  error: PropTypes.string,
  uploading: PropTypes.bool.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  uploadPercent: PropTypes.number,
  uploadSuccess: PropTypes.bool,
};

const defaultProps = {
  error: undefined,
  uploadPercent: undefined,
  currentFile: undefined,
  uploadSuccess: undefined,
};

export const UPLOAD_MEDIUM = gql`
  mutation MediumUpload($file: Upload!) {
    mediumUpload(file: $file) {
      medium {
        id
        url
        link
        defaultMetadata {
          altText
          title
        }
      }
      userErrors {
        code
        message
      }
    }
  }
`;

const fileUpload = (props) => {
  const { currentFile, error, uploading, uploadAsset, uploadPercent, uploadSuccess } = props;
  // map these to v1 by default
  let tempCurrentFile = currentFile;
  let tempError = error;
  let tempUploading = uploading;
  let tempUploadAsset = uploadAsset;
  let tempUploadPercent = uploadPercent;
  let tempUploadSuccess = uploadSuccess;

  const mediaGalleryEnabled = useFlipper(FLIPPER_VOYAGER_MEDIA_GALLERY);
  const [
    uploadImage,
    { data: returnMediumData, error: mediumUploadError, loading },
  ] = useMutation(UPLOAD_MEDIUM, { refetchQueries: ['GetAllMedia'] });

  if (mediaGalleryEnabled) {
    // use v2
    tempUploadAsset = (file) => {
      uploadImage({ variables: { file } });
    };
    if (returnMediumData) {
      const { mediumUpload: { userErrors } } = returnMediumData;
      if (userErrors.length) {
        tempError = userErrors[0].message;
      } else {
        const { mediumUpload: { medium: { url, defaultMetadata: { altText, title }, link } } } = returnMediumData;
        let href = '';
        let target = '';
        if (link) {
          href = link.href;
          target = link.target;
        }
        tempCurrentFile = {
          file: url,
          alt: altText,
          title,
          link: href,
          target,
        };
      }
    }
    tempError = tempError || mediumUploadError;
    tempUploading = loading;
    tempUploadPercent = 100; // gql does not afford progress reporting, so turn the bar into a "spinner"
    tempUploadSuccess = !tempUploading && !tempError;
  }
  return <FileUpload
    {...props}
    currentFile={tempCurrentFile}
    error={tempError}
    uploading={tempUploading}
    uploadAsset={tempUploadAsset}
    uploadPercent={tempUploadPercent}
    uploadSuccess={tempUploadSuccess}
  />;
};

fileUpload.propTypes = propTypes;
fileUpload.defaultProps = defaultProps;

export default connect(
  ({
    assets: {
      currentFile, error, uploadPercent, uploading, uploadSuccess,
    },
  }) => ({
    currentFile,
    error,
    uploadPercent,
    uploading,
    uploadSuccess,
  }),
  { uploadAsset: upload, clearAsset: clear },
)(fileUpload);
