// These constants map to the available website permissions that come over from FMS:
// https://github.com/rednovalabs/fms/blob/master/app/models/website_ability.rb
export const NON_FACILITY_PAGES = 'website/content/non_facility_pages';
export const FACILITY_PAGES = 'website/content/facility_pages';
export const BLOG = 'website/blog';
export const FACILITY_ADD_ONS = 'website/facility_page_add_ons';
export const NON_FACILITY_ADD_ONS = 'website/non_facility_page_add_ons';
export const BUILDER = 'website/builder';
export const PUBLISH_FACILITY_PAGES = 'website/publishing_facility_pages';
export const PUBLISH_NON_FACILITY_PAGES = 'website/publishing_non_facility_pages';
export const SEO_PAGES = 'website/settings/seo_pages';
export const SEO_REDIRECTS = 'website/settings/seo_redirects';
export const NAVIGATION = 'website/settings/navigation';
export const DRAFT = 'website/draft';

// https://github.com/rednovalabs/fms/blob/master/app/models/ability.rb
export const FACILITY_INFO = 'corporate/facility_info';
export const REVIEW_SETTINGS = 'corporate/website_review_settings';
export const FACILITY_UNITS_SETTINGS = 'settings/units';
export const WEBSITE_SETTINGS = 'corporate/website_settings';
