import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, IntlProvider, useIntl } from 'react-intl';

const propTypes = {
  children: PropTypes.func,
  currency: PropTypes.string,
  currencyDisplay: PropTypes.string,
  locale: PropTypes.string,
  round: PropTypes.bool,
  value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
  children: undefined,
  currency: 'USD',
  currencyDisplay: 'symbol',
  locale: undefined,
  round: undefined,
};

const Price = ({
  children,
  currency,
  currencyDisplay,
  locale,
  value,
  round,
  ...rest
}) => {
  const intl = useIntl();
  const fractionDigits = round ? 0 : 2;

  const component = (
    <FormattedNumber
      {...rest}
      currency={currency}
      currencyDisplay={currencyDisplay}
      style="currency" // eslint-disable-line react/style-prop-object
      value={value}
      minimumFractionDigits={fractionDigits}
      maximumFractionDigits={fractionDigits}
    >
      {(formattedNumber) => {
        if (typeof children === 'function') {
          return children(formattedNumber);
        }

        return formattedNumber;
      }}
    </FormattedNumber>
  );

  if (!locale) {
    return component;
  }

  // Sometimes we may want a price to display in the currency and format for
  // a specific locale other than that set on the page level. By wrapping the
  // formatted number in another i18n provider, we are able to override the
  // user's locale higher up the tree.
  //
  // Using the `locale` as the component key allows us to trigger a re-render
  // whenever the locale is changed, which causes all components to re-render
  // with translations for the new locale.
  return (
    <IntlProvider key={locale} locale={locale} messages={intl.messages} textComponent="span">
      {component}
    </IntlProvider>
  );
};

Price.propTypes = propTypes;
Price.defaultProps = defaultProps;

export default Price;
