import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from '../../../../src/web/utils/react';
import Field from '../../../../modules/react/components/stateful/Field';
import Input from '../../../../modules/react/components/stateful/Input';
import Checkbox from '../../../../modules/react/components/stateful/Checkbox/Checkbox';
import PhoneInput from '../../../../modules/react/components/stateful/PhoneInput';
import Translation from '../../stateless/Translation';

const BasicTenantForm = ({ required }) => (
  <div className="facility-info">
    <Field
      label={<Translation id="widgets.popup.form.firstname" defaultMessage="First Name" />}
      key="firstName"
      id="basicTenantForm-firstName"
      name="firstName"
      component={Input}
      required={required}
    />
    <Field
      label={<Translation id="widgets.popup.form.lastname" defaultMessage="Last Name" />}
      key="lastName"
      id="basicTenantForm-lastName"
      name="lastName"
      component={Input}
      required={required}
    />
    <Field
      label={<Translation id="widgets.popup.form.emailaddress" defaultMessage="Email Address" />}
      key="email"
      id="basicTenantForm-email"
      name="email"
      type="email"
      component={Input}
      required={required}
    />
    <Field
      label={<Translation id="widgets.popup.form.phonenumber" defaultMessage="Phone Number" />}
      key="phone"
      id="basicTenantForm-phone"
      name="phone"
      component={PhoneInput}
      required={required}
    />
    <Field
      label={(
        <Translation
          id="widgets.popup.form.smsOptIn"
          defaultMessage="Yes! You can text me updates about my storage account. (Message and data rates may apply"
        />
      )}
      key="smsOptIn"
      id="basicTenantForm-smsOptIn"
      name="smsOptIn"
      component={Checkbox}
    />
  </div>
);

BasicTenantForm.propTypes = {
  required: PropTypes.bool,
};

BasicTenantForm.defaultProps = {
  required: false,
};

export default compose(
  pure(),
)(BasicTenantForm);
