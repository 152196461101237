import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export default class AsyncFeedback extends React.Component {
  static propTypes = {
    error: PropTypes.any,
    isSaving: PropTypes.bool.isRequired,
    success: PropTypes.any.isRequired,
    errorProps: PropTypes.object,
    successProps: PropTypes.object,
  };

  static defaultProps = {
    errorProps: {},
    successProps: {},
  };

  state = {
    showSuccess: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaving && !nextProps.isSaving && !nextProps.error) {
      this.setState({ showSuccess: true });
    } else if (nextProps.isSaving) {
      this.setState({ showSuccess: false });
    }
  }

  renderErrorContents(error) {
    if (Array.isArray(error)) {
      return (
        <ul>
          {error.map((err, index) => (
            <li key={index}>{err.message || err}</li>
          ))}
        </ul>
      );
    }

    return error;
  }

  renderError() {
    const { error, errorProps } = this.props;
    const { showSuccess } = this.state;

    if (!error || showSuccess) {
      return false;
    }

    return (
      <Alert bsStyle="danger" {...errorProps}>
        {this.renderErrorContents(error)}
      </Alert>
    );
  }

  renderSuccess() {
    const { showSuccess } = this.state;
    const { success, successProps } = this.props;

    if (!showSuccess) {
      return false;
    }

    return (
      <Alert bsStyle="success" {...successProps}>
        {success}
      </Alert>
    );
  }

  render() {
    return (
      <div>
        {this.renderError()}
        {this.renderSuccess()}
      </div>
    );
  }
}
