import React from 'react';
import PropTypes from 'prop-types';
import { Row as BootstrapRow } from 'react-bootstrap';
import classes from './Row.scss';

function prepareClassName(verticalAlign, className) {
  if (!verticalAlign) {
    return className;
  }

  return [
    classes[`verticalAlign${verticalAlign.charAt(0).toUpperCase() + verticalAlign.slice(1)}`],
    className,
  ].filter(Boolean).join(' ');
}

/**
 * The `verticalAlign` property sets the minimum Bootstrap width needed for the
 * vertical alignment property to be enabled. Using `xs` will enable this property
 * at all breakpoints.
 */
const Row = ({ verticalAlign, className, ...props }) => (
  <BootstrapRow
    className={prepareClassName(verticalAlign, className)}
    {...props}
  />
);
Row.propTypes = {
  verticalAlign: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  className: PropTypes.string,
};

export default Row;
