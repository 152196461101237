import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useIntl } from 'react-intl';
import { Icon } from '@canaveral/components';
import ApolloQuery from '../../modules/react/components/stateful/ApolloQuery';
import FacilitySelectLabel from '../components/FacilitySelectLabel';
import Select from '../components/Select';

const GET_FACILITIES = gql`
  query GetFacilitySelectData($locale: String!, $filter: FacilitiesFilterInput) {
    facilities(filter: $filter) {
      edges {
        node {
          id
          linkOutUrl
          primaryImageUrl
          page(locale: $locale) {
            id
          }
          ...FacilitySelectLabelFacility
        }
      }
    }
  }

  ${FacilitySelectLabel.fragments.facility}
`;

const propTypes = {
  scopeToUser: PropTypes.bool,
  showDisabled: PropTypes.bool,
};

const defaultProps = {
  scopeToUser: undefined,
  showDisabled: undefined,
};

const FacilitySelect = ({
  scopeToUser,
  showDisabled,
  ...rest
}) => {
  const { locale } = useIntl();

  const errorIndicator = (
    <Select
      {...rest}
      disabled
      placeholder="Could not retrieve facilities"
    />
  );

  const loadingIndicator = (
    <Select
      {...rest}
      disabled
      placeholder="Loading..."
    />
  );

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.searchField) {
        return candidate.searchField.toLowerCase().indexOf(input.toLowerCase()) > -1;
      }
      return false;
    }
    return true;
  };

  const edgeToOption = ({ node }) => {
    const disabled = showDisabled ? false : !node.page;

    return {
      label: <FacilitySelectLabel disabled={disabled} facility={node} />,
      searchField: `${node.name} ${node.address.fullAddress}`,
      value: node.id,
      disabled,
    };
  };

  // This component typically appears in admin area modals, so we can safely skip
  // this query during server-side rendering to speed page loads. A potential
  // further improvement would be to wait until the select dropdown receives
  // focus before making this query.
  return (
    <ApolloQuery
      errorIndicator={errorIndicator}
      loadingIndicator={loadingIndicator}
      query={GET_FACILITIES}
      ssr={false}
      variables={{ locale, filter: { scopeToUser } }}
    >
      {({ facilities: { edges } }) => (
        <Select
          {...rest}
          options={edges.map(edgeToOption)}
          filterOption={filterOptions}
          valueRenderer={option => (
            <>
              <span style={{ marginRight: '5px', position: 'relative', top: '2px' }}>
                <Icon
                  id="new-location"
                  label="facility"
                />
              </span>
              {option.label.props.facility.name}
            </>
          )}
        />
      )}
    </ApolloQuery>
  );
};

FacilitySelect.propTypes = propTypes;
FacilitySelect.defaultProps = defaultProps;

export default FacilitySelect;
