import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Field from '../../../../modules/react/components/stateful/Field';
import RadioButtonsInput from '../form/inputs/RadioButtons/RadioButtons';
import { IMAGES, VIDEOS } from './constants';
import { MultiImageDrop, VideoUpload } from './components';
import FileList from './components/FileList';

const mediaDescriptions = {
  [IMAGES]: 'Add Image',
  [VIDEOS]: 'Add Video',
};

const propTypes = {
  count: PropTypes.number,
  clearAsset: PropTypes.func.isRequired,
  currentFile: PropTypes.object,
  error: PropTypes.string,
  fileKey: PropTypes.string,
  initialValues: PropTypes.object,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  lockAlt: PropTypes.bool,
  mediaTypes: PropTypes.array,
  uploading: PropTypes.bool.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  uploadPercent: PropTypes.number,
  uploadSuccess: PropTypes.bool,
};

const defaultProps = {
  lockAlt: false,
  mediaTypes: [IMAGES],
};

const FileUpload = ({
  count,
  clearAsset,
  currentFile,
  error,
  fileKey,
  initialValues,
  input: { onChange },
  lockAlt,
  mediaTypes,
  uploading,
  uploadAsset,
  uploadPercent,
  uploadSuccess,
}) => {
  const initFiles = initialValues ? initialValues.files : [];

  const prevInitFilesRef = useRef();
  useEffect(() => {
    prevInitFilesRef.current = initFiles;
  });
  const prevInitFiles = prevInitFilesRef.current;

  useEffect(() => {
    if (!isEqual(initFiles, prevInitFiles)) {
      onChange(initFiles);
    }
  }, [initFiles, prevInitFiles]);

  const [files, setFiles] = useState(initFiles);
  const [mediaType, setMediaType] = useState(mediaTypes[0]);

  const onListChange = (filesUpdate) => {
    setFiles(filesUpdate);
    onChange(filesUpdate);
  };

  const onAddMedia = (file) => {
    let updatedFiles = files;

    if (files.length === count) {
      updatedFiles.pop();
    }
    updatedFiles = [...updatedFiles, file];
    setFiles(updatedFiles);
    onChange(updatedFiles);
  };

  const renderMediaTypeRadioButtons = () => {
    if (mediaTypes.length > 1) {
      const options = mediaTypes.reduce((opts, type) => {
        opts[type] = mediaDescriptions[type];
        return opts;
      }, {});

      return (
        <Field
          label="Media Options"
          name="mediaTypes"
          component={RadioButtonsInput}
          options={options}
          input={{
            onChange: setMediaType,
            value: mediaType,
          }}
        />
      );
    }

    return null;
  };

  return (
    <div>
      {renderMediaTypeRadioButtons()}
      {
        mediaType === IMAGES ?
          <MultiImageDrop
            clearAsset={clearAsset}
            currentFile={currentFile}
            error={error}
            fileKey={fileKey}
            onUpload={onAddMedia}
            uploading={uploading}
            uploadAsset={uploadAsset}
            uploadPercent={uploadPercent}
            uploadSuccess={uploadSuccess}
          />
          :
          <VideoUpload onSave={onAddMedia} />
      }
      <FileList
        files={files}
        onChange={onListChange}
        count={count}
        fileKey={fileKey}
        lockAlt={lockAlt}
      />
    </div>
  );
};

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export default FileUpload;
