import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';
import { categories } from '../data';
import {
  TRACK_CLICK_SIZING_GUIDE_CATEGORY,
  TRACK_CLICK_SIZING_GUIDE_SEARCH,
} from '../../../../../../src/web/utils/dataLayer';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  previousCategory: PropTypes.string,
  selectedCategory: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
};

const defaultProps = {
  previousCategory: undefined,
};

const SEARCH_CATEGORY = 'search';

function ItemCategories({ previousCategory, selectedCategory, setCategory, setSearchText }) {
  const searchInput = useRef(null);

  useEffect(() => {
    if (selectedCategory === SEARCH_CATEGORY && searchInput.current) {
      searchInput.current.focus();
    }
  }, [selectedCategory]);

  return (
    <div className={styles.categories}>
      <div className={classNames(styles.search, {
        [styles.active]: selectedCategory === SEARCH_CATEGORY,
      })}
      >
        <button
          className={classNames(styles.categoryButton)}
          type="button"
          onClick={() => setCategory(SEARCH_CATEGORY)}
          {...TRACK_CLICK_SIZING_GUIDE_SEARCH}
          aria-label="search category"
        >
          <i className="fa fa-fw fa-search" aria-hidden="true" />
        </button>
        <div className={styles.searchForm}>
          <input type="text" className={styles.searchBox} ref={searchInput} onKeyUp={event => setSearchText(event.target.value)} />
          <i className={classNames(styles.searchIcon, 'fa fa-fw fa-search')} aria-hidden="true" />
          <button className={classNames(styles.categoryButton)} type="button" onClick={() => setCategory(previousCategory)} aria-label="close search">
            <i className="fa fa-fw fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
      {
        Object.entries(categories).map(([key, category]) => (
          <div key={key} className={styles.category}>
            <button
              className={classNames(styles.categoryButton, {
                [styles.active]: selectedCategory === key,
              })}
              type="button"
              onClick={() => setCategory(key)}
              {...TRACK_CLICK_SIZING_GUIDE_CATEGORY(category)}
            >
              <Translation
                id={`storagecalculator.itemcategory.${key}`}
                defaultMessage={category}
              />
            </button>
          </div>
        ))
      }
    </div>
  );
}

ItemCategories.propTypes = propTypes;
ItemCategories.defaultProps = defaultProps;

export default ItemCategories;
