export const EN_US = 'en-us';
export const EN_GB = 'en-gb';
export const ES = 'es';
export const FR_CA = 'fr-ca';
export const EN_CA = 'en-ca';
export const CA = 'CA';
export const GB = 'GB';
export const QUEBEC = 'QC';

export const LOCALES = [
  EN_US,
  EN_GB,
  ES,
  FR_CA,
  EN_CA,
];

export const LOCALE_PILL_VALUES = {
  [EN_US]: 'EN',
  [EN_GB]: 'EN-GB',
  [ES]: 'ES',
  [FR_CA]: 'FR-CA',
  [EN_CA]: 'EN-CA',
};

export const LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'English (US)',
  [EN_GB]: 'English (UK)',
  [ES]: 'Spanish',
  [FR_CA]: 'French (Canada)',
  [EN_CA]: 'English (Canada)',
};

export const NATIVE_LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'English (US)',
  [EN_GB]: 'English (UK)',
  [ES]: 'Español',
  [FR_CA]: 'Français',
  [EN_CA]: 'English (CA)',
};

export const ADMIN_LANGUAGE_NAME_MAPPING = {
  [EN_US]: 'American English',
  [EN_GB]: 'British English',
  [ES]: 'Spanish',
  [FR_CA]: 'French Canadian',
  [EN_CA]: 'Canadian English',
};

export const DEFAULT_LOCALE = EN_US;
export const NON_DEFAULT_LOCALES = LOCALES.filter(locale => locale !== DEFAULT_LOCALE);

export const DEFAULT_PAGE_LOCALES = [
  {
    name: LANGUAGE_NAME_MAPPING[DEFAULT_LOCALE],
    code: DEFAULT_LOCALE,
    enabled: true,
  },
  ...NON_DEFAULT_LOCALES.map(locale => ({
    name: LANGUAGE_NAME_MAPPING[locale],
    code: locale,
    enabled: false,
  })),
];

const formatQuebecCurrency = amount => `${amount.replace('.', ',')} $`;

export const formatCurrency = ({ price, state, currencySymbol, decimals }) => {
  const displayPrice = typeof price === 'number' ? price.toFixed(decimals) : price;
  if (state === QUEBEC) return formatQuebecCurrency(displayPrice);
  return `${currencySymbol}${displayPrice}`;
};
