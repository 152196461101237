import React from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/react-common';

const propTypes = {
  children: PropTypes.func.isRequired,
};

const defaultProps = {};

const ApolloClientConsumer = ({
  children,
  ...rest
}) => (
  <ApolloConsumer {...rest}>
    {children}
  </ApolloConsumer>
);

ApolloClientConsumer.propTypes = propTypes;
ApolloClientConsumer.defaultProps = defaultProps;

export default ApolloClientConsumer;
