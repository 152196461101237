// Actions
const TOGGLE_COMPONENT_LIBRARY = 'voyager/builder/TOGGLE_COMPONENT_LIBRARY';
const SECTION_START_DRAGGING = 'voyager/builder/SECTION_START_DRAGGING';
const SECTION_STOP_DRAGGING = 'voyager/builder/SECTION_STOP_DRAGGING';
const WIDGET_START_DRAGGING = 'voyager/builder/WIDGET_START_DRAGGING';
const WIDGET_STOP_DRAGGING = 'voyager/builder/WIDGET_STOP_DRAGGING';
const SECTION_EDIT_START = 'voyager/builder/SECTION_EDIT_START';
const SECTION_EDIT_STOP = 'voyager/builder/SECTION_EDIT_STOP';
export const WIDGET_EDIT_START = 'voyager/builder/WIDGET_EDIT_START';
const WIDGET_EDIT_STOP = 'voyager/builder/WIDGET_EDIT_STOP';
const WIDGET_START_SWITCHING = 'voyager/builder/WIDGET_START_SWITCHING';
const WIDGET_STOP_SWITCHING = 'voyager/builder/WIDGET_STOP_SWITCHING';

// Reducer
const initialState = {
  showComponentLibrary: false,
  sectionIsDragging: false,
  widgetIsDragging: false,
  componentIsDragging: false,
  isEditingPage: true,
  editingSections: {},
  editingWidgets: {},
  widgetSwitchingId: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_COMPONENT_LIBRARY:
      return {
        ...state,
        showComponentLibrary: !state.showComponentLibrary,
        widgetSwitchingId: null,
      };
    case SECTION_START_DRAGGING:
      return {
        ...state,
        sectionIsDragging: true,
        showComponentLibrary: false,
      };
    case SECTION_STOP_DRAGGING:
      return { ...state, sectionIsDragging: false };
    case WIDGET_START_DRAGGING:
      return {
        ...state,
        widgetIsDragging: true,
        showComponentLibrary: false,
      };
    case WIDGET_STOP_DRAGGING:
      return { ...state, widgetIsDragging: false };
    case SECTION_EDIT_START:
      return {
        ...state,
        editingSections: {
          ...state.editingSections,
          [action.id]: action.value,
        },
      };
    case SECTION_EDIT_STOP:
      return {
        ...state,
        editingSections: {
          ...state.editingSections,
          [action.id]: false,
        },
      };
    case WIDGET_EDIT_START:
      return {
        ...state,
        editingWidgets: {
          ...state.editingWidgets,
          [action.id]: action.value,
        },
      };
    case WIDGET_EDIT_STOP:
      return {
        ...state,
        editingWidgets: {
          ...state.editingWidgets,
          [action.id]: false,
        },
      };

    case WIDGET_START_SWITCHING: {
      return {
        ...state,
        widgetSwitchingId: action.id,
      };
    }
    case WIDGET_STOP_SWITCHING: {
      return {
        ...state,
        widgetSwitchingId: null,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function toggleComponentLibrary() {
  return {
    type: TOGGLE_COMPONENT_LIBRARY,
  };
}

export function sectionStartDragging() {
  return {
    type: SECTION_START_DRAGGING,
  };
}

export function sectionStopDragging() {
  return {
    type: SECTION_STOP_DRAGGING,
  };
}


export function widgetStartDragging() {
  return {
    type: WIDGET_START_DRAGGING,
  };
}

export function widgetStopDragging() {
  return {
    type: WIDGET_STOP_DRAGGING,
  };
}

export function widgetStartSwitching(id) {
  return {
    type: WIDGET_START_SWITCHING,
    id,
  };
}

export function widgetStopSwitching() {
  return {
    type: WIDGET_STOP_SWITCHING,
  };
}

export function sectionEditStart(id, value = true) {
  return { type: SECTION_EDIT_START, id, value };
}

export function sectionEditStop(id) {
  return { type: SECTION_EDIT_STOP, id };
}

export function widgetEditStart(id, value = true) {
  return { type: WIDGET_EDIT_START, id, value };
}

export function widgetEditStop(id) {
  return { type: WIDGET_EDIT_STOP, id };
}

// Selectors

export const getWidgetSwitchingId = state => state.builder && state.builder.widgetSwitchingId;
