export const STANDARD_PRICING_TYPE_ID = 2;
export const EASIER_PRICING_TYPE_ID = 3;
export const EASIEST_PRICING_TYPE_ID = 4;
export const FIRST_MONTH_FREE_ID = 5;

export const sizeMessageProps = {
  closet: { id: 'unit.size.closet', defaultMessage: 'Closet' },
  small: { id: 'unit.size.small', defaultMessage: 'Small' },
  medium: { id: 'unit.size.medium', defaultMessage: 'Medium' },
  large: { id: 'unit.size.large', defaultMessage: 'Large' },
  workshop: { id: 'unit.size.workshop', defaultMessage: 'Workshop' },
  parking: { id: 'unit.size.parking', defaultMessage: 'Parking' },
  vehicle: { id: 'unit.size.parking', defaultMessage: 'Parking' },
  other: { id: 'unit.size.other', defaultMessage: 'Other' },
};

export const errorTokens = {
  default: { id: 'widgets.checkout.formError', defaultMessage: 'An error was encountered when submitting the form. Please try again.' },
  'Unit Type is No Longer Available': {
    id: 'widgets.checkout.formError.unitNotAvailable',
    defaultMessage: 'Unit Type is No Longer Available',
  },
  'Error: Please verify your payment again as there was an internal error that prevented the payment from being taken': {
    id: 'widgets.checkout.formError.verifyPayment',
    defaultMessage: 'Please verify your payment again as there was an internal error that prevented the payment from being taken',
  },
  'Credit Card Invalid': {
    id: 'widgets.checkout.formError.creditCardInvalid',
    defaultMessage: 'The credit card entered is invalid. Please update the card information or try using a different card',
  },
  'Customer is already moved into unit, please contact store manager': {
    id: 'widgets.checkout.formError.customerAlreadyMovedIn',
    defaultMessage: 'The customer is already moved into the selected unit. Please contact the store manager for additional information',
  },
};
