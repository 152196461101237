import React from 'react';
import PropTypes from 'prop-types';
import BaseDropzone from 'react-dropzone';
import Icon from '../../../../modules/react/components/stateless/Icon';
import classes from './Dropzone.scss';

const defaultIconClassName = classes.uploadIcon;

const Dropzone = ({
  className,
  iconClassName = defaultIconClassName,
  children = 'Drag and drop your file or click here to select a file.',
  hideIcon,
  ...props
}) => (
  <BaseDropzone
    className={[classes.root, className].filter(Boolean).join(' ')}
    {...props}
  >
    {!hideIcon && (
      <Icon
        className={iconClassName}
        id="upload"
      />
    )}
    {children}
  </BaseDropzone>
);
Dropzone.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  hideIcon: PropTypes.bool,
};

export default Dropzone;
