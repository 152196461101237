export const CANADA = {
  name: 'Canada',
  code: 'CA',
};

export const UNITED_KINGDOM = {
  name: 'United Kingdom',
  abbreviation: 'UK',
  code: 'GB',
};

export const isUnitedKingdom = (country) => {
  if (!country) {
    return false;
  }

  switch (country.toUpperCase()) {
    case UNITED_KINGDOM.name.toUpperCase():
    case UNITED_KINGDOM.abbreviation.toUpperCase():
    case UNITED_KINGDOM.code.toUpperCase():
      return true;
    default:
      return false;
  }
};

export const UNITED_STATES = {
  name: 'United States',
  abbreviation: 'USA',
  code: 'US',
};
