import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  component = 'span',
  icon,
  className = '',
  ...props
}) => React.createElement(
  component,
  {
    'aria-hidden': true,
    ...props,
    className: [
      'icon',
      `icon-${icon}`,
      className,
    ].join(' '),
  },
);
Icon.propTypes = {
  component: PropTypes.any,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
