import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as BootstrapCheckbox } from 'react-bootstrap';
import { compose, pure } from '../../../../../src/web/utils/react';

const propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
  input: {},
  meta: {},
};

const Checkbox = ({
  label,
  input,
  meta, // eslint-disable-line no-unused-vars
  ...props
}) => (
  <BootstrapCheckbox
    {...input}
    {...props}
    checked={input.value}
  >
    {label}
  </BootstrapCheckbox>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default compose(
  pure(),
)(Checkbox);
