import React from 'react';
import PropTypes from 'prop-types';
import {
  HOME_PAGE,
  FACILITIES_LIST_PAGE,
  FACILITY_PAGE,
  FACILITY_LINK,
  FACILITY_LISTING,
  MISC_PAGE,
} from 'shared/pageTypes';
import cx from 'classnames';
import Address from '../../../../website/components/stateless/Address';
import style from './PageName.scss';

function formatTitle({ metadata: { type, title } }, showPageType) {
  if (!showPageType) {
    return title;
  }

  switch (type) {
    case HOME_PAGE:
      return `Home: ${title}`;
    case FACILITIES_LIST_PAGE:
      return `All Locations: ${title}`;
    case MISC_PAGE:
      return `Misc: ${title}`;
    default:
      return title;
  }
}

function formatSubtitle(page, bridge, addressProps) {
  // Only include address for facility type pages
  if ([FACILITY_PAGE, FACILITY_LINK, FACILITY_LISTING].indexOf(page.metadata.type) === -1 || !page.facility) {
    return false;
  }

  return (
    <div className="subtitle">
      {bridge}
      <Address {...page.facility.address} {...addressProps} />
    </div>
  );
}

function formatLive(page) {
  if (page.live) {
    return <span className={style.live}>live</span>;
  }
  return false;
}

const PageName = ({
  page,
  title,
  showPageType = true,
  className = '',
  subtitleBridge = ' - ',
  showLive,
  parentTitle,
  ...addressProps
}) => (
  <div className={className}>
    {parentTitle}
    <div className={cx({ [style.withParentTitle]: parentTitle })}>
      {title || formatTitle(page, showPageType)}
    </div>
    {formatSubtitle(page, subtitleBridge, addressProps)}
    {showLive ? formatLive(page) : null}
  </div>
);
PageName.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  subtitleBridge: PropTypes.string,
  showLive: PropTypes.bool,
  showPageType: PropTypes.bool,
  page: PropTypes.shape({
    facility: PropTypes.shape({
      address: PropTypes.object.isRequired,
    }),
    metadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  parentTitle: PropTypes.string,
};

PageName.defaultProps = {
  parentTitle: null,
};

export default PageName;
