/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import '../style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from 'store/create';
import ApiClient from 'helpers/ApiClient';
import ApolloClientProvider from 'modules/react/components/stateful/ApolloClientProvider';
import CurrentPageClientProvider from 'modules/Page/containers/CurrentPageClientProvider';
import { setGatedQuoteFormCookie } from 'utils/gatedQuoteFormCookie';
import dynamic from '../../website/widgets/dynamic/client';
import AppProvider from './containers/AppProvider';
import 'utils/marchex';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'theme/fontawesome/css/fontawesome.css';
import 'theme/fontawesome/css/solid.css';
import 'theme/fontawesome/css/regular.css';
import 'theme/icons/style.scss';
import '@canaveral/components/styles.css';
import '@canaveral/rte/styles.css';
import '@glidejs/glide/dist/css/glide.core.min.css';
import './theme/bootstrap-overrides.css';
import './theme/facility-globals.scss';
import 'intersection-observer';
// import styles index files for stateful components, dynamic widgets needing to always be loaded
import '../../website/widgets/dynamic/styles';
import '../../website/components/stateful/styles';
import '../../website/storagemart/components/stateful/styles';
import '../../website/storagemart/widgets/dynamic/styles';
// Import styles index files for stateless components, static widgets, and sections
import '../../website/components/stateless/styles';
import '../../modules/react/components/stateless/styles';
import '../../website/widgets/static/styles';
import './components/sections/styles';
import './components/styles';
import './containers/styles';
import './modules/PrintCoupon/styles';

const client = new ApiClient();
const initialState = window.__data;

const store = createStore(client, initialState);
setGatedQuoteFormCookie();

// Find all hydratable react applications on the page
const reactAppNodes = document.querySelectorAll('[data-id]');

// Find all carousel nodes
const carouselNodes = document.querySelectorAll('[data-glide]');

// Initialize non react carousels (used to avoid hydrating sections with dynamic UI elements)
if (carouselNodes && carouselNodes.length) {
  import('@glidejs/glide').then(({ default: Glide }) => {
    carouselNodes.forEach((node) => {
      const config = JSON.parse(node.innerHTML);
      node.remove();
      const glide = new Glide(`#${CSS.escape(config.carouselId)}`, {
        startAt: 0,
        perView: 1,
        gap: '25px',
        peek: {
          before: '50px',
          after: '50px',
        },
      });
      glide.mount();
    });
  });
}

const hydrateNode = (node, props, observer) => (
  dynamic[node.dataset.id]().then(({ default: Widget }) => {
    ReactDOM.hydrate(
      <ApolloClientProvider>
        <Provider store={store} key="provider">
          <AppProvider>
            <CurrentPageClientProvider>
              <Widget {...props} />
            </CurrentPageClientProvider>
          </AppProvider>
        </Provider>
      </ApolloClientProvider>,
      node,
    );
    if (observer) observer.unobserve(node);
  })
);

// Initialize react applications
reactAppNodes.forEach((node) => {
  if (node.nextSibling
    && node.nextSibling.nodeName === 'SCRIPT'
    && node.nextSibling.type === 'application/json'
  ) {
    const props = JSON.parse(node.nextSibling.innerHTML);

    node.nextSibling.remove();

    if (props.preload) {
      hydrateNode(node, props);
    } else {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            return;
          }
          hydrateNode(node, props, observer);
        },
        {
          rootMargin: '50px',
        },
      );
      observer.observe(node);
    }
  }
});
