import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../../../src/web/utils/react';
import { formatFullAddress } from '../../../../modules/i18n';

const streetAddressPropTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  element: PropTypes.string,
};

const streetAddressDefaultProps = {
  address1: undefined,
  address2: undefined,
  element: 'div',
};

const cityStatePropTypes = {
  city: PropTypes.string,
  state: PropTypes.string,
  postal: PropTypes.string,
  country: PropTypes.string,
  element: PropTypes.string,
};

const cityStateDefaultProps = {
  city: undefined,
  state: undefined,
  postal: undefined,
  country: undefined,
  element: 'div',
};

const addressPropTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postal: PropTypes.string,
  country: PropTypes.string,
  element: PropTypes.string,
  multiLine: PropTypes.bool,
};

const addressDefaultProps = {
  address1: undefined,
  address2: undefined,
  city: undefined,
  state: undefined,
  postal: undefined,
  country: undefined,
  element: 'div',
  multiLine: undefined,
};

const StreetAddress = ({
  address1,
  address2,
  element,
  ...props
}) => React.createElement(element, props, address1 + (address2 ? `, ${address2}` : ''));

StreetAddress.propTypes = streetAddressPropTypes;
StreetAddress.defaultProps = streetAddressDefaultProps;

const CityStatePostal = ({
  city,
  state,
  postal,
  country,
  element,
  ...props
}) => {
  const Component = element;
  return (
    <Component {...props}>
      { formatFullAddress({ city, state, postal, country }) }
    </Component>
  );
};

CityStatePostal.propTypes = cityStatePropTypes;
CityStatePostal.defaultProps = cityStateDefaultProps;

const Address = ({
  address1,
  address2,
  city,
  state,
  postal,
  element,
  multiLine,
  /* eslint-disable no-unused-vars */
  /* eslint-disable react/prop-types */
  longitude,
  latitude,
  country,
  fullAddress,
  /* eslint-enable react/prop-types */
  /* eslint-enable no-unused-vars */
  ...props
}) => React.createElement(element, props,
  <StreetAddress
    address1={address1}
    address2={address2}
    element={multiLine ? 'div' : 'span'}
  />,
  multiLine ? '' : ', ',
  <CityStatePostal
    city={city}
    state={state}
    postal={postal}
    country={country}
    element={multiLine ? 'div' : 'span'}
  />);

Address.propTypes = addressPropTypes;
Address.defaultProps = addressDefaultProps;

export default pure()(Address);
