import React from 'react';
import PropTypes from 'prop-types';
import { TRACK_SUBMIT_SEND_TEXT_SUCCESS } from 'utils/dataLayer';
// eslint-disable-next-line id-length
import CurrentUserLocationIntlPhoneInput from 'website/containers/CurrentUserLocationIntlPhoneInput';
import withTheme from 'theme/withTheme';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ThemedButton from '../../../../website/components/stateless/ThemedButton';
import Translation from '../../../../website/components/stateless/Translation';
import styles from './styles.scss';

class TextDirections extends React.Component {
  state = {
    phoneNumber: '',
  }

  sendText = async () => {
    const { phoneNumber } = this.state;
    const { facility, postTextDirections, locale } = this.props;
    await postTextDirections(facility.id, phoneNumber, locale);
    document.body.click();
  }

  updatePhone = (_, _2, _3, phoneNumber) => {
    this.setState({ phoneNumber });
  };

  clearPhoneNumber = () => {
    this.setState({ phoneNumber: '' });
  }

  render() {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={this.renderOverlay()}
        rootClose
        onExited={this.clearPhoneNumber}
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }

  renderOverlay() {
    const phoneInputId = 'textMeDirections-phone';
    return (
      <Popover
        id="text-me-directions-popover"
        className="textMeDirectionsPopover"
        style={{ fontFamily: this.props.theme.fonts.primary.name }}
      >
        <button
          type="button"
          className={styles.popoverDismiss}
          onClick={() => document.body.click()}
          aria-label="Dismiss"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className={styles.popoverContent}>
          <div className="form-group">
            <label htmlFor={phoneInputId}>
              <Translation id="enterprise.widgets.nap.yourNumber" defaultMessage="Your phone number" />
            </label>
          </div>
          <CurrentUserLocationIntlPhoneInput
            fieldName={phoneInputId}
            onChange={this.updatePhone}
          />
          <div className="theme">
            <ThemedButton
              context="bare"
              className={styles.sendTextButton}
              type="submit"
              onClick={this.sendText}
              {...TRACK_SUBMIT_SEND_TEXT_SUCCESS}
            >
              <Translation id="enterprise.widgets.nap.sendText" defaultMessage="Send Text" />
            </ThemedButton>
          </div>
        </div>
      </Popover>
    );
  }
}

TextDirections.propTypes = {
  facility: PropTypes.object.isRequired,
  postTextDirections: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.array,
  locale: PropTypes.string,
};

export default withTheme(TextDirections);
