import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@canaveral/components';
import { Grid, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Buttons from './Buttons';
import styles from './styles.scss';

const propTypes = {
  currentStep: (props, propName, componentName) => {
    const steps = (props.steps || {}).reduce((arr, { value }) => {
      arr.push(value);
      return arr;
    }, []);

    if (steps.includes(props[propName])) {
      return false;
    }

    const err = `Invalid value \`${props[propName]}\` supplied to ${componentName} for prop ${propName}.`;
    const requirements = `Must be one of ${steps.join(', ')}.`;
    return new Error(`${err} ${requirements}`);
  },
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  stepComponent: PropTypes.object.isRequired,
  previewComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  modalSize: PropTypes.string,
  nextBtnDisabled: PropTypes.bool,
  futureStepsDisabled: PropTypes.bool,
};

const defaultProps = {
  onDelete: undefined,
  modalSize: 'xl',
  nextBtnDisabled: false,
  futureStepsDisabled: false,
};

const Wizard = ({
  currentStep,
  steps,
  stepComponent,
  previewComponent,
  title,
  show,
  isSubmitting,
  onSelectStep,
  onSave,
  onClose,
  onDelete,
  modalSize,
  nextBtnDisabled,
  futureStepsDisabled,
}) => {
  const leftProps = {
    xs: previewComponent ? 5 : 12,
    md: previewComponent ? 5 : 12,
  };

  return (
    <Modal
      size={modalSize}
      className={styles.wizard}
      show={show}
      onDismiss={onClose}
      aria-labelledby="wizard"
    >
      <Modal.Header>
        <Modal.Title id="wizard">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col {...leftProps}>
              <div className={styles.step}>
                <Breadcrumbs
                  className={styles.breadCrumbContainer}
                  steps={steps}
                  currentStep={currentStep}
                  onClick={onSelectStep}
                  nextBtnDisabled={nextBtnDisabled}
                  futureStepsDisabled={futureStepsDisabled}
                />
                <form onSubmit={onSave}>
                  {stepComponent}
                </form>
              </div>
            </Col>
            {previewComponent && (
              <Col xs={7} md={7}>
                {previewComponent}
              </Col>
            )}
          </Row>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Buttons
          steps={steps}
          currentStep={currentStep}
          onSelectStep={onSelectStep}
          onSave={onSave}
          onDelete={onDelete}
          onCancel={onClose}
          isSubmitting={isSubmitting}
          nextBtnDisabled={nextBtnDisabled}
        />
      </Modal.Footer>
    </Modal>
  );
};

Wizard.propTypes = propTypes;
Wizard.defaultProps = defaultProps;

export default Wizard;
