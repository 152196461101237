export const PRIMARY_COLOR_BACKGROUND_CLASS = 'color-primary-background';
export const PRIMARY_COLOR_BACKGROUND_HOVER_CLASS = 'color-primary-background-hover';
export const PRIMARY_COLOR_BORDER_CLASS = 'color-primary-border';
export const PRIMARY_COLOR_BORDER_HOVER_CLASS = 'color-primary-border-hover';
export const PRIMARY_COLOR_FILL_CLASS = 'color-primary-fill';
export const PRIMARY_COLOR_CLASS = 'color-primary-foreground';
export const PRIMARY_COLOR_HOVER_CLASS = 'color-primary-foreground-hover';

export const SECONDARY_COLOR_BACKGROUND_CLASS = 'color-secondary-background';
export const SECONDARY_COLOR_BACKGROUND_HOVER_CLASS = 'color-secondary-background-hover';
export const SECONDARY_COLOR_BORDER_CLASS = 'color-secondary-border';
export const SECONDARY_COLOR_BORDER_HOVER_CLASS = 'color-secondary-border-hover';
export const SECONDARY_COLOR_FILL_CLASS = 'color-secondary-fill';
export const SECONDARY_COLOR_CLASS = 'color-secondary-foreground';
export const SECONDARY_COLOR_HOVER_CLASS = 'color-secondary-foreground-hover';

export const TERTIARY_COLOR_BACKGROUND_CLASS = 'color-tertiary-background';
export const TERTIARY_COLOR_BACKGROUND_HOVER_CLASS = 'color-tertiary-background-hover';
export const TERTIARY_COLOR_BORDER_CLASS = 'color-tertiary-border';
export const TERTIARY_COLOR_BORDER_HOVER_CLASS = 'color-tertiary-border-hover';
export const TERTIARY_COLOR_FILL_CLASS = 'color-tertiary-fill';
export const TERTIARY_COLOR_CLASS = 'color-tertiary-foreground';
export const TERTIARY_COLOR_HOVER_CLASS = 'color-tertiary-foreground-hover';

export const PRIMARY_FONT_FAMILY_CLASS = 'font-family-primary';
export const SECONDARY_FONT_FAMILY_CLASS = 'font-family-secondary';

export const SECONDARY_BUTTON_CLASS = 'themed-secondary-button';
export const TERTIARY_BUTTON_CLASS = 'themed-tertiary-button';
