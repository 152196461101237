import { query } from 'utils/graphql';

// Actions
const SEND = 'voyager/contact/SEND';
const SEND_SUCCESS = 'voyager/contact/SEND_SUCCESS';
const SEND_FAIL = 'voyager/contact/SEND_FAIL';

// Reducer
const initialState = {
  sending: false,
  sent: false,
  showSuccessMessage: false,
  errorMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEND:
      return {
        ...state,
        sending: true,
        sent: true,
        showSuccessMessage: false,
        errorMessage: '',
      };
    case SEND_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true,
        showSuccessMessage: true,
      };
    case SEND_FAIL:
      return {
        ...state,
        sending: false,
        showSuccessMessage: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    default:
      return state;
  }
}

// Action Creators
export function textDirections(facilityId, phoneNumber, locale) {
  return {
    types: [SEND, SEND_SUCCESS, SEND_FAIL],
    force: true,
    promise: query`
      mutation TextDirections(
        ${{ facilityId }}: String!
        ${{ phoneNumber }}: String!
        ${{ locale }}: String!
      ) {
        textDirections(facilityId: $facilityId, phoneNumber: $phoneNumber, locale: $locale)
      }
    `,
  };
}

export const sending = state => state.contact.sending;

export const sent = state => state.contact.sent;

export const showSuccessMessage = state => state.contact.showSuccessMessage;

export const errorMessage = state => state.contact.errorMessage;
