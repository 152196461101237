export default class User {
  constructor(profile) {
    this.id = profile.id;
    this.name = profile.displayName;
    this.email = profile.email;
    this.companyId = profile.companyId;
    this.hyper = profile.hyper;
    // Some users may still have cancanPermissions in their cached sessions
    // when this code is committed. `profile.cancanPermissions ||` can be
    // safely removed after some time has passed.
    this.permissions = profile.cancanPermissions || profile.permissions;

    // Store the (slightly cleaned up) raw data for serialization later
    const { _raw, _json, ...rest } = profile;
    this.profile = rest;
  }

  can(action, subject) {
    const isAuthorized = subj => this.permissions.some(({ can, actions, subjects }) => (
      can &&
      actions.some(_action => ['manage', action].includes(_action)) &&
      subjects.some(_subject => ['all', subj].includes(_subject))
    ));

    return Array.isArray(subject) ? subject.some(isAuthorized) : isAuthorized(subject);
  }

  cannot(action, subject) {
    return !this.can(action, subject);
  }

  isAuthorized({
    action,
    subject,
    requireHyper,
    facilityId,
    companyId,
  }) {
    if (this.hyper) {
      return true;
    }

    if (requireHyper) {
      return false;
    }

    if (companyId !== this.companyId) {
      return false;
    }

    if (!(action || subject)) {
      return true;
    }

    return this.can(action, subject);
  }

  isAuthenticated() {
    return this.id && this.companyId;
  }

  toObject() {
    return this.profile;
  }
}
