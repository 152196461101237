import { connect } from 'react-redux';
import compose from '../../../../../src/web/utils/react/compose';
import widget from '../../../../../src/web/components/widgets/decorators/widget';
import StorageMartCheckoutConfirmation from './StorageMartCheckoutConfirmation';
import { storableCheckoutConfirmation, getLedgerWithAddons } from '../../../../../src/web/store/modules/movein';
import { getLeadData } from '../../../../../src/web/store/modules/lead';
import { NAME, ID } from './properties';

const component = compose(
  connect(
    (state) => {
      const { movein, lead } = state;
      const {
        loadingStorableConfirmation,
        storableConfirmationData,
        storableConfirmationError,
        loadingStorableLedger,
        storableLedgerData,
        storableLedgerError,
      } = movein;

      const {
        leadLoading,
        leadData,
        leadError,
      } = lead;

      return {
        loadingStorableConfirmation,
        storableConfirmationData,
        storableConfirmationError,
        loadingStorableLedger,
        storableLedgerData,
        storableLedgerError,
        leadLoading,
        leadData,
        leadError,
      };
    },
    {
      storableCheckoutConfirmation,
      getLedgerWithAddons,
      getLeadData,
    },
  ),
  widget(
    { name: NAME, category: 'Checkout', componentId: ID },
    {
      menu: false,
    },
  ),
)(StorageMartCheckoutConfirmation);

export default component;
