import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PSEUDOLOCALIZE, TRANSLATION_ID, TRANSLATION_TEXT } from '../../../../src/web/store/modules/site';
import useCurrentSite from '../../../../modules/react/hooks/useCurrentSite';
import pseudoLocalizeText from '../../../lib/pseudoLocalizeText';
import styles from './Translation.scss';

export const DEFAULT_MESSAGE_FALLBACK = 'Missing translation';
export const MISSING_ID_MESSAGE = 'No ID Provided';

const propTypes = {
  children: PropTypes.func,
  defaultMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  children: undefined,
  defaultMessage: DEFAULT_MESSAGE_FALLBACK,
};

const Translation = ({
  children,
  defaultMessage,
  id,
  ...rest
}) => {
  const isChildFn = typeof children === 'function';
  const { translationTransform } = useCurrentSite();

  if (!id) {
    if (isChildFn) {
      return children(defaultMessage || DEFAULT_MESSAGE_FALLBACK);
    }

    return translationTransform === TRANSLATION_ID
      ? MISSING_ID_MESSAGE : defaultMessage || DEFAULT_MESSAGE_FALLBACK;
  }

  return (
    <FormattedMessage
      id={id.toLowerCase()}
      defaultMessage={defaultMessage}
      {...rest}
    >
      {(...nodes) => {
        switch (translationTransform) {
          case PSEUDOLOCALIZE: {
            const formattedNodes = nodes.map(node => (typeof node === 'string' ? pseudoLocalizeText(node) : node));
            return (
              <React.Fragment>
                {isChildFn ? children(...formattedNodes) : formattedNodes}
              </React.Fragment>
            );
          }
          case TRANSLATION_ID:
            return (
              <div className={styles.rawId}>
                {isChildFn ? children(id.toLowerCase()) : id.toLowerCase()}
              </div>
            );
          case TRANSLATION_TEXT:
          default:
            return (
              <React.Fragment>
                {isChildFn ? children(...nodes) : nodes}
              </React.Fragment>
            );
        }
      }}
    </FormattedMessage>
  );
};

Translation.propTypes = propTypes;
Translation.defaultProps = defaultProps;

export default Translation;
