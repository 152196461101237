import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, string, number } from 'yup';
import { Row, Col, Alert } from 'react-bootstrap';
import get from 'lodash/get';
import SMFormFieldToggle from '../../../../components/stateless/SMFormFieldToggle';
import SMFormFieldPhone from '../../../../components/stateless/SMFormFieldPhone';
import withForm from '../../../../../../admin/hocs/withForm';
import Translation from '../../../../../components/stateless/Translation';
import CheckoutSubmitButton from '../CheckoutSubmitButton';
import CheckoutFormFieldText from '../CheckoutFormFieldText';
import CheckoutFormStateDropdown from '../CheckoutFormStateDropdown';
import {
  TRACK_CLICK_CHECKOUT_STEP_THREE_SUBMIT,
  TRACK_CHECKOUT_STEP,
} from '../../../../../../src/web/utils/dataLayer';
import styles from '../styles.scss';
import { GB } from '../../../../../../src/shared/i18n';

const propTypes = {
  facility: PropTypes.shape().isRequired,
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

const validationSchema = object().shape({
  activeDutyMilitary: string()
    .required('widgets.checkout.activeDutyMilitary.required'),
  commanderFullName: string()
    .when('activeDutyMilitary', {
      is: 'yes',
      then: string().required('widgets.checkout.commanderFullName.required'),
    }),
  unitAddress1: string()
    .when('activeDutyMilitary', {
      is: 'yes',
      then: string().required('widgets.checkout.unitAddress1.required'),
    }),
  unitCity: string()
    .when('activeDutyMilitary', {
      is: 'yes',
      then: string().required('widgets.checkout.unitCity.required'),
    }),
  unitState: string()
    .when(['activeDutyMilitary', 'isFacilityGB'], {
      is: (activeDutyMilitary, isFacilityGB) => (
        activeDutyMilitary === 'yes'
        && !isFacilityGB
      ),
      then: string().required('widgets.checkout.unitState.required'),
    }),
  unitPostal: string()
    .when('activeDutyMilitary', {
      is: 'yes',
      then: string().required('widgets.checkout.unitPostal.required'),
    }),
  addEmergencyContact: string()
    .required('widgets.checkout.addEmergencyContact.required'),
  emergencyContactFirstName: string()
    .when('addEmergencyContact', {
      is: 'yes',
      then: string().required('widgets.checkout.emergencyContactFirstName.required'),
    }),
  emergencyContactLastName: string()
    .when('addEmergencyContact', {
      is: 'yes',
      then: string().required('widgets.checkout.emergencyContactLastName.required'),
    }),
  emergencyContactPhone: string()
    .when('addEmergencyContact', {
      is: 'yes',
      then: string().required('widgets.checkout.emergencyContactPhone.required'),
    }),
  addContact: string()
    .required('widgets.checkout.addContact.required'),
  contactFirstName: string()
    .when('addContact', {
      is: 'yes',
      then: string().required('widgets.checkout.contactFirstName.required'),
    }),
  contactLastName: string()
    .when('addContact', {
      is: 'yes',
      then: string().required('widgets.checkout.contactLastName.required'),
    }),
  storedValue: number()
    .when('isFacilityGB', {
      is: true,
      then: number()
        .typeError('widgets.checkout.storedValue.number')
        .positive('widgets.checkout.storedValue.positive')
        .integer('widgets.checkout.storedValue.wholeNumber')
        .required('widgets.checkout.storedValue.required'),
    }),
});

const initialValues = {
  activeDutyMilitary: '',
  commanderFullName: '',
  unitAddress1: '',
  unitAddress2: '',
  unitCity: '',
  unitState: '',
  unitPostal: '',
  addEmergencyContact: '',
  emergencyContactFirstName: '',
  emergencyContactLastName: '',
  emergencyContactPhone: {
    country: null,
    phoneNumber: '',
    unformattedPhoneNumber: '',
  },
  addContact: '',
  contactFirstName: '',
  contactLastName: '',
  isFacilityGB: false,
  storedValue: '',
};

const AccountDetailsForm = ({
  handleSubmit,
  handleChange,
  submitting,
  formError,
  values,
  facility,
  setFieldValue,
}) => {
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);

  useEffect(() => {
    if (values.activeDutyMilitary === 'no') {
      setFieldValue('commanderFullName', initialValues.commanderFullName);
      setFieldValue('unitAddress1', initialValues.unitAddress1);
      setFieldValue('unitAddress2', initialValues.unitAddress2);
      setFieldValue('unitCity', initialValues.unitCity);
      setFieldValue('unitState', initialValues.unitState);
      setFieldValue('unitPostal', initialValues.unitPostal);
    }
  }, [values.activeDutyMilitary]);

  useEffect(() => {
    if (values.addEmergencyContact === 'no') {
      setFieldValue('emergencyContactFirstName', initialValues.emergencyContactFirstName);
      setFieldValue('emergencyContactLastName', initialValues.emergencyContactLastName);
      setFieldValue('emergencyContactPhone', initialValues.emergencyContactPhone);
    }
  }, [values.addEmergencyContact]);

  useEffect(() => {
    if (values.addContact === 'no') {
      setFieldValue('contactFirstName', initialValues.contactFirstName);
      setFieldValue('contactLastName', initialValues.contactLastName);
    }
  }, [values.addContact]);

  useEffect(() => {
    if (!hasTrackedDataLayer && facility) {
      setHasTrackedDataLayer(true);
      TRACK_CHECKOUT_STEP(get(facility, 'unitGroup'), facility, 'new_booking_flow_step3', 'account details');
    }
  }, [facility, hasTrackedDataLayer]);

  return (
    <form
      onSubmit={handleSubmit}
    >
      {
        formError && (
          <Alert bsStyle="danger">
            <Translation
              id="widgets.checkout.formError"
              defaultMessage="An error was encountered when submitting the form. Please try again."
            />
          </Alert>
        )
      }
      <Row>
        <Col xs={12} md={8}>
          <h5>
            <Translation
              id="widgets.checkout.activeMilitary"
              defaultMessage="Are you active-duty military?"
            />
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="activeDutyMilitary"
            value="yes"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.yes"
                  defaultMessage="Yes"
                />
              </span>
            )}
          />
        </Col>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="activeDutyMilitary"
            value="no"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.no"
                  defaultMessage="No"
                />
              </span>
            )}
          />
        </Col>
      </Row>
      {
        values.activeDutyMilitary === 'yes' && (
          <>
            <Row>
              <Col xs={12} md={8}>
                <CheckoutFormFieldText
                  required
                  name="commanderFullName"
                  label={{
                    defaultMessage: 'Commander\'s Full Name',
                    id: 'widgets.checkout.commandersFullName',
                  }}
                />
                <CheckoutFormFieldText
                  required
                  name="unitAddress1"
                  label={{
                    defaultMessage: 'Unit Address',
                    id: 'widgets.checkout.unitAddress1',
                  }}
                />
                <CheckoutFormFieldText
                  name="unitAddress2"
                  label={{
                    defaultMessage: 'Bldg/Apt/Suite (Optional)',
                    id: 'widgets.checkout.unitAddress2',
                  }}
                />
                <CheckoutFormFieldText
                  required
                  name="unitCity"
                  label={{
                    defaultMessage: 'City',
                    id: 'widgets.checkout.unitCity',
                  }}
                />
              </Col>
            </Row>
            <Row>
              {get(facility, 'address.country') !== GB ? (
                <Col xs={12} md={4}>
                  <CheckoutFormStateDropdown
                    label={{
                      defaultMessage: 'State',
                      id: 'widgets.checkout.unitState',
                    }}
                    required
                    name="unitState"
                    country={get(facility, 'address.country')}
                    onChange={handleChange}
                    emptyOption={{
                      defaultMessage: 'Select State',
                      id: 'widgets.checkout.stateDefault',
                    }}
                  />
                </Col>
              ) : null}
              <Col xs={12} md={4}>
                <CheckoutFormFieldText
                  required
                  name="unitPostal"
                  label={{
                    defaultMessage: 'Zip Code',
                    id: 'widgets.checkout.unitPostal',
                  }}
                />
              </Col>
            </Row>
          </>
        )
      }
      <Row>
        <Col xs={12} md={8}>
          <h5>
            <Translation
              id="widgets.checkout.emergencyContact"
              defaultMessage="Would you like to assign an emergency contact?"
            />
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="addEmergencyContact"
            value="yes"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.yes"
                  defaultMessage="Yes"
                />
              </span>
            )}
          />
        </Col>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="addEmergencyContact"
            value="no"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.no"
                  defaultMessage="No"
                />
              </span>
            )}
          />
        </Col>
      </Row>
      {
        values.addEmergencyContact === 'yes' && (
          <Row>
            <Col xs={12} md={8}>
              <CheckoutFormFieldText
                required
                name="emergencyContactFirstName"
                label={{
                  defaultMessage: 'First Name',
                  id: 'widgets.checkout.emergencyContactFirstName',
                }}
              />
              <CheckoutFormFieldText
                required
                name="emergencyContactLastName"
                label={{
                  defaultMessage: 'Last Name',
                  id: 'widgets.checkout.emergencyContactLastName',
                }}
              />
              <SMFormFieldPhone
                required
                name="emergencyContactPhone"
                label={{
                  defaultMessage: 'Phone Number',
                  id: 'widgets.checkout.emergencyContactPhone',
                }}
                help={{
                  id: 'widgets.checkout.emergencyContactPhone.help',
                  defaultMessage: 'We will only contact them during emergencies',
                }}
              />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col xs={12} md={8}>
          <h5>
            <Translation
              id="widgets.checkout.additionalContact"
              defaultMessage="Would you like to authorize others to access your unit?"
            />
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="addContact"
            value="yes"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.yes"
                  defaultMessage="Yes"
                />
              </span>
            )}
          />
        </Col>
        <Col xs={3} md={3}>
          <SMFormFieldToggle
            type="radio"
            name="addContact"
            value="no"
            label={(
              <span className={styles.formFieldToggleLabel}>
                <Translation
                  id="widgets.checkout.no"
                  defaultMessage="No"
                />
              </span>
            )}
          />
        </Col>
      </Row>
      {
        values.addContact === 'yes' && (
          <Row>
            <Col xs={12} md={8}>
              <CheckoutFormFieldText
                required
                name="contactFirstName"
                label={{
                  defaultMessage: 'First Name',
                  id: 'widgets.checkout.contactFirstName',
                }}
              />
              <CheckoutFormFieldText
                required
                name="contactLastName"
                label={{
                  defaultMessage: 'Last Name',
                  id: 'widgets.checkout.contactLastName',
                }}
              />
            </Col>
          </Row>
        )
      }
      {get(facility, 'address.country') === GB ? (
        <Row>
          <Col xs={12} md={8}>
            <CheckoutFormFieldText
              required
              name="storedValue"
              label={{
                id: 'widgets.checkout.storedValue',
                defaultMessage: 'Please provide an approximate total value for the items you intend to store:',
              }}
              placeholder="00"
              prefixAddOn={get(facility, 'currencySymbol')}
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={12} md={4}>
          <CheckoutSubmitButton
            submitLabel={{
              id: 'widgets.checkout.saveButton.submit',
              defaultMessage: 'Save and Continue',
            }}
            submitting={submitting}
            submittingLabel={{
              id: 'widgets.checkout.saveButton.submitting',
              defaultMessage: 'Saving...',
            }}
            {...TRACK_CLICK_CHECKOUT_STEP_THREE_SUBMIT}
          />
        </Col>
      </Row>
    </form>
  );
};

AccountDetailsForm.propTypes = propTypes;
AccountDetailsForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: (props) => {
    const formattedInitialValues = initialValues;

    if (props.formData) {
      const {
        maxStep,
        currentStep,
      } = props;
      const {
        commanderFullName,
        unitAddress,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactPhone,
        contactFirstName,
        contactLastName,
        activeDutyMilitary,
      } = props.formData.tenant;
      if (currentStep === maxStep && !activeDutyMilitary) {
        formattedInitialValues.activeDutyMilitary = null;
      } else {
        formattedInitialValues.activeDutyMilitary = activeDutyMilitary ? 'yes' : 'no';
      }
      formattedInitialValues.commanderFullName = commanderFullName || '';
      formattedInitialValues.unitAddress1 = get(unitAddress, 'address1', '');
      formattedInitialValues.unitAddress2 = get(unitAddress, 'address2', '');
      formattedInitialValues.unitCity = get(unitAddress, 'city', '');
      formattedInitialValues.unitState = get(unitAddress, 'state', '');
      formattedInitialValues.unitPostal = get(unitAddress, 'postal', '');
      formattedInitialValues.addEmergencyContact = emergencyContactFirstName ? 'yes' : 'no';
      formattedInitialValues.emergencyContactFirstName = emergencyContactFirstName || '';
      formattedInitialValues.emergencyContactLastName = emergencyContactLastName || '';
      formattedInitialValues.emergencyContactPhone = {
        country: null,
        phoneNumber: emergencyContactPhone,
        unformattedPhoneNumber: emergencyContactPhone,
      };
      formattedInitialValues.addContact = contactFirstName ? 'yes' : 'no';
      formattedInitialValues.contactFirstName = contactFirstName || '';
      formattedInitialValues.contactLastName = contactLastName || '';
      formattedInitialValues.storedValue = (props.formData.storedValue || '').toString();
    }

    formattedInitialValues.isFacilityGB = get(props, 'facility.address.country') === GB;

    return formattedInitialValues;
  },
  validationSchema,
  handleSubmit: (formPayload, formikBag) => (
    formikBag.props.onSubmit(
      formPayload,
      formikBag.props.currentStep,
      formikBag.props.nextStep,
      formikBag.props.scrollToRef,
    )
  ),
})(AccountDetailsForm);
