export const ENGLISH_US = {
  name: 'English (US)',
  nativeName: 'English (US)',
};

export const ENGLISH_UK = {
  name: 'English (UK)',
  nativeName: 'English (UK)',
};

export const SPANISH = {
  name: 'Spanish',
  nativeName: 'Español',
};

export const CANADIAN_FRENCH = {
  name: 'French (Canada)',
  nativeName: 'Français (Canada)',
};

export const CANADIAN_ENGLISH = {
  name: 'English (Canada)',
  nativeName: 'English (Canada)',
};
