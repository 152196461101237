import React from 'react';
import styles from './styles.scss';
import Translation from '../../../../../components/stateless/Translation';

const RequiredFieldsLabel = () => (
  <div className={styles.requiredFieldsLabel}>
    <span className={styles.required}>*</span>
    {' '}
    <Translation
      id="widgets.checkout.requiredFields"
      defaultMessage="Required Fields"
    />
  </div>
);

export default RequiredFieldsLabel;
