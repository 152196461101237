import React from 'react';

export const CHECKOUT_STEPS = {
  CONTACT_INFORMATION: 1,
  UNIT_INFORMATION: 2,
  ACCOUNT_DETAILS: 3,
  PAYMENT: 4,
  COMPLETE: 5,
};

const CurrentStepContext = React.createContext(CHECKOUT_STEPS.CONTACT_INFORMATION);

CurrentStepContext.displayName = 'CurrentStepContext';

export default CurrentStepContext;
