import googleSessionID from '../../../website/lib/googleSessionID';
import { getClientId } from './googleAnalytics';

export default function adCampaignValues() {
  const payload = {
    googleClientId: getClientId(),
  };

  if (window.Storage && localStorage.getItem('utm')) {
    const utmData = JSON.parse(localStorage.getItem('utm'));

    if (new Date() < new Date(utmData.expire)) {
      payload.utmSource = utmData.source;
      payload.utmMedium = utmData.medium;
      payload.utmCampaign = utmData.campaign;
    }
  }

  const ga4SessionID = googleSessionID();
  if (ga4SessionID) {
    payload.googleSessionId = ga4SessionID;
  }

  if (window.Storage && localStorage.getItem('meta-ad-data')) {
    const metaAdData = JSON.parse(localStorage.getItem('meta-ad-data'));

    if (new Date() < new Date(metaAdData.expire)) {
      payload.metaAdSetId = metaAdData.adSetId;
      payload.metaCreativeId = metaAdData.creativeId;
      payload.metaAdId = metaAdData.adId;
    }
  }

  return payload;
}
