import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import StepTitle from '../StepTitle';
import StepSection from '../StepSection';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import AccountDetailsForm from './AccountDetailsForm';
import Translation from '../../../../../components/stateless/Translation';
import styles from '../styles.scss';

const propTypes = {
  facility: PropTypes.shape().isRequired,
};

const defaultProps = {};

const AccountDetails = ({ facility, tenantData }) => {
  const stepContext = useContext(CurrentStepContext);
  const scrollToRef = useRef(null);
  let emergencyContact;
  let authorizedUser;

  if (tenantData) {
    emergencyContact = find(tenantData.contacts, { description: 'Emergency Contact' });
    authorizedUser = find(tenantData.contacts, { description: 'Authorized User' });
  }

  return (
    <div>
      <StepTitle stepNumber={CHECKOUT_STEPS.ACCOUNT_DETAILS}>
        {`${CHECKOUT_STEPS.ACCOUNT_DETAILS}.`}
        {' '}
        <Translation
          id="widgets.checkout.progress.step3"
          defaultMessage="Account Details"
        />
      </StepTitle>
      {
        stepContext.currentStep === CHECKOUT_STEPS.ACCOUNT_DETAILS
        && (
          <StepSection active>
            <AccountDetailsForm
              onSubmit={stepContext.onStepSubmit}
              currentStep={stepContext.currentStep}
              nextStep={CHECKOUT_STEPS.PAYMENT}
              formError={stepContext.formError}
              submitting={stepContext.submitting}
              formData={stepContext.checkoutData}
              maxStep={stepContext.maxStep}
              facility={facility}
              scrollToRef={scrollToRef}
              tenantData={tenantData}
            />
          </StepSection>
        )
      }
      {
        stepContext.currentStep !== CHECKOUT_STEPS.ACCOUNT_DETAILS
        && stepContext.maxStep > CHECKOUT_STEPS.ACCOUNT_DETAILS
        && (
          <StepSection showEditStepButton stepNumber={CHECKOUT_STEPS.ACCOUNT_DETAILS}>
            <h5>
              <Translation
                id="widgets.checkout.activeMilitaryTitle"
                defaultMessage="Active-Duty Military:"
              />
              {' '}
              {get(tenantData, 'isMilitary', false) ? 'Yes' : 'No'}
            </h5>
            {get(tenantData, 'isMilitary', false) ? (
              <>
                <div>
                  {get(tenantData, 'commanderFirstName')}
                  {' '}
                  {get(tenantData, 'commanderLastName')}
                </div>
                <div>{get(tenantData, 'unitAddress')}</div>
                <div>
                  {get(tenantData, 'unitCity')}
                  {', '}
                  {get(tenantData, 'unitState')}
                  {' '}
                  {get(tenantData, 'unitPostal')}
                </div>
              </>
            ) : null}

            <h5>
              <Translation
                id="widgets.checkout.emergencyContactTitle"
                defaultMessage="Emergency Contact:"
              />
              {' '}
              {emergencyContact ? 'Yes' : 'No'}
            </h5>
            <div>
              {get(emergencyContact, 'firstName')}
              {' '}
              {get(emergencyContact, 'lastName')}
            </div>
            <div>{get(emergencyContact, 'phoneNumber')}</div>

            <h5>
              <Translation
                id="widgets.checkout.additionalContactTitle"
                defaultMessage="Authorize Access:"
              />
              {' '}
              {authorizedUser ? 'Yes' : 'No'}
            </h5>
            <div>
              {get(authorizedUser, 'firstName')}
              {' '}
              {get(authorizedUser, 'lastName')}
            </div>

            {get(stepContext, 'checkoutData.storedValue') ? (
              <>
                <h5>
                  <Translation
                    id="widgets.checkout.storedValueTitle"
                    defaultMessage="Approximate Stored Value:"
                  />
                  {' '}
                  {get(facility, 'currencySymbol')}
                  {get(stepContext, 'checkoutData.storedValue')}
                </h5>
              </>
            ) : null}
          </StepSection>
        )
      }
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  );
};

AccountDetails.propTypes = propTypes;
AccountDetails.defaultProps = defaultProps;

export default AccountDetails;
