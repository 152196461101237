// Action
export const SHOW_POPUP = 'voyager/popup/SHOW';
export const HIDE_POPUP = 'voyager/popup/HIDE';

// Reducer
const initialState = {
  showingPopup: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        ...state,
        showingPopup: true,
      };
    case HIDE_POPUP:
      return {
        ...state,
        showingPopup: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const showPopup = () => ({
  type: SHOW_POPUP,
});

export const hidePopup = () => ({
  type: HIDE_POPUP,
});

export const togglePopup = (view) => ({
  type: view ? SHOW_POPUP : HIDE_POPUP,
});
