import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import ApolloQuery from '../../../modules/react/components/stateful/ApolloQuery';
import SMFacilityLinkButton from '../components/stateless/SMFacilityLinkButton';
import SMFormFeedback from '../components/stateless/SMFormFeedback';

const GET_FACILITY = gql`
  query GetSMMoveInUnavailableData($id: ID!) {
    facility(id: $id) {
      id
      ...SMFacilityLinkButtonFacility
    }
  }

  ${SMFacilityLinkButton.fragments.facility}
`;

const propTypes = {
  facilityId: PropTypes.string.isRequired,
};

const defaultProps = {};

const SMMoveInUnavailable = ({
  facilityId,
}) => (
  <ApolloQuery
    query={GET_FACILITY}
    variables={{ id: facilityId }}
  >
    {({ facility }) => (
      <Row>
        <Col xs={12}>
          <SMFormFeedback
            context="danger"
            id="widgets.movein.unitUnavailable.feedback"
            defaultMessage="The current unit group has no more units available"
          />
        </Col>
        <Col sm={6} xs={12}>
          <SMFacilityLinkButton
            facility={facility}
            id="widgets.movein.unitUnavailable.backToFacilityPage"
            defaultMessage="Return to facility page"
          />
        </Col>
      </Row>
    )}
  </ApolloQuery>
);

SMMoveInUnavailable.propTypes = propTypes;
SMMoveInUnavailable.defaultProps = defaultProps;

export default SMMoveInUnavailable;
