import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import style from './ImageProgress.scss';

const ImageProgress = ({ percent }) => (
  <div className={style.progress}>
    <ProgressBar active now={percent} />
  </div>
);

ImageProgress.propTypes = {
  percent: PropTypes.number.isRequired,
};

export default ImageProgress;
