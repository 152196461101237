import React from 'react';
import { FormattedDate } from 'react-intl';

const propTypes = {};

const defaultProps = {};

const Date = ({
  ...rest
}) => (
  <FormattedDate {...rest} />
);

Date.propTypes = propTypes;
Date.defaultProps = defaultProps;

export default Date;
