import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import styles from './styles.scss';
import ItemListItem from './ItemListItem';

const propTypes = {
  inventory: PropTypes.shape().isRequired,
  setInventory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  searchText: PropTypes.string,
};

const defaultProps = {
  searchText: undefined,
};

const ItemList = ({ inventory, setInventory, selectedCategory, searchText }) => {
  const findItemIndex = item => (
    findIndex(inventory, ['id', item.id])
  );

  const addItem = (item) => {
    const updatedInventory = inventory;
    const itemIndex = findItemIndex(item);
    updatedInventory[itemIndex].count += 1;
    setInventory([...updatedInventory]);
  };

  const removeItem = (item) => {
    const updatedInventory = inventory;
    const itemIndex = findItemIndex(item);
    updatedInventory[itemIndex].count = Math.max(0, updatedInventory[itemIndex].count - 1);
    setInventory([...updatedInventory]);
  };

  return (
    <div className={styles.itemList}>
      {
        filter(inventory, (item) => {
          if (selectedCategory === 'search') {
            return item.defaultMessage.toLowerCase().search((searchText || '').toLowerCase()) > -1;
          }

          return item.category === selectedCategory;
        }).map(item => (
          <ItemListItem key={item.name} item={item} addItem={addItem} removeItem={removeItem} />
        ))
      }
    </div>
  );
};

ItemList.propTypes = propTypes;
ItemList.defaultProps = defaultProps;

export default ItemList;
