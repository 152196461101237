import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import StepTitle from '../StepTitle';
import StepSection from '../StepSection';
import CurrentStepContext, { CHECKOUT_STEPS } from '../CurrentStepContext';
import ContactInformationForm from './ContactInformationForm';
import Translation from '../../../../../components/stateless/Translation';
import styles from '../styles.scss';

const propTypes = {
  facility: PropTypes.shape().isRequired,
};

const defaultProps = {};

const ContactInformation = ({ facility }) => {
  const stepContext = useContext(CurrentStepContext);
  const scrollToRef = useRef(null);

  return (
    <div>
      <StepTitle stepNumber={CHECKOUT_STEPS.CONTACT_INFORMATION}>
        {`${CHECKOUT_STEPS.CONTACT_INFORMATION}.`}
        {' '}
        <Translation
          id="widgets.checkout.progress.step1"
          defaultMessage="Contact Information"
        />
      </StepTitle>
      {
        stepContext.currentStep === CHECKOUT_STEPS.CONTACT_INFORMATION
        && (
          <StepSection active>
            <ContactInformationForm
              onSubmit={stepContext.onStepSubmit}
              currentStep={stepContext.currentStep}
              nextStep={CHECKOUT_STEPS.UNIT_INFORMATION}
              formError={stepContext.formError}
              submitting={stepContext.submitting}
              formData={stepContext.checkoutData}
              isReservation={stepContext.isReservation}
              facility={facility}
              scrollToRef={scrollToRef}
            />
          </StepSection>
        )
      }
      {
        stepContext.currentStep !== CHECKOUT_STEPS.CONTACT_INFORMATION
        && stepContext.maxStep > CHECKOUT_STEPS.CONTACT_INFORMATION
        && (
          <StepSection showEditStepButton stepNumber={CHECKOUT_STEPS.CONTACT_INFORMATION}>
            <h5>
              { get(stepContext.checkoutData, 'tenant.firstName') }
              {' '}
              { get(stepContext.checkoutData, 'tenant.lastName') }
            </h5>
            <div>{ get(stepContext.checkoutData, 'tenant.email') }</div>
            <div>{ get(stepContext.checkoutData, 'tenant.phone') }</div>
            <h5>
              <Translation
                id="widgets.checkout.textNotifications.title"
                defaultMessage="Text Notifications:"
              />
              {' '}
              { get(stepContext.checkoutData, 'tenant.smsOptIn')
                ? (
                  <Translation
                    id="widgets.checkout.yes"
                    defaultMessage="Yes"
                  />
                )
                : (
                  <Translation
                    id="widgets.checkout.no"
                    defaultMessage="No"
                  />
                )
              }
            </h5>
            { !stepContext.isReservation && (
                <>
                  <h5>
                    <Translation
                      id="widgets.checkout.mailingAddressTitle"
                      defaultMessage="Mailing Address"
                    />
                  </h5>
                  <div>{ get(stepContext.checkoutData, 'tenant.address1') }</div>
                  <div>{ get(stepContext.checkoutData, 'tenant.address2') }</div>
                  <div>
                    { get(stepContext.checkoutData, 'tenant.city') }
                    {', '}
                    { get(stepContext.checkoutData, 'tenant.state') }
                    {' '}
                    { get(stepContext.checkoutData, 'tenant.postal') }
                  </div>
                </>
            )}
          </StepSection>
        )
      }
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  );
};

ContactInformation.propTypes = propTypes;
ContactInformation.defaultProps = defaultProps;

export default ContactInformation;
