import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class Sortable extends React.Component {
  static propTypes = {
    root: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object,
    children: PropTypes.node,
  };

  static defaultProps = {
    root: 'ul',
    options: {},
  };

  state = {
    freeze: false,
  };

  componentDidMount() {
    const SortableJS = require('sortablejs'); // eslint-disable-line global-require
    const { options: defaults, onChange } = this.props;
    const element = ReactDOM.findDOMNode(this);
    let sortable;

    const options = {
      onMove: () => {
        this.freeze();
      },
      onSort: () => {
        this.freeze();

        if (onChange) {
          onChange(sortable.toArray());
        }
      },
      ...defaults,
    };

    sortable = SortableJS.create(element, options);
  }

  shouldComponentUpdate(newProps, newState) {
    // Note: Sortablejs moves DOM elements, and React gets very unhappy if it can't find
    // elements where it expects them. This "hack" prevents React from getting angry when
    // Sortablejs moves the elements
    return !this.state.freeze && !newState.freeze;
  }

  freeze() {
    // Prevent React from making further changes at this point, see `shouldComponentUpdate()`
    if (!this.state.freeze) {
      this.setState({ freeze: true });
    }
  }

  render() {
    const {
      root, children, onChange, options, ...props
    } = this.props; // eslint-disable-line no-unused-vars

    return React.createElement(root, props, children);
  }
}
