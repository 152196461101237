import filter from 'lodash/filter';
import first from 'lodash/first';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import find from 'lodash/find';

const SIZES = {
  closet: 'Closet',
  fiveXFive: '5x5',
  fiveXTen: '5x10',
  fiveXFifteen: '5x15',
  tenXTen: '10x10',
  tenXFifteen: '10x15',
  tenXTwenty: '10x20',
  tenXTwentyFive: '10x25',
  tenXThirty: '10x30',
  call: 'Call',
};

function category(categoryName, items) {
  return items.map(item => ({
    ...item,
    category: categoryName,
  }));
}

function define(translationId, defaultMessage, size, minSize = SIZES.fiveXFive) {
  return { translationId, defaultMessage, size, count: 0, minSize };
}

function defineSize(id, area, volume, translationId, translationDefaultMessage, volumePercentage) {
  return { id, area, volume, translationId, translationDefaultMessage, volumePercentage };
}

export const categories = {
  appliances: 'Appliances',
  automotive: 'Automotive',
  bedroomFurniture: 'Bedroom Furniture',
  boxesAndTotes: 'Boxes & Totes',
  electronics: 'Electronics',
  homeGoods: 'Home Goods',
  kitchenAndDining: 'Kitchen & Dining',
  livingRoomFurniture: 'Living Room Furniture',
  musicalInstruments: 'Musical Instruments',
  officeFurniture: 'Office Furniture',
  outdoorLawnCare: 'Outdoor & Lawn Care',
  sportingAndFitness: 'Sporting & Fitness',
};

export const items = [
  ...category('appliances', [
    define('airconditioner', 'Air Conditioner', 15, SIZES.closet),
    define('dehumidifier', 'Dehumidifier', 6),
    define('dishwasher', 'Dishwasher', 20),
    define('dryer', 'Dryer', 25),
    define('refrigeratorsmall', 'Refrigerator: 7-10 cu. ft.', 30),
    define('refrigeratormedium', 'Refrigerator: 11-15 cu. ft.', 45),
    define('refrigeratorlarge', 'Refrigerator: 16-25 cu. ft.', 65),
    define('stovesmall', 'Stove: 20', 10),
    define('stovemedium', 'Stove: 30', 15),
    define('stovelarge', 'Stove: 36', 18),
    define('washingmachine', 'Washing Machine', 25),
    define('microwave', 'Microwave', 4, SIZES.closet),
    define('freezerupright', 'Freezer, upright', 10),
    define('freezerchest', 'Freezer, chest', 10),
  ]),

  ...category('automotive', [
    define('motorcycle', 'Motorcycle', 96),
    define('seado', 'Seado', 140),
  ]),

  ...category('bedroomFurniture', [
    define('armoire', 'Armoire', 40),
    define('mattressbunk', 'Mattress, bunk', 70),
    define('mattressdouble', 'Mattress, double', 60, SIZES.fiveXTen),
    define('mattresstwin', 'Mattress, twin', 35),
    define('mattressfull', 'Mattress, full', 45),
    define('mattressking', 'Mattress, king', 70, SIZES.fiveXTen),
    define('mattressqueen', 'Mattress, queen', 65, SIZES.fiveXTen),
    define('bedframerollaway', 'Bedframe rollaway', 20),
    define('headboardtwin', 'Headboard, twin', 10),
    define('headboarddouble', 'Headboard, double', 10),
    define('headboardfull', 'Headboard, full', 10),
    define('headboardqueen', 'Headboard, queen', 10),
    define('headboardking', 'Headboard, king', 10),
    define('standingmirror', 'Standing mirror', 10),
    define('bureau', 'Bureau', 12),
    define('chestcedar', 'Chest: cedar', 15),
    define('crib', 'Crib', 10),
    define('dressersingle', 'Dresser, single', 30),
    define('dresserdouble', 'Dresser, double', 40),
    define('nightstand', 'Nightstand', 5, SIZES.closet),
  ]),

  ...category('boxesAndTotes', [
    define('boxlegaltote', 'Box, legal tote', 3, SIZES.closet),
    define('boxsmall', 'Box, small', 2, SIZES.closet),
    define('boxmedium', 'Box, medium', 4, SIZES.closet),
    define('boxlarge', 'Box, large', 5, SIZES.closet),
    define('boxextralarge', 'Box, extra large', 6, SIZES.closet),
    define('boxwardrobe', 'Box, wardrobe', 6),
    define('plastictotesmall', 'Plastic tote with lid, small', 2, SIZES.closet),
    define('plastictotemedium', 'Plastic tote with lid, medium', 4, SIZES.closet),
    define('plastictotelarge', 'Plastic tote with lid, large', 5, SIZES.closet),
    define('boxunderbed', 'Box, under bed', 16),
  ]),

  ...category('electronics', [
    define('stereoconsole', 'Stereo, console', 15),
    define('computermonitor', 'Computer monitor', 3, SIZES.closet),
    define('computerconsole', 'Computer console', 1, SIZES.closet),
    define('laptop', 'Laptop', 1, SIZES.closet),
    define('tv42', 'TV <42"', 7, SIZES.closet),
    define('tv4250', 'TV, 42-50"', 7),
    define('tv5060', 'TV, 50-60"', 8),
    define('tv6075', 'TV, 60-75"', 9),
    define('tv75', 'TV >75"', 10),
  ]),

  ...category('homeGoods', [
    define('sewingmachine', 'Sewing machine', 4, SIZES.closet),
    define('firesafe', 'Fire safe / lockbox', 3, SIZES.closet),
    define('fireplaceequipment', 'Fireplace equipment', 5, SIZES.closet),
    define('lampfloor', 'Lamp - floor', 6),
    define('magazinerack', 'Magazine rack', 2, SIZES.closet),
    define('ironingboard', 'Ironing Board', 3),
    define('vacuumcleaner', 'Vacuum cleaner', 5, SIZES.closet),
    define('stroller', 'Stroller', 4, SIZES.closet),
    define('fan', 'Fan', 5, SIZES.closet),
    define('hamperclothes', 'Hamper, clothes', 5),
    define('ruglarge', 'Rug: large', 15),
    define('rugsmall', 'Rug: small', 5, SIZES.closet),
    define('suitcase', 'Suitcase', 5, SIZES.closet),
    define('trunk', 'Trunk', 5, SIZES.closet),
  ]),

  ...category('kitchenAndDining', [
    define('chinacabinet', 'China cabinet', 10),
    define('kitchenisland', 'Kitchen island', 18),
    define('curio', 'Curio', 5),
    define('buffetbase', 'Buffet, base', 30),
    define('buffethutchtop', 'Buffet, hutch top', 25),
    define('tabledining', 'Table, dining', 30),
    define('chairdining', 'Chair, dining', 5, SIZES.closet),
    define('table', 'Table', 12),
    define('barstool', 'Barstool', 4, SIZES.closet),
    define('server', 'Server', 15),
    define('cabinet', 'Cabinet', 10),
  ]),

  ...category('livingRoomFurniture', [
    define('tableend', 'Table, end', 5, SIZES.closet),
    define('tablecoffee', 'Table, coffee', 4),
    define('tvstand', 'TV stand', 15),
    define('cabinetcorner', 'Cabinet, corner', 20),
    define('ottoman', 'Ottoman', 6, SIZES.closet),
    define('futon', 'Futon', 19),
    define('chairarm', 'Chair, arm', 10),
    define('chairoccasional', 'Chair occasional', 15),
    define('chairoverstuffed', 'Chair overstuffed', 25),
    define('chairrocking', 'Chair rocking', 15),
    define('sofa3cushion', 'Sofa, 3 cushion', 50),
    define('sofa4cushion', 'Sofa, 4 cushion', 65),
    define('sofasectional', 'Sofa, sectional/pc', 30),
    define('sofaloveseat', 'Sofa, loveseat', 35),
  ]),

  ...category('musicalInstruments', [
    define('pianobabygrand', 'Piano: baby grand', 75, SIZES.fiveXTen),
    define('pianoupright', 'Piano: upright', 60, SIZES.fiveXTen),
    define('musicalinstruments', 'Musical instruments', 6),
  ]),

  ...category('officeFurniture', [
    define('desksmall', 'Desk, small', 22),
    define('bookshelf', 'Bookshelf', 5),
    define('filecabinet2drawer', 'File cabinet, 2 drawer', 10),
    define('filecabinet4drawer', 'File cabinet, 4 drawer', 20),
  ]),

  ...category('outdoorLawnCare', [
    define('lawnmowerpush', 'Lawn mower, push', 35),
    define('lawnmowerriding', 'Lawn mower, riding', 50),
    define('lawntrimmer', 'Lawn trimmer', 4),
    define('patioumbrella', 'Patio umbrella', 4),
    define('firepit', 'Fire pit', 5, SIZES.closet),
    define('grillsmokerlarge', 'Grill or smoker, large', 25),
    define('grillsmokersmall', 'Grill or smoker, small', 10),
    define('patiotable', 'Patio table', 10),
    define('patiochair', 'Patio chair', 6),
    define('patiochaiselounge', 'Patio chaise lounge', 35),
    define('picnictable', 'Picnic table', 75),
    define('picnicbench', 'Picnic bench', 9),
  ]),

  ...category('sportingAndFitness', [
    define('bicycle', 'Bicycle', 6),
    define('golfclubs', 'Golf clubs & bag', 4, SIZES.closet),
    define('pingpongtable', 'Ping pong table', 30),
    define('sled', 'Sled', 8),
    define('airhockeytable', 'Air hockey table', 120),
    define('pooltable', 'Pool table', 120),
    define('snowboard', 'Snowboard', 12),
    define('skis', 'Skis', 3),
    define('kayak', 'Kayak / canoe', 40),
    define('treadmill', 'Treadmill', 50),
    define('basketballhoop', 'Basketball hoop', 16),
    define('cardtable', 'Card table', 2, SIZES.closet),
    define('gametable', 'Game table', 15),
    define('homegym', 'Home gym system', 180),
  ]),

].map((item, index) => ({
  ...item,
  // We'll add the current index as a simple way to identify these items
  id: index.toString(),
}));

/* eslint-disable max-len */
export const unitSizes = [
  defineSize(
    SIZES.closet,
    16,
    64,
    'storagecalculator.suggestedsize.closet',
    'Closet Description',
    0.8,
  ),
  defineSize(
    SIZES.fiveXFive,
    25,
    200,
    'storagecalculator.suggestedsize.5x5',
    '5x5 Description',
  ),
  defineSize(
    SIZES.fiveXTen,
    50,
    400,
    'storagecalculator.suggestedsize.5x10',
    '5x10 Description',
  ),
  defineSize(
    SIZES.fiveXFifteen,
    75,
    600,
    'storagecalculator.suggestedsize.5x15',
    '5x15 Description',
  ),
  defineSize(
    SIZES.tenXTen,
    100,
    800,
    'storagecalculator.suggestedsize.10x10',
    '10x10 Description',
  ),
  defineSize(
    SIZES.tenXFifteen,
    150,
    1200,
    'storagecalculator.suggestedsize.10x15',
    '10x15 Description',
  ),
  defineSize(
    SIZES.tenXTwenty,
    200,
    1600,
    'storagecalculator.suggestedsize.10x20',
    '10x20 Description',
  ),
  defineSize(
    SIZES.tenXTwentyFive,
    250,
    2000,
    'storagecalculator.suggestedsize.10x25',
    '10x25 Description',
  ),
  defineSize(
    SIZES.tenXThirty,
    300,
    2400,
    'storagecalculator.suggestedsize.10x30',
    '10x30 Description',
  ),
  defineSize(
    SIZES.call,
    100000000,
    100000000,
    'storagecalculator.suggestedsize.call',
    'Call us for details',
  ),
].map(({ volume, volumePercentage, ...unitSize }) => ({
  ...unitSize,
  // We don't want to fill these units passed 60% volume for now. This logic may need to be
  // tweaked later because we're doing stupid-simple volumetric comparisons and furniture
  // is not a liquid.
  volume: volume * (volumePercentage || 0.6),
}));
/* eslint-enable max-len */

export const determineSize = (inventorySize, inventory) => {
  if (inventorySize === 0) {
    return {};
  }

  let suggestedSize = first(filter(unitSizes, size => (
    size.volume > inventorySize
  )));

  const minSizes = uniq(map(inventory.filter(item => item.count > 0), item => item.minSize));
  minSizes.forEach((minSizeId) => {
    const minSize = find(unitSizes, { id: minSizeId });

    if (minSize.volume > suggestedSize.volume) {
      suggestedSize = minSize;
    }
  });

  suggestedSize.percentageFilled = inventorySize / suggestedSize.volume;

  return suggestedSize;
};
