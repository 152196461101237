import React from 'react';

export const defaultValue = {
  loading: false,
  loaded: false,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const CurrentBuilderPageContext = React.createContext(defaultValue);

CurrentBuilderPageContext.displayName = 'CurrentBuilderPageContext';

export default CurrentBuilderPageContext;
