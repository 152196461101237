const googleSessionID = () => {
  const pattern = /_ga_S2KKF7NYHJ=GS\d\.\d\.(.+?)(?:;|$)/;
  const match = document.cookie.match(pattern);
  const parts = match?.[1].split('.');

  if (!parts) {
    return false;
  }

  const [sessionId] = parts;

  return sessionId;
};

export default googleSessionID;
