export const NAME = 'Nearby Locations';

export const OPTION_NEAR_FACILITY = 'facilityDistance';
export const OPTION_NEAR_FACILITY_GROUP = 'facilityGroup';
export const OPTION_NEAR_CURRENT_USER = 'userDistance';

export const OPTION_DEFAULT = OPTION_NEAR_CURRENT_USER;

export const FACILITY_DIST = 10;
export const HEADER_TEXT = NAME;

export const ID = 'b11da676-4f2d-4bfa-b857-4e8d647930de';
