import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import RequiredFieldsLabel from '../RequiredFieldsLabel';
import EditStepButton from '../EditStepButton';

const propTypes = {
  active: PropTypes.bool,
  showRequiredFields: PropTypes.bool,
  showEditStepButton: PropTypes.bool,
  stepNumber: PropTypes.number.isRequired,
};

const defaultProps = {
  active: false,
  showRequiredFields: true,
  showEditStepButton: false,
};

const StepSection = ({ children, stepNumber, active, showRequiredFields, showEditStepButton }) => (
  <div className={`${active ? 'bg-white' : 'bg-[#fbfaf9]'} ${styles.stepSection} rounded-md shadow-md border border-gray-200 p-4 mb-12 relative text-[#42423D]`}>
    <EditStepButton stepNumber={stepNumber} showOnDesktop={showEditStepButton} />
    {
      showRequiredFields && !showEditStepButton && (
        <div className="bg-[#E8F7F7] rounded-t-md border-b border-[#007A7C] m-[-1em] mb-4 p-2 lg:hidden">
          <RequiredFieldsLabel />
        </div>
      )
    }
    {children}
  </div>
);

StepSection.propTypes = propTypes;
StepSection.defaultProps = defaultProps;

export default StepSection;
