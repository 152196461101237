import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'utils/react';
import Address from '../../../../website/components/stateless/Address';
import Phone from '../../../../website/components/stateless/Phone';

const FacilityInfo = ({ facility, showPhone = true, className }) => (
  <div className={`facility-info ${className}`}>
    <div className="name color-primary-foreground">
      {facility.name}
    </div>
    <Address className="address" {...facility.address} />
    {!showPhone ?
      false :
      <Phone
        number={facility.phone}
        element="div"
        className="phone"
      />
    }
  </div>
);
FacilityInfo.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  showPhone: PropTypes.bool,
  className: PropTypes.string,
};

export default pure()(FacilityInfo);
