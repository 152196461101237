import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';

const isDevelopment = process.env.NODE_ENV === 'development';

const propTypes = {
  advanced: PropTypes.bool,
  children: PropTypes.func.isRequired,
  errorIndicator: PropTypes.node,
  loadingIndicator: PropTypes.node,
};

const defaultProps = {
  advanced: undefined,
  errorIndicator: undefined,
  loadingIndicator: undefined,
};

const ApolloQuery = ({
  advanced,
  children,
  errorIndicator,
  loadingIndicator,
  ...rest
}) => {
  if (advanced) {
    return (
      <Query {...rest}>
        {children}
      </Query>
    );
  }

  return (
    <Query {...rest}>
      {({ loading, error, data }) => {
        if (loading) {
          return loadingIndicator || null;
        }

        if (error) {
          if (isDevelopment) {
            console.log(error); // eslint-disable-line
          }

          return errorIndicator || null;
        }

        return children(data);
      }}
    </Query>
  );
};

ApolloQuery.propTypes = propTypes;
ApolloQuery.defaultProps = defaultProps;

export default ApolloQuery;
