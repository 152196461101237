import { query } from 'utils/graphql';
import get from 'lodash/get';

// Action
export const LOAD = 'voyager/page/LOAD';
export const LOAD_SUCCESS = 'voyager/page/LOAD_SUCCESS';
export const LOAD_FAIL = 'voyager/page/LOAD_FAIL';

// Reducer
const initialState = {
  loading: false,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ...action.data.page,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

// Selectors
export const getPageFacility = state => (
  state.page.facility
);

export const getPageAlternativeLocales = state => (
  state.page.alternativeLocales
);

export const getPageLocale = state => (
  state.page.locale
);

export const getPageType = state => (
  state.page && state.page.metadata.type
);

export const isLoaded = (state, id) => (
  getId(state) === id && loaded(state)
);

export const isLoadedWithTypeAndLocale = (state, type, locale) => (
  getType(state) === type && getLocale(state) === locale && loaded(state)
);

export const getAvailableLanguages = (state) => {
  const languages = state.page && state.page.availableLanguages ?
    get(state, 'page.availableLanguages') :
    get(state, 'pageBuilder.availableLanguages');

  return languages && languages.sort(({ locale: a }, { locale: b }) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }

    return 0;
  });
};

export const onlyCurrentLangAvailable = (state) => {
  const languages = state.page && state.page.availableLanguages ?
    get(state, 'page.availableLanguages') :
    get(state, 'pageBuilder.availableLanguages');

  const locale = state.page && state.page.locale ?
    get(state, 'page.locale') :
    get(state, 'pageBuilder.locale');

  return languages && languages.length === 1 && languages[0].locale === locale;
};

export const getId = state => (
  state.page.id
);

export const getType = state => (
  state.page.metadata && state.page.metadata.type
);

export const getLocale = state => (
  state.page.locale
);

export const loaded = state => (
  state.page.loaded
);

export const getVersion = state => (
  state.page && state.page.publishedVersion
);

export const getPath = state => (
  state.page && state.page.path
);

// Fragments

const VersionFields = `
  fragment VersionFields on PageVersion {
    id
    sections {
      id
      type
      data
      payload
      widgets {
        id
        type
        payload
        componentId
      }
    }
    styles
  }
`;

const VersionV2Fields = `
  fragment VersionV2Fields on Version {
    id
    sections {
      id
      type
      data
      payload
      widgets {
        id
        type
        payload
        componentId
      }
    }
  }
`;

const PageFields = `
  fragment PageFields on Page {
    id
    parent
    companyId
    siteId
    facilityId
    facility {
      storeNumber
      primaryColor
      secondaryColor
      tertiaryColor
      socialMediaPlatforms {
        enabled
        link
        provider
      }
      publicLogo {
        medium {
          id
          filename
          url
          placeholder
          srcSet
          sourceWidth
          sourceHeight
          trash
          defaultMetadata {
            title
            altText
          }
          metadata {
            title
            altText
          }
        }
        alt
        link {
          href
          target
        }
        path
        title
        url
      }
      settings {
        softwareProvider
      }
    }
    metadata {
      description
      linkOut
      slug
      title
      internalPageName
      titlePostfix
      type
      noIndex
      customUrl
      metaTags {
        content
        property
      }
      canonical
    }
    layout {
      id
      publishedVersion {
        ...VersionV2Fields
      }
    }
    locale
    siblings
    availableLanguages {
      locale
      path
    }
    alternativeLocales {
      locale
      path
    }
    boilerplate
    live
    disableEnUsPage
    path
    publishedVersion {
      ...VersionFields
    }
  }

  ${VersionFields}
  ${VersionV2Fields}
`;

// Action Creators

export function load(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    force: true,
    promise: query`
      query GetPage(${{ id }}: String!) {
        page(id: $id) {
          ...PageFields
        }
      }

      ${PageFields}
    `,
  };
}

export function loadPageWithTypeAndLocale(type, locale) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    type,
    locale,
    force: true,
    promise: query`
      query GetPageWithTypeAndLocale(${{ type }}: String!, ${{ locale }}: String!) {
        page(type: $type, locale: $locale, live: true) {
          ...PageFields
        }
      }

      ${PageFields}
    `,
  };
}
