export const STANDARD_PRICING_TYPE_ID = '2ba072b0-c42c-409e-8ce5-5900900b1fcb';
export const EASIER_PRICING_TYPE_ID = 'ab8ca009-92e6-482c-8e5a-a7e576a913d1';
export const EASIEST_PRICING_TYPE_ID = '094f0ee5-f1dd-4424-ab75-0129e7defef5';

export const FIRST_FULL_MONTH_FREE_ID = '89a8d2ca-8243-4953-8635-b175d7ee139c';
export const SECOND_FULL_MONTH_FREE_ID = 'dab7984e-32a9-4ba6-9cbc-961f287b21bd';

export const sizeMessageProps = {
  closet: { id: 'unit.size.closet', defaultMessage: 'Closet' },
  small: { id: 'unit.size.small', defaultMessage: 'Small' },
  medium: { id: 'unit.size.medium', defaultMessage: 'Medium' },
  large: { id: 'unit.size.large', defaultMessage: 'Large' },
  workshop: { id: 'unit.size.workshop', defaultMessage: 'Workshop' },
  parking: { id: 'unit.size.parking', defaultMessage: 'Parking' },
  vehicle: { id: 'unit.size.parking', defaultMessage: 'Parking' },
  other: { id: 'unit.size.other', defaultMessage: 'Other' },
};

export const errorTokens = {
  default: { id: 'widgets.checkout.formError', defaultMessage: 'An error was encountered when submitting the form. Please try again.' },
  'Unit Type is No Longer Available': {
    id: 'widgets.checkout.formError.unitNotAvailable',
    defaultMessage: 'Unit Type is No Longer Available',
  },
  'Error: Please verify your payment again as there was an internal error that prevented the payment from being taken': {
    id: 'widgets.checkout.formError.verifyPayment',
    defaultMessage: 'Please verify your payment again as there was an internal error that prevented the payment from being taken',
  },
  'Credit Card Invalid': {
    id: 'widgets.checkout.formError.creditCardInvalid',
    defaultMessage: 'The credit card entered is invalid. Please update the card information or try using a different card',
  },
  'Customer is already moved into unit, please contact store manager': {
    id: 'widgets.checkout.formError.customerAlreadyMovedIn',
    defaultMessage: 'The customer is already moved into the selected unit. Please contact the store manager for additional information',
  },
};

export const RENT_MONTH_NAMES = {
  1: { id: 'widgets.rentalsummary.rentlineitem.january', defaultMessage: 'January Rent' },
  2: { id: 'widgets.rentalsummary.rentlineitem.february', defaultMessage: 'February Rent' },
  3: { id: 'widgets.rentalsummary.rentlineitem.march', defaultMessage: 'March Rent' },
  4: { id: 'widgets.rentalsummary.rentlineitem.april', defaultMessage: 'April Rent' },
  5: { id: 'widgets.rentalsummary.rentlineitem.may', defaultMessage: 'May Rent' },
  6: { id: 'widgets.rentalsummary.rentlineitem.june', defaultMessage: 'June Rent' },
  7: { id: 'widgets.rentalsummary.rentlineitem.july', defaultMessage: 'July Rent' },
  8: { id: 'widgets.rentalsummary.rentlineitem.august', defaultMessage: 'August Rent' },
  9: { id: 'widgets.rentalsummary.rentlineitem.september', defaultMessage: 'September Rent' },
  10: { id: 'widgets.rentalsummary.rentlineitem.october', defaultMessage: 'October Rent' },
  11: { id: 'widgets.rentalsummary.rentlineitem.november', defaultMessage: 'November Rent' },
  12: { id: 'widgets.rentalsummary.rentlineitem.december', defaultMessage: 'December Rent' },
};

export const VALUE_COVERAGE_MONTH_NAMES = {
  1: { id: 'widgets.rentalsummary.valuecoveragelineitem.january', defaultMessage: 'January Value Coverage' },
  2: { id: 'widgets.rentalsummary.valuecoveragelineitem.february', defaultMessage: 'February Value Coverage' },
  3: { id: 'widgets.rentalsummary.valuecoveragelineitem.march', defaultMessage: 'March Value Coverage' },
  4: { id: 'widgets.rentalsummary.valuecoveragelineitem.april', defaultMessage: 'April Value Coverage' },
  5: { id: 'widgets.rentalsummary.valuecoveragelineitem.may', defaultMessage: 'May Value Coverage' },
  6: { id: 'widgets.rentalsummary.valuecoveragelineitem.june', defaultMessage: 'June Value Coverage' },
  7: { id: 'widgets.rentalsummary.valuecoveragelineitem.july', defaultMessage: 'July Value Coverage' },
  8: { id: 'widgets.rentalsummary.valuecoveragelineitem.august', defaultMessage: 'August Value Coverage' },
  9: { id: 'widgets.rentalsummary.valuecoveragelineitem.september', defaultMessage: 'September Value Coverage' },
  10: { id: 'widgets.rentalsummary.valuecoveragelineitem.october', defaultMessage: 'October Value Coverage' },
  11: { id: 'widgets.rentalsummary.valuecoveragelineitem.november', defaultMessage: 'November Value Coverage' },
  12: { id: 'widgets.rentalsummary.valuecoveragelineitem.december', defaultMessage: 'December Value Coverage' },
};

export const DATE_FORMAT_LOCALE = 'en-CA';
export const DOOR_QUANTITY_CUSTOM_FIELD = 'Door Quantity';
