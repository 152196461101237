import React from 'react';
import PropTypes from 'prop-types';
import { phone } from '../../../../src/shared/format';

const propTypes = {
  number: PropTypes.string.isRequired,
  element: PropTypes.string,
  locale: PropTypes.string,
};

const defaultProps = {
  element: 'a',
  locale: '',
};

const Phone = ({ number, element, locale, ...props }) => (
  React.createElement(element, props, phone(number, locale))
);

Phone.propTypes = propTypes;
Phone.defaultProps = defaultProps;

export default Phone;
