import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import LegacyAsset from '../../../../../website/components/stateless/Asset';
import useCurrentBuilderPage from '../../../../../admin/hooks/useCurrentBuilderPage';
import useImageLoading from '../../../hooks/useImageLoading';
import getPlaceholderMetadata from '../../../../media/getPlaceholderMetadata';
import styles from './styles.scss';

const propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  medium: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMetadata: PropTypes.shape({
      altText: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    sourceWidth: PropTypes.number.isRequired,
    sourceHeight: PropTypes.number.isRequired,
  }),
  legacyImage: PropTypes.shape({
    file: PropTypes.string,
    alt: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  target: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  withoutWidth: PropTypes.bool,
};

const defaultProps = {
  medium: undefined,
  legacyImage: undefined,
  className: undefined,
  link: undefined,
  onClick: undefined,
  onLoad: undefined,
  target: undefined,
  width: undefined,
  height: undefined,
  withoutWidth: undefined,
};

const MediaGalleryImage = ({
  className,
  medium,
  legacyImage,
  link,
  onClick,
  onLoad,
  target,
  width,
  height,
  withoutWidth,
}) => {
  const { preload, eager, lazy } = useImageLoading();
  const { locale: builderPageLocale, isBuilderView } = useCurrentBuilderPage();
  const { locale: intlProviderLocale } = useIntl();
  const locale = builderPageLocale || intlProviderLocale;

  // Can't find a medium by filename or id, render Asset component
  if (!medium && legacyImage && legacyImage.file) {
    return (
      <LegacyAsset
        file={legacyImage.file}
        alt={legacyImage.alt}
        className={className}
        width={width}
        height={height}
      />
    );
  }

  if (!medium) { return null; }

  const {
    url,
    srcSet,
    trash,
  } = medium;

  if (trash) { return null; }

  const { altText } = getPlaceholderMetadata(medium, locale);

  let image;
  const sharedProps = {
    className,
    onLoad,
    src: url,
    srcSet,
    width: withoutWidth ? undefined : width,
    height,
  };

  if (!isBuilderView) {
    image = (
      <img
        alt={altText}
        loading={(lazy && 'lazy') || (eager && 'eager') || 'auto'}
        decoding={(lazy || eager) ? 'async' : 'auto'}
        {...sharedProps}
      />
    );
  } else {
    image = (
      <img
        src={url}
        srcSet={srcSet}
        alt={altText}
        {...sharedProps}
      />
    );
  }

  const asset = (
    <>
      {typeof window === 'undefined' && preload && (
        <Helmet>
          <link rel="preload" as="image" href={url} imagesrcset={srcSet} />
        </Helmet>
      )}
      {onClick
        ? (
          <button
            onClick={onClick}
            type="button"
            className={styles.imageButton}
          >
            {image}
          </button>
        )
        : image
      }
    </>
  );


  if (link) {
    return (
      <a href={link} target={target}>
        {asset}
      </a>
    );
  }

  return asset;
};

MediaGalleryImage.propTypes = propTypes;
MediaGalleryImage.defaultProps = defaultProps;

export default MediaGalleryImage;
