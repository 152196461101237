import compose from '../../../../../src/web/utils/react/compose';
import widget from '../../../../../src/web/components/widgets/decorators/widget';
import StorageMartCheckoutConfirmation from './StorageMartCheckoutConfirmation';
import { NAME, ID } from './properties';

const component = compose(
  widget(
    { name: NAME, category: 'Checkout', componentId: ID },
    {
      menu: false,
    },
  ),
)(StorageMartCheckoutConfirmation);

export default component;
