import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@canaveral/components';

const propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentStep: PropTypes.string.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  nextBtnDisabled: PropTypes.bool,
};

const defaultProps = {
  onDelete: undefined,
  nextBtnDisabled: false,
};

class Buttons extends React.Component {
  state = { showDeleteConfirmation: false }

  onConfirmDelete = (e) => {
    this.props.onDelete(e);
  }

  showDeleteConfirmation = () => (
    this.setState({ showDeleteConfirmation: true })
  )

  hideDeleteConfirmation = () => (
    this.setState({ showDeleteConfirmation: false })
  )

  render() {
    const { showDeleteConfirmation } = this.state;
    const {
      steps,
      currentStep,
      onSelectStep,
      onSave,
      onDelete,
      onCancel,
      isSubmitting,
      nextBtnDisabled,
    } = this.props;

    const onFirstStep = currentStep === (steps[0] || {}).value;
    const onLastStep = currentStep === (steps[steps.length - 1] || {}).value;

    const currentStepIndex = steps.findIndex(step => step.value === currentStep);
    const previousStep = steps[currentStepIndex - 1];
    const nextStep = steps[currentStepIndex + 1];

    const stepBack = () => onSelectStep(previousStep.value);
    const stepForward = () => onSelectStep(nextStep.value);

    return (
      <span>
        <span className="pull-left">
          {onDelete && (
            showDeleteConfirmation ?
              <span>
                Are you sure?&nbsp;
                <Button
                  size="sm"
                  context="negative"
                  onClick={onDelete}
                  disabled={isSubmitting}
                >
                  Confirm
                </Button>
                <Button
                  context="neutral"
                  size="sm"
                  onClick={this.hideDeleteConfirmation}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </span> :
              <Button
                context="negative"
                size="sm"
                onClick={this.showDeleteConfirmation}
                disabled={isSubmitting}
              >
                Delete
              </Button>
          )}
        </span>
        <Button
          context="neutral"
          size="sm"
          onClick={onFirstStep ? onCancel : stepBack}
          disabled={isSubmitting}
        >
          {onFirstStep ? 'Cancel' : 'Previous'}
        </Button>
        <Button
          context="positive"
          size="sm"
          onClick={onLastStep ? onSave : stepForward}
          disabled={isSubmitting || nextBtnDisabled}
        >
          {onLastStep ? 'Save' : 'Next'}
        </Button>
      </span>
    );
  }
}


Buttons.propTypes = propTypes;
Buttons.defaultProps = defaultProps;

export default Buttons;
