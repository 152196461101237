import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCompanyId } from 'store/modules/company';
import { getUserAuthProfile } from 'store/modules/user';
import User from 'shared/user';

const propTypes = {
  action: PropTypes.string,
  subject: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  requireHyper: PropTypes.bool,
  facilityId: PropTypes.string,
  profile: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape({
      can: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.string).isRequired,
      subjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    })),
  }),
  children: PropTypes.func.isRequired,
};

const defaultProps = {
  action: undefined,
  subject: undefined,
  requireHyper: undefined,
  facilityId: undefined,
  profile: {
    permissions: [],
  },
};

const Authorization = ({
  action, subject, requireHyper, facilityId, profile, children, ...props
}) => {
  const user = new User(profile);
  const { companyId } = props;
  const authorized = user.isAuthorized({
    action, subject, requireHyper, facilityId, companyId,
  });

  return children(authorized, props);
};

Authorization.propTypes = propTypes;
Authorization.defaultProps = defaultProps;

const mapStateToProps = state => ({
  profile: getUserAuthProfile(state),
  companyId: getCompanyId(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Authorization);
