import { connect } from 'react-redux';
import { updatePricing, reviewCost } from '../../../../../src/web/store/modules/movein';
import compose from '../../../../../src/web/utils/react/compose';
import StorageMartCheckout from './StorageMartCheckout';

const component = compose(
  connect(
    (state) => {
      const { movein } = state;
      const {
        moveInDate,
        pricing,
        availableUnit,
      } = movein;

      const availableDates = [];
      if (pricing) {
        pricing.forEach(({ moveInDate: pricingMoveInDate }) => {
          availableDates.push(pricingMoveInDate);
        });
      }

      let discountPlan;
      let receipt;

      if (moveInDate && pricing) {
        const desiredMoveInDateYear = moveInDate.getFullYear();
        const desiredMoveInDateMonth = `0${(moveInDate.getMonth() + 1)}`.slice(-2);
        const desiredMoveInDateDay = `0${moveInDate.getDate()}`.slice(-2);
        const desiredMoveInDate = `${desiredMoveInDateYear}-${desiredMoveInDateMonth}-${desiredMoveInDateDay}`;
        const pricingForDate = pricing.find(option => option.moveInDate === desiredMoveInDate);
        const hasDiscountPlans = pricingForDate
          && pricingForDate.discountPlans.length
          && pricingForDate.discountPlans[0].discountPlanDiscounts.length;

        discountPlan = hasDiscountPlans ? {
          amount: hasDiscountPlans
            ? pricingForDate.discountPlans[0].discountPlanDiscounts[0].amount
            : 0,
        } : undefined;

        receipt = pricingForDate ? {
          creditAmount: pricingForDate.proratedCredit,
          feeAmount: pricingForDate.invoiceableFees.length
            ? pricingForDate.invoiceableFees[0].amount
            : 0,
          rentAmount: pricingForDate.dueFirstMonth,
          rentMonth: moveInDate,
          nextDueDate: pricingForDate.nextDueDate,
          totalAmount: pricingForDate.dueAtMoveIn,
          taxAmount: pricingForDate.taxAmount,
        } : undefined;
      }

      return {
        unitUnavailableError: availableUnit && availableUnit.errorMessage,
        availableDates,
        pricing,
        receipt,
        discountPlan,
      };
    },
    {
      completeReviewCost: reviewCost,
      updateCost: updatePricing,
    },
  ),
)(StorageMartCheckout);

export default component;
