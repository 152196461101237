import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'utils/react';
import { InputGroup, FormControl, HelpBlock } from 'react-bootstrap';

const TextBox = ({
  type,
  label,
  required,
  disabled,
  input,
  addOn,
  prefixAddOn,
  helpText,
}) => (
  <span>
    <label htmlFor={name}>{label}</label>
    <InputGroup>
      {prefixAddOn && <InputGroup.Addon>{prefixAddOn}</InputGroup.Addon>}
      <FormControl
        {...input}
        type="text"
        required={required}
        disabled={disabled}
      />
      {addOn && <InputGroup.Addon>{addOn}</InputGroup.Addon>}
    </InputGroup>
    {helpText && <HelpBlock>{helpText}</HelpBlock>}
  </span>
);

TextBox.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  addOn: PropTypes.string,
  prefixAddOn: PropTypes.string,
  helpText: PropTypes.string,
};

TextBox.defaultProps = {
  type: 'text',
  value: null,
  label: '',
  required: false,
  disabled: false,
  input: {},
  addOn: undefined,
  prefixAddOn: undefined,
  helpText: undefined,
};

export default compose(
  pure(),
)(TextBox);
