import React from 'react';
import LeaseSignatureForm from './LeaseSignatureForm';

const propTypes = {};

const defaultProps = {};

const LeaseSignature = props => (
  <div>
    <LeaseSignatureForm {...props} />
  </div>
);

LeaseSignature.propTypes = propTypes;
LeaseSignature.defaultProps = defaultProps;

export default LeaseSignature;
