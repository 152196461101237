import findKey from 'lodash/findKey';

/**
 * Converts a named color into its color value.
 */
export function fromNamed(colors = {}, color) {
  return colors[color] || color;
}

/**
 * Converts a color value into its named color if one exits. If the color does not match any named color,
 * the color value itself is returned.
 */
export function toNamed(colors = {}, color) {
  return findKey(colors, val => val === color) || color;
}


/**
 * Determines if the background color is dark enough to warrant changing text color for contrast.
 */
export function isBackgroundDark(backgroundColor) {
  if (!backgroundColor) return false;

  const color = backgroundColor.slice(1);

  const r = parseInt(color.substr(0, 2), 16); // eslint-disable-line id-length
  const g = parseInt(color.substr(2, 2), 16); // eslint-disable-line id-length
  const b = parseInt(color.substr(4, 2), 16); // eslint-disable-line id-length

  const coefficient = ((r * 299) + (g * 587) + (b * 114) / 1000);

  return coefficient >= 150;
}
